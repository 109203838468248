import { StyleSheet, Font } from '@react-pdf/renderer';
Font.register({
    family: 'OpenSans',
    fonts: [
        { src: require('assets/fonts/OpenSans-Regular.ttf'), fontWeight: 'normal' },
        { src: require('assets/fonts/OpenSans-Medium.ttf'), fontWeight: 'medium' },
        { src: require('assets/fonts/OpenSans-Bold.ttf'), fontWeight: 'bold' }
    ]
});
export const styles = StyleSheet.create({
    page: {
        padding: '32px'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
        borderBottomWidth: 0.5,
        borderBottomStyle: 'solid',
        borderBottomColor: '#CCCCCC',
        paddingBottom: 12,
        marginBottom: 12
    },
    logo: {
        height: 35
    },
    caption: {
        fontFamily: 'OpenSans',
        fontSize: 10,
        fontWeight: 400,
        color: '#6F6F6F',
        marginBottom: 0
    },
    title: {
        fontFamily: 'OpenSans',
        fontSize: 16,
        fontWeight: 600,
        color: '#363636',
        marginBottom: 4
    },
    subtitle: {
        fontFamily: 'OpenSans',
        fontSize: 12,
        fontWeight: 400,
        color: '#6F6F6F',
        marginBottom: 0
    }
});
