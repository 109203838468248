import React from 'react';
import { AircraftModelInternal, AircraftModelList } from './screens';
const AircraftModelModule = [
    {
        path: '/operational/aircraft-models',
        element: React.createElement(AircraftModelList, null)
    },
    {
        path: '/operational/aircraft-models/new',
        element: React.createElement(AircraftModelInternal, null)
    },
    {
        path: '/operational/aircraft-models/:aircraftModelId',
        element: React.createElement(AircraftModelInternal, null)
    }
];
export { AircraftModelModule };
