import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import Picker from 'react-month-picker';
import './MonthFieldModal.scss';
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;
const DEFAULT_VALUE = {
    month: currentMonth,
    year: currentYear
};
const MonthFieldModal = ({ value, years, onChange }, ref) => {
    const monthPickerRef = useRef(null);
    const monthPickerLang = useMemo(() => ({
        months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        from: 'De',
        to: 'Para'
    }), []);
    const show = useCallback(() => monthPickerRef.current?.show(), [monthPickerRef]);
    const close = useCallback(() => monthPickerRef.current?.dismiss(), [monthPickerRef]);
    useImperativeHandle(ref, () => ({
        show,
        close
    }));
    return (React.createElement(Picker, { ref: monthPickerRef, lang: monthPickerLang, years: years, value: value || DEFAULT_VALUE, onChange: onChange, className: "month-field-modal" }));
};
export default forwardRef(MonthFieldModal);
