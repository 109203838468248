import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useAuth } from 'contexts/auth.context';
import { useSolicitationMutation } from 'hooks';
import { SolicitationStateReasonLabel } from 'types/enum';
const SolicitationVoteDialog = ({ solicitationStateToBeApproved }) => {
    const { vote, isVoting } = useSolicitationMutation();
    const { success, errorCallback } = useAlert();
    const { user } = useAuth();
    const [showDialog, setShowDialog] = useState(false);
    const toggleDialog = useCallback(() => setShowDialog(showDialog => !showDialog), []);
    const voteSolicitation = useCallback(async (isApproved) => {
        const solicitationApprovalVoteData = {
            UserId: user.Id,
            SolicitationStateApprovalId: solicitationStateToBeApproved?.SolicitationStateApprovalId,
            Vote: isApproved
        };
        return vote(solicitationApprovalVoteData, {
            onSuccess: () => {
                success({
                    title: 'Voto confirmado!',
                    message: 'Voto confirmado com sucesso.',
                    id: 'SOLICITATION_VOTE_SUCCESS'
                });
                toggleDialog();
            },
            onError: err => errorCallback(err, 'SOLICITATION_VOTE_FAILURE')
        });
    }, [vote, user, solicitationStateToBeApproved, toggleDialog, success, errorCallback]);
    const handleApproveSolicitation = useCallback(() => voteSolicitation(true), [voteSolicitation]);
    const handleRepproveSolicitation = useCallback(() => voteSolicitation(false), [voteSolicitation]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { text: "Continuar Requerimento", color: "info", animationHover: "pop", onClick: toggleDialog, className: "mr-2 mb-2 lg:mb-0" }),
        React.createElement(Modal, { show: showDialog, handleClose: toggleDialog, size: "alert", title: "Continuar Requerimento" },
            React.createElement("p", { className: "text-gray-light dark:text-gray-400 mb-1" }, "A solicita\u00E7\u00E3o est\u00E1 aguardando aprova\u00E7\u00E3o."),
            React.createElement("p", { className: "text-orange" },
                "Motivo: ",
                SolicitationStateReasonLabel[solicitationStateToBeApproved?.StateReason]),
            React.createElement("div", { className: "flex justify-end mt-4" },
                React.createElement(Button, { text: "Continuar", color: "success", animationHover: "pop", className: "mr-1", onClick: handleApproveSolicitation, disabled: isVoting }),
                React.createElement(Button, { text: "Finalizar", color: "danger", animationHover: "pop", onClick: handleRepproveSolicitation, disabled: isVoting })))));
};
export default SolicitationVoteDialog;
