import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { makeHttpService } from 'services';
import { SOLICITATION_BY_BENEFICIARY_QUERY_KEY } from './useSolicitationConfig';
export const useSolicitationByBeneficiaryQuery = (beneficiaryId) => {
    const getSolicitations = useCallback(async ({ signal }) => {
        const params = {
            SearchField: 'BeneficiaryId',
            SearchQuery: beneficiaryId.toString(),
            PerPage: undefined,
            OrderBy: 'CreatedAt',
            OrderDescending: true
        };
        return makeHttpService()
            .get('/solicitation/by-parameters', {
            params,
            signal
        })
            .then(res => res.Registers ?? [])
            .catch(() => {
            return [];
        });
    }, [beneficiaryId]);
    const { data, isLoading } = useQuery([SOLICITATION_BY_BENEFICIARY_QUERY_KEY, beneficiaryId], getSolicitations, {
        enabled: !!beneficiaryId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    const memoizedData = useMemo(() => data ?? [], [data]);
    return [memoizedData, isLoading];
};
