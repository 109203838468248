import React, { useCallback, useMemo, useState } from 'react';
import { Button, Loading, Modal, Table, Tooltip } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useModulePermissions, usePremiumRecipientList, usePremiumRecipientMutation, usePremiumRecipientQuery, useDocumentTitle, useBeneficiaryQuery } from 'hooks';
import moment from 'moment-timezone';
import { PremiumRecipientContractFile } from './PremiumRecipientContractFile';
import { BeneficiaryPremiumRecipientForm } from './PremiumRecipientForm';
const BeneficiaryPremiumRecipientTab = ({ beneficiaryId }) => {
    const { success, confirm, errorCallback } = useAlert();
    const { canRead, canCreate, canDelete } = useModulePermissions();
    const [showModal, setShowModal] = useState(false);
    const [selectedPremiumRecipientId, setSelectedPremiumRecipientId] = useState(undefined);
    const [beneficiary, isLoadingBeneficiary] = useBeneficiaryQuery(beneficiaryId);
    const [premiumRecipients, isLoadingPremiumRecipients] = usePremiumRecipientList(beneficiaryId);
    const [premiumRecipient] = usePremiumRecipientQuery(selectedPremiumRecipientId);
    const { create, update, remove } = usePremiumRecipientMutation(beneficiaryId, selectedPremiumRecipientId);
    const onClickNewPremiumRecipient = useCallback(() => {
        setSelectedPremiumRecipientId(undefined);
        setShowModal(true);
    }, []);
    const onClickEditPremiumRecipient = useCallback((selectedPremiumRecipientId) => {
        const selectedPremiumRecipient = premiumRecipients.find(x => x.Id === selectedPremiumRecipientId);
        if (!selectedPremiumRecipient)
            return;
        setSelectedPremiumRecipientId(selectedPremiumRecipient.Id);
        setShowModal(true);
    }, [premiumRecipients]);
    const onClosePremiumRecipientModal = useCallback(() => {
        setSelectedPremiumRecipientId(undefined);
        setShowModal(false);
    }, []);
    const onSubmitPremiumRecipient = useCallback(async (premiumRecipientData) => {
        const premiumRecipientDataSubmit = { ...premiumRecipientData };
        premiumRecipientDataSubmit.BeneficiaryId = beneficiaryId;
        premiumRecipientDataSubmit.CreatedAt = moment().format();
        premiumRecipientDataSubmit.UpdatedAt = null;
        if (premiumRecipientData.Id) {
            await update(premiumRecipientDataSubmit, {
                onSuccess: () => {
                    success({
                        title: 'Premiado atualizado!',
                        message: 'Dados do premiado atualizados com sucesso.',
                        id: 'PREMIUM_RECIPIENT_PUT_SUCCESS'
                    });
                    onClosePremiumRecipientModal();
                },
                onError: err => errorCallback(err, 'PREMIUM_RECIPIENT_PUT_FAILURE')
            });
        }
        else {
            await create(premiumRecipientDataSubmit, {
                onSuccess: () => {
                    success({
                        title: 'Premiado cadastrado!',
                        message: 'Premiado cadastrado com sucesso.',
                        id: 'PREMIUM_RECIPIENT_POST_SUCCESS'
                    });
                    onClosePremiumRecipientModal();
                },
                onError: err => errorCallback(err, 'PREMIUM_RECIPIENT_POST_FAILURE')
            });
        }
    }, [beneficiaryId, create, update, success, errorCallback, onClosePremiumRecipientModal]);
    const deletePremiumRecipient = useCallback((premiumRecipientId) => {
        return remove(premiumRecipientId, {
            onSuccess: () => success({
                title: 'Premiado deletado!',
                message: 'Premiado deletado com sucesso.',
                id: 'PREMIUM_RECIPIENT_DELETE_SUCCESS'
            }),
            onError: err => errorCallback(err, 'PREMIUM_RECIPIENT_DELETE_FAILURE')
        });
    }, [remove, success, errorCallback]);
    const onDeletePremiumRecipient = useCallback((premiumRecipientId) => {
        confirm({
            title: 'Você tem certeza?',
            description: (React.createElement("p", null,
                "O premiumRecipiente ser\u00E1 deletado. ",
                React.createElement("br", null),
                " Essa a\u00E7\u00E3o \u00E9 irrevers\u00EDvel!")),
            confirmText: 'Sim, deletar',
            cancelText: 'Não, cancelar',
            onConfirm: () => deletePremiumRecipient(premiumRecipientId)
        });
    }, [confirm, deletePremiumRecipient]);
    const columns = useMemo(() => [
        {
            Header: 'Código de Registro',
            accessor: 'RegistrationCode'
        },
        {
            Header: 'Nome',
            accessor: 'Name'
        },
        {
            Header: 'Porcentagem',
            accessor: 'PremiumPercentage',
            Cell: ({ cell: { value } }) => React.createElement(React.Fragment, null,
                value,
                "%")
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            Cell: ({ cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" },
                    React.createElement(Tooltip, { id: `premiumRecipients-${value}-edit`, text: canRead ? 'Editar' : 'Visualizar' },
                        React.createElement(Button, { icon: canRead ? 'edit' : 'eye', color: "info", rounded: true, onClick: () => onClickEditPremiumRecipient(value) })),
                    canDelete && (React.createElement(Tooltip, { id: `premiumRecipients-${value}-remove`, text: "Deletar" },
                        React.createElement(Button, { className: "ml-2", icon: "trash", color: "danger", title: "Deletar", onClick: () => onDeletePremiumRecipient(value), rounded: true })))));
            }
        }
    ], [canRead, canDelete, onClickEditPremiumRecipient, onDeletePremiumRecipient]);
    const pageTitle = useMemo(() => 'Premiados do Associado', []);
    useDocumentTitle(pageTitle);
    const isLoading = isLoadingPremiumRecipients || isLoadingBeneficiary;
    return (React.createElement(React.Fragment, null,
        React.createElement(Loading, { show: isLoading }),
        React.createElement("h4", { className: "text-base text-gray-light dark:text-gray-200 mb-4" }, "Contrato da ap\u00F3lice"),
        React.createElement(PremiumRecipientContractFile, { beneficiary: beneficiary }),
        canCreate && (React.createElement("div", { className: "flex justify-end mb-4" },
            React.createElement(Button, { text: "Novo Premiado", icon: "add", color: "info", animationHover: "pop", onClick: onClickNewPremiumRecipient }))),
        React.createElement("h4", { className: "text-base text-gray-light dark:text-gray-200 mb-4" }, "Premiados cadastrados"),
        React.createElement(Table, { data: premiumRecipients, columns: columns, isLoading: isLoading, noResultsMessage: "Nenhum premiado a ser exibido" }),
        React.createElement(Modal, { show: showModal, handleClose: onClosePremiumRecipientModal, size: "lg", title: selectedPremiumRecipientId ? 'Editar Premiado' : 'Novo Premiado' },
            React.createElement(BeneficiaryPremiumRecipientForm, { premiumRecipient: premiumRecipient, onSubmitPremiumRecipient: onSubmitPremiumRecipient }))));
};
export default BeneficiaryPremiumRecipientTab;
