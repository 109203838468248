import React from 'react';
import { DailyRateInternal, DailyRateList } from './screens';
const DailyRateModule = [
    {
        path: '/cam/daily-rates',
        element: React.createElement(DailyRateList, null)
    },
    {
        path: '/cam/daily-rates/new',
        element: React.createElement(DailyRateInternal, null)
    },
    {
        path: '/cam/daily-rates/:dailyRateId',
        element: React.createElement(DailyRateInternal, null)
    }
];
export { DailyRateModule };
