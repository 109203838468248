import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader, Card } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useDocumentTitle, useRecompositionInequalityMutation } from 'hooks';
import { RecompositionInequalityForm } from '../components';
const RecompositionInequalityCreation = () => {
    const { success, errorCallback, error } = useAlert();
    const { create } = useRecompositionInequalityMutation();
    const navigate = useNavigate();
    async function submitRecompositionInequality(solicitationId) {
        await create(solicitationId)
            .then(res => {
            if (res) {
                success({
                    title: 'RDV cadastrado!',
                    message: 'RDV cadastrado com sucesso.',
                    id: 'RECOMPOSITION_INEQUALITY_POST_SUCCESS'
                });
                navigate(`/cam/recomposition-inequalities/${res.Id}`);
            }
            else
                error({ message: 'Não foi possível cadastrar o RDV', id: 'RECOMPOSITION_INEQUALITY_POST_FAILURE' });
        })
            .catch(err => errorCallback(err, 'RECOMPOSITION_INEQUALITY_POST_FAILURE'));
    }
    const pageTitle = useMemo(() => 'Novo RDV', []);
    const breadcrumb = useMemo(() => [
        {
            Title: 'RDV',
            Url: '/cam/recomposition-inequalities'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "RDV", breadcrumb: breadcrumb }),
        React.createElement(Card, { id: "recomposition-inequality-card" },
            React.createElement(RecompositionInequalityForm, { handleSubmit: submitRecompositionInequality }))));
};
export default RecompositionInequalityCreation;
