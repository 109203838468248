import React, { useCallback, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { TextField, Button, FormField, FormFieldError, Form, Select, FormikValidationHandler, useFormikValidationHandler } from 'components';
import { useFormik } from 'formik';
import { useModulePermissions, useBankList } from 'hooks';
import { AccountType } from 'types/enum';
import { ValidationUtils } from 'utils';
import { BeneficiaryPaymentDataSchema } from './BeneficiaryPaymentDataForm.schema';
const defaultValues = {
    Id: 0,
    BankId: null,
    BankAgencyNumber: '',
    BankAccountNumber: '',
    AccountTypeId: null
};
const accountTypes = [
    {
        name: 'Conta Poupança',
        value: AccountType.SAVING_ACCOUNT
    },
    {
        name: 'Conta Corrente',
        value: AccountType.CHECKING_ACCOUNT
    }
];
const BeneficiaryPaymentDataForm = ({ beneficiary, isLoading, onSubmitBeneficiary }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions(beneficiary?.Id);
    const [banks, isLoadingBanks] = useBankList();
    const onSubmit = useCallback(async (beneficiaryValues) => onSubmitBeneficiary(beneficiaryValues), [onSubmitBeneficiary]);
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => BeneficiaryPaymentDataSchema,
        onSubmit,
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleChange, handleSubmit, isSubmitting, setValues } = formikContext;
    const onInitForm = useCallback(() => {
        if (ValidationUtils.isEmptyObject(beneficiary))
            return;
        const beneficiaryData = {
            Id: beneficiary.Id,
            AccountTypeId: beneficiary.AccountTypeId || null,
            BankId: beneficiary.BankId || null,
            BankAgencyNumber: beneficiary.BankAgencyNumber || '',
            BankAccountNumber: beneficiary.BankAccountNumber || ''
        };
        setValues(beneficiaryData);
    }, [beneficiary, setValues]);
    useEffect(onInitForm, [onInitForm]);
    if (isLoading)
        return null;
    return (React.createElement(Form, { name: "BeneficiaryPaymentDataForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, null,
            React.createElement(Col, { lg: 4 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "AccountTypeId", label: "Tipo da conta", options: accountTypes, value: accountTypes?.find(accountType => accountType.value === values.AccountTypeId), onChange: option => setFieldValue('AccountTypeId', option.value), getOptionLabel: option => option.name, getOptionValue: option => option.value.toString() }),
                    React.createElement(FormFieldError, { name: "AccountTypeId" }))),
            React.createElement(Col, { lg: 8 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "BankId", label: "Banco", options: banks, value: banks?.find(bank => bank.Id === values.BankId), onChange: option => setFieldValue('BankId', option.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingBanks }),
                    React.createElement(FormFieldError, { name: "BankId" }))),
            React.createElement(Col, { lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "BankAgencyNumber", label: "Ag\u00EAncia", value: values.BankAgencyNumber, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "BankAgencyNumber" }))),
            React.createElement(Col, { lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "BankAccountNumber", label: "Conta", value: values.BankAccountNumber, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "BankAccountNumber" })))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Salvar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default BeneficiaryPaymentDataForm;
