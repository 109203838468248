import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { BENEFIT_CONSUMPTION_LIST_QUERY_KEY, BENEFIT_CONSUMPTION_PAGINATION_QUERY_KEY, BENEFIT_CONSUMPTION_QUERY_KEY } from './useBenefitConsumptionConfig';
export const useBenefitConsumptionMutation = (benefitConsumptionId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createBenefitConsumption = useCallback(async (benefitConsumptionData) => {
        return httpService.post('/benefit-consumption', benefitConsumptionData);
    }, [httpService]);
    const updateBenefitConsumption = useCallback(async (benefitConsumptionData) => {
        return httpService.put('/benefit-consumption', benefitConsumptionData);
    }, [httpService]);
    const removeBenefitConsumption = useCallback(async (benefitConsumptionId) => {
        return httpService.delete(`/benefit-consumption?id=${benefitConsumptionId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createBenefitConsumption, {
        onSuccess: () => {
            queryClient.invalidateQueries(BENEFIT_CONSUMPTION_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries(BENEFIT_CONSUMPTION_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateBenefitConsumption, {
        onSuccess: () => {
            queryClient.invalidateQueries(BENEFIT_CONSUMPTION_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries([BENEFIT_CONSUMPTION_QUERY_KEY, benefitConsumptionId]);
            queryClient.invalidateQueries(BENEFIT_CONSUMPTION_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removeBenefitConsumption, {
        onSuccess: (_, benefitConsumptionId) => {
            queryClient.invalidateQueries(BENEFIT_CONSUMPTION_PAGINATION_QUERY_KEY);
            queryClient.removeQueries([BENEFIT_CONSUMPTION_QUERY_KEY, benefitConsumptionId]);
            queryClient.invalidateQueries(BENEFIT_CONSUMPTION_LIST_QUERY_KEY);
        }
    });
    return { create, update, remove, isCreating, isUpdating, isRemoving };
};
