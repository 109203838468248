import { useCallback, useMemo, useState } from 'react';
import { ValidationUtils } from 'utils';
const defaultValues = {
    SearchField: null,
    SearchQuery: '',
    ClientId: null,
    DateRange: [null, null],
    Active: null
};
export const useTableFilter = ({ initialParams = defaultValues, mountVisible = false, customParamsHandle } = {}) => {
    const [filterParams, setParams] = useState(initialParams);
    const [showFilters, setShowFilters] = useState(mountVisible);
    const toggleFilters = useCallback(() => setShowFilters(showFilters => !showFilters), []);
    const requestFilterParams = useMemo(() => {
        const params = {
            Active: filterParams.Active
        };
        if (!!filterParams.PaymentMethod && filterParams.PaymentMethod !== 0) {
            const paymentMethod = filterParams.PaymentMethod;
            params.PaymentMethod = paymentMethod;
        }
        if (!!filterParams.PaymentStatus && filterParams.PaymentStatus !== 0) {
            const paymentStatus = filterParams.PaymentStatus;
            params.PaymentStatus = paymentStatus;
        }
        if (!ValidationUtils.isNullOrUndefined(filterParams.RegistrationCode)) {
            const registrationCode = filterParams.RegistrationCode;
            params.RegistrationCode = registrationCode;
        }
        if (!ValidationUtils.isNullOrUndefined(filterParams.PersonDocument)) {
            const personDocument = filterParams.PersonDocument;
            params.PersonDocument = personDocument;
        }
        if (filterParams.DateRange?.length) {
            const [startDate, endDate] = filterParams.DateRange;
            if (ValidationUtils.isNotNullOrUndefined(startDate) && ValidationUtils.isNotNullOrUndefined(endDate)) {
                params.StartDate = startDate?.utc().format();
                params.EndDate = endDate?.utc().format();
            }
        }
        return {
            ...(customParamsHandle ? customParamsHandle(filterParams) : {}),
            ...params
        };
    }, [filterParams, customParamsHandle]);
    return {
        filterParams,
        requestFilterParams,
        setParams,
        showFilters,
        toggleFilters
    };
};
