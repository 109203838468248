import React from 'react';
import { BenefitCategoryInternal, BenefitCategoryList } from './screens';
const BenefitCategoryModule = [
    {
        path: '/benefits/categories',
        element: React.createElement(BenefitCategoryList, null)
    },
    {
        path: '/benefits/categories/new',
        element: React.createElement(BenefitCategoryInternal, null)
    },
    {
        path: '/benefits/categories/:benefitCategoryId',
        element: React.createElement(BenefitCategoryInternal, null)
    }
];
export { BenefitCategoryModule };
