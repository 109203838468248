import { useContext, useMemo } from 'react';
import { useAuth } from 'contexts/auth.context';
import { ModuleContext } from 'contexts/module.context';
import { ScopeAction } from 'types/enum';
import { ScopeUtils } from 'utils';
const canMutateEntity = ({ canRead, canCreate, canUpdate }, entityId) => {
    if (!canRead)
        return false;
    if (!entityId && !canCreate)
        return false;
    if (entityId && !canUpdate)
        return false;
    return true;
};
export const useModulePermissions = (entityId) => {
    const context = useContext(ModuleContext);
    if (context === undefined)
        throw new Error('useModulePermissions must be used within a ModuleProvider.');
    const canMutate = canMutateEntity(context.permissions, entityId);
    return {
        ...context.permissions,
        canMutate
    };
};
export const useModulePermissionsByScope = (scope, entityId) => {
    const { user } = useAuth();
    const modulePermissions = useMemo(() => {
        if (!user?.Id)
            return {};
        const userScopes = user.UserScopes.map(x => x.Scope.Name);
        const modulePermissions = {
            canRead: ScopeUtils.hasPermissionInScope(userScopes, scope, ScopeAction.Read),
            canList: ScopeUtils.hasPermissionInScope(userScopes, scope, ScopeAction.List),
            canCreate: ScopeUtils.hasPermissionInScope(userScopes, scope, ScopeAction.Create),
            canUpdate: ScopeUtils.hasPermissionInScope(userScopes, scope, ScopeAction.Update),
            canDelete: ScopeUtils.hasPermissionInScope(userScopes, scope, ScopeAction.Delete),
            canReport: ScopeUtils.hasPermissionInScope(userScopes, scope, ScopeAction.Report),
            canExecute: ScopeUtils.hasPermissionInScope(userScopes, scope, ScopeAction.Execute)
        };
        const canMutate = canMutateEntity(modulePermissions, entityId);
        return {
            ...modulePermissions,
            canMutate
        };
    }, [scope, entityId, user]);
    return modulePermissions;
};
