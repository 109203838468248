import React, { useLayoutEffect, useState } from 'react';
import { Container } from 'react-grid-system';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import { Header, Sidebar } from 'components';
import { LayoutConstants } from 'configs';
import './AppLayout.scss';
const AppLayout = ({ children }) => {
    const [sidebarOpened, toggleSidebar] = useState(() => {
        const isSidebarOpened = localStorage.getItem(LayoutConstants.SIDEBAR_OPENED);
        return isSidebarOpened === null ? true : isSidebarOpened === 'true';
    });
    const onToggleSidebar = () => {
        toggleSidebar(oldSidebarOpened => {
            const isSidebarOpened = !oldSidebarOpened;
            localStorage.setItem(LayoutConstants.SIDEBAR_OPENED, isSidebarOpened.toString());
            return !oldSidebarOpened;
        });
    };
    useLayoutEffect(() => {
        setTimeout(() => document.getElementsByTagName('html')[0]?.classList.remove('auth'), 100);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { sidebarOpened: sidebarOpened, onToggleSidebar: onToggleSidebar }),
        React.createElement(Sidebar, { sidebarOpened: sidebarOpened }),
        React.createElement("div", { className: clsx('content-wrapper', sidebarOpened && 'sidebar-opened') },
            React.createElement(PerfectScrollbar, { className: "content-scroll", options: { wheelSpeed: 0.2 } },
                React.createElement("div", { className: "content-inner" },
                    React.createElement(Container, { fluid: true }, children))))));
};
export default AppLayout;
