import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'components';
import ExportButtonItem from './ExportButtonItem';
const ExportButton = ({ params, config, isMultiple = false }) => {
    const [showModal, setShowModal] = useState(false);
    const toggleModal = useCallback(() => setShowModal(showModal => !showModal), []);
    if (!isMultiple) {
        return React.createElement(ExportButtonItem, { params: params, ...config });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { text: "Exportar", icon: "download", color: "info", animationHover: "pop", onClick: toggleModal }),
        React.createElement(Modal, { show: showModal, handleClose: toggleModal, title: "Exportar", closable: true },
            React.createElement("h3", { className: "text-base text-gray-800 dark:text-gray-50 text-center mb-4" }, "Escolha os dados a serem exportados:"),
            React.createElement("div", { className: "flex flex-col gap-4 items-center" }, config.map((exportConfig, i) => (React.createElement(ExportButtonItem, { key: i, params: params, ...exportConfig })))))));
};
export default ExportButton;
