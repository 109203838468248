import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { BENEFIT_LIST_QUERY_KEY, BENEFIT_PAGINATION_QUERY_KEY, BENEFIT_QUERY_KEY } from './useBenefitConfig';
export const useBenefitMutation = (benefitId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createBenefit = useCallback(async (benefitData) => {
        return httpService.post('/benefit', benefitData);
    }, [httpService]);
    const updateBenefit = useCallback(async (benefitData) => {
        return httpService.put('/benefit', benefitData);
    }, [httpService]);
    const removeBenefit = useCallback(async (benefitId) => {
        return httpService.delete(`/benefit?id=${benefitId}`);
    }, [httpService]);
    const recalculateBenefit = useCallback(async () => {
        return httpService.put(`/benefit/${benefitId}/recalculate`);
    }, [httpService, benefitId]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createBenefit, {
        onSuccess: () => {
            queryClient.invalidateQueries(BENEFIT_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries(BENEFIT_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateBenefit, {
        onSuccess: () => {
            queryClient.invalidateQueries(BENEFIT_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries([BENEFIT_QUERY_KEY, benefitId]);
            queryClient.invalidateQueries(BENEFIT_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removeBenefit, {
        onSuccess: (_, benefitId) => {
            queryClient.invalidateQueries(BENEFIT_PAGINATION_QUERY_KEY);
            queryClient.removeQueries([BENEFIT_QUERY_KEY, benefitId]);
            queryClient.invalidateQueries(BENEFIT_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: recalculate, isLoading: isRecalculating } = useMutation(recalculateBenefit, {
        onSuccess: (_, benefitId) => {
            queryClient.invalidateQueries(BENEFIT_PAGINATION_QUERY_KEY);
            queryClient.removeQueries([BENEFIT_QUERY_KEY, benefitId]);
            queryClient.invalidateQueries(BENEFIT_LIST_QUERY_KEY);
        }
    });
    return { create, update, remove, recalculate, isCreating, isUpdating, isRemoving, isRecalculating };
};
