import React from 'react';
import OperationBaseInternal from './screens/OperationBaseInternal';
import OperationBaseList from './screens/OperationBaseList';
const OperationBaseModule = [
    {
        path: '/operational/operation-bases',
        element: React.createElement(OperationBaseList, null)
    },
    {
        path: '/operational/operation-bases/new',
        element: React.createElement(OperationBaseInternal, null)
    },
    {
        path: '/operational/operation-bases/:operationBaseId',
        element: React.createElement(OperationBaseInternal, null)
    }
];
export { OperationBaseModule };
