import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { PLAN_LIST_QUERY_KEY, PLAN_PAGINATION_QUERY_KEY, PLAN_QUERY_KEY } from './usePlanConfig';
export const usePlanMutation = (planId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createPlan = useCallback(async (planData) => {
        return httpService.post('/plan', planData);
    }, [httpService]);
    const updatePlan = useCallback(async (planData) => {
        return httpService.put('/plan', planData);
    }, [httpService]);
    const removePlan = useCallback(async (planId) => {
        return httpService.delete(`/plan?id=${planId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createPlan, {
        onSuccess: () => {
            queryClient.invalidateQueries(PLAN_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries(PLAN_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updatePlan, {
        onSuccess: () => {
            queryClient.invalidateQueries(PLAN_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries([PLAN_QUERY_KEY, planId]);
            queryClient.invalidateQueries(PLAN_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removePlan, {
        onSuccess: (_, planId) => {
            queryClient.invalidateQueries(PLAN_PAGINATION_QUERY_KEY);
            queryClient.removeQueries([PLAN_QUERY_KEY, planId]);
            queryClient.invalidateQueries(PLAN_LIST_QUERY_KEY);
        }
    });
    return { create, update, remove, isCreating, isUpdating, isRemoving };
};
