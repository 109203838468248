export const AuthenticationConstants = {
    ACCESS_TOKEN: 'AccessToken',
    ACCESS_TOKEN_EXPIRATION: 'AccessTokenExpiration',
    REFRESH_TOKEN: 'RefreshToken',
    USER_DATA: 'UserData'
};
export const LayoutConstants = {
    SIDEBAR_OPENED: 'SidebarOpened'
};
export const TimeMillisecondsConstants = {
    ZERO_MINUTES: 0,
    THIRTY_MINUTES: 30 * 100 * 60
};
export const SolicitationPaymentFlowInstallmentDefaultValues = {
    DEFAULT_WORK_ACCIDENT_PERCENTAGE: 60
};
