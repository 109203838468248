import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { BENEFIT_CATEGORY_LIST_QUERY_KEY, BENEFIT_CATEGORY_PAGINATION_QUERY_KEY, BENEFIT_CATEGORY_QUERY_KEY } from './useBenefitCategoryConfig';
export const useBenefitCategoryMutation = (benefitCategoryId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createBenefitCategory = useCallback(async (benefitCategoryData) => {
        return httpService.post('/benefit-category', benefitCategoryData);
    }, [httpService]);
    const updateBenefitCategory = useCallback(async (benefitCategoryData) => {
        return httpService.put('/benefit-category', benefitCategoryData);
    }, [httpService]);
    const removeBenefitCategory = useCallback(async (benefitCategoryId) => {
        return httpService.delete(`/benefit-category?id=${benefitCategoryId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createBenefitCategory, {
        onSuccess: () => {
            queryClient.invalidateQueries(BENEFIT_CATEGORY_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries(BENEFIT_CATEGORY_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateBenefitCategory, {
        onSuccess: () => {
            queryClient.invalidateQueries(BENEFIT_CATEGORY_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries([BENEFIT_CATEGORY_QUERY_KEY, benefitCategoryId]);
            queryClient.invalidateQueries(BENEFIT_CATEGORY_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removeBenefitCategory, {
        onSuccess: (_, benefitCategoryId) => {
            queryClient.invalidateQueries(BENEFIT_CATEGORY_PAGINATION_QUERY_KEY);
            queryClient.removeQueries([BENEFIT_CATEGORY_QUERY_KEY, benefitCategoryId]);
            queryClient.invalidateQueries(BENEFIT_CATEGORY_LIST_QUERY_KEY);
        }
    });
    return { create, update, remove, isCreating, isUpdating, isRemoving };
};
