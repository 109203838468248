import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { Icon } from 'components/Icon';
import './PageHeader.scss';
const PageHeader = ({ title, breadcrumb, rightContent, withDivisor = true }) => {
    return (React.createElement("section", { className: clsx('page-header', !withDivisor && 'without-divisor') },
        React.createElement("div", { className: "page-breadcrumb" }, !!(breadcrumb && breadcrumb.length) &&
            breadcrumb.map((item, index) => (React.createElement(React.Fragment, { key: index },
                item.Url ? (React.createElement(NavLink, { to: item.Url },
                    React.createElement("span", null, item.Title))) : (React.createElement("span", null, item.Title)),
                index + 1 < breadcrumb.length && React.createElement(Icon, { name: "chevron-right" }))))),
        React.createElement("div", { className: "page-header-title" },
            React.createElement("h1", null, title),
            rightContent && rightContent)));
};
export default memo(PageHeader);
