const sortAsc = (value, property) => {
    return value.sort((a, b) => a[property].localeCompare(b[property]));
};
const sortDesc = (value, property) => {
    return value.sort((a, b) => b[property].localeCompare(a[property]));
};
const getFirstItemByProperty = (value, property) => {
    return sortAsc(value, property)[0];
};
const getLastItemByProperty = (value, property) => {
    return sortDesc(value, property)[0];
};
const getItemByProperty = (value, property, searchValue) => {
    return value.find(x => x[property] === searchValue);
};
const groupBy = (value, property) => {
    const map = value.reduce((acc, cur) => acc.set(cur[property], [...(acc.get(cur[property]) || []), cur]), new Map());
    return Array.from(map.values());
};
export const ArrayUtils = {
    sortAsc,
    sortDesc,
    getFirstItemByProperty,
    getLastItemByProperty,
    getItemByProperty,
    groupBy
};
