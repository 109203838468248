import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Loading, Card } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useDocumentTitle, useAircraftModelQuery, useAircraftModelMutation } from 'hooks';
import moment from 'moment-timezone';
import { AircraftModelForm } from '../components';
const AircraftModelInternal = () => {
    const aircraftModelIdRoute = parseInt(useParams()?.aircraftModelId ?? '0');
    const { success, errorCallback } = useAlert();
    const navigate = useNavigate();
    const [aircraftModel, isLoading] = useAircraftModelQuery(aircraftModelIdRoute);
    const { create, update } = useAircraftModelMutation(aircraftModelIdRoute);
    async function submitAircraftModel(aircraftModelData) {
        const aircraftModelDataSubmit = { ...aircraftModelData };
        aircraftModelDataSubmit.CreatedAt = moment().format();
        aircraftModelDataSubmit.UpdatedAt = null;
        if (aircraftModelData.Id) {
            return update(aircraftModelDataSubmit, {
                onSuccess: () => success({
                    title: 'Modelo atualizado!',
                    message: 'Dados do modelo atualizados com sucesso.',
                    id: 'AIRCRAFT_MODEL_PUT_SUCCESS'
                }),
                onError: err => errorCallback(err, 'AIRCRAFT_MODEL_PUT_FAILURE')
            });
        }
        else {
            return create(aircraftModelDataSubmit, {
                onSuccess: res => {
                    success({
                        title: 'Modelo cadastrado!',
                        message: 'Modelo cadastrado com sucesso.',
                        id: 'AIRCRAFT_MODEL_POST_SUCCESS'
                    });
                    navigate(`/operational/aircraft-models/${res.Id}`);
                },
                onError: err => errorCallback(err, 'AIRCRAFT_MODEL_POST_FAILURE')
            });
        }
    }
    const pageTitle = useMemo(() => `${aircraftModelIdRoute ? 'Visualizar' : 'Novo'} Modelo de Aeronave`, [
        aircraftModelIdRoute
    ]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Modelos de Aeronave',
            Url: '/operational/aircraft-models'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Modelos de Aeronave", breadcrumb: breadcrumb }),
        React.createElement(Loading, { show: isLoading }),
        React.createElement(Card, { id: "aircraft-model-card" },
            React.createElement(AircraftModelForm, { aircraftModel: aircraftModel, isLoading: isLoading, handleSubmit: submitAircraftModel }))));
};
export default AircraftModelInternal;
