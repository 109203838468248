import 'styles/main.css';
import 'styles/global.scss';
import React from 'react';
import { setConfiguration } from 'react-grid-system';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AppContainer } from 'components/AppContainer';
import { AppProvider } from 'contexts';
import moment from 'moment-timezone';
setConfiguration({ maxScreenClass: 'xl', gutterWidth: 16 });
moment.locale('pt-br');
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false
        }
    }
});
const App = () => {
    return (React.createElement(BrowserRouter, null,
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(AppProvider, null,
                React.createElement(AppContainer, null))),
        React.createElement(ToastContainer, { containerId: "app-container", theme: "dark", icon: false })));
};
export default App;
