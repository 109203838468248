import React, { useCallback, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { TextField, Button, FormField, FormFieldError, Form, MaskedTextField, Select, SituationField, FormikValidationHandler, useFormikValidationHandler, FormFieldSeparator } from 'components';
import { useFormik } from 'formik';
import { useModulePermissions, useGenderList, useStates, useCities, useZipCode } from 'hooks';
import { FormatterUtils, TextUtils, ValidationUtils } from 'utils';
import { BeneficiaryPersonalDataSchema } from './BeneficiaryPersonalDataForm.schema';
import { BeneficiaryPersonalPicture } from './PersonalPicture';
const defaultValues = {
    Id: 0,
    RegistrationCode: '',
    Name: '',
    CallName: '',
    SocialName: '',
    TreatmentPronoun: '',
    BadgeNumber: '',
    AssociateCode: '',
    ATLRegistration: '',
    GenderId: null,
    Email: '',
    CellPhone: '',
    BirthDate: '',
    RG: '',
    CPF: '',
    Address: {},
    Active: true
};
const BeneficiaryPersonalDataForm = ({ beneficiary, isLoading, onSubmitBeneficiary }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions(beneficiary?.Id);
    const [genders, isLoadingGenders] = useGenderList();
    const onSubmit = useCallback(async (beneficiaryValues) => onSubmitBeneficiary(beneficiaryValues), [onSubmitBeneficiary]);
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => BeneficiaryPersonalDataSchema,
        onSubmit,
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleChange, handleSubmit, isSubmitting, setValues } = formikContext;
    const [states, isLoadingStates] = useStates();
    const [cities, isLoadingCities] = useCities(values.Address?.StateId);
    const [searchedAddress, isLoadingZipCode, getAddressByZipCode] = useZipCode();
    const onChangeZipCode = useCallback((value) => {
        if (value.replace(/\D/g, '').length === 8) {
            getAddressByZipCode(value);
        }
        setFieldValue('Address.ZipCode', value);
    }, [setFieldValue, getAddressByZipCode]);
    const onSearchZipCode = useCallback(() => {
        const selectedState = states.find(x => x.Code === searchedAddress?.State);
        if (!selectedState) {
            return;
        }
        setFieldValue('Address.Street', searchedAddress?.Street);
        setFieldValue('Address.Neighborhood', searchedAddress?.Neighborhood);
        setFieldValue('Address.StateId', selectedState.Id);
    }, [states, setFieldValue, searchedAddress]);
    const onSelectState = useCallback((selectedValue) => {
        const selectedState = states.find(x => x.Id === selectedValue.Id);
        if (!selectedState)
            return;
        setFieldValue('Address.StateId', selectedState.Id);
        setFieldValue('Address.CityId', null);
    }, [states, setFieldValue]);
    const onCitiesLoad = useCallback(() => {
        if (!searchedAddress || !cities.length)
            return;
        const cityUrl = TextUtils.slugify(searchedAddress.City);
        const selectedCity = cities.find(x => TextUtils.slugify(x.Name) === cityUrl);
        if (!selectedCity)
            return;
        setFieldValue('Address.CityId', selectedCity.Id);
    }, [searchedAddress, cities, setFieldValue]);
    const onInitForm = useCallback(() => {
        if (ValidationUtils.isEmptyObject(beneficiary))
            return;
        const beneficiaryData = {
            Id: beneficiary.Id,
            RegistrationCode: beneficiary.RegistrationCode,
            Name: beneficiary.Name,
            CallName: beneficiary.CallName || '',
            SocialName: beneficiary.SocialName || '',
            TreatmentPronoun: beneficiary.TreatmentPronoun || '',
            BadgeNumber: beneficiary.BadgeNumber || '',
            AssociateCode: beneficiary.AssociateCode || '',
            ATLRegistration: beneficiary.ATLRegistration || '',
            GenderId: beneficiary.GenderId || null,
            Email: beneficiary.Email || '',
            CellPhone: beneficiary.CellPhone || '',
            BirthDate: FormatterUtils.date(beneficiary.BirthDate),
            RG: beneficiary.RG || '',
            CPF: beneficiary.CPF || '',
            AddressId: beneficiary.AddressId || 0,
            Address: {
                Id: beneficiary.Address?.Id || 0,
                ZipCode: beneficiary.Address?.ZipCode || '',
                Street: beneficiary.Address?.Street || '',
                Number: beneficiary.Address?.Number || '',
                Neighborhood: beneficiary.Address?.Neighborhood || '',
                Complement: beneficiary.Address?.Complement || '',
                StateId: beneficiary.Address?.StateId || '',
                CityId: beneficiary.Address?.CityId || ''
            },
            CreatedAt: FormatterUtils.dateTime(beneficiary.CreatedAt),
            UpdatedAt: FormatterUtils.dateTime(beneficiary.UpdatedAt),
            Active: beneficiary.Active
        };
        setValues(beneficiaryData);
    }, [beneficiary, setValues]);
    useEffect(onInitForm, [onInitForm]);
    useEffect(onSearchZipCode, [onSearchZipCode]);
    useEffect(onCitiesLoad, [onCitiesLoad]);
    if (isLoading)
        return null;
    return (React.createElement(Form, { name: "BeneficiaryPersonalDataForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        Boolean(beneficiary?.Id) && React.createElement(BeneficiaryPersonalPicture, { beneficiary: beneficiary }),
        React.createElement(Row, null,
            !!beneficiary?.Id && (React.createElement(Col, { lg: 2 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "RegistrationCode", label: "C\u00F3d. registro", value: values.RegistrationCode, disabled: true })))),
            React.createElement(Col, { lg: 2 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "TreatmentPronoun", label: "Pronome", value: values.TreatmentPronoun, onChange: handleChange, maxLength: 10 }),
                    React.createElement(FormFieldError, { name: "TreatmentPronoun" }))),
            React.createElement(Col, { lg: beneficiary?.Id ? 8 : 10 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Name", label: "Nome", value: values.Name, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Name" }))),
            React.createElement(Col, { lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "SocialName", label: "Nome social", value: values.SocialName, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "SocialName" }))),
            React.createElement(Col, { lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "CallName", label: "Nome de guerra", value: values.CallName, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "CallName" }))),
            React.createElement(Col, { lg: 4 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "BadgeNumber", label: "N\u00FAmero crach\u00E1", value: values.BadgeNumber, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "BadgeNumber" }))),
            React.createElement(Col, { lg: 4 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "AssociateCode", label: "C\u00F3digo BP", value: values.AssociateCode, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "AssociateCode" }))),
            React.createElement(Col, { lg: 4 },
                React.createElement(Select, { name: "GenderId", label: "Escolha o g\u00EAnero", options: genders, value: genders?.find((gender) => gender.Id === values.GenderId), onChange: option => setFieldValue('GenderId', option.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingGenders }),
                React.createElement(FormFieldError, { name: "GenderId" })),
            React.createElement(Col, { lg: 4 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "RG", label: "RG", value: values.RG, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "RG" }))),
            React.createElement(Col, { lg: 4 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "cpf", name: "CPF", label: "CPF", value: values.CPF, onChange: value => setFieldValue('CPF', value) }),
                    React.createElement(FormFieldError, { name: "CPF" }))),
            React.createElement(Col, { lg: 4 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "date", name: "BirthDate", label: "Data de nascimento", value: values.BirthDate, onChange: value => setFieldValue('BirthDate', value) }),
                    React.createElement(FormFieldError, { name: "BirthDate" }))),
            React.createElement(Col, { lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Email", label: "E-mail", value: values.Email, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Email" }))),
            React.createElement(Col, { lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "cellphone", name: "CellPhone", label: "Celular", value: values.CellPhone, onChange: value => setFieldValue('CellPhone', value) }),
                    React.createElement(FormFieldError, { name: "CellPhone" }))),
            React.createElement(Col, { lg: 4 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "ATLRegistration", label: "Matr\u00EDcula ATL", value: values.ATLRegistration, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "ATLRegistration" })))),
        React.createElement(FormFieldSeparator, { text: "Endere\u00E7o", className: "mt-3", icon: "address" }),
        React.createElement(Row, null,
            React.createElement(Col, { lg: 3 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "zip-code", name: "Address.ZipCode", label: "CEP", value: values.Address.ZipCode, onChange: onChangeZipCode, loading: isLoadingZipCode }),
                    React.createElement(FormFieldError, { name: "Address.ZipCode" }))),
            React.createElement(Col, { lg: 7 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Address.Street", label: "Rua", value: values.Address.Street, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Address.Street" }))),
            React.createElement(Col, { lg: 2 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Address.Number", label: "N\u00FAmero", value: values.Address.Number, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Address.Number" }))),
            React.createElement(Col, { lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Address.Complement", label: "Complemento", value: values.Address.Complement, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Address.Complement" }))),
            React.createElement(Col, { lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Address.Neighborhood", label: "Bairro", value: values.Address.Neighborhood, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Address.Neighborhood" }))),
            React.createElement(Col, { lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "Address.StateId", label: "Escolha o estado", options: states, value: states?.find((state) => state.Id === values.Address?.StateId), onChange: onSelectState, getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingStates }),
                    React.createElement(FormFieldError, { name: "Address.StateId" }))),
            React.createElement(Col, { lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "Address.CityId", label: "Escolha a cidade", options: cities, value: cities?.find((state) => state.Id === values.Address?.CityId), onChange: option => setFieldValue('Address.CityId', option.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingCities, isDisabled: !values.Address?.StateId }),
                    React.createElement(FormFieldError, { name: "Address.CityId" })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Situa\u00E7\u00E3o do associado", handleChange: isActive => setFieldValue('Active', isActive), value: values.Active, falseLabel: "Inativo", trueLabel: "Ativo" })))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: values.Id ? 'Atualizar' : 'Continuar', color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default BeneficiaryPersonalDataForm;
