import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useModulePermissions, useTableFilter, useDocumentTitle, useJobDescriptionPagination, useJobDescriptionMutation } from 'hooks';
const JobDescriptionList = () => {
    const { canCreate, canRead, canUpdate, canDelete } = useModulePermissions();
    const { success, confirm, errorCallback } = useAlert();
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter();
    const { data, isLoading, ...pagination } = useJobDescriptionPagination(requestFilterParams);
    const { remove } = useJobDescriptionMutation();
    const deleteJobDescription = useCallback(async (jobDescriptionId) => {
        await remove(jobDescriptionId)
            .then(() => success({
            title: 'Cargo deletado!',
            message: 'Cargo deletado com sucesso.',
            id: 'JOB_DESCRIPTION_DELETE_SUCCESS'
        }))
            .catch(err => errorCallback(err, 'JOB_DESCRIPTION_DELETE_FAILURE'));
    }, [remove, success, errorCallback]);
    const onDeleteJobDescription = useCallback((jobDescriptionId) => {
        confirm({
            title: 'Você tem certeza?',
            description: (React.createElement("p", null,
                "O cargo ser\u00E1 deletado. ",
                React.createElement("br", null),
                " Essa a\u00E7\u00E3o \u00E9 irrevers\u00EDvel!")),
            confirmText: 'Sim, deletar',
            cancelText: 'Não, cancelar',
            onConfirm: async () => deleteJobDescription(jobDescriptionId)
        });
    }, [confirm, deleteJobDescription]);
    const searchFields = useMemo(() => [
        {
            Value: 'Name',
            Description: 'Nome'
        }
    ], []);
    const columns = useMemo(() => [
        {
            Header: 'Nome',
            accessor: 'Name'
        },
        {
            Header: 'Situação',
            accessor: 'Active',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold', value ? 'text-green-light' : 'text-red-light') }, value ? 'ATIVO' : 'INATIVO'))
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" },
                    (canRead || canUpdate) && (React.createElement(Link, { to: `/cam/job-descriptions/${value}` },
                        React.createElement(Tooltip, { id: `job-description-${value}-edit`, text: canUpdate ? 'Editar' : 'Visualizar' },
                            React.createElement(Button, { icon: canUpdate ? 'edit' : 'eye', color: "info", rounded: true })))),
                    canDelete && (React.createElement(Tooltip, { id: `job-description-${value}-remove`, text: "Deletar" },
                        React.createElement(Button, { className: "ml-2", icon: "trash", color: "danger", title: "Deletar", onClick: () => onDeleteJobDescription(value), rounded: true })))));
            }
        }
    ], [canRead, canUpdate, canDelete, onDeleteJobDescription]);
    useDocumentTitle('Cargos');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Cargos" }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            canCreate && (React.createElement(Link, { to: "/cam/job-descriptions/new" },
                React.createElement(Button, { text: "Novo Cargo", icon: "add", color: "info", animationHover: "pop" }))),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Cargos", formConfig: { searchFields, filterParams, withFields: true, withSituation: true }, handleFilter: setParams }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhum cargo a ser exibido" })));
};
export default JobDescriptionList;
