import React from 'react';
import { animated, useTransition } from 'react-spring';
const TabContent = ({ isActive, ...props }) => {
    const transition = useTransition(isActive, {
        from: { opacity: 0, transform: 'translateX(100%)', zIndex: 1 },
        enter: { opacity: 1, transform: 'translateX(0)', zIndex: 2 },
        leave: { opacity: 0, transform: 'translateX(-100%)', position: 'absolute', zIndex: 1 },
        trail: 150
    });
    return transition((style, value) => value && (React.createElement(animated.div, { style: style, className: "w-full" },
        React.createElement("div", { ...props }))));
};
export default TabContent;
