import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Icon } from 'components';
import { useTableFilter, useDocumentTitle, useExportQuery, useSolicitationLeftOverMoneyPagination } from 'hooks';
import moment from 'moment-timezone';
import { FormatterUtils } from 'utils';
import { SolicitationLeftOverMoneyTableFilterForm } from './components';
const SolicitationLeftOverMoneyReport = () => {
    const customParamsHandle = useCallback((filterParams) => {
        const params = {};
        if (filterParams.PersonCallName) {
            params.SearchField = 'CallName';
            params.SearchQuery = filterParams.PersonCallName;
        }
        if (filterParams.PersonName) {
            params.SearchField = 'PersonName';
            params.SearchQuery = filterParams.PersonName;
        }
        if (filterParams.SolicitationStateReason) {
            params.SearchField = 'SolicitationStateReason';
            params.SearchQuery = filterParams.SolicitationStateReason;
        }
        return params;
    }, []);
    const startDate = moment().utc().startOf('month');
    const endDate = moment().utc().endOf('month');
    const initialParams = {
        DateRange: [startDate, endDate]
    };
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter({
        initialParams,
        customParamsHandle
    });
    const { data, isLoading, ...pagination } = useSolicitationLeftOverMoneyPagination(requestFilterParams);
    const exportExcelQuery = useExportQuery('solicitation-left-over-money/by-parameters/download', requestFilterParams);
    const breadcrumb = useMemo(() => [
        {
            Title: 'CAM'
        },
        {
            Title: 'Caixa CAM'
        }
    ], []);
    const columns = useMemo(() => [
        {
            Header: 'Associado',
            accessor: 'Beneficiary',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement(Link, { to: `/beneficiaries/${value?.Id}`, target: "_blank" },
                    React.createElement("span", { className: "flex items-center font-semibold text-gray-light dark:text-gray-50" },
                        value.CallName,
                        React.createElement(Icon, { name: "new-tab", size: 14, className: "text-blue-light ml-1" }))));
            }
        },
        {
            Header: 'Cargo',
            accessor: 'Beneficiary',
            id: 'BeneficiaryJobDescription',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => value.JobDescription?.Name ?? ''
        },
        {
            Header: 'Requerimento',
            accessor: 'Solicitation',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement(Link, { to: `/cam/solicitations/${value.Id}`, target: "_blank" },
                    React.createElement("span", { className: "flex items-center font-semibold text-gray-light dark:text-gray-50" },
                        value.RegistrationCode,
                        React.createElement(Icon, { name: "new-tab", size: 14, className: "text-blue-light ml-1" }))));
            }
        },
        {
            Header: 'Mês/Ano',
            accessor: 'MonthYearReference',
            Cell: ({ cell: { value } }) => moment(value).format('MM/YYYY')
        },
        {
            Header: 'Duração',
            accessor: 'TotalDays',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => `${value} dias`
        },
        {
            Header: 'Valor Original',
            accessor: 'InstallmentAmount',
            Cell: ({ cell: { value } }) => FormatterUtils.money(value)
        },
        {
            Header: 'Valor Recebido Associado',
            accessor: 'ReceivedAmount',
            Cell: ({ cell: { value } }) => FormatterUtils.money(value)
        },
        {
            Header: 'Valor Caixa CAM',
            accessor: 'LeftOverAmount',
            Cell: ({ cell: { value } }) => FormatterUtils.money(value)
        }
    ], []);
    useDocumentTitle('Caixa CAM');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Caixa CAM", breadcrumb: breadcrumb }),
        React.createElement("div", { className: clsx('flex mb-4 justify-between') },
            React.createElement("a", { href: exportExcelQuery, target: "_blank", rel: "noreferrer" },
                React.createElement(Button, { text: "Exportar", icon: "download", color: "info", animationHover: "pop" })),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Relat\u00F3rio", formConfig: { filterParams }, handleFilter: setParams, customFilters: SolicitationLeftOverMoneyTableFilterForm }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhum registro a ser exibido" })));
};
export default SolicitationLeftOverMoneyReport;
