import React, { useMemo } from 'react';
import { Card, PageHeader, Loading } from 'components';
import { useAlert } from 'contexts/alert.context';
import hash from 'crypto-js/sha256';
import { useDocumentTitle, useMeQuery, useMeMutation } from 'hooks';
import { UserForm } from 'modules/User/components';
import moment from 'moment-timezone';
const Profile = () => {
    const { success, errorCallback } = useAlert();
    const [user, isLoading] = useMeQuery();
    const [update] = useMeMutation();
    async function submitUser(userData) {
        const userDataSubmit = { ...userData };
        userDataSubmit.CreatedAt = moment().format();
        userDataSubmit.UpdatedAt = null;
        if (userDataSubmit.Password && userDataSubmit.NewPassword) {
            userDataSubmit.Password = hash(userDataSubmit.Password).toString();
            userDataSubmit.NewPassword = hash(userDataSubmit.NewPassword).toString();
        }
        return update(userDataSubmit, {
            onSuccess: () => success({
                title: 'Perfil atualizado!',
                message: 'Seus dados foram atualizados com sucesso',
                id: 'USER_PUT_SUCCESS'
            }),
            onError: err => errorCallback(err, 'USER_PUT_FAILURE')
        });
    }
    const pageTitle = useMemo(() => 'Meu Perfil', []);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Meu Perfil" }),
        React.createElement(Card, null,
            React.createElement(Loading, { show: isLoading }),
            React.createElement(UserForm, { user: user, isLoading: isLoading, handleSubmit: submitUser, isProfileEdition: true }))));
};
export default Profile;
