import React, { useMemo } from 'react';
import { Icon, Tooltip } from 'components';
import { SolicitationPaymentFlowInstallmentDefaultValues } from 'configs';
import moment from 'moment-timezone';
import { FormatterUtils } from 'utils';
import './PaymentFlowInstallmentDetails.scss';
const PaymentFlowInstallmentDetails = ({ paymentFlow, paymentFlowInstallment }) => {
    const totalValue = useMemo(() => paymentFlowInstallment.WorkAccident
        ? paymentFlowInstallment.TotalValueAfterAccidentPercentage
        : paymentFlowInstallment.TotalValueAfterMonthlyPercentage, [paymentFlowInstallment]);
    const formattedMonth = useMemo(() => moment(paymentFlowInstallment.MonthYear).format('MMM/YY'), [
        paymentFlowInstallment
    ]);
    const planDetails = useMemo(() => paymentFlow.Plan?.PlanRanges?.find(x => x.Id === paymentFlowInstallment.PlanRangeId), [paymentFlow, paymentFlowInstallment]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "payment-flow-installment-details" },
            React.createElement("h3", { className: "font-semibold" },
                "Parcela ",
                formattedMonth),
            React.createElement("div", { className: "payment-flow-installment-details-item" },
                React.createElement("small", null, "Qnt. de di\u00E1rias \u00E0 serem pagas"),
                React.createElement("p", { className: "text-sm text-gray font-bold dark:text-gray-200" }, paymentFlowInstallment.DailyRateAmount)),
            React.createElement("div", { className: "payment-flow-installment-details-item" },
                React.createElement("small", null, "Qnt. de di\u00E1rias do plano"),
                React.createElement("p", { className: "text-sm text-gray font-bold dark:text-gray-200" }, planDetails?.DailyAmount)),
            React.createElement("div", { className: "payment-flow-installment-details-item" },
                React.createElement("small", { className: "flex items-center" },
                    "Valor Base Mensal",
                    React.createElement(Tooltip, { id: `payment-flow-installment-${paymentFlowInstallment.Id.toString()}-month-value`, text: "Qnt. de Di\u00E1rias do Plano x Valor da Di\u00E1ria" },
                        React.createElement(Icon, { name: "warning", size: 14, className: "ml-1" }))),
                React.createElement("p", { className: "text-sm text-gray font-bold dark:text-gray-200" }, FormatterUtils.money(paymentFlowInstallment.DailyRateCalculatedValue))),
            Boolean(paymentFlowInstallment.TotalValueAfterCompensationRate) && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "payment-flow-installment-details-item" },
                    React.createElement("small", null, "Car\u00EAncia (Escalonamento)"),
                    React.createElement("p", { className: "text-sm text-gray font-bold dark:text-gray-200" }, FormatterUtils.percentage(paymentFlowInstallment.CompensationPercentage))),
                React.createElement("div", { className: "payment-flow-installment-details-item" },
                    React.createElement("small", null, "Subtotal"),
                    React.createElement("p", { className: "text-sm text-gray font-bold dark:text-gray-200" }, FormatterUtils.money(paymentFlowInstallment.TotalValueAfterCompensationRate))))),
            Boolean(paymentFlowInstallment.TotalValueAfterMonthlyPercentage) && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "payment-flow-installment-details-item" },
                    React.createElement("small", null, "Taxa Base Mensal"),
                    React.createElement("p", { className: "text-sm text-gray font-bold dark:text-gray-200" }, FormatterUtils.percentage(paymentFlowInstallment.MonthYearPercentage))),
                React.createElement("div", { className: "payment-flow-installment-details-item" },
                    React.createElement("small", null, "Subtotal"),
                    React.createElement("p", { className: "text-sm text-gray font-bold dark:text-gray-200" }, FormatterUtils.money(paymentFlowInstallment.TotalValueAfterMonthlyPercentage))))),
            Boolean(paymentFlowInstallment.TotalValueAfterAccidentPercentage) && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "payment-flow-installment-details-item" },
                    React.createElement("small", null, "Taxa Fixa de Acidente de Trabalho"),
                    React.createElement("p", { className: "text-sm text-gray font-bold dark:text-gray-200" }, FormatterUtils.percentage(SolicitationPaymentFlowInstallmentDefaultValues.DEFAULT_WORK_ACCIDENT_PERCENTAGE))))),
            React.createElement("div", { className: "payment-flow-installment-details-item total-value" },
                React.createElement("small", null, "Valor Total"),
                paymentFlowInstallment.CancelledBeforePayment ? (React.createElement("div", { className: "flex items-center" },
                    React.createElement("span", { className: "text-xs text-gray line-through dark:text-gray-200 mr-1" }, FormatterUtils.money(totalValue)),
                    React.createElement("span", { className: "text-lg font-bold text-red-light dark:text-red-400" }, FormatterUtils.money(0)))) : (React.createElement("p", { className: "text-lg font-bold text-red-light dark:text-red-400" }, FormatterUtils.money(totalValue)))))));
};
export default PaymentFlowInstallmentDetails;
