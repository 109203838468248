import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { makeHttpService } from 'services/http';
export const useSolicitationInstallment = () => {
    const httpService = makeHttpService();
    const getSolicitationInstallmentCount = useCallback(async (solicitationInstallmentCountData) => {
        return httpService.post('/solicitation/installment-count', solicitationInstallmentCountData);
    }, [httpService]);
    const { mutateAsync, isLoading } = useMutation(getSolicitationInstallmentCount);
    return [mutateAsync, isLoading];
};
