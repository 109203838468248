import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { Icon } from 'components/Icon';
const SidebarItem = ({ module, active, onMouseEnter }) => {
    return (React.createElement("li", { onMouseEnter: onMouseEnter }, module.Url ? (React.createElement(NavLink, { end: true, to: module.Url || '', className: ({ isActive }) => clsx('sidebar-link', isActive && 'active') },
        React.createElement(Icon, { name: module.Icon, size: 32 }),
        React.createElement("span", null, module.Name))) : (React.createElement("div", { className: clsx('sidebar-link', active && 'active') },
        React.createElement(Icon, { name: module.Icon, size: 32 }),
        React.createElement("span", null, module.Name)))));
};
export default SidebarItem;
