import React, { useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import clsx from 'clsx';
import { Button, Loading, PageHeader } from 'components';
import { useDocumentTitle, useSolicitationQuery } from 'hooks';
import { SolicitationApprovalReportContent } from './components';
const SolicitationApprovalReport = () => {
    const solicitationIdRoute = parseInt(useParams()?.solicitationId ?? '0');
    const [solicitation, isLoading] = useSolicitationQuery(solicitationIdRoute);
    const printComponentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current
    });
    const pageTitle = useMemo(() => `Relatório de Aprovações do Requerimento #${solicitationIdRoute}`, [
        solicitationIdRoute
    ]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Requerimentos',
            Url: '/cam/solicitations'
        },
        {
            Title: `#${solicitationIdRoute}`,
            Url: `/cam/solicitations/${solicitationIdRoute}`
        },
        {
            Title: 'Relatório de Aprovações'
        }
    ], [solicitationIdRoute]);
    useDocumentTitle(pageTitle);
    if (isLoading || !solicitation?.Id) {
        return (React.createElement(React.Fragment, null,
            React.createElement(PageHeader, { title: pageTitle, breadcrumb: breadcrumb }),
            React.createElement(Loading, { show: true })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: pageTitle, breadcrumb: breadcrumb }),
        React.createElement("div", { className: clsx('flex justify-end mb-4') },
            React.createElement(Button, { text: "Imprimir", icon: "printer", color: "info", animationHover: "pop", onClick: handlePrint })),
        React.createElement(SolicitationApprovalReportContent, { ref: printComponentRef, solicitation: solicitation })));
};
export default SolicitationApprovalReport;
