import Add from 'assets/icons/add.svg';
import Address from 'assets/icons/address.svg';
import Bank from 'assets/icons/bank.svg';
import Beneficiary from 'assets/icons/beneficiary.svg';
import Benefit from 'assets/icons/benefit.svg';
import Calendar from 'assets/icons/calendar.svg';
import Certificate from 'assets/icons/certificate.svg';
import ChevronDown from 'assets/icons/chevron-down.svg';
import ChevronLeft from 'assets/icons/chevron-left.svg';
import ChevronRight from 'assets/icons/chevron-right.svg';
import ChevronUp from 'assets/icons/chevron-up.svg';
import Clock from 'assets/icons/clock.svg';
import Close from 'assets/icons/close.svg';
import Dependent from 'assets/icons/dependent.svg';
import Dislike from 'assets/icons/dislike.svg';
import Download from 'assets/icons/download.svg';
import Edit from 'assets/icons/edit.svg';
import Error from 'assets/icons/error.svg';
import Exit from 'assets/icons/exit.svg';
import EyeClosed from 'assets/icons/eye-closed.svg';
import Eye from 'assets/icons/eye.svg';
import Filter from 'assets/icons/filter.svg';
import ForgotPassword from 'assets/icons/forgot-password.svg';
import Home from 'assets/icons/home.svg';
import IdCard from 'assets/icons/id-card.svg';
import Info from 'assets/icons/info.svg';
import Like from 'assets/icons/like.svg';
import Mail from 'assets/icons/mail.svg';
import Menu from 'assets/icons/menu.svg';
import Minus from 'assets/icons/minus.svg';
import MoneyBag from 'assets/icons/money-bag.svg';
import Moon from 'assets/icons/moon.svg';
import NewTab from 'assets/icons/new-tab.svg';
import New from 'assets/icons/new.svg';
import Operation from 'assets/icons/operation.svg';
import Password from 'assets/icons/password.svg';
import Phone from 'assets/icons/phone.svg';
import Printer from 'assets/icons/printer.svg';
import Report from 'assets/icons/report.svg';
import Revision from 'assets/icons/revision.svg';
import Search from 'assets/icons/search.svg';
import Solicitation from 'assets/icons/solicitation.svg';
import SortAsc from 'assets/icons/sort-asc.svg';
import SortDesc from 'assets/icons/sort-desc.svg';
import Spinner from 'assets/icons/spinner.svg';
import Success from 'assets/icons/success.svg';
import Sun from 'assets/icons/sun.svg';
import Tick from 'assets/icons/tick.svg';
import Trash from 'assets/icons/trash.svg';
import Upload from 'assets/icons/upload.svg';
import UserSuccess from 'assets/icons/user-success.svg';
import User from 'assets/icons/user.svg';
import Warning from 'assets/icons/warning.svg';
export const iconComponents = {
    add: Add,
    address: Address,
    bank: Bank,
    beneficiary: Beneficiary,
    benefit: Benefit,
    calendar: Calendar,
    certificate: Certificate,
    'chevron-down': ChevronDown,
    'chevron-left': ChevronLeft,
    'chevron-right': ChevronRight,
    'chevron-up': ChevronUp,
    clock: Clock,
    close: Close,
    dependent: Dependent,
    dislike: Dislike,
    download: Download,
    edit: Edit,
    error: Error,
    exit: Exit,
    'eye-closed': EyeClosed,
    eye: Eye,
    filter: Filter,
    'forgot-password': ForgotPassword,
    home: Home,
    'id-card': IdCard,
    info: Info,
    like: Like,
    mail: Mail,
    menu: Menu,
    minus: Minus,
    'money-bag': MoneyBag,
    moon: Moon,
    'new-tab': NewTab,
    new: New,
    operation: Operation,
    password: Password,
    phone: Phone,
    printer: Printer,
    report: Report,
    revision: Revision,
    search: Search,
    solicitation: Solicitation,
    'sort-asc': SortAsc,
    'sort-desc': SortDesc,
    spinner: Spinner,
    success: Success,
    sun: Sun,
    tick: Tick,
    trash: Trash,
    upload: Upload,
    'user-success': UserSuccess,
    user: User,
    warning: Warning
};
