export var SolicitationStateReason;
(function (SolicitationStateReason) {
    SolicitationStateReason[SolicitationStateReason["IN_REVISION_PAYMENT_FLOW_REVISION"] = 1] = "IN_REVISION_PAYMENT_FLOW_REVISION";
    SolicitationStateReason[SolicitationStateReason["IN_REVISION_MEDICAL_REVISION"] = 2] = "IN_REVISION_MEDICAL_REVISION";
    SolicitationStateReason[SolicitationStateReason["CANCELLED_INVALID_REGISTRATION_DATA"] = 3] = "CANCELLED_INVALID_REGISTRATION_DATA";
    SolicitationStateReason[SolicitationStateReason["REPROVED_REPROVED_ON_VOTE"] = 4] = "REPROVED_REPROVED_ON_VOTE";
    SolicitationStateReason[SolicitationStateReason["FINISHED_CHANGE_TREATMENT_METHOD"] = 5] = "FINISHED_CHANGE_TREATMENT_METHOD";
    SolicitationStateReason[SolicitationStateReason["FINISHED_FINISHED_WITH_SUCCESS"] = 6] = "FINISHED_FINISHED_WITH_SUCCESS";
    SolicitationStateReason[SolicitationStateReason["IN_APPROVAL_WAITING_COMITEE_APPROVAL"] = 7] = "IN_APPROVAL_WAITING_COMITEE_APPROVAL";
    SolicitationStateReason[SolicitationStateReason["FINISHED_DOCTOR_REPROVAL_ON_PERIODIC_REVISION"] = 8] = "FINISHED_DOCTOR_REPROVAL_ON_PERIODIC_REVISION";
    SolicitationStateReason[SolicitationStateReason["FINISHED_DOCTOR_APPROVAL_ON_PERIODIC_REVISION"] = 9] = "FINISHED_DOCTOR_APPROVAL_ON_PERIODIC_REVISION";
    SolicitationStateReason[SolicitationStateReason["IN_REVISION_SOLICITATION_PERIODIC_REVISION"] = 10] = "IN_REVISION_SOLICITATION_PERIODIC_REVISION";
    SolicitationStateReason[SolicitationStateReason["FINISHED_SUCCESSFUL_ON_PERIODIC_REVISION"] = 11] = "FINISHED_SUCCESSFUL_ON_PERIODIC_REVISION";
    SolicitationStateReason[SolicitationStateReason["CANCELLED_REGISTRATION_LOST"] = 12] = "CANCELLED_REGISTRATION_LOST";
    SolicitationStateReason[SolicitationStateReason["CANCELLED_INTERRUPTION_BY_JFC"] = 13] = "CANCELLED_INTERRUPTION_BY_JFC";
    SolicitationStateReason[SolicitationStateReason["CANCELLED_OTHER_REASONS"] = 14] = "CANCELLED_OTHER_REASONS";
})(SolicitationStateReason || (SolicitationStateReason = {}));
export const SolicitationStateReasonLabel = {
    [SolicitationStateReason.IN_REVISION_PAYMENT_FLOW_REVISION]: 'Aguardando aprovação do fluxo de pagamento',
    [SolicitationStateReason.IN_REVISION_MEDICAL_REVISION]: 'Aguardando aprovação médica',
    [SolicitationStateReason.CANCELLED_INVALID_REGISTRATION_DATA]: 'Dados cadastrais inválidos',
    [SolicitationStateReason.REPROVED_REPROVED_ON_VOTE]: 'Requerimento reprovado',
    [SolicitationStateReason.FINISHED_CHANGE_TREATMENT_METHOD]: 'Alteração no método de tratamento',
    [SolicitationStateReason.FINISHED_FINISHED_WITH_SUCCESS]: 'Finalizada com sucesso',
    [SolicitationStateReason.IN_APPROVAL_WAITING_COMITEE_APPROVAL]: 'Aguardando votação do comitê',
    [SolicitationStateReason.FINISHED_DOCTOR_REPROVAL_ON_PERIODIC_REVISION]: 'Revisão médica reprovada',
    [SolicitationStateReason.FINISHED_DOCTOR_APPROVAL_ON_PERIODIC_REVISION]: 'Revisão médica aprovada',
    [SolicitationStateReason.IN_REVISION_SOLICITATION_PERIODIC_REVISION]: 'Em revisão médica',
    [SolicitationStateReason.FINISHED_SUCCESSFUL_ON_PERIODIC_REVISION]: 'Alta médica',
    [SolicitationStateReason.CANCELLED_REGISTRATION_LOST]: 'Perda de carteira',
    [SolicitationStateReason.CANCELLED_INTERRUPTION_BY_JFC]: 'Interrupção do processo pela JFC',
    [SolicitationStateReason.CANCELLED_OTHER_REASONS]: 'Outros motivos'
};
export const solicitationStateFinishedReasonOptions = [
    SolicitationStateReason.FINISHED_CHANGE_TREATMENT_METHOD,
    SolicitationStateReason.FINISHED_FINISHED_WITH_SUCCESS,
    SolicitationStateReason.FINISHED_SUCCESSFUL_ON_PERIODIC_REVISION,
    SolicitationStateReason.CANCELLED_INTERRUPTION_BY_JFC
];
export const solicitationStateCancelledReasonOptions = [
    SolicitationStateReason.CANCELLED_INVALID_REGISTRATION_DATA,
    SolicitationStateReason.CANCELLED_REGISTRATION_LOST,
    SolicitationStateReason.CANCELLED_INTERRUPTION_BY_JFC,
    SolicitationStateReason.CANCELLED_OTHER_REASONS
];
export const solicitationStateReviewReasonOptions = [SolicitationStateReason.IN_REVISION_MEDICAL_REVISION];
export const convertStateEnumListToOptionList = (enumList) => {
    return enumList.map(x => ({
        label: SolicitationStateReasonLabel[x],
        value: x
    }));
};
