import React from 'react';
import { Navigate, Outlet, useHref, useLocation } from 'react-router-dom';
export const CommonRoute = () => {
    const { pathname } = useLocation();
    const to = useHref(pathname);
    const loginUrl = `/login?returnUrl=${to}`;
    const authPaths = {
        '/login': '/login',
        '/recover-password': '/recover-password'
    };
    return authPaths[pathname] ? React.createElement(Outlet, null) : React.createElement(Navigate, { to: loginUrl });
};
