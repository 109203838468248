import { useEffect } from 'react';
import { useFormikContext } from 'formik';
const getFirstErrorKey = (object, keys = []) => {
    const firstErrorKey = Object.keys(object)[0];
    const type = typeof object[firstErrorKey];
    if (object[firstErrorKey] !== null && (type === 'object' || type === 'function')) {
        return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
    }
    return [...keys, firstErrorKey].join('.');
};
const FormikOnError = ({ children }) => {
    const formik = useFormikContext();
    useEffect(() => {
        if (!formik.isValid && formik.submitCount > 0) {
            const firstErrorKey = getFirstErrorKey(formik.errors);
            if (window.document.getElementsByName(firstErrorKey).length) {
                window.document.getElementsByName(firstErrorKey)[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [formik.submitCount, formik.isValid, formik.errors]);
    return children;
};
export default FormikOnError;
