import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { OPERATION_BASE_LIST_QUERY_KEY } from './useOperationBaseConfig';
export const useOperationBaseList = () => {
    const { errorCallback } = useAlert();
    const getOperationBases = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get('/operation-base/by-parameters', {
            signal
        })
            .then(res => res.Registers)
            .catch(error => {
            errorCallback(error, 'OPERATION_BASE_GET_ALL_FAILURE');
            return [];
        });
    }, [errorCallback]);
    const { data, isLoading } = useQuery([OPERATION_BASE_LIST_QUERY_KEY], getOperationBases, {
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    const memoizedData = useMemo(() => data ?? [], [data]);
    return [memoizedData, isLoading];
};
