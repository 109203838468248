import React from 'react';
import { Col, Row } from 'react-grid-system';
import clsx from 'clsx';
import { Card } from 'components/Card';
import { StepperFormContext, StepperFormProvider } from './StepperFormContext';
import { StepperFormHeader } from './StepperFormHeader';
import './StepperForm.scss';
const BASE_ELEMENT_CLASS = 'stepper-form-';
const StepperForm = ({ items, initialIndex = 0 }) => {
    return (React.createElement(StepperFormProvider, { initialIndex: initialIndex, items: items },
        React.createElement(StepperFormContext.Consumer, null, ({ activeItem, onPressItem }) => (React.createElement(Row, null,
            React.createElement(Col, { lg: 3 },
                React.createElement(Card, { className: clsx(BASE_ELEMENT_CLASS + 'header') },
                    React.createElement("ul", { className: clsx(BASE_ELEMENT_CLASS + 'header-list') }, items
                        .filter(x => !x.hide)
                        .map((item, index) => (React.createElement(StepperFormHeader, { key: index, item: item, active: activeItem.key === item.key, onPressItem: onPressItem })))))),
            React.createElement(Col, { lg: 9 },
                React.createElement(Card, { className: clsx(BASE_ELEMENT_CLASS + 'content') },
                    React.createElement("h1", { className: "text-xl font-semibold text-gray dark:text-gray-50 mb-4" }, activeItem?.title),
                    activeItem?.content)))))));
};
export default StepperForm;
