import React, { useCallback, useState } from 'react';
import { Modal, ScrollView } from 'components';
import { useModulePermissionsByScope } from 'hooks';
import { Scope } from 'types/enum';
import { SolicitationCard } from '../Card';
import { PaymentFlowInstallment } from './PaymentFlowInstallment';
import { PaymentFlowInstallmentDetails } from './PaymentFlowInstallmentDetails';
const SolicitationPaymentFlowCard = ({ paymentFlow }) => {
    const { canList } = useModulePermissionsByScope(Scope.SOLICITATION_PAYMENT_FLOW);
    const [selectedPaymentFlowInstallment, setSelectedPaymentFlowInstallment] = useState(null);
    const showPaymentFlowInstallmentDetailsModal = !!selectedPaymentFlowInstallment?.Id;
    const onSelectInstallment = useCallback((paymentFlowInstallment) => {
        setSelectedPaymentFlowInstallment(paymentFlowInstallment);
    }, []);
    const closeInstallmentModal = useCallback(() => setSelectedPaymentFlowInstallment(null), []);
    if (!paymentFlow.SolicitationPaymentFlowInstallments?.length) {
        return (React.createElement(SolicitationCard, { title: "Detalhes dos Pagamentos", className: "h-full" },
            React.createElement("p", { className: "text-xs text-red-light mt-2" }, "Erro ao recuperar a configura\u00E7\u00E3o de pagamento.")));
    }
    if (!canList) {
        return (React.createElement(SolicitationCard, { title: "Detalhes dos Pagamentos", className: "h-full" },
            React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400 mt-2" }, "Voc\u00EA n\u00E3o tem permiss\u00E3o para ver esse conte\u00FAdo.")));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(SolicitationCard, { title: "Detalhes dos Pagamentos", className: "h-full" },
            React.createElement(ScrollView, { maxHeight: 300 }, paymentFlow.SolicitationPaymentFlowInstallments.map(paymentFlowInstallment => (React.createElement(PaymentFlowInstallment, { key: paymentFlowInstallment.Id.toString(), paymentFlowInstallment: paymentFlowInstallment, onSelectInstallment: onSelectInstallment }))))),
        React.createElement(Modal, { show: showPaymentFlowInstallmentDetailsModal, handleClose: closeInstallmentModal, title: "Detalhes do Pagamento", closable: true },
            React.createElement(PaymentFlowInstallmentDetails, { paymentFlow: paymentFlow, paymentFlowInstallment: selectedPaymentFlowInstallment }))));
};
export default SolicitationPaymentFlowCard;
