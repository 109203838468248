import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { SOLICITATION_DOCUMENT_LIST_KEY } from './useSolicitationDocumentConfig';
export const useSolicitationDocumentMutation = (solicitationId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createSolicitationDocument = useCallback(async (solicitationDocumentData) => {
        return httpService.post('/solicitation-document', solicitationDocumentData);
    }, [httpService]);
    const removeSolicitationDocument = useCallback(async (solicitationDocumentId) => {
        return httpService.delete(`/solicitation-document?id=${solicitationDocumentId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createSolicitationDocument, {
        onSuccess: () => {
            queryClient.invalidateQueries([SOLICITATION_DOCUMENT_LIST_KEY, solicitationId]);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removeSolicitationDocument, {
        onSuccess: () => {
            queryClient.invalidateQueries([SOLICITATION_DOCUMENT_LIST_KEY, solicitationId]);
        }
    });
    return {
        create,
        remove,
        isCreating,
        isRemoving
    };
};
