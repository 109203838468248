import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useBeneficiaryQuery, useBeneficiaryMutation, useDocumentTitle } from 'hooks';
import moment from 'moment-timezone';
import { DateFormat } from 'types/enum';
import { FormatterUtils, ValidationUtils } from 'utils';
import { BeneficiaryPersonalStepTab } from './BeneficiaryPersonalTab.types';
import { BeneficiaryPaymentDataForm } from './PaymentDataForm';
import { BeneficiaryPersonalDataForm } from './PersonalDataForm';
import { BeneficiaryProfessionalDataForm } from './ProfessionalDataForm';
import { BeneficiaryRecommendationForm } from './RecommendationForm';
const BeneficiaryPersonalTab = ({ beneficiaryId }) => {
    const { success, errorCallback } = useAlert();
    const navigate = useNavigate();
    const tabsRef = useRef(null);
    const [beneficiary, isLoading] = useBeneficiaryQuery(beneficiaryId);
    const [beneficiaryRegistrationData, setBeneficiaryRegistrationData] = useState(null);
    const { create, update } = useBeneficiaryMutation(beneficiaryId);
    const createBeneficiary = useCallback(async (beneficiaryRegistrationData) => {
        const beneficiaryDataSubmit = {
            ...beneficiaryRegistrationData.PersonalData,
            ...beneficiaryRegistrationData.ProfessionalData,
            ...beneficiaryRegistrationData.PaymentData
        };
        beneficiaryDataSubmit.CreatedAt = moment().format();
        beneficiaryDataSubmit.BirthDate = FormatterUtils.date(beneficiaryDataSubmit?.BirthDate, DateFormat.DATE_EN_US);
        beneficiaryDataSubmit.CompanyStartDate = FormatterUtils.date(beneficiaryDataSubmit?.CompanyStartDate, DateFormat.DATE_EN_US);
        beneficiaryDataSubmit.AssociationStartDate = FormatterUtils.date(beneficiaryDataSubmit?.AssociationStartDate, DateFormat.DATE_EN_US);
        beneficiaryDataSubmit.CMACertificateExpirationDate = beneficiaryDataSubmit.CMACertificateExpirationDate
            ? FormatterUtils.date(beneficiaryDataSubmit?.CMACertificateExpirationDate, DateFormat.DATE_EN_US)
            : null;
        await create(beneficiaryDataSubmit)
            .then(res => {
            success({
                title: 'Associado cadastrado!',
                message: 'Associado cadastrado com sucesso.',
                id: 'BENEFICIARY_POST_SUCCESS'
            });
            navigate(`/beneficiaries/${res.Id}`);
            tabsRef.current?.changeActiveKey(BeneficiaryPersonalStepTab.PERSONAL_DATA);
        })
            .catch(err => errorCallback(err, 'BENEFICIARY_POST_FAILURE'));
    }, [create, errorCallback, success, navigate]);
    const updateBeneficiary = useCallback(async (beneficiaryData) => {
        const beneficiaryDataSubmit = { ...beneficiaryData };
        beneficiaryDataSubmit.CreatedAt = beneficiary?.CreatedAt || moment().format();
        beneficiaryDataSubmit.UpdatedAt = moment().format();
        beneficiaryDataSubmit.BirthDate = FormatterUtils.date(beneficiaryDataSubmit?.BirthDate, DateFormat.DATE_EN_US);
        beneficiaryDataSubmit.CompanyStartDate = FormatterUtils.date(beneficiaryDataSubmit?.CompanyStartDate, DateFormat.DATE_EN_US);
        beneficiaryDataSubmit.AssociationStartDate = FormatterUtils.date(beneficiaryDataSubmit?.AssociationStartDate, DateFormat.DATE_EN_US);
        beneficiaryDataSubmit.CMACertificateExpirationDate = beneficiaryDataSubmit.CMACertificateExpirationDate
            ? FormatterUtils.date(beneficiaryDataSubmit?.CMACertificateExpirationDate, DateFormat.DATE_EN_US)
            : null;
        await update(beneficiaryDataSubmit)
            .then(() => success({
            title: 'Associado atualizado!',
            message: 'Dados do associado atualizados com sucesso.',
            id: 'BENEFICIARY_PUT_SUCCESS'
        }))
            .catch(err => errorCallback(err, 'BENEFICIARY_PUT_FAILURE'));
    }, [update, errorCallback, success]);
    const onSubmitPersonalData = useCallback(async (beneficiaryPersonalData) => {
        if (beneficiaryPersonalData.Id)
            return updateBeneficiary({ ...beneficiary, ...beneficiaryPersonalData });
        setBeneficiaryRegistrationData(beneficiaryRegistrationData => ({
            ...beneficiaryRegistrationData,
            PersonalData: beneficiaryPersonalData
        }));
        tabsRef.current?.changeActiveKey(BeneficiaryPersonalStepTab.PROFESSIONAL_DATA);
    }, [beneficiary, updateBeneficiary]);
    const onSubmitProfessionalData = useCallback(async (beneficiaryProfessionalData) => {
        if (beneficiaryProfessionalData.Id)
            return updateBeneficiary({ ...beneficiary, ...beneficiaryProfessionalData });
        setBeneficiaryRegistrationData(beneficiaryRegistrationData => ({
            ...beneficiaryRegistrationData,
            ProfessionalData: beneficiaryProfessionalData
        }));
        tabsRef.current?.changeActiveKey(BeneficiaryPersonalStepTab.PAYMENT_DATA);
    }, [beneficiary, updateBeneficiary]);
    const onSubmitPaymentData = useCallback(async (beneficiaryPaymentData) => {
        if (beneficiaryPaymentData.Id)
            return updateBeneficiary({ ...beneficiary, ...beneficiaryPaymentData });
        const beneficiaryData = {
            ...beneficiaryRegistrationData,
            PaymentData: beneficiaryPaymentData
        };
        return createBeneficiary(beneficiaryData);
    }, [beneficiary, updateBeneficiary, createBeneficiary, beneficiaryRegistrationData]);
    const BENEFICIARY_PERSONAL_STEP_TABS = useMemo(() => [
        {
            key: BeneficiaryPersonalStepTab.PERSONAL_DATA,
            title: 'Dados Pessoais',
            content: (React.createElement(BeneficiaryPersonalDataForm, { beneficiary: beneficiary ?? beneficiaryRegistrationData?.PersonalData, isLoading: isLoading, onSubmitBeneficiary: onSubmitPersonalData }))
        },
        {
            key: BeneficiaryPersonalStepTab.PROFESSIONAL_DATA,
            title: 'Dados Profissionais',
            content: (React.createElement(BeneficiaryProfessionalDataForm, { beneficiary: beneficiary ?? beneficiaryRegistrationData?.ProfessionalData, isLoading: isLoading, onSubmitBeneficiary: onSubmitProfessionalData })),
            disabled: !beneficiary?.Id && ValidationUtils.isEmptyObject(beneficiaryRegistrationData?.PersonalData)
        },
        {
            key: BeneficiaryPersonalStepTab.PAYMENT_DATA,
            title: 'Dados Bancários',
            content: (React.createElement(BeneficiaryPaymentDataForm, { beneficiary: beneficiary ?? beneficiaryRegistrationData?.PaymentData, isLoading: isLoading, onSubmitBeneficiary: onSubmitPaymentData })),
            disabled: !beneficiary?.Id && ValidationUtils.isEmptyObject(beneficiaryRegistrationData?.ProfessionalData)
        },
        {
            key: BeneficiaryPersonalStepTab.RECOMMENDATION,
            title: 'Indicação',
            content: React.createElement(BeneficiaryRecommendationForm, { beneficiaryId: beneficiaryId }),
            visible: !!beneficiary?.Id
        }
    ], [
        beneficiaryId,
        beneficiary,
        beneficiaryRegistrationData,
        isLoading,
        onSubmitPersonalData,
        onSubmitProfessionalData,
        onSubmitPaymentData
    ]);
    const pageTitle = useMemo(() => `${beneficiaryId ? 'Visualizar' : 'Novo'} Associado`, [beneficiaryId]);
    useDocumentTitle(pageTitle);
    return React.createElement(Tabs, { ref: tabsRef, items: BENEFICIARY_PERSONAL_STEP_TABS });
};
export default BeneficiaryPersonalTab;
