import React, { useMemo } from 'react';
import { ArrayUtils } from 'utils';
import { SolicitationCard } from '../Card';
import { SolicitationStateItem } from './StateItem';
const SolicitationStateTimelineCard = ({ states }) => {
    const orderedStates = useMemo(() => ArrayUtils.sortDesc(states, 'CreatedAt'), [states]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SolicitationCard, { title: "Hist\u00F3rico", className: "h-full" }, orderedStates.map(state => (React.createElement(SolicitationStateItem, { key: state.Id.toString(), state: state }))))));
};
export default SolicitationStateTimelineCard;
