import React, { useState } from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import { Icon } from 'components/Icon';
import './DateField.scss';
const DateField = ({ id, date, onDateChange, isOutsideRange, icon }) => {
    const [showDateField, toggleDateField] = useState(false);
    return (React.createElement(SingleDatePicker, { id: id, date: date, displayFormat: "DD/MM/YYYY", placeholder: "Escolha a data", onDateChange: onDateChange, isOutsideRange: isOutsideRange, focused: showDateField, customInputIcon: icon && React.createElement(Icon, { name: "calendar", className: "mr-1" }), hideKeyboardShortcutsPanel: true, onFocusChange: () => toggleDateField(!showDateField), withPortal: true }));
};
export default DateField;
