import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col } from 'react-grid-system';
import { Button, FormField, FormFieldError, Form, useFormikValidationHandler, FormikValidationHandler, PercentageField, Select, MonthField, SituationField } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useFormik } from 'formik';
import { useJobDescriptionList, useModulePermissions } from 'hooks';
import moment from 'moment-timezone';
import { FormatterUtils } from 'utils';
import { convertToMonthlyBasePercentageDto } from './MonthlyBasePercentageForm.converter';
import { MonthlyBasePercentageFormSchema } from './MonthlyBasePercentageForm.schema';
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;
const defaultValues = {
    Id: 0,
    JobDescriptionId: 0,
    MonthYear: {
        month: currentMonth,
        year: currentYear
    },
    Percentage: 0,
    CreatedAt: '',
    UpdatedAt: '',
    Active: true
};
const MonthlyBasePercentageForm = ({ monthlyBasePercentage, isLoading, handleSubmit: onSubmitMonthlyBasePercentage }) => {
    const { confirm } = useAlert();
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions(monthlyBasePercentage?.Id);
    const [jobDescriptions, isLoadingJobDescriptions] = useJobDescriptionList();
    const onSubmit = useCallback(async (monthlyBasePercentageValues, { setSubmitting }) => {
        confirm({
            title: 'Atenção!',
            description: (React.createElement("p", null,
                "Essa a\u00E7\u00E3o poder\u00E1 recalcular todas as parcelas relacionadas a este registro. ",
                React.createElement("br", null),
                "Deseja continuar?")),
            confirmText: 'Sim, continuar',
            cancelText: 'Não, cancelar',
            onConfirm: async () => {
                setSubmitting(true);
                const monthlyBasePercentageData = convertToMonthlyBasePercentageDto(monthlyBasePercentageValues);
                await onSubmitMonthlyBasePercentage(monthlyBasePercentageData);
                setSubmitting(false);
            },
            onCancel: () => setSubmitting(false)
        });
    }, [confirm, onSubmitMonthlyBasePercentage]);
    const yearsOptions = useMemo(() => {
        const currentYear = new Date().getFullYear();
        const nextYears = [...Array(5).keys()].map(i => {
            const position = i + 1;
            const nextYear = new Date();
            nextYear.setFullYear(currentYear + position);
            return nextYear.getFullYear();
        });
        return [currentYear, ...nextYears];
    }, []);
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => MonthlyBasePercentageFormSchema,
        onSubmit,
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleSubmit, isSubmitting, setValues } = formikContext;
    useEffect(() => {
        if (!monthlyBasePercentage?.Id)
            return;
        const monthlyBasePercentageDate = moment(monthlyBasePercentage.MonthYear, true);
        const monthlyBasePercentageData = {
            Id: monthlyBasePercentage.Id,
            JobDescriptionId: monthlyBasePercentage.JobDescriptionId,
            Percentage: monthlyBasePercentage.Percentage,
            MonthYear: {
                month: monthlyBasePercentageDate.month() + 1,
                year: monthlyBasePercentageDate.year()
            },
            CreatedAt: FormatterUtils.dateTime(monthlyBasePercentage.CreatedAt),
            UpdatedAt: FormatterUtils.dateTime(monthlyBasePercentage.UpdatedAt),
            Active: monthlyBasePercentage.Active ?? true
        };
        setValues(monthlyBasePercentageData);
    }, [monthlyBasePercentage, setValues]);
    if (isLoading)
        return null;
    return (React.createElement(Form, { name: "MonthlyBasePercentageForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "JobDescriptionId", label: "Cargo", options: jobDescriptions, value: jobDescriptions?.find(jobDescription => jobDescription.Id === values.JobDescriptionId), onChange: value => setFieldValue('JobDescriptionId', value.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingJobDescriptions }),
                    React.createElement(FormFieldError, { name: "JobDescriptionId" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(MonthField, { name: "MonthYear", label: "M\u00EAs", value: values.MonthYear, onChange: value => setFieldValue('MonthYear', value), years: yearsOptions }),
                    React.createElement(FormFieldError, { name: "MonthYear" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(PercentageField, { name: "Percentage", label: "Porcentagem", onChange: value => setFieldValue('Percentage', value), value: values.Percentage }),
                    React.createElement(FormFieldError, { name: "Percentage" })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Situa\u00E7\u00E3o da porcentagem mensal", handleChange: isActive => setFieldValue('Active', isActive), value: values.Active, falseLabel: "Inativa", trueLabel: "Ativa" })))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Enviar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default MonthlyBasePercentageForm;
