import { AircraftModelModule } from './AircraftModel';
import { AwayByCamModule } from './AwayByCam';
import { AwayWithoutSolicitationModule } from './AwayWithoutSolicitation';
import { BankModule } from './Bank';
import { BeneficiariesModule } from './Beneficiary';
import { BenefitModule } from './Benefit';
import { BenefitCategoryModule } from './BenefitCategory';
import { BenefitConsumptionModule } from './BenefitConsumption';
import { BenefitConsumptionBatchModule } from './BenefitConsumptionBatch';
import { CompensationRateModule } from './CompensationRate';
import { DailyRateModule } from './DailyRate';
import { DashboardModule } from './Dashboard';
import { DeseaseModule } from './Desease';
import { FinancialStatementModule } from './FinancialStatements';
import { JobDescriptionModule } from './JobDescription';
import { MonthlyBasePercentageModule } from './MonthlyBasePercentage';
import { OperationBaseModule } from './OperationBase';
import { PlanModule } from './Plan';
import { ProfileModule } from './Profile';
import { RecompositionInequalityModule } from './RecompositionInequality';
import { ReportModule } from './Report';
import { SolicitationModule } from './Solicitation';
import { SolicitationRevisionModule } from './SolicitationRevision';
import { UsersModule } from './User';
const PrivateRouter = [];
PrivateRouter.push(...DashboardModule);
PrivateRouter.push(...UsersModule);
PrivateRouter.push(...ProfileModule);
PrivateRouter.push(...BenefitModule);
PrivateRouter.push(...BenefitCategoryModule);
PrivateRouter.push(...BenefitConsumptionBatchModule);
PrivateRouter.push(...BenefitConsumptionModule);
PrivateRouter.push(...BeneficiariesModule);
PrivateRouter.push(...JobDescriptionModule);
PrivateRouter.push(...PlanModule);
PrivateRouter.push(...OperationBaseModule);
PrivateRouter.push(...AircraftModelModule);
PrivateRouter.push(...BankModule);
PrivateRouter.push(...DeseaseModule);
PrivateRouter.push(...DailyRateModule);
PrivateRouter.push(...CompensationRateModule);
PrivateRouter.push(...MonthlyBasePercentageModule);
PrivateRouter.push(...SolicitationModule);
PrivateRouter.push(...SolicitationRevisionModule);
PrivateRouter.push(...ReportModule);
PrivateRouter.push(...RecompositionInequalityModule);
PrivateRouter.push(...AwayWithoutSolicitationModule);
PrivateRouter.push(...AwayByCamModule);
PrivateRouter.push(...FinancialStatementModule);
export { PrivateRouter };
