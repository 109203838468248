import React from 'react';
import MonthlyBasePercentageInternal from './screens/MonthlyBasePercentageInternal';
import MonthlyBasePercentageList from './screens/MonthlyBasePercentageList';
const MonthlyBasePercentageModule = [
    {
        path: '/operational/monthly-percentages',
        element: React.createElement(MonthlyBasePercentageList, null)
    },
    {
        path: '/operational/monthly-percentages/new',
        element: React.createElement(MonthlyBasePercentageInternal, null)
    },
    {
        path: '/operational/monthly-percentages/:monthlyPercentageId',
        element: React.createElement(MonthlyBasePercentageInternal, null)
    }
];
export { MonthlyBasePercentageModule };
