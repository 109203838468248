import { DefaultScopeDTO } from 'types/dtos';
import { ScopeAction } from 'types/enum';
import { ArrayUtils, TextUtils } from 'utils';
const filterScopesByModule = (module, scopes, user) => {
    return scopes
        .filter(x => x.Module === module)
        .map(x => {
        const userHasScope = user.UserScopes.some(y => y.ScopeId === x.ScopeId);
        return {
            Action: x.Action,
            ScopeId: x.ScopeId,
            Value: userHasScope
        };
    });
};
const groupScopesByModule = (scopes, user) => {
    return scopes.reduce((acc, cur) => {
        const moduleName = TextUtils.camelCaseToCapitalize(cur.Module);
        if (acc.find(x => x.Module === moduleName))
            return acc;
        acc.push({
            Module: moduleName,
            Scopes: filterScopesByModule(cur.Module, scopes, user)
        });
        return acc;
    }, []);
};
const getScopeNameAndAction = (scopeName) => {
    const scopeActions = Object.values(ScopeAction);
    return scopeActions.reduce((acc, cur) => {
        const caseInsensitiveRule = 'ig';
        const regEx = new RegExp(cur, caseInsensitiveRule);
        if (!regEx.test(scopeName))
            return acc;
        if (cur === ScopeAction.Report) {
            acc.push(...[ScopeAction.Report, scopeName.replace(regEx, '')]);
            return acc;
        }
        acc.push(...[scopeName.replace(regEx, ''), cur]);
        return acc;
    }, []);
};
const removeAdminScope = (scope) => !DefaultScopeDTO.Administrator.find(x => x.Id === scope.Id);
export const mapUserScopes = (scopes, user) => {
    const mappedScopes = scopes.filter(removeAdminScope).reduce((acc, cur) => {
        const [scopeName, actionName] = getScopeNameAndAction(cur.Name);
        if (!scopeName || !actionName)
            return acc;
        acc.push({
            ScopeId: cur.Id,
            Module: scopeName,
            Action: actionName
        });
        return acc;
    }, []);
    const groupedModules = groupScopesByModule(mappedScopes, user);
    console.log(groupedModules);
    return ArrayUtils.sortAsc(groupedModules, 'Module');
};
