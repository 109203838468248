import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip } from 'components';
import { useModulePermissions, useTableFilter, useDocumentTitle, useCompensationRatePagination } from 'hooks';
import { DailyRateCustomTableFilter } from '../components';
const CompensationRateList = () => {
    const { canCreate, canRead, canUpdate } = useModulePermissions();
    const customParamsHandle = useCallback((filterParams) => {
        const params = {};
        if (filterParams.DailyRateId) {
            params.DailyRateId = filterParams.DailyRateId;
        }
        return params;
    }, []);
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter({
        customParamsHandle
    });
    const { data, isLoading, ...pagination } = useCompensationRatePagination(requestFilterParams);
    const searchFields = useMemo(() => [
        {
            Value: 'Name',
            Description: 'Nome'
        }
    ], []);
    const columns = useMemo(() => [
        {
            Header: 'Nome',
            accessor: 'Name'
        },
        {
            Header: 'Diária',
            accessor: cell => cell.DailyRate?.Name,
            disableSortBy: true
        },
        {
            Header: 'Dias De / Dias Até',
            accessor: cell => ({
                ContributionDaysFrom: cell.ContributionDaysFrom,
                ContributionDaysTo: cell.ContributionDaysTo
            }),
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement(React.Fragment, null,
                value.ContributionDaysFrom,
                " a ",
                value.ContributionDaysTo,
                " dias"))
        },
        {
            Header: 'Situação',
            accessor: 'Active',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold', value ? 'text-green-light' : 'text-red-light') }, value ? 'ATIVA' : 'INATIVA'))
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" }, (canRead || canUpdate) && (React.createElement(Link, { to: `/cam/compensation-rates/${value}` },
                    React.createElement(Tooltip, { id: `compensation-rate-${value}-edit`, text: canUpdate ? 'Editar' : 'Visualizar' },
                        React.createElement(Button, { icon: canUpdate ? 'edit' : 'eye', color: "info", rounded: true }))))));
            }
        }
    ], [canRead, canUpdate]);
    useDocumentTitle('Carência (Escalonamento)');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Car\u00EAncia (Escalonamento)" }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            canCreate && (React.createElement(Link, { to: "/cam/compensation-rates/new" },
                React.createElement(Button, { text: "Nova", icon: "add", color: "info", animationHover: "pop" }))),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Car\u00EAncia (Escalonamento)", formConfig: { searchFields, filterParams, withFields: true, withSituation: true }, handleFilter: setParams, customFilters: DailyRateCustomTableFilter }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhuma car\u00EAncia a ser exibida" })));
};
export default CompensationRateList;
