import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { animated, useTransition } from 'react-spring';
import ReactPDF from '@react-pdf/renderer';
import { Button, FormField, Modal, MonthField, Tooltip } from 'components';
import { ReportExportEndpoint, useReportBeneficiaryFinancialHistoryQuery, useReportExportMutation } from 'hooks';
import { TimeUtils } from 'utils';
import BeneficiaryFinancialHistoryExportPdf from './BeneficiaryFinancialHistoryExportPdf';
const currentYear = new Date().getFullYear();
const BeneficiaryFinancialHistoryExport = ({ beneficiary }) => {
    const [reportFile, setReportFile] = useState();
    const [monthYearRange, setMonthYearRange] = useState([
        { month: 1, year: currentYear },
        { month: 12, year: currentYear }
    ]);
    const [startMonthYear, endMonthYear] = monthYearRange;
    const reportFileName = `beneficiary-${beneficiary.Id}-financial-history-${new Date().toISOString()}.pdf`;
    const onSelectStartMonthYear = useCallback((value) => {
        setMonthYearRange([value, endMonthYear]);
    }, [endMonthYear]);
    const onSelectEndMonthYear = useCallback((value) => {
        setMonthYearRange([startMonthYear, value]);
    }, [startMonthYear]);
    const [shouldFetchBeneficiaryFinancialHistory, setShouldFetchBeneficiaryFinancialHistory] = useState(false);
    const requestFilterParams = useMemo(() => {
        const params = {};
        const startDate = `${startMonthYear?.year}-${startMonthYear?.month}-01`;
        const endDate = `${endMonthYear?.year}-${endMonthYear?.month}-01`;
        params.StartDate = startDate;
        params.EndDate = endDate;
        params.BeneficiaryId = beneficiary.Id;
        return params;
    }, [startMonthYear, endMonthYear, beneficiary]);
    const [beneficiaryFinancialHistory, isFetched] = useReportBeneficiaryFinancialHistoryQuery(shouldFetchBeneficiaryFinancialHistory, requestFilterParams);
    const [exportReport, isExporting] = useReportExportMutation(ReportExportEndpoint.BENEFICIARY_FINANCIAL_HISTORY);
    const onExportReportClick = useCallback(async () => {
        await exportReport({
            file: reportFile,
            beneficiaryId: beneficiary.Id,
            startDate: `${startMonthYear?.year}-${startMonthYear?.month}-01`,
            endDate: `${endMonthYear?.year}-${endMonthYear?.month}-01`
        });
    }, [beneficiary, reportFile, exportReport, startMonthYear, endMonthYear]);
    const [isGenerating, setIsGenerating] = useState(false);
    const PdfDocument = useMemo(() => (React.createElement(BeneficiaryFinancialHistoryExportPdf, { beneficiary: beneficiary, beneficiaryFinancialHistory: beneficiaryFinancialHistory })), [beneficiary, beneficiaryFinancialHistory]);
    const onBeneficiaryFinancialHistoryFetched = useCallback(async () => {
        const pdfInstance = await ReactPDF.pdf(PdfDocument).toBlob();
        const pdfFile = Object.assign(pdfInstance, {
            url: URL.createObjectURL(pdfInstance)
        });
        setReportFile(pdfFile);
        setIsGenerating(false);
    }, [PdfDocument]);
    useEffect(() => {
        isFetched && onBeneficiaryFinancialHistoryFetched();
    }, [onBeneficiaryFinancialHistoryFetched, isFetched]);
    const onGenerateReportClick = useCallback(async () => {
        setIsGenerating(true);
        setShouldFetchBeneficiaryFinancialHistory(true);
    }, []);
    const [showModal, setShowModal] = useState(false);
    const toggleModal = useCallback(() => setShowModal(showModal => !showModal), []);
    const canGenerate = startMonthYear && endMonthYear;
    const isGenerated = !!reportFile;
    const transition = useTransition(isGenerated, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        delay: 200
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { id: `beneficiaries-${beneficiary.Id}-export-financial`, text: "Exportar Extrato do Tripulante" },
            React.createElement(Button, { icon: "download", color: "secondary", onClick: toggleModal, className: "ml-2", rounded: true })),
        React.createElement(Modal, { show: showModal, handleClose: toggleModal, title: "Exportar", closable: true, scroll: false },
            React.createElement("h3", { className: "text-base text-gray-800 dark:text-gray-50 text-center" }, "Extrato do Tripulante"),
            React.createElement("p", { className: "text-sm text-gray-light dark:text-gray-400 text-center" }, "Escolha o per\u00EDodo para extra\u00E7\u00E3o do relat\u00F3rio."),
            React.createElement(Row, { className: "mt-4" },
                React.createElement(Col, { md: 6 },
                    React.createElement(FormField, null,
                        React.createElement(MonthField, { name: "StartMonthYear", label: "Escolha o m\u00EAs in\u00EDcio", value: startMonthYear, onChange: onSelectStartMonthYear, years: TimeUtils.getLastYears(3) }))),
                React.createElement(Col, { md: 6 },
                    React.createElement(FormField, null,
                        React.createElement(MonthField, { name: "EndMonthYear", label: "Escolha o m\u00EAs fim", value: endMonthYear, onChange: onSelectEndMonthYear, years: TimeUtils.getLastYears(3) })))),
            React.createElement("div", { className: "flex justify-center mb-[50px]" }, transition(({ opacity }, item) => item ? (React.createElement(animated.div, { style: {
                    opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
                    position: 'absolute'
                }, className: "flex gap-4" },
                React.createElement("a", { href: reportFile?.url, download: reportFileName },
                    React.createElement(Button, { text: "Exportar", icon: "download", color: "info", animationHover: "pop", loading: isExporting })),
                React.createElement(Button, { text: "Enviar por e-mail", icon: "mail", color: "secondary", animationHover: "pop", onClick: onExportReportClick, loading: isExporting }))) : (React.createElement(animated.div, { style: {
                    opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
                    position: 'absolute'
                } },
                React.createElement(Button, { text: "Gerar Extrato", color: "secondary", animationHover: "pop", onClick: onGenerateReportClick, loading: isGenerating, disabled: !canGenerate }))))))));
};
export default BeneficiaryFinancialHistoryExport;
