import { useCallback } from 'react';
import { useAlert } from 'contexts/alert.context';
import { useQueryPagination } from 'hooks/queryPagination';
import { omitBy, isEmpty, isNil } from 'lodash';
import { makeHttpService } from 'services';
import { REPORT_BENEFICIARY_BY_AGE_QUERY_KEY } from './useReportConfig';
export const useReportBeneficiaryByAgeQuery = (filterParams) => {
    const { errorCallback } = useAlert();
    const prepareParameters = useCallback((pageSize, pageSkip, sort) => {
        return {
            PerPage: pageSize,
            Skip: pageSkip,
            OrderBy: sort?.sortBy || 'CreatedAt',
            OrderDescending: sort?.sortAsc ?? true,
            ...filterParams
        };
    }, [filterParams]);
    const getBeneficiariesByAgeReport = useCallback(async ({ pageSize, pageSkip, sort, signal }) => {
        const params = prepareParameters(pageSize, pageSkip, sort);
        return makeHttpService()
            .get('/report/beneficiaries-by-age', {
            params,
            signal
        })
            .catch(error => {
            errorCallback(error, 'REPORT_BENEFICIARIES_BY_AGE_FAILURE');
            return {};
        });
    }, [errorCallback, prepareParameters]);
    const shouldEnableQuery = !isEmpty(omitBy(filterParams, isNil));
    return useQueryPagination([REPORT_BENEFICIARY_BY_AGE_QUERY_KEY, filterParams], getBeneficiariesByAgeReport, {
        enabled: shouldEnableQuery
    });
};
