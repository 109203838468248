import React, { useCallback, useLayoutEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import logoWhite from 'assets/images/logo-white.png';
import logo from 'assets/images/logo.png';
import clsx from 'clsx';
import { useTheme } from 'contexts/theme.context';
import './AuthLayout.scss';
const AuthLayout = ({ position, type, children }) => {
    const { darkMode } = useTheme();
    const onInitAuthLayout = useCallback(() => {
        setTimeout(() => {
            document.body.classList.remove('darker');
            document.getElementsByTagName('html')[0]?.classList.add('auth');
        }, 100);
    }, []);
    useLayoutEffect(onInitAuthLayout);
    const fadeInAnimationProps = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 }
    });
    return (React.createElement("section", { className: clsx('auth-container', position, type) },
        React.createElement("div", { className: "auth-box" },
            React.createElement(animated.div, { className: "auth-form", style: fadeInAnimationProps },
                React.createElement("img", { src: darkMode ? logoWhite : logo, alt: "ATL" }),
                children)),
        React.createElement(animated.div, { className: "auth-hero-image", style: fadeInAnimationProps })));
};
export default AuthLayout;
