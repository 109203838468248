import { useCallback } from 'react';
import { authenticationService } from 'services';
export const useBenefitConsumptionBatchErrorFileDownload = () => {
    const onDownloadImportErrorsFile = useCallback((benefitConsumptionBatchId) => {
        const authenticationToken = authenticationService.getToken();
        const downloadImportErrorsFileUrl = `${ENVIRONMENT.API}/benefit-consumption-batch/${benefitConsumptionBatchId}/download-import-errors?token=${authenticationToken}`;
        window.open(downloadImportErrorsFileUrl, '_blank');
    }, []);
    return onDownloadImportErrorsFile;
};
