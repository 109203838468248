import { Validation } from 'utils';
export const BenefitConsumptionBatchFormSchema = Validation.object().shape({
    BenefitId: Validation.number().required('Selecione o benefício').nullable(),
    MonthYearReference: Validation.object().required('Informe o mês'),
    BatchLines: Validation.array().of(Validation.object().shape({
        BeneficiaryName: Validation.string().required('Informe o nome'),
        BeneficiaryRegistration: Validation.string().required('Informe a matrícula'),
        Type: Validation.string().required('Informe o tipo'),
        Value: Validation.number().required('Informe o valor')
    }))
});
