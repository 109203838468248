import Papa from 'papaparse';
const getFileExtension = (fileName) => fileName.substring(fileName.lastIndexOf('.') + 1);
const getFileName = (fileName) => fileName.split('.').slice(0, -1).join('.');
const parseJson = (input) => {
    try {
        const parsedInput = JSON.parse(input);
        if (typeof parsedInput === 'object')
            return parsedInput;
        return input;
    }
    catch (e) {
        return false;
    }
};
const parseCsv = (file) => {
    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            header: true,
            delimiter: ';',
            skipEmptyLines: true,
            complete: results => {
                resolve(results?.data);
            },
            error: error => {
                reject(error);
            }
        });
    });
};
const parseCurrency = (value) => {
    return parseFloat(value.replace(/,/g, '.'));
};
export const ParserUtils = {
    getFileExtension,
    getFileName,
    parseJson,
    parseCsv,
    parseCurrency
};
