import React, { useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import { DateRangeField, FormField, MaskedTextField, SituationField, TextField } from 'components';
import { SolicitationTreatmentMethod, SolicitationTreatmentMethodLabel } from 'types/enum';
const AwayByCamTableFilterForm = ({ values, setFieldValue, handleChange }) => {
    const solicitationTreatmentMethodOptions = useMemo(() => [
        {
            name: SolicitationTreatmentMethodLabel[SolicitationTreatmentMethod.CONSERVATIVE],
            value: SolicitationTreatmentMethod.CONSERVATIVE,
            color: 'info',
            textColor: 'white'
        },
        {
            name: SolicitationTreatmentMethodLabel[SolicitationTreatmentMethod.SURGICAL],
            value: SolicitationTreatmentMethod.SURGICAL,
            color: 'info',
            textColor: 'white'
        }
    ], []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 3 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "RegistrationCode", label: "C\u00F3digo de registro", value: values.RegistrationCode ?? '', onChange: handleChange }))),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(DateRangeField, { startDate: values.DateRange[0], endDate: values.DateRange[1], onDatesChange: ({ startDate, endDate }) => setFieldValue('DateRange', [startDate, endDate]), isOutsideRange: () => false })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "cpf", name: "PersonDocument", label: "CPF do associado", value: values.PersonDocument ?? '', onChange: value => setFieldValue('PersonDocument', value) }))),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "CallName", label: "Nome de guerra do associado", value: values.CallName ?? '', onChange: handleChange })))),
        React.createElement(Row, { justify: "end" },
            React.createElement(Col, { xs: 12, md: 3 },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "M\u00E9todo de tratamento", handleChange: value => setFieldValue('SolicitationTreatmentMethod', value), value: values.SolicitationTreatmentMethod, options: solicitationTreatmentMethodOptions }))),
            React.createElement(Col, { xs: 12, md: 3 },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Acidente de trabalho", trueLabel: "N\u00E3o", falseLabel: "Sim", handleChange: value => setFieldValue('IsWorkAccident', value), value: values.IsWorkAccident }))))));
};
export default AwayByCamTableFilterForm;
