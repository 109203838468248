import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip } from 'components';
import { useModulePermissions, useTableFilter, useDocumentTitle, useBeneficiaryPagination, buildExportQuery, useModulePermissionsByScope } from 'hooks';
import { Scope } from 'types/enum';
import { FormatterUtils } from 'utils';
import { BeneficiaryExportModal, BeneficiaryFinancialHistoryExport, BeneficiaryTableFilterForm } from '../components';
const BeneficiariesList = () => {
    const { canCreate, canRead, canUpdate } = useModulePermissions();
    const { canReport: canReportBeneficiaryFinancialHistory } = useModulePermissionsByScope(Scope.BENEFICIARY_FINANCIAL_HISTORY_REPORT);
    const { canReport: canReportBeneficiarySolicitationHistory } = useModulePermissionsByScope(Scope.BENEFICIARY_SOLICITATION_HISTORY);
    const customParamsHandle = useCallback((filterParams) => {
        const params = {
            Active: filterParams.Active
        };
        if (filterParams.PersonDocument) {
            params.PersonDocument = filterParams.PersonDocument;
        }
        if (filterParams.JobDescriptionId) {
            params.JobDescriptionId = filterParams.JobDescriptionId;
        }
        if (filterParams.BeneficiaryTypeId) {
            params.BeneficiaryTypeId = filterParams.BeneficiaryTypeId;
        }
        if (filterParams.SearchField && filterParams.SearchQuery) {
            params.SearchField = filterParams.SearchField.Value;
            params.SearchQuery = filterParams.SearchQuery;
        }
        return params;
    }, []);
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter({
        customParamsHandle
    });
    const { data, isLoading, ...pagination } = useBeneficiaryPagination(requestFilterParams);
    const onExportCamHistoryClick = useCallback((beneficiaryId) => {
        const query = buildExportQuery(`report/beneficiary-cam-history-excel/${beneficiaryId}`);
        window.open(query, '_blank');
    }, []);
    const columns = useMemo(() => [
        {
            Header: 'Nome',
            accessor: 'Name'
        },
        {
            Header: 'CPF',
            accessor: 'CPF',
            Cell: ({ cell: { value } }) => FormatterUtils.cpf(value)
        },
        {
            Header: 'Tipo',
            accessor: 'BeneficiaryType',
            Cell: ({ cell: { value } }) => value.Name
        },
        {
            Header: 'Situação',
            accessor: 'Active',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold', value ? 'text-green-light' : 'text-red-light') }, value ? 'ATIVO' : 'INATIVO'))
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value }, row: { original: beneficiary } }) => {
                return (React.createElement("div", { className: "flex items-center" },
                    (canRead || canUpdate) && (React.createElement(Link, { to: `/beneficiaries/${value}` },
                        React.createElement(Tooltip, { id: `beneficiaries-${value}-edit`, text: canUpdate ? 'Editar' : 'Visualizar' },
                            React.createElement(Button, { icon: canUpdate ? 'edit' : 'eye', color: "info", rounded: true })))),
                    canReportBeneficiaryFinancialHistory && React.createElement(BeneficiaryFinancialHistoryExport, { beneficiary: beneficiary }),
                    React.createElement(Tooltip, { id: `beneficiaries-${value}-export-cam`, text: "Exportar Extrato do CAM" },
                        React.createElement(Button, { icon: "download", color: "info", rounded: true, className: "ml-2", onClick: () => onExportCamHistoryClick(value), disabled: !canReportBeneficiarySolicitationHistory }))));
            }
        }
    ], [
        canRead,
        canUpdate,
        canReportBeneficiaryFinancialHistory,
        canReportBeneficiarySolicitationHistory,
        onExportCamHistoryClick
    ]);
    useDocumentTitle('Associados');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Associados" }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            canCreate && (React.createElement(Link, { to: "/beneficiaries/new" },
                React.createElement(Button, { text: "Novo Associado", icon: "add", color: "info", animationHover: "pop" }))),
            React.createElement("div", { className: "flex gap-2" },
                React.createElement(BeneficiaryExportModal, { filterParams: requestFilterParams }),
                React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters }))),
        React.createElement(TableFilter, { show: showFilters, title: "Associados", formConfig: { filterParams, withSituation: true }, handleFilter: setParams, customFilters: BeneficiaryTableFilterForm }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhum associado a ser exibido" })));
};
export default BeneficiariesList;
