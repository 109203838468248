import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Button, Modal, Table } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useBenefitConsumptionResumedPagination, useModulePermissionsByScope } from 'hooks';
import { useFinancialStatementMutation } from 'hooks/financialStatements';
import { Scope } from 'types/enum';
import { FormatterUtils } from 'utils';
import { FinancialStatementsForm } from '../FinancialStatementsForm';
const FinancialStatementModal = ({ financialStatement, consumptionIdList, refetch }) => {
    const { data } = useBenefitConsumptionResumedPagination({
        SearchField: 'Id',
        SearchQuery: `${consumptionIdList}`
    });
    const { update } = useFinancialStatementMutation(financialStatement.Id);
    const { canRead: canReadFinacialStatements } = useModulePermissionsByScope(Scope.FINANCIAL_STATEMENTS);
    const [showModal, setShowModal] = useState(false);
    const toggleModal = useCallback(() => {
        setShowModal(showModal => !showModal);
    }, []);
    const { success, errorCallback } = useAlert();
    const onSubmitData = useCallback(async (financialStatementData) => {
        setShowModal(!showModal);
        update({ ...financialStatement, ...financialStatementData })
            .then(() => {
            success({
                title: 'Pagamento atualizado!',
                message: 'Pagamento atualizado com sucesso.',
                id: 'FINANCIAL_STATEMENT_PUT_SUCCESS'
            });
            refetch();
        })
            .catch(async (err) => {
            errorCallback(err, 'FINANCIAL_STATEMENT_PUT_ERROR');
        });
    }, [financialStatement, update, showModal, errorCallback, success]);
    const columns = useMemo(() => [
        {
            Header: 'Valor',
            accessor: 'Value',
            id: 'Valor',
            Cell: ({ cell: { value } }) => React.createElement("span", { className: clsx('font-semibold') }, value)
        },
        {
            Header: 'Descrição',
            accessor: 'Description',
            id: 'Descricao',
            Cell: ({ cell: { value } }) => React.createElement("span", { className: clsx('font-semibold') }, value ?? '')
        }
    ], []);
    return (React.createElement(React.Fragment, null, canReadFinacialStatements && (React.createElement(React.Fragment, null,
        React.createElement(Button, { icon: "edit", color: "info", animationHover: "pop", onClick: toggleModal }),
        React.createElement(Modal, { show: showModal, handleClose: toggleModal, title: "Editar pagamento", closable: true },
            React.createElement("div", null,
                React.createElement("h3", { className: "text-base dark:text-white text-gray-800 text-center" }, data[0]?.Beneficiary?.Name)),
            React.createElement("div", { className: "mt-4" },
                React.createElement(Table, { data: data.map(x => ({
                        Value: FormatterUtils.money(x.Value),
                        Description: x.Benefit.Name
                    })), columns: columns, noResultsMessage: "Nenhuma informa\u00E7\u00E3o a ser exibida", className: "mt-4" })),
            React.createElement("div", { className: "mt-4" },
                React.createElement(FinancialStatementsForm, { financialStatement: financialStatement, onSubmitFinancialStatement: onSubmitData })))))));
};
export default FinancialStatementModal;
