import React, { useRef } from 'react';
import Chart from 'react-apexcharts';
import { Card } from 'components';
import { useTheme } from 'contexts/theme.context';
const DashboardBeneficiaryChart = ({ awayWithoutSolicitation, solicitationInProgress, solicitationInRevision }) => {
    const chartRef = useRef(null);
    const { theme, darkMode } = useTheme();
    const series = [solicitationInProgress, solicitationInRevision, awayWithoutSolicitation];
    const labels = ['Requerimentos Em Andamento', 'Requerimentos Em Revisão', 'Afastados'];
    return (React.createElement(Card, null,
        React.createElement("h2", { className: "text-lg font-semibold text-gray dark:text-gray-50 mb-4" }, "Processos CAM"),
        React.createElement(Chart, { ref: chartRef, type: "donut", options: {
                theme: {
                    monochrome: {
                        enabled: true,
                        color: theme.colors.blue.light,
                        shadeTo: 'dark'
                    }
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontFamily: 'OpenSans, sans-serif',
                                    offsetY: 20
                                },
                                total: {
                                    showAlways: true,
                                    show: true,
                                    label: 'Processos',
                                    fontFamily: 'OpenSans, sans-serif',
                                    fontSize: '14px',
                                    color: darkMode ? theme.colors.gray['400'] : theme.colors.gray.DEFAULT
                                },
                                value: {
                                    show: true,
                                    fontFamily: 'OpenSans, sans-serif',
                                    fontSize: '32px',
                                    offsetY: -20,
                                    formatter: (value) => {
                                        const formatter = Intl.NumberFormat('en', { notation: 'compact' });
                                        return formatter.format(parseInt(value));
                                    },
                                    color: darkMode ? theme.colors.gray['50'] : theme.colors.gray.DEFAULT
                                }
                            }
                        }
                    }
                },
                labels,
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: false
                },
                legend: {
                    position: 'bottom',
                    fontFamily: 'OpenSans, sans-serif',
                    fontSize: '12px',
                    labels: {
                        useSeriesColors: false,
                        colors: darkMode ? theme.colors.gray['50'] : theme.colors.gray.DEFAULT
                    }
                }
            }, series: series, height: 320 })));
};
export default DashboardBeneficiaryChart;
