import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { OPERATION_BASE_LIST_QUERY_KEY, OPERATION_BASE_PAGINATION_QUERY_KEY, OPERATION_BASE_QUERY_KEY } from './useOperationBaseConfig';
export const useOperationBaseMutation = (operationBaseId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createOperationBase = useCallback(async (operationBaseData) => {
        return httpService.post('/operation-base', operationBaseData);
    }, [httpService]);
    const updateOperationBase = useCallback(async (operationBaseData) => {
        return httpService.put('/operation-base', operationBaseData);
    }, [httpService]);
    const removeOperationBase = useCallback(async (operationBaseId) => {
        return httpService.delete(`/operation-base?id=${operationBaseId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createOperationBase, {
        onSuccess: () => {
            queryClient.invalidateQueries(OPERATION_BASE_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries(OPERATION_BASE_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateOperationBase, {
        onSuccess: () => {
            queryClient.invalidateQueries(OPERATION_BASE_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries([OPERATION_BASE_QUERY_KEY, operationBaseId]);
            queryClient.invalidateQueries(OPERATION_BASE_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removeOperationBase, {
        onSuccess: (_, operationBaseId) => {
            queryClient.invalidateQueries(OPERATION_BASE_PAGINATION_QUERY_KEY);
            queryClient.removeQueries([OPERATION_BASE_QUERY_KEY, operationBaseId]);
            queryClient.invalidateQueries(OPERATION_BASE_LIST_QUERY_KEY);
        }
    });
    return { create, update, remove, isCreating, isUpdating, isRemoving };
};
