import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Icon, Tooltip } from 'components';
import { FormatterUtils } from 'utils';
import { SolicitationCard } from '../Card';
const SolicitationBeneficiaryCard = ({ beneficiary }) => {
    return (React.createElement(SolicitationCard, { title: "Detalhes do Associado", className: "h-full" },
        React.createElement("div", { className: "flex items-center mt-4" },
            React.createElement("h3", { className: "text-base font-semibold text-gray dark:text-gray-50 mb-0" }, beneficiary.Name),
            React.createElement(Tooltip, { id: `beneficiary-${beneficiary.Id.toString()}`, text: "Visualizar detalhes do associado" },
                React.createElement("a", { title: "Visualizar detalhes do associado", className: "ml-1 text-blue-light", href: `/beneficiaries/${beneficiary.Id}`, target: "_blank", rel: "noreferrer" },
                    React.createElement(Icon, { name: "new-tab", size: 14 })))),
        React.createElement(Row, { className: "mt-2" },
            React.createElement(Col, { width: 30 },
                React.createElement(Icon, { name: "id-card", size: 22, className: "text-gray-light dark:text-gray-50" })),
            React.createElement(Col, { xs: "content" },
                React.createElement("p", { className: "text-sm text-gray dark:text-gray-50" }, FormatterUtils.cpf(beneficiary.CPF)))),
        React.createElement(Row, null,
            React.createElement(Col, { width: 30 },
                React.createElement(Icon, { name: "mail", size: 20, className: "text-gray-light dark:text-gray-50" })),
            React.createElement(Col, { xs: "content" },
                React.createElement("p", { className: "text-sm text-gray dark:text-gray-50" }, beneficiary.Email))),
        React.createElement(Row, null,
            React.createElement(Col, { width: 30 },
                React.createElement(Icon, { name: "phone", size: 20, className: "text-gray-light dark:text-gray-50" })),
            React.createElement(Col, { xs: "content" },
                React.createElement("p", { className: "text-sm text-gray dark:text-gray-50" }, FormatterUtils.phone(beneficiary.CellPhone))))));
};
export default SolicitationBeneficiaryCard;
