import React from 'react';
import { BenefitConsumptionInternal, BenefitConsumptionList } from './screens';
const BenefitConsumptionModule = [
    {
        path: '/benefits/consumptions',
        element: React.createElement(BenefitConsumptionList, null)
    },
    {
        path: '/benefits/consumptions/new',
        element: React.createElement(BenefitConsumptionInternal, null)
    },
    {
        path: '/benefits/consumptions/:benefitConsumptionId',
        element: React.createElement(BenefitConsumptionInternal, null)
    }
];
export { BenefitConsumptionModule };
