import { Validation, ValidationUtils } from 'utils';
export const AwayWithoutSolicitationFormSchema = Validation.object().shape({
    Beneficiary: Validation.object().required('Informe o associado').nullable(),
    AwayReasonId: Validation.number().required('Informe o motivo').nullable(),
    AwayStartDate: Validation.string().required('Informe a data de início').date('Informe uma data válida'),
    AwayEndDate: Validation.string()
        .nullable()
        .when({
        is: value => Boolean(value),
        then: Validation.string()
            .date('Informe uma data válida')
            .test('dateAfter', 'Informe uma data válida', (value, context) => {
            return ValidationUtils.dateAfter(value, context.parent.AwayStartDate);
        })
    })
});
