import React, { useCallback } from 'react';
import { Icon, Tooltip } from 'components';
import { FileExtension, FileExtensionIcon, FileTypeLabel } from 'types/enum';
import { ParserUtils } from 'utils';
import './SolicitationAttachmentItemList.scss';
const SolicitationAttachmentItemList = ({ attachments, onRemoveAttachment, isDisabled, canRemove }) => {
    const getFileExtensionIcon = useCallback((attachment) => {
        const attachmentExtension = ParserUtils.getFileExtension(attachment.FileName);
        switch (attachmentExtension) {
            case FileExtension.DOC:
                return FileExtensionIcon[FileExtension.DOC];
            case FileExtension.PNG:
                return FileExtensionIcon[FileExtension.PNG];
            case FileExtension.PDF:
                return FileExtensionIcon[FileExtension.PDF];
            default:
                return FileExtensionIcon[FileExtension.JPG];
        }
    }, []);
    return (React.createElement("ul", { className: "solicitation-attachment-item-list" }, attachments.map(attachment => (React.createElement("li", { className: "solicitation-attachment-item-list-item", key: attachment.Id.toString() },
        React.createElement("img", { src: getFileExtensionIcon(attachment) }),
        React.createElement("div", { className: "flex items-center mt-1" },
            React.createElement("a", { className: "solicitation-attachment-item-list-item-link", href: attachment.FileUrl, target: "_blank", rel: "noreferrer" }, attachment.FileName),
            !isDisabled && canRemove && (React.createElement(Tooltip, { id: `solicitation-attachment-item-${attachment.Id}-remove`, text: "Deletar" },
                React.createElement("button", { className: "solicitation-attachment-item-list-item-delete", title: "Deletar", onClick: () => onRemoveAttachment(attachment) },
                    React.createElement(Icon, { name: "close", size: 10 }))))),
        React.createElement("span", null, FileTypeLabel[attachment.FileType]))))));
};
export default SolicitationAttachmentItemList;
