export var Scope;
(function (Scope) {
    Scope["USER"] = "user";
    Scope["BENEFIT_CATEGORY"] = "benefitCategory";
    Scope["OCCUPATION"] = "occupation";
    Scope["PLAN"] = "plan";
    Scope["DAILY_RATE"] = "dailyRate";
    Scope["COMPENSATION_RATE"] = "compensationRate";
    Scope["DESEASE"] = "desease";
    Scope["SOLICITATION"] = "solicitation";
    Scope["SOLICITATION_NOTE"] = "solicitationNote";
    Scope["SOLICITATION_REVISION"] = "solicitationRevision";
    Scope["SOLICITATION_DOCUMENT"] = "solicitationDocument";
    Scope["SOLICITATION_PAYMENT_FLOW"] = "solicitationPaymentFlow";
    Scope["BENEFIT"] = "benefit";
    Scope["BENEFICIARY"] = "beneficiary";
    Scope["DEPENDENT"] = "dependent";
    Scope["BENEFIT_CONSUMPTION"] = "benefitConsumption";
    Scope["BENEFIT_CONSUMPTION_MONTHLY_BALANCE"] = "benefitConsumptionMonthlyBalance";
    Scope["ADMINISTRATOR"] = "administrator";
    Scope["JOB_DESCRIPTION"] = "jobDescription";
    Scope["OPERATION_BASE"] = "operationBase";
    Scope["AIRCRAFT_MODEL"] = "aircraftModel";
    Scope["PREMIUM_RECIPIENT"] = "premiumRecipient";
    Scope["MONTHLY_BASE_PERCENTAGE"] = "monthlyBasePercentage";
    Scope["BENEFIT_CONSUMPTION_BATCH"] = "benefitConsumptionBatch";
    Scope["BIRTHDAYS_REPORT"] = "birthdaysReport";
    Scope["BENEFICIARIES_BY_AGE_REPORT"] = "beneficiariesByAgeReport";
    Scope["BENEFITS_LATAM_REPORT"] = "benefitsForLATAMReport";
    Scope["RECEIVABLES_REPORT"] = "receivablesReport";
    Scope["BENEFICIARY_FINANCIAL_HISTORY_REPORT"] = "beneficiaryFinancialHistoryReport";
    Scope["AWAY_WITHOUT_SOLICITATION"] = "awayWithoutSolicitation";
    Scope["BENEFICIARY_SOLICITATION_HISTORY"] = "solicitationBeneficiaryHistory";
    Scope["ALERTS"] = "alerts";
    Scope["FINANCIAL"] = "financial";
    Scope["FINANCIAL_STATEMENTS"] = "financial-statements";
})(Scope || (Scope = {}));
