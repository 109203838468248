export var FileType;
(function (FileType) {
    FileType[FileType["MEDICAL_CERTIFICATE"] = 1] = "MEDICAL_CERTIFICATE";
    FileType[FileType["LATAM_INSS_LETTER"] = 2] = "LATAM_INSS_LETTER";
    FileType[FileType["LATAM_MEDICAL_CERTIFICATE"] = 3] = "LATAM_MEDICAL_CERTIFICATE";
    FileType[FileType["EXAMS"] = 4] = "EXAMS";
    FileType[FileType["INSS_DECISION"] = 5] = "INSS_DECISION";
})(FileType || (FileType = {}));
export const FileTypeLabel = {
    [FileType.MEDICAL_CERTIFICATE]: 'Atestado',
    [FileType.LATAM_INSS_LETTER]: 'Carta da LATAM encaminhando ao INSS',
    [FileType.LATAM_MEDICAL_CERTIFICATE]: 'Carta médica da LATAM',
    [FileType.EXAMS]: 'Exames',
    [FileType.INSS_DECISION]: 'Decisão do INSS'
};
