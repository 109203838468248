import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { BENEFIT_CATEGORY_QUERY_KEY } from './useBenefitCategoryConfig';
export const useBenefitCategoryQuery = (benefitCategoryId) => {
    const { errorCallback } = useAlert();
    const navigate = useNavigate();
    const getBenefitCategory = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/benefit-category/${benefitCategoryId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'BENEFIT_CATEGORY_GET_FAILURE');
            navigate('/benefits/categories');
            throw error;
        });
    }, [errorCallback, benefitCategoryId, navigate]);
    const { data, isLoading } = useQuery([BENEFIT_CATEGORY_QUERY_KEY, benefitCategoryId], getBenefitCategory, {
        enabled: !!benefitCategoryId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
