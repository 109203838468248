import React, { useCallback, useMemo } from 'react';
import { Row, Col } from 'react-grid-system';
import { Select, Button, FormField, SituationField, Form, TextField } from 'components';
import { DateRangeField } from 'components/DateRangeField';
import { useFormik } from 'formik';
import { ValidationUtils } from 'utils';
const defaultValues = {
    SearchField: null,
    SearchQuery: '',
    ClientId: null,
    DateRange: [null, null],
    Active: null,
    StatusId: null
};
const TableFilterForm = ({ searchFields, filterParams, defaultFilterParams = {}, handleFilter, customFilters, withFields, withSituation, withDateRange, situationFieldLables }) => {
    const hasValidCustomFilters = useMemo(() => ValidationUtils.isNotNullOrUndefined(customFilters), [customFilters]);
    const formikContext = useFormik({
        initialValues: {
            ...defaultValues,
            ...defaultFilterParams,
            ...filterParams
        },
        onSubmit: async (filterValues) => {
            const valueKeys = Object.keys(filterValues);
            valueKeys.forEach(key => {
                const value = filterValues[key];
                if (value === null || (Array.isArray(value) && value.find(x => x === null)))
                    delete filterValues[key];
            });
            return handleFilter(filterValues);
        }
    });
    const { values, setFieldValue, setValues, handleChange, handleSubmit, isSubmitting, resetForm } = formikContext;
    const clearFilter = useCallback(async () => {
        resetForm();
        await handleFilter({
            ...defaultValues,
            ...defaultFilterParams
        });
    }, [defaultFilterParams, handleFilter, resetForm]);
    const isSearchDisabled = useCallback(() => {
        if (isSubmitting)
            return true;
        if (hasValidCustomFilters)
            return false;
        const hasFields = !!(values.SearchField?.Value && values.SearchQuery);
        const hasSituation = values.Active !== filterParams.Active;
        const hasDate = values.DateRange?.filter(date => date !== null).length === 2;
        if (withFields && withSituation && withDateRange)
            return !hasFields && !hasSituation && !hasDate;
        else if (withFields && !withSituation && withDateRange)
            return !hasFields && !hasSituation && !hasDate;
        else if (withFields && withSituation && !withDateRange)
            return !hasFields && !hasSituation;
        else if (withFields && !withSituation && !withDateRange)
            return !hasFields;
        else if (!withFields && !withSituation && withDateRange)
            return !hasDate;
    }, [isSubmitting, hasValidCustomFilters, values, filterParams, withFields, withSituation, withDateRange]);
    const isClearDisabled = useCallback(() => {
        if (isSubmitting)
            return true;
        if (hasValidCustomFilters)
            return false;
        const hasFields = !!(values.SearchField || values.SearchQuery);
        const hasSituation = values.Active !== null;
        const hasDate = values.DateRange?.filter(date => date !== null).length === 2;
        if (withFields && withSituation && withDateRange)
            return !hasFields && !hasSituation && !hasDate;
        else if (withFields && !withSituation && withDateRange)
            return !hasFields && !hasSituation && !hasDate;
        else if (withFields && withSituation && !withDateRange)
            return !hasFields && !hasSituation;
        else if (withFields && !withSituation && !withDateRange)
            return !hasFields;
        else if (!withFields && !withSituation && withDateRange)
            return !hasDate;
    }, [isSubmitting, hasValidCustomFilters, values, withFields, withSituation, withDateRange]);
    const situationFieldOptions = useMemo(() => {
        const situationDefaultValues = [
            { name: 'Todos', color: 'info', textColor: 'primary', value: null },
            { name: 'Ativos', color: 'success', textColor: 'success', value: true },
            { name: 'Inativos', color: 'danger', textColor: 'danger', value: false }
        ];
        return situationFieldLables || situationDefaultValues;
    }, [situationFieldLables]);
    return (React.createElement(Form, { name: "tableFilterForm", onSubmit: handleSubmit, disabled: false, formikContext: formikContext },
        withFields && searchFields && (React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "SearchField", label: "Escolha um campo", options: searchFields, value: searchFields.find((searchField) => searchField.Value === values.SearchField?.Value), onChange: value => setFieldValue('SearchField', value), getOptionLabel: option => option.Description, getOptionValue: option => option.Value, isClearable: true }))),
            React.createElement(Col, { xs: 12, md: 8 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "SearchQuery", label: "Digite a sua busca", value: values.SearchQuery, onChange: handleChange }))))),
        hasValidCustomFilters && customFilters
            ? customFilters({
                values,
                setFieldValue,
                handleChange,
                setValues
            })
            : null,
        withSituation && (React.createElement(Row, { justify: "end" },
            React.createElement(Col, { xs: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Filtrar por situa\u00E7\u00E3o", defaultOption: null, options: situationFieldOptions, handleChange: value => setFieldValue('Active', value), value: values.Active }))))),
        withDateRange && (React.createElement(Row, { justify: "end" },
            React.createElement(Col, { xs: "content" },
                React.createElement(FormField, null,
                    React.createElement(DateRangeField, { startDate: values.DateRange[0], endDate: values.DateRange[1], onDatesChange: ({ startDate, endDate }) => setFieldValue('DateRange', [startDate, endDate]) }))))),
        React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { text: "Buscar", icon: "search", color: "success", type: "submit", animationHover: "pop", loading: isSubmitting, disabled: isSearchDisabled() }),
            React.createElement(Button, { text: "Cancelar", icon: "close", color: "danger", animationHover: "pop", loading: isSubmitting, style: { marginLeft: 10 }, disabled: isClearDisabled(), onClick: clearFilter }))));
};
export default TableFilterForm;
