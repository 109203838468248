import React, { useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import { useParams } from 'react-router-dom';
import { PageHeader, Loading } from 'components';
import { useDocumentTitle, useModulePermissions, useSolicitationQuery } from 'hooks';
import { SolicitationStatus } from 'types/enum';
import { SolicitationActionCard, SolicitationAttachmentCard, SolicitationBeneficiaryCard, SolicitationDetailsCard, SolicitationNoteCard, SolicitationPaymentFlowCard, SolicitationReportCard, SolicitationRevisionCard, SolicitationStateTimelineCard } from '../components';
const SolicitationInternal = () => {
    const solicitationIdRoute = parseInt(useParams()?.solicitationId ?? '0');
    const { canUpdate } = useModulePermissions();
    const [solicitation, isLoading] = useSolicitationQuery(solicitationIdRoute);
    const solicitationPaymentFlow = useMemo(() => solicitation?.SolicitationPaymentFlowHistory?.length
        ? solicitation?.SolicitationPaymentFlowHistory[0]
        : {}, [solicitation?.SolicitationPaymentFlowHistory]);
    const isSolicitationFinished = useMemo(() => {
        if (!solicitation?.SolicitationStates.length)
            return false;
        return solicitation.SolicitationStates.some(x => [SolicitationStatus.CANCELLED, SolicitationStatus.FINISHED].includes(x.StatusId));
    }, [solicitation?.SolicitationStates]);
    const pageTitle = useMemo(() => `Requerimento #${solicitationIdRoute}`, [solicitationIdRoute]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Requerimentos',
            Url: '/cam/solicitations'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    if (isLoading) {
        return (React.createElement(React.Fragment, null,
            React.createElement(PageHeader, { title: "Gerenciar Requerimento", breadcrumb: breadcrumb }),
            React.createElement(Loading, { show: isLoading })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Gerenciar Requerimento", breadcrumb: breadcrumb }),
        React.createElement(Row, { gutterWidth: 25 },
            React.createElement(Col, { xl: 12 },
                React.createElement(SolicitationActionCard, { solicitationId: solicitation?.Id, solicitationStates: solicitation?.SolicitationStates, isDisabled: !canUpdate }))),
        React.createElement(Row, { gutterWidth: 25 },
            React.createElement(Col, { xl: 6 },
                React.createElement(SolicitationDetailsCard, { solicitation: solicitation })),
            React.createElement(Col, { xl: 3, className: "mt-4 xl:mt-0" },
                React.createElement(SolicitationBeneficiaryCard, { beneficiary: solicitation?.Beneficiary })),
            React.createElement(Col, { xl: 3, className: "mt-4 xl:mt-0" },
                React.createElement(SolicitationPaymentFlowCard, { paymentFlow: solicitationPaymentFlow }))),
        React.createElement(Row, { gutterWidth: 25, className: "mt-4" },
            React.createElement(Col, { xl: 6 },
                React.createElement(SolicitationAttachmentCard, { solicitationId: solicitation?.Id, isDisabled: isSolicitationFinished })),
            React.createElement(Col, { xl: 3, className: "mt-4 xl:mt-0" },
                React.createElement(SolicitationNoteCard, { solicitationId: solicitation?.Id, isDisabled: isSolicitationFinished })),
            React.createElement(Col, { xl: 3, className: "mt-4 xl:mt-0" },
                React.createElement(SolicitationStateTimelineCard, { solicitationId: solicitation?.Id, states: solicitation?.SolicitationStates }))),
        React.createElement(Row, { gutterWidth: 25, className: "mt-4" },
            React.createElement(Col, { xl: 12 },
                React.createElement(SolicitationRevisionCard, { solicitationId: solicitation?.Id, solicitationStates: solicitation?.SolicitationStates }))),
        React.createElement(Row, { gutterWidth: 25, className: "mt-4" },
            React.createElement(Col, { xl: 12 },
                React.createElement(SolicitationReportCard, { solicitationId: solicitation?.Id })))));
};
export default SolicitationInternal;
