import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { BENEFIT_CONSUMPTION_LIST_QUERY_KEY, BENEFIT_CONSUMPTION_PAGINATION_QUERY_KEY, BENEFIT_CONSUMPTION_QUERY_KEY } from 'hooks/benefitConsumption/useBenefitConsumptionConfig';
import { makeHttpService } from 'services/http';
export const useFinancialStatementMutation = (financialStatementId, benefitConsumptionId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const updateFinancialStatement = useCallback(async (financialStatementData) => {
        return httpService.put('/financial-statement', financialStatementData);
    }, [httpService]);
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateFinancialStatement, {
        onSuccess: () => {
            queryClient.invalidateQueries(BENEFIT_CONSUMPTION_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries([BENEFIT_CONSUMPTION_QUERY_KEY, benefitConsumptionId]);
            queryClient.invalidateQueries(BENEFIT_CONSUMPTION_LIST_QUERY_KEY);
        }
    });
    const create = {};
    const isCreating = false;
    return { create, update, isCreating, isUpdating };
};
