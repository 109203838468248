import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Loading, Card } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useDocumentTitle, useBankQuery, useBankMutation } from 'hooks';
import moment from 'moment-timezone';
import { BankForm } from '../components';
const BankInternal = () => {
    const bankIdRoute = parseInt(useParams()?.bankId ?? '0');
    const { success, errorCallback } = useAlert();
    const navigate = useNavigate();
    const [bank, isLoading] = useBankQuery(bankIdRoute);
    const { create, update } = useBankMutation(bankIdRoute);
    async function submitBank(bankData) {
        const bankDataSubmit = { ...bankData };
        bankDataSubmit.CreatedAt = moment().format();
        bankDataSubmit.UpdatedAt = null;
        if (bankData.Id) {
            return update(bankDataSubmit, {
                onSuccess: () => success({
                    title: 'Modelo atualizado!',
                    message: 'Dados do modelo atualizados com sucesso.',
                    id: 'BANK_PUT_SUCCESS'
                }),
                onError: err => errorCallback(err, 'BANK_PUT_FAILURE')
            });
        }
        else {
            return create(bankDataSubmit, {
                onSuccess: res => {
                    success({ title: 'Modelo cadastrado!', message: 'Modelo cadastrado com sucesso.', id: 'BANK_POST_SUCCESS' });
                    navigate(`/operational/banks/${res.Id}`);
                },
                onError: err => errorCallback(err, 'BANK_POST_FAILURE')
            });
        }
    }
    const pageTitle = useMemo(() => `${bankIdRoute ? 'Visualizar' : 'Novo'} Banco`, [bankIdRoute]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Bancos',
            Url: '/operational/banks'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Bancos", breadcrumb: breadcrumb }),
        React.createElement(Loading, { show: isLoading }),
        React.createElement(Card, { id: "bank-card" },
            React.createElement(BankForm, { bank: bank, isLoading: isLoading, handleSubmit: submitBank }))));
};
export default BankInternal;
