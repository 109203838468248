import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { SOLICITATION_NOTE_LIST_KEY } from './useSolicitationNoteConfig';
export const useSolicitationNoteMutation = (solicitationId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createSolicitationNote = useCallback(async (solicitationNoteData) => {
        return httpService.post('/solicitation-note', solicitationNoteData);
    }, [httpService]);
    const removeSolicitationNote = useCallback(async (solicitationNoteId) => {
        return httpService.delete(`/solicitation-note?id=${solicitationNoteId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createSolicitationNote, {
        onSuccess: () => {
            queryClient.invalidateQueries([SOLICITATION_NOTE_LIST_KEY, solicitationId]);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removeSolicitationNote, {
        onSuccess: () => {
            queryClient.invalidateQueries([SOLICITATION_NOTE_LIST_KEY, solicitationId]);
        }
    });
    return {
        create,
        remove,
        isCreating,
        isRemoving
    };
};
