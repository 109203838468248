import React, { useCallback, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { TextField, Button, FormField, FormFieldError, Form, SituationField, Select, PercentageField, FormikValidationHandler, useFormikValidationHandler } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useFormik } from 'formik';
import { useDailyRateList, useModulePermissions } from 'hooks';
import { FormatterUtils } from 'utils';
import { convertToCompensationRateDto } from './CompensationRateForm.converter';
import { CompensationRateFormSchema } from './CompensationRateForm.schema';
const defaultValues = {
    Id: 0,
    Name: '',
    DailyRateId: null,
    Percentage: undefined,
    ContributionDaysFrom: undefined,
    ContributionDaysTo: undefined,
    CreatedAt: '',
    UpdatedAt: '',
    Active: true
};
const CompensationRateForm = ({ compensationRate, isLoading, handleSubmit: onSubmitCompensationRate }) => {
    const { confirm } = useAlert();
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions(compensationRate?.Id);
    const [dailyRates, isLoadingDailyRates] = useDailyRateList();
    const handleSubmitCompensationRate = useCallback((compensationRateValues) => {
        const compensationRateData = convertToCompensationRateDto(compensationRateValues);
        return onSubmitCompensationRate(compensationRateData);
    }, [onSubmitCompensationRate]);
    const handleCreateCompensationRate = useCallback((compensationRateValues, { setSubmitting }) => {
        confirm({
            title: 'Atenção!',
            description: (React.createElement(React.Fragment, null,
                React.createElement("p", null, "Essa a\u00E7\u00E3o poder\u00E1 recalcular todas as parcelas relacionadas a configura\u00E7\u00E3o de dias de contribui\u00E7\u00E3o."),
                React.createElement("p", null,
                    "Caso exista alguma car\u00EAncia cadastrada com a mesma configura\u00E7\u00E3o, ela ser\u00E1 inativada. ",
                    React.createElement("br", null),
                    "Deseja continuar?"))),
            confirmText: 'Sim, cadastrar',
            cancelText: 'Não, cancelar',
            onConfirm: async () => {
                setSubmitting(true);
                await handleSubmitCompensationRate(compensationRateValues);
                setSubmitting(false);
            },
            onCancel: () => setSubmitting(false)
        });
    }, [confirm, handleSubmitCompensationRate]);
    const handleUpdateCompensationRate = useCallback((compensationRateValues, { setSubmitting }) => {
        const isPercentageChanged = compensationRate?.Id && compensationRateValues.Percentage !== compensationRate?.Percentage;
        if (!isPercentageChanged)
            return handleSubmitCompensationRate(compensationRateValues);
        confirm({
            title: 'Atenção!',
            description: (React.createElement("p", null,
                "A nova porcentagem de contribui\u00E7\u00E3o ser\u00E1 atualizada apenas para as parcelas futuras dos requerimentos novas e existentes. ",
                React.createElement("br", null),
                "Deseja continuar?")),
            confirmText: 'Sim, continuar',
            cancelText: 'Não, cancelar',
            onConfirm: async () => {
                setSubmitting(true);
                await handleSubmitCompensationRate(compensationRateValues);
                setSubmitting(false);
            },
            onCancel: () => setSubmitting(false)
        });
    }, [confirm, handleSubmitCompensationRate, compensationRate]);
    const onSubmit = useCallback(async (compensationRateValues, formikHelpers) => {
        return compensationRate?.Id
            ? handleUpdateCompensationRate(compensationRateValues, formikHelpers)
            : handleCreateCompensationRate(compensationRateValues, formikHelpers);
    }, [handleCreateCompensationRate, handleUpdateCompensationRate, compensationRate]);
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => CompensationRateFormSchema,
        onSubmit,
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleChange, handleSubmit, isSubmitting, setValues } = formikContext;
    useEffect(() => {
        if (!compensationRate?.Id)
            return;
        const compensationRateData = {
            Id: compensationRate.Id,
            Name: compensationRate.Name,
            DailyRateId: compensationRate.DailyRateId,
            Percentage: compensationRate.Percentage,
            ContributionDaysFrom: compensationRate.ContributionDaysFrom.toString(),
            ContributionDaysTo: compensationRate.ContributionDaysTo.toString(),
            Active: compensationRate.Active ?? true,
            CreatedAt: FormatterUtils.dateTime(compensationRate.CreatedAt),
            UpdatedAt: FormatterUtils.dateTime(compensationRate.UpdatedAt)
        };
        setValues(compensationRateData);
    }, [compensationRate, setValues]);
    const isCreated = Boolean(values.Id);
    if (isLoading)
        return null;
    return (React.createElement(Form, { name: "compensationRateForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Name", label: "Nome", value: values.Name, onChange: handleChange, disabled: isCreated }),
                    React.createElement(FormFieldError, { name: "Name" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "DailyRateId", label: "Escolha a di\u00E1ria", options: dailyRates, value: dailyRates?.find((dailyRate) => dailyRate.Id === values.DailyRateId), onChange: option => setFieldValue('DailyRateId', option.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingDailyRates, isDisabled: isCreated }),
                    React.createElement(FormFieldError, { name: "DailyRateId" })))),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "ContributionDaysFrom", label: "Qnt. dias m\u00EDnimo de contribui\u00E7\u00E3o", value: values.ContributionDaysFrom || '', onChange: handleChange, type: "tel", pattern: "[0-9]*", disabled: isCreated }),
                    React.createElement(FormFieldError, { name: "ContributionDaysFrom" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "ContributionDaysTo", label: "Qnt. dias m\u00E1ximo de contribui\u00E7\u00E3o", value: values.ContributionDaysTo || '', onChange: handleChange, type: "tel", pattern: "[0-9]*", disabled: isCreated }),
                    React.createElement(FormFieldError, { name: "ContributionDaysTo" })))),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(PercentageField, { name: "Percentage", label: "Porcentagem de contribui\u00E7\u00E3o", value: values.Percentage, onChange: value => setFieldValue('Percentage', value) }),
                    React.createElement(FormFieldError, { name: "Percentage" })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Situa\u00E7\u00E3o", handleChange: isActive => setFieldValue('Active', isActive), value: values.Active, falseLabel: "Inativa", trueLabel: "Ativa" })))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Enviar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default CompensationRateForm;
