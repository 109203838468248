import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useModulePermissions, useTableFilter, useDocumentTitle, useBenefitCategoryMutation, useBenefitCategoryPagination } from 'hooks';
const BenefitCategoryList = () => {
    const { canCreate, canRead, canUpdate, canDelete } = useModulePermissions();
    const { success, confirm, errorCallback } = useAlert();
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter();
    const { data, isLoading, ...pagination } = useBenefitCategoryPagination(requestFilterParams);
    const { remove } = useBenefitCategoryMutation();
    const deleteBenefitCategory = useCallback(async (benefitCategoryId) => {
        await remove(benefitCategoryId)
            .then(() => success({
            title: 'Categoria deletada!',
            message: 'Categoria deletada com sucesso.',
            id: 'BENEFIT_CATEGORY_DELETE_SUCCESS'
        }))
            .catch(err => errorCallback(err, 'BENEFIT_CATEGORY_DELETE_FAILURE'));
    }, [remove, success, errorCallback]);
    const onDeleteBenefitCategory = useCallback((benefitCategoryId) => {
        confirm({
            title: 'Você tem certeza?',
            description: (React.createElement("p", null,
                "A categoria ser\u00E1 deletada. ",
                React.createElement("br", null),
                " Essa a\u00E7\u00E3o \u00E9 irrevers\u00EDvel!")),
            confirmText: 'Sim, deletar',
            cancelText: 'Não, cancelar',
            onConfirm: async () => deleteBenefitCategory(benefitCategoryId)
        });
    }, [confirm, deleteBenefitCategory]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Benefícios',
            Url: '/benefits'
        },
        {
            Title: 'Categorias'
        }
    ], []);
    const searchFields = useMemo(() => [
        {
            Value: 'Name',
            Description: 'Nome'
        }
    ], []);
    const columns = useMemo(() => [
        {
            Header: 'Nome',
            accessor: 'Name'
        },
        {
            Header: 'Situação',
            accessor: 'Active',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold', value ? 'text-green-light' : 'text-red-light') }, value ? 'ATIVA' : 'INATIVA'))
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" },
                    (canRead || canUpdate) && (React.createElement(Link, { to: `/benefits/categories/${value}` },
                        React.createElement(Tooltip, { id: `benefit-category-${value}-edit`, text: canUpdate ? 'Editar' : 'Visualizar' },
                            React.createElement(Button, { icon: canUpdate ? 'edit' : 'eye', color: "info", rounded: true })))),
                    canDelete && (React.createElement(Tooltip, { id: `benefit-category-${value}-remove`, text: "Deletar" },
                        React.createElement(Button, { className: "ml-2", icon: "trash", color: "danger", title: "Deletar", onClick: () => onDeleteBenefitCategory(value), rounded: true })))));
            }
        }
    ], [canRead, canUpdate, canDelete, onDeleteBenefitCategory]);
    useDocumentTitle('Categorias de Benefícios');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Categorias de Benef\u00EDcios", breadcrumb: breadcrumb }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            canCreate && (React.createElement(Link, { to: "/benefits/categories/new" },
                React.createElement(Button, { text: "Nova Categoria", icon: "add", color: "info", animationHover: "pop" }))),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Categorias", formConfig: { searchFields, filterParams, withFields: true, withSituation: true }, handleFilter: setParams }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhuma categoria a ser exibida" })));
};
export default BenefitCategoryList;
