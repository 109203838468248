import React, { useCallback, useMemo } from 'react';
import { Loading } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useBeneficiaryBenefitList, useDocumentTitle, useBeneficiaryBenefitMutation, useBeneficiaryTypeQuery, useBeneficiaryQuery } from 'hooks';
import moment from 'moment-timezone';
import { BeneficiaryBenefitForm } from './BenefitForm';
import { BeneficiaryBenefitItem } from './BenefitItem';
const BeneficiaryBenefitTab = ({ beneficiaryId }) => {
    const { success, errorCallback } = useAlert();
    const [beneficiary, isLoadingBeneficiary] = useBeneficiaryQuery(beneficiaryId);
    const [beneficiaryType, isLoadingBeneficiaryType] = useBeneficiaryTypeQuery(beneficiary?.BeneficiaryTypeId);
    const [beneficiaryBenefit, isLoadingBeneficiaryBenefit] = useBeneficiaryBenefitList(beneficiaryId);
    const { create, remove } = useBeneficiaryBenefitMutation(beneficiaryId);
    const submitBeneficiaryBenefit = useCallback(async (beneficiaryBenefitData) => {
        beneficiaryBenefitData.CreatedAt = moment().format();
        beneficiaryBenefitData.UpdatedAt = null;
        if (beneficiaryBenefitData.Id)
            return;
        return create(beneficiaryBenefitData);
    }, [create]);
    const removeBeneficiaryBenefit = useCallback(async (beneficiaryBenefitId) => {
        return remove(beneficiaryBenefitId);
    }, [remove]);
    const onSubmitBeneficiaryBenefit = useCallback(async (beneficiaryBenefitData, benefitiLimitationRemovedIds) => {
        try {
            for (const beneficiaryBenefit of beneficiaryBenefitData) {
                beneficiaryBenefit.BeneficiaryId = beneficiaryId;
                await submitBeneficiaryBenefit(beneficiaryBenefit);
            }
            for (const beneficiaryBenefitId of benefitiLimitationRemovedIds) {
                await removeBeneficiaryBenefit(beneficiaryBenefitId);
            }
            success({
                title: 'Benefícios atualizados!',
                message: 'Benefícios atualizados com sucesso.',
                id: 'BENEFICIARY_BENEFIT_SAVE_SUCCESS'
            });
        }
        catch (error) {
            errorCallback(error, 'BENEFICIARY_BENEFIT_SAVE_FAILURE');
        }
    }, [submitBeneficiaryBenefit, removeBeneficiaryBenefit, beneficiaryId, success, errorCallback]);
    const pageTitle = useMemo(() => 'Benefícios do Associado', []);
    const isLoading = isLoadingBeneficiary || isLoadingBeneficiaryType || isLoadingBeneficiaryBenefit;
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(Loading, { show: isLoading }),
        React.createElement("h4", { className: "text-base text-gray-light dark:text-gray-200 mb-4" }, "Benef\u00EDcios pr\u00E9 definidos"),
        beneficiaryType?.BeneficiaryTypeDefaultBenefits?.length ? (beneficiaryType?.BeneficiaryTypeDefaultBenefits.map(x => (React.createElement(BeneficiaryBenefitItem, { key: x.Id, benefit: x.Benefit })))) : (React.createElement("p", { className: "text-gray-light dark:text-gray-400 text-sm mb-4" }, "N\u00E3o h\u00E1 benef\u00EDcios pr\u00E9 definidos cadastrados.")),
        React.createElement("hr", null),
        React.createElement("h4", { className: "text-base text-gray-light dark:text-gray-200 mb-4" }, "Benef\u00EDcios customizados"),
        React.createElement(BeneficiaryBenefitForm, { beneficiaryBenefit: beneficiaryBenefit, beneficiaryType: beneficiaryType, isLoading: isLoading, onSubmitBeneficiaryBenefit: onSubmitBeneficiaryBenefit })));
};
export default BeneficiaryBenefitTab;
