import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TextField, Form, ButtonAnimated, FormFieldError, useFormikValidationHandler, FormikValidationHandler } from 'components';
import hash from 'crypto-js/sha256';
import { useFormik } from 'formik';
import { SourceSystem } from 'types/dtos';
import { LoginSchema } from './LoginForm.schema';
const defaultValues = {
    UserName: '',
    Password: ''
};
const LoginForm = props => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const [showPassword, setShowPassword] = useState(false);
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => LoginSchema,
        onSubmit: async (data) => {
            const loginData = {
                UserName: data.UserName,
                SourceSystem: SourceSystem.BACKOFFICE,
                Password: hash(data.Password).toString()
            };
            return props.handleSubmit(loginData);
        },
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, handleChange, handleSubmit, isSubmitting } = formikContext;
    return (React.createElement(Form, { name: "loginForm", onSubmit: handleSubmit, disabled: false, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(TextField, { name: "UserName", label: "Usu\u00E1rio", value: values.UserName, onChange: handleChange, variant: "outlined" }),
        React.createElement(FormFieldError, { name: "UserName" }),
        React.createElement(TextField, { name: "Password", label: "Senha", value: values.Password, onChange: handleChange, variant: "outlined", type: showPassword ? 'text' : 'password', rightIcon: !showPassword ? 'eye' : 'eye-closed', rightAction: () => setShowPassword(!showPassword) }),
        React.createElement(FormFieldError, { name: "Password" }),
        React.createElement("div", { className: "flex flex-col items-center mt-8" },
            React.createElement(ButtonAnimated, { loading: isSubmitting, text: "Entrar" }),
            React.createElement(Link, { to: "/recover-password", className: "mt-2" },
                React.createElement("span", { className: "text-xs inline-block" }, "Esqueceu sua senha?")))));
};
export default LoginForm;
