import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { ME_QUERY_KEY } from './useMeConfig';
export const useMeMutation = () => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const updateDependent = useCallback(async (userData) => {
        return httpService.put('/me', userData);
    }, [httpService]);
    const { mutate, isLoading } = useMutation(updateDependent, {
        onSuccess: (_, user) => {
            queryClient.setQueryData([ME_QUERY_KEY], user);
        }
    });
    return [mutate, isLoading];
};
