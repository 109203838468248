import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'react-grid-system';
import { Button, FormField, Form, Select, FormikValidationHandler, useFormikValidationHandler, SituationField } from 'components';
import { useFormik } from 'formik';
import { useModulePermissions } from 'hooks';
import { FinancialStatementPaymentMethodEnum, FinancialStatementPaymentMethodLabel, FinancialStatementPaymentStatusEnum, FinancialStatementPaymentStatusLabel } from 'types/enum/financial/FinancialStatementPaymentEnum';
import { ValidationUtils } from 'utils';
import { FinancialStatementsSchema } from './FinancialStatementsSchema.schema';
const defaultValues = {};
const FinancialStatementsForm = ({ financialStatement, onSubmitFinancialStatement }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions(financialStatement?.Id);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const solicitationPaymentMethodOptions = useMemo(() => [
        {
            label: FinancialStatementPaymentMethodLabel[FinancialStatementPaymentMethodEnum.PAYMENT_SLIP],
            value: FinancialStatementPaymentMethodEnum.PAYMENT_SLIP
        },
        {
            label: FinancialStatementPaymentMethodLabel[FinancialStatementPaymentMethodEnum.DEPOSIT_ATL_ACCOUNT],
            value: FinancialStatementPaymentMethodEnum.DEPOSIT_ATL_ACCOUNT
        }
    ], []);
    const solicitationPaymentStatusOptions = useMemo(() => [
        {
            name: FinancialStatementPaymentStatusLabel[FinancialStatementPaymentStatusEnum.OPEN],
            value: FinancialStatementPaymentStatusEnum.OPEN,
            color: 'info',
            textColor: 'white'
        },
        {
            name: FinancialStatementPaymentStatusLabel[FinancialStatementPaymentStatusEnum.PAID],
            value: FinancialStatementPaymentStatusEnum.PAID,
            color: 'info',
            textColor: 'white'
        }
    ], []);
    const onSubmit = useCallback(async (financialStatementFormValues) => onSubmitFinancialStatement(financialStatementFormValues), [onSubmitFinancialStatement]);
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => FinancialStatementsSchema,
        onSubmit,
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleSubmit, isSubmitting, setValues } = formikContext;
    const onInitForm = useCallback(() => {
        if (ValidationUtils.isEmptyObject(financialStatement))
            return;
        const beneficiaryData = {};
        setValues(beneficiaryData);
    }, [financialStatement, setValues]);
    useEffect(onInitForm, [onInitForm]);
    const onPaymentMethodChange = useCallback((value) => {
        const selectedValue = value;
        setPaymentMethod(selectedValue.value);
        setFieldValue('PaymentMethod', selectedValue.value);
    }, [setFieldValue]);
    return (React.createElement(Form, { name: "FinancialStatementsForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "PaymentMethod", label: "M\u00E9todo de pagamento", options: solicitationPaymentMethodOptions, value: solicitationPaymentMethodOptions?.find(x => x.value === paymentMethod), onChange: onPaymentMethodChange, getOptionLabel: option => option.label, getOptionValue: option => option.label })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Status do Pagamento", handleChange: value => setFieldValue('PaymentStatus', value), value: values.PaymentStatus, options: solicitationPaymentStatusOptions })))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: 'Salvar', color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default FinancialStatementsForm;
