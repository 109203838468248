import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { BENEFIT_QUERY_KEY } from './useBenefitConfig';
export const useBenefitQuery = (benefitId) => {
    const { errorCallback } = useAlert();
    const navigate = useNavigate();
    const getBenefit = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/benefit/${benefitId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'BENEFIT_GET_FAILURE');
            navigate('/benefits');
            throw error;
        });
    }, [errorCallback, benefitId, navigate]);
    const { data, isLoading } = useQuery([BENEFIT_QUERY_KEY, benefitId], getBenefit, {
        enabled: !!benefitId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
