import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip } from 'components';
import { useModulePermissions, useTableFilter, useDocumentTitle, useUserPagination } from 'hooks';
const UsersList = () => {
    const { canCreate, canRead, canUpdate } = useModulePermissions();
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter();
    const { data, isLoading, ...pagination } = useUserPagination(requestFilterParams);
    const searchFields = useMemo(() => [
        {
            Value: 'Name',
            Description: 'Nome'
        },
        {
            Value: 'Username',
            Description: 'Username'
        }
    ], []);
    const columns = useMemo(() => [
        {
            Header: 'Código de Registro',
            accessor: 'Id'
        },
        {
            Header: 'Nome',
            accessor: 'Name'
        },
        {
            Header: 'Tipo',
            accessor: 'UserType',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => value.Name
        },
        {
            Header: 'Situação',
            accessor: 'Active',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold', value ? 'text-green-light' : 'text-red-light') }, value ? 'ATIVO' : 'INATIVO'))
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" }, (canRead || canUpdate) && (React.createElement(Link, { to: `/users/${value}` },
                    React.createElement(Tooltip, { id: `users-${value}-edit`, text: canUpdate ? 'Editar' : 'Visualizar' },
                        React.createElement(Button, { icon: canUpdate ? 'edit' : 'eye', color: "info", rounded: true }))))));
            }
        }
    ], [canRead, canUpdate]);
    useDocumentTitle('Usuários');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Usu\u00E1rios" }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            canCreate && (React.createElement(Link, { to: "/users/new" },
                React.createElement(Button, { text: "Novo Usu\u00E1rio", icon: "add", color: "info", animationHover: "pop" }))),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Usu\u00E1rios", formConfig: { searchFields, filterParams, withFields: true, withSituation: true }, handleFilter: setParams }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhum usu\u00E1rio a ser exibido" })));
};
export default UsersList;
