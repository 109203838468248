import React, { useMemo } from 'react';
import { Card, Table } from 'components';
import { useModulePermissionsByScope, useTableFilter } from 'hooks';
import { useAlertsPagination } from 'hooks/alerts';
import { Scope } from 'types/enum';
import { FormatterUtils } from 'utils';
const DashboardActionList = () => {
    const { canRead: canReadAlerts } = useModulePermissionsByScope(Scope.ALERTS);
    const { requestFilterParams } = useTableFilter();
    const { data, isLoading, ...pagination } = useAlertsPagination(requestFilterParams);
    const columns = useMemo(() => [
        {
            Header: 'Descrição',
            accessor: 'Description'
        },
        {
            Header: 'Data de Criação',
            accessor: 'CreatedAt',
            Cell: ({ cell: { value } }) => FormatterUtils.dateTime(value)
        }
    ], []);
    return (React.createElement(React.Fragment, null, canReadAlerts && (React.createElement(React.Fragment, null,
        React.createElement(Card, { className: "mt-8" },
            React.createElement("h2", { className: "text-lg font-semibold text-gray dark:text-gray-50 mb-4" }, "Alertas"),
            React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhum alerta a ser exibido" }))))));
};
export default DashboardActionList;
