import React, { useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { TextField, Button, FormField, FormFieldError, Form, SituationField, Select, useFormikValidationHandler, FormikValidationHandler, CurrencyField } from 'components';
import { useFormik } from 'formik';
import { useModulePermissions, useBenefitCategoryList } from 'hooks';
import { FormatterUtils } from 'utils';
import { BenefitFormSchema } from './BenefitForm.schema';
const defaultValues = {
    Id: 0,
    BenefitCategoryId: 0,
    Name: '',
    Description: '',
    Value: 0,
    CreatedAt: '',
    IsReimbursedFromLATAM: false,
    IsMonthlyContributionRegistry: false,
    Active: true
};
const BenefitForm = ({ benefit, isLoading, handleSubmit: handleSubmitBenefit }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions(benefit?.Id);
    const [benefitCategories, isLoadingBenefitCategories] = useBenefitCategoryList();
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => BenefitFormSchema,
        onSubmit: async (benefitData) => handleSubmitBenefit(benefitData),
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleChange, handleSubmit, isSubmitting, setValues } = formikContext;
    useEffect(() => {
        if (!benefit?.Id)
            return;
        const benefitData = {
            Id: benefit.Id,
            BenefitCategoryId: benefit.BenefitCategoryId,
            Name: benefit.Name,
            Description: benefit.Description,
            Value: benefit.Value,
            IsReimbursedFromLATAM: benefit.IsReimbursedFromLATAM ?? true,
            IsMonthlyContributionRegistry: benefit.IsMonthlyContributionRegistry ?? false,
            Active: benefit.Active ?? true,
            CreatedAt: FormatterUtils.dateTime(benefit.CreatedAt)
        };
        setValues(benefitData);
    }, [benefit, setValues]);
    if (isLoading)
        return null;
    return (React.createElement(Form, { name: "benefitForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "BenefitCategoryId", label: "Categoria", options: benefitCategories, value: benefitCategories?.find(benefitCategory => benefitCategory.Id === values.BenefitCategoryId), onChange: value => setFieldValue('BenefitCategoryId', value.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingBenefitCategories }),
                    React.createElement(FormFieldError, { name: "BenefitCategoryId" }))),
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Name", label: "Nome", value: values.Name, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Name" }))),
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Description", label: "Descri\u00E7\u00E3o", value: values.Description, onChange: handleChange, element: "textarea", rows: 5 }),
                    React.createElement(FormFieldError, { name: "Description" }))),
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(CurrencyField, { name: "Value", label: "Valor", onChange: value => {
                            setFieldValue('Value', value);
                        }, value: values.Value, rightIcon: "warning", rightIconTooltip: "Informe um valor fixo que ser\u00E1 cobrado fixamente dos usu\u00E1rios todos os meses. Caso informe 0 ir\u00E1 utilizar o valor da importa\u00E7\u00E3o de consumos." }),
                    React.createElement(FormFieldError, { name: "Value" })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Reembolso LATAM", handleChange: value => setFieldValue('IsReimbursedFromLATAM', value), value: values.IsReimbursedFromLATAM, falseLabel: "N\u00E3o", trueLabel: "Sim" }))),
            React.createElement(Col, { xs: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Mensalidade ATL", handleChange: value => setFieldValue('IsMonthlyContributionRegistry', value), value: values.IsMonthlyContributionRegistry, falseLabel: "N\u00E3o", trueLabel: "Sim" })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Situa\u00E7\u00E3o do benef\u00EDcio", handleChange: isActive => setFieldValue('Active', isActive), value: values.Active, falseLabel: "Inativo", trueLabel: "Ativo" })))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Enviar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default BenefitForm;
