import React from 'react';
import { PlanInternal, PlanList } from './screens';
const PlanModule = [
    {
        path: '/cam/plans',
        element: React.createElement(PlanList, null)
    },
    {
        path: '/cam/plans/new',
        element: React.createElement(PlanInternal, null)
    },
    {
        path: '/cam/plans/:planId',
        element: React.createElement(PlanInternal, null)
    }
];
export { PlanModule };
