export var FinancialStatementPaymentMethodEnum;
(function (FinancialStatementPaymentMethodEnum) {
    FinancialStatementPaymentMethodEnum[FinancialStatementPaymentMethodEnum["PAYMENT_SLIP"] = 1] = "PAYMENT_SLIP";
    FinancialStatementPaymentMethodEnum[FinancialStatementPaymentMethodEnum["DEPOSIT_ATL_ACCOUNT"] = 2] = "DEPOSIT_ATL_ACCOUNT";
})(FinancialStatementPaymentMethodEnum || (FinancialStatementPaymentMethodEnum = {}));
export var FinancialStatementPaymentStatusEnum;
(function (FinancialStatementPaymentStatusEnum) {
    FinancialStatementPaymentStatusEnum[FinancialStatementPaymentStatusEnum["OPEN"] = 1] = "OPEN";
    FinancialStatementPaymentStatusEnum[FinancialStatementPaymentStatusEnum["PAID"] = 2] = "PAID";
})(FinancialStatementPaymentStatusEnum || (FinancialStatementPaymentStatusEnum = {}));
export const FinancialStatementPaymentMethodLabel = {
    [FinancialStatementPaymentMethodEnum.PAYMENT_SLIP]: 'Boleto Bancário',
    [FinancialStatementPaymentMethodEnum.DEPOSIT_ATL_ACCOUNT]: 'Depósito em Conta'
};
export const FinancialStatementPaymentStatusLabel = {
    [FinancialStatementPaymentStatusEnum.OPEN]: 'Em Aberto',
    [FinancialStatementPaymentStatusEnum.PAID]: 'Pago'
};
export const FinancialStatementPaymentMethodOptions = [
    FinancialStatementPaymentMethodEnum.PAYMENT_SLIP,
    FinancialStatementPaymentMethodEnum.DEPOSIT_ATL_ACCOUNT
];
export const FinancialStatementPaymentStatusOptions = [
    FinancialStatementPaymentStatusEnum.OPEN,
    FinancialStatementPaymentStatusEnum.PAID
];
