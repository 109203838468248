import React from 'react';
import { Dashboard } from './screens';
const DashboardModule = [
    {
        path: '/',
        element: React.createElement(Dashboard, null)
    },
    {
        path: '/dashboard',
        element: React.createElement(Dashboard, null)
    }
];
export { DashboardModule };
