import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { JOB_DESCRIPTION_QUERY_KEY } from './useJobDescriptionConfig';
export const useJobDescriptionQuery = (jobDescriptionId) => {
    const { errorCallback } = useAlert();
    const navigate = useNavigate();
    const getJobDescription = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/job-description/${jobDescriptionId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'JOB_DESCRIPTION_GET_FAILURE');
            navigate('/cam/job-descriptions');
            throw error;
        });
    }, [errorCallback, jobDescriptionId, navigate]);
    const { data, isLoading } = useQuery([JOB_DESCRIPTION_QUERY_KEY, jobDescriptionId], getJobDescription, {
        enabled: !!jobDescriptionId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
