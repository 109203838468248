import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip } from 'components';
import { useModulePermissions, useTableFilter, useDocumentTitle, useBenefitConsumptionBatchPagination, useBenefitConsumptionBatchErrorFileDownload } from 'hooks';
import { BenefitConsumptionStatusLabel, BenefitConsumptionStatusLabelColor } from 'types/enum';
import { FormatterUtils } from 'utils';
import { BenefitConsumptionBatchTableFilterForm } from '../components';
const BenefitConsumptionBatchList = () => {
    const { canCreate, canRead } = useModulePermissions();
    const customParamsHandle = useCallback((filterParams) => {
        const params = {};
        if (filterParams.FileName) {
            params.SearchField = 'FileName';
            params.SearchQuery = filterParams.FileName;
        }
        if (filterParams.BenefitId) {
            params.BenefitId = filterParams.BenefitId;
        }
        if (filterParams.Statuses) {
            params.SolicitationStatusIds = filterParams.Statuses
                .filter(x => x)
                .map(x => x.value);
        }
        return params;
    }, []);
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter({
        customParamsHandle
    });
    const { data, isLoading, ...pagination } = useBenefitConsumptionBatchPagination(requestFilterParams);
    const onDownloadImportErrorsFile = useBenefitConsumptionBatchErrorFileDownload();
    const columns = useMemo(() => [
        {
            Header: 'Benefício',
            accessor: 'Benefit',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => value.Name
        },
        {
            Header: 'Arquivo',
            accessor: 'FileName',
            Cell: ({ cell: { value } }) => React.createElement("i", null, value)
        },
        {
            Header: 'Status',
            accessor: 'Status',
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold', BenefitConsumptionStatusLabelColor(value)) }, BenefitConsumptionStatusLabel[value]))
        },
        {
            Header: 'Data Cadastro',
            accessor: 'CreatedAt',
            Cell: ({ cell: { value } }) => FormatterUtils.dateTime(value)
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value }, row: { original: values } }) => {
                return (React.createElement("div", { className: "flex items-center" }, canRead && values.HasError && (React.createElement(Tooltip, { id: `benefit-consumption-batch-${value}-view`, text: "Visualizar Falhas" },
                    React.createElement(Button, { icon: "download", color: "danger", onClick: () => onDownloadImportErrorsFile(value), rounded: true })))));
            }
        }
    ], [canRead, onDownloadImportErrorsFile]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Benefícios',
            Url: '/benefits'
        },
        {
            Title: 'Consumos',
            Url: '/benefits/consumptions'
        },
        {
            Title: 'Importações'
        }
    ], []);
    useDocumentTitle('Importação de Consumos de Benefícios');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Importa\u00E7\u00E3o de Consumos de Benef\u00EDcios", breadcrumb: breadcrumb }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            canCreate && (React.createElement(Link, { to: "/benefits/consumptions/batches/new" },
                React.createElement(Button, { text: "Nova Importa\u00E7\u00E3o", icon: "add", color: "info", animationHover: "pop" }))),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Importa\u00E7\u00F5es", formConfig: { filterParams }, handleFilter: setParams, customFilters: BenefitConsumptionBatchTableFilterForm }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhuma importa\u00E7\u00E3o a ser exibida" })));
};
export default BenefitConsumptionBatchList;
