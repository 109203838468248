import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
const defaultPagination = {
    pageSize: 10,
    pageSkip: 0,
    numberOfPages: 0,
    currentPage: 1
};
const defaultSort = {};
const defaultOptions = {
    enabled: true
};
export const useQueryPagination = (queryKey, queryFn, queryOptions = defaultOptions) => {
    const [pagination, setPagination] = useState(defaultPagination);
    const [sort, setSort] = useState(defaultSort);
    const [isFirstCall, setIsFirstCall] = useState(!queryOptions.enabled);
    const { pageSize, currentPage, numberOfPages } = pagination;
    const { enabled } = queryOptions;
    const fetchPaginatedData = useCallback(async ({ signal }) => {
        const pageSkip = (currentPage - 1) * pageSize;
        return queryFn({ pageSize, pageSkip, sort, signal }).then(res => {
            setPagination(pagination => ({
                ...pagination,
                numberOfPages: res.NumberOfPages,
                pageSkip
            }));
            setIsFirstCall(false);
            return res.Registers;
        });
    }, [currentPage, pageSize, sort, queryFn]);
    const { data, isLoading, isFetching, refetch } = useQuery([...queryKey, currentPage, pageSize, sort], fetchPaginatedData, {
        enabled,
        keepPreviousData: true,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    const fetchNextPage = useCallback(() => {
        setPagination(pagination => ({
            ...pagination,
            currentPage: pagination.currentPage + 1
        }));
    }, [setPagination]);
    const fetchPreviousPage = useCallback(() => {
        setPagination(pagination => ({
            ...pagination,
            currentPage: pagination.currentPage - 1
        }));
    }, [setPagination]);
    const fetchPage = useCallback((pageNumber) => {
        setPagination(pagination => ({
            ...pagination,
            currentPage: pageNumber
        }));
    }, [setPagination]);
    const fetchPageSize = useCallback((pageSize) => {
        setPagination(pagination => ({
            ...pagination,
            pageSize
        }));
    }, [setPagination]);
    const fetchSortedData = useCallback((sortData) => {
        setSort(sortData);
    }, [setSort]);
    return {
        data: data ?? [],
        perPage: pageSize,
        currentPage,
        numberOfPages,
        isLoading: isLoading || isFetching,
        isFirstCall,
        fetchNextPage,
        fetchPreviousPage,
        fetchPage,
        fetchPageSize,
        fetchSortedData,
        refetch
    };
};
