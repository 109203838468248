import React, { useMemo } from 'react';
import InputMask from 'react-input-mask';
import { FormConsumer } from 'components';
import TextField from './TextField';
const maskTypes = [
    {
        name: 'cpf',
        mask: '999.999.999-99'
    },
    {
        name: 'cnpj',
        mask: '99.999.999/9999-99'
    },
    {
        name: 'date',
        mask: '99/99/9999'
    },
    {
        name: 'zip-code',
        mask: '99999-999'
    },
    {
        name: 'phone',
        mask: (v) => {
            const phoneClean = v ? v.replace(/\D/g, '') : '';
            if (phoneClean && phoneClean.length <= 10)
                return '(99) 9999-9999';
            return '(99) 9 9999-9999';
        }
    },
    {
        name: 'cellphone',
        mask: '(99) 9 9999-9999'
    },
    {
        name: 'month-year',
        mask: '99/9999'
    }
];
const MaskedTextField = ({ value, onChange, onBlur, name, maskType, customMask, onKeyDown, disabled, readOnly, ...props }) => {
    const mask = useMemo(() => {
        if (maskType) {
            const requestedMask = maskTypes.find(x => x.name === maskType);
            return requestedMask?.mask instanceof Function ? requestedMask?.mask(value) : requestedMask?.mask;
        }
        return customMask;
    }, [maskType, customMask, value]);
    function handleChange(e) {
        e.persist();
        const targetValue = e.target.value;
        const value = maskType === 'date' || maskType === 'month-year' ? targetValue : targetValue.replace(/\D/g, '');
        onChange && onChange(value);
    }
    return (React.createElement(FormConsumer, null, ({ disabled: formDisabled }) => (React.createElement(InputMask, { mask: mask, maskPlaceholder: "", value: value, name: name, disabled: disabled || formDisabled, readOnly: readOnly, onChange: e => handleChange(e), onBlur: onBlur }, () => (React.createElement(TextField, { name: name, value: value, disabled: disabled || formDisabled, onKeyDown: onKeyDown, readOnly: readOnly, ...props }))))));
};
export default MaskedTextField;
