import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { DESEASE_LIST_QUERY_KEY, DESEASE_PAGINATION_QUERY_KEY, DESEASE_QUERY_KEY } from './useDeseaseConfig';
export const useDeseaseMutation = (deseaseId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createDesease = useCallback(async (deseaseData) => {
        return httpService.post('/desease', deseaseData);
    }, [httpService]);
    const updateDesease = useCallback(async (deseaseData) => {
        return httpService.put('/desease', deseaseData);
    }, [httpService]);
    const removeDesease = useCallback(async (deseaseId) => {
        return httpService.delete(`/desease?id=${deseaseId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createDesease, {
        onSuccess: () => {
            queryClient.invalidateQueries(DESEASE_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries(DESEASE_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateDesease, {
        onSuccess: () => {
            queryClient.invalidateQueries(DESEASE_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries([DESEASE_QUERY_KEY, deseaseId]);
            queryClient.invalidateQueries(DESEASE_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removeDesease, {
        onSuccess: (_, deseaseId) => {
            queryClient.invalidateQueries(DESEASE_PAGINATION_QUERY_KEY);
            queryClient.removeQueries([DESEASE_QUERY_KEY, deseaseId]);
            queryClient.invalidateQueries(DESEASE_LIST_QUERY_KEY);
        }
    });
    return { create, update, remove, isCreating, isUpdating, isRemoving };
};
