import React from 'react';
import { BeneficiaryAgeReport, BenefitConsumptionLatamReport, BenefitConsumptionMonthlyBalanceReport, BirthdayReport, MonthlyReceiptReport, MonthlyReceivableReport, SolicitationApprovalReport, SolicitationDetailedReport, SolicitationLeftOverMoneyReport } from './screens';
const ReportModule = [
    {
        path: '/cam/solicitations/:solicitationId/monthly-receipt-report',
        element: React.createElement(MonthlyReceiptReport, null)
    },
    {
        path: '/cam/solicitations/:solicitationId/detailed-report',
        element: React.createElement(SolicitationDetailedReport, null)
    },
    {
        path: '/cam/solicitations/:solicitationId/approval-report',
        element: React.createElement(SolicitationApprovalReport, null)
    },
    {
        path: '/cam/solicitation-left-over-money',
        element: React.createElement(SolicitationLeftOverMoneyReport, null)
    },
    {
        path: '/reports/benefit-consumption-monthly-balance',
        element: React.createElement(BenefitConsumptionMonthlyBalanceReport, null)
    },
    {
        path: '/reports/benefit-consumptions-latam',
        element: React.createElement(BenefitConsumptionLatamReport, null)
    },
    {
        path: '/reports/beneficiaries-by-age',
        element: React.createElement(BeneficiaryAgeReport, null)
    },
    {
        path: '/reports/birthdays',
        element: React.createElement(BirthdayReport, null)
    },
    {
        path: '/reports/monthly-receivable',
        element: React.createElement(MonthlyReceivableReport, null)
    }
];
export { ReportModule };
