import React, { useCallback, useState } from 'react';
import { Button, TextField } from 'components';
const SolicitationNoteForm = ({ onSubmitNote, isLoading }) => {
    const [note, setNote] = useState('');
    const onChangeNote = useCallback((e) => {
        setNote(e.target.value);
    }, []);
    const onSubmit = useCallback(() => onSubmitNote(note), [onSubmitNote, note]);
    return (React.createElement(React.Fragment, null,
        React.createElement(TextField, { name: "Note", label: "Nota", value: note, onChange: onChangeNote, element: "textarea", rows: 5 }),
        React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Confirmar", color: "success", type: "submit", disabled: !note, loading: isLoading, onClick: onSubmit, animationHover: "pop" }))));
};
export default SolicitationNoteForm;
