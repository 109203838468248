import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { SOLICITATION_QUERY_KEY } from 'hooks/solicitation/useSolicitationConfig';
import { makeHttpService } from 'services/http';
import { SOLICITATION_REVISION_LIST_KEY, SOLICITATION_REVISION_PAGINATION_QUERY_KEY } from './useSolicitationRevisionConfig';
export const useSolicitationRevisionMutation = (solicitationId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const onAttributeDoctorUser = useCallback(async (solicitationRevisionId) => {
        return httpService.post(`/solicitation-revision/${solicitationRevisionId}/set-doctor-user`);
    }, [httpService]);
    const onAddSolicitationRevisionState = useCallback(async (solicitationRevisionState) => {
        return httpService.post('/solicitation-revision-state', solicitationRevisionState);
    }, [httpService]);
    const { mutateAsync: attributeDoctorUser } = useMutation(onAttributeDoctorUser, {
        onSuccess: () => {
            queryClient.invalidateQueries([SOLICITATION_REVISION_LIST_KEY, solicitationId]);
            queryClient.invalidateQueries([SOLICITATION_QUERY_KEY, solicitationId]);
            queryClient.invalidateQueries(SOLICITATION_REVISION_PAGINATION_QUERY_KEY);
        }
    });
    const { mutateAsync: addSolicitationRevisionState } = useMutation(onAddSolicitationRevisionState, {
        onSuccess: () => {
            queryClient.invalidateQueries([SOLICITATION_REVISION_LIST_KEY, solicitationId]);
            queryClient.invalidateQueries([SOLICITATION_QUERY_KEY, solicitationId]);
            queryClient.invalidateQueries(SOLICITATION_REVISION_PAGINATION_QUERY_KEY);
        }
    });
    return {
        attributeDoctorUser,
        addSolicitationRevisionState
    };
};
