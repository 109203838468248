import React, { createContext, useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModuleUrlBlacklist } from 'configs';
import { ScopeAction } from 'types/enum';
import { ModuleUtils, ScopeUtils, ValidationUtils } from 'utils';
import { useAuth } from './auth.context';
export const ModuleContext = createContext({});
export const ModuleProvider = ({ children }) => {
    const [currentUrl, setCurrentUrl] = useState('');
    const [permissions, setPermissions] = useState({});
    const { user, modules, isLogged } = useAuth();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const handleConfigModule = useCallback(() => {
        if (!isLogged)
            return;
        (() => {
            const currentUrl = ModuleUtils.clearUrl(pathname);
            if (currentUrl === '/' || ModuleUrlBlacklist.includes(currentUrl)) {
                return;
            }
            const currentModule = ModuleUtils.getCurrentModule(pathname, modules);
            if (ValidationUtils.isEmptyObject(currentModule)) {
                navigate('/not-found');
                return;
            }
            const userScopes = user.UserScopes.map(x => x.Scope.Name);
            const permissions = {
                canRead: ScopeUtils.hasPermissionInScope(userScopes, currentModule.ScopePrefix, ScopeAction.Read),
                canList: ScopeUtils.hasPermissionInScope(userScopes, currentModule.ScopePrefix, ScopeAction.List),
                canCreate: ScopeUtils.hasPermissionInScope(userScopes, currentModule.ScopePrefix, ScopeAction.Create),
                canUpdate: ScopeUtils.hasPermissionInScope(userScopes, currentModule.ScopePrefix, ScopeAction.Update),
                canDelete: ScopeUtils.hasPermissionInScope(userScopes, currentModule.ScopePrefix, ScopeAction.Delete),
                canReport: ScopeUtils.hasPermissionInScope(userScopes, currentModule.ScopePrefix, ScopeAction.Report),
                canExecute: ScopeUtils.hasPermissionInScope(userScopes, currentModule.ScopePrefix, ScopeAction.Execute)
            };
            const canAccessModule = ModuleUtils.hasPermissionInModule(pathname, permissions);
            if (!canAccessModule) {
                navigate('/not-authorized');
                return;
            }
            setPermissions(permissions);
            setCurrentUrl(currentUrl);
        })();
    }, [isLogged, pathname, modules, user, navigate]);
    useEffect(handleConfigModule, [handleConfigModule]);
    return React.createElement(ModuleContext.Provider, { value: { currentUrl, permissions } }, children);
};
