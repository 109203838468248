export var PasswordValidationError;
(function (PasswordValidationError) {
    PasswordValidationError[PasswordValidationError["MIN_CHARACTER"] = 0] = "MIN_CHARACTER";
    PasswordValidationError[PasswordValidationError["UPPERCASE_CHARACTER"] = 1] = "UPPERCASE_CHARACTER";
    PasswordValidationError[PasswordValidationError["NUMBER_CHARACTER"] = 2] = "NUMBER_CHARACTER";
})(PasswordValidationError || (PasswordValidationError = {}));
export const PASSWORD_VALIDATION_ERRORS = {
    [PasswordValidationError.MIN_CHARACTER]: 'A senha deve conter ao menos 8 caracteres',
    [PasswordValidationError.UPPERCASE_CHARACTER]: 'A senha deve conter ao menos um caracter maiúsculo',
    [PasswordValidationError.NUMBER_CHARACTER]: 'A senha deve conter ao menos um caracter numérico'
};
export function validatePassword(value) {
    if (value.length < 8)
        return PasswordValidationError.MIN_CHARACTER;
    if (!value.match(/[A-Z]/))
        return PasswordValidationError.UPPERCASE_CHARACTER;
    if (!value.match(/[0-9]/))
        return PasswordValidationError.NUMBER_CHARACTER;
}
