import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { BENEFIT_CONSUMPTION_QUERY_KEY } from './useBenefitConsumptionConfig';
export const useBenefitConsumptionQuery = (benefitConsumptionId) => {
    const { errorCallback } = useAlert();
    const navigate = useNavigate();
    const getBenefitConsumption = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/benefit-consumption/${benefitConsumptionId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'BENEFIT_CONSUMPTION_GET_FAILURE');
            navigate('/benefits/consumptions');
            throw error;
        });
    }, [errorCallback, benefitConsumptionId, navigate]);
    const { data, isLoading } = useQuery([BENEFIT_CONSUMPTION_QUERY_KEY, benefitConsumptionId], getBenefitConsumption, {
        enabled: !!benefitConsumptionId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
