import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'components';
import { useExportQuery, useModulePermissionsByScope } from 'hooks';
import { Scope } from 'types/enum';
const BeneficiaryExportModal = ({ filterParams }) => {
    const { canRead: canRedBeneficiary } = useModulePermissionsByScope(Scope.BENEFICIARY);
    const { canRead: canRedDependent } = useModulePermissionsByScope(Scope.DEPENDENT);
    const { canRead: canRedPremiumRecipient } = useModulePermissionsByScope(Scope.PREMIUM_RECIPIENT);
    const beneficiaryExportQuery = useExportQuery('beneficiary/by-parameters-excel', filterParams);
    const dependentExportQuery = useExportQuery('dependent/by-parameters-excel', filterParams);
    const premiumRecipientExportQuery = useExportQuery('premium-recipient/by-parameters-excel', filterParams);
    const beneficiaryBenefitExportQuery = useExportQuery('report/beneficiaries-and-benefits-excel', filterParams);
    const [showModal, setShowModal] = useState(false);
    const toggleModal = useCallback(() => setShowModal(showModal => !showModal), []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { text: "Exportar", icon: "download", color: "info", animationHover: "pop", onClick: toggleModal }),
        React.createElement(Modal, { show: showModal, handleClose: toggleModal, title: "Exportar", closable: true },
            React.createElement("h3", { className: "text-base text-gray-800 dark:text-gray-50 text-center mb-4" }, "Escolha os dados a serem exportados:"),
            React.createElement("div", { className: "flex flex-col gap-4 items-center" },
                React.createElement("a", { href: beneficiaryExportQuery, target: "_blank", rel: "noreferrer", className: "max-w-sm w-full" },
                    React.createElement(Button, { text: "Associados", icon: "download", color: "info", variant: "outlined", animationHover: "pop", className: "w-full", disabled: !canRedBeneficiary })),
                React.createElement("a", { href: dependentExportQuery, target: "_blank", rel: "noreferrer", className: "max-w-sm w-full" },
                    React.createElement(Button, { text: "Dependentes", icon: "download", color: "info", variant: "outlined", animationHover: "pop", className: "w-full", disabled: !canRedDependent })),
                React.createElement("a", { href: premiumRecipientExportQuery, target: "_blank", rel: "noreferrer", className: "max-w-sm w-full" },
                    React.createElement(Button, { text: "Premiados", icon: "download", color: "info", variant: "outlined", animationHover: "pop", className: "w-full", disabled: !canRedPremiumRecipient })),
                React.createElement("a", { href: beneficiaryBenefitExportQuery, target: "_blank", rel: "noreferrer", className: "max-w-sm w-full" },
                    React.createElement(Button, { text: "Associados e Benef\u00EDcios", icon: "download", color: "info", variant: "outlined", animationHover: "pop", className: "w-full", disabled: !canRedBeneficiary }))))));
};
export default BeneficiaryExportModal;
