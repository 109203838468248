import React from 'react';
import { AuthLayout } from 'components';
import { useAuth } from 'contexts/auth.context';
import { useDocumentTitle } from 'hooks';
import { AuthModule } from 'types/enum';
import { LoginForm } from '../components';
const Login = () => {
    const { login } = useAuth();
    useDocumentTitle('Entrar');
    return (React.createElement(AuthLayout, { type: AuthModule.LOGIN },
        React.createElement("h3", { className: "auth-title" }, "Entrar"),
        React.createElement("p", { className: "auth-description" }, "Entre com seu e-mail e senha para acessar o painel."),
        React.createElement(LoginForm, { handleSubmit: login })));
};
export default Login;
