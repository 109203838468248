import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { ME_QUERY_KEY } from './useMeConfig';
export const useMeQuery = (enabled = true) => {
    const { errorCallback } = useAlert();
    const navigate = useNavigate();
    const getMe = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get('/me', {
            signal
        })
            .catch(error => {
            errorCallback(error, 'ME_FAILURE');
            navigate('/not-found');
            throw error;
        });
    }, [errorCallback, navigate]);
    const { data, isLoading } = useQuery([ME_QUERY_KEY], getMe, {
        enabled
    });
    const memoizedData = useMemo(() => data ?? {}, [data]);
    return [memoizedData, isLoading];
};
