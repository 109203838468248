import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { DEPENDENT_LIST_QUERY_KEY, DEPENDENT_QUERY_KEY } from './useDependentConfig';
export const useDependentMutation = (beneficiaryId, dependentId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createDependent = useCallback(async (dependentData) => {
        return httpService.post('/dependent', dependentData);
    }, [httpService]);
    const updateDependent = useCallback(async (dependentData) => {
        return httpService.put('/dependent', dependentData);
    }, [httpService]);
    const removeDependent = useCallback(async (dependentId) => {
        return httpService.delete(`/dependent?id=${dependentId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createDependent, {
        onSuccess: () => {
            queryClient.invalidateQueries([DEPENDENT_LIST_QUERY_KEY, beneficiaryId]);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateDependent, {
        onSuccess: () => {
            queryClient.invalidateQueries([DEPENDENT_QUERY_KEY, dependentId]);
            queryClient.invalidateQueries([DEPENDENT_LIST_QUERY_KEY, beneficiaryId]);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removeDependent, {
        onSuccess: (_, dependentId) => {
            queryClient.removeQueries([DEPENDENT_QUERY_KEY, dependentId]);
            queryClient.invalidateQueries([DEPENDENT_LIST_QUERY_KEY, beneficiaryId]);
        }
    });
    return { create, update, remove, isCreating, isUpdating, isRemoving };
};
