import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useModulePermissions, useTableFilter, useDocumentTitle, useBenefitPagination, useBenefitMutation } from 'hooks';
import { BenefitTableFilterForm } from '../components';
const BenefitList = () => {
    const { canRead, canCreate, canUpdate, canDelete } = useModulePermissions();
    const { success, confirm, errorCallback } = useAlert();
    const customParamsHandle = useCallback((filterParams) => {
        const params = {};
        if (filterParams.BenefitCategoryId) {
            params.BenefitCategoryId = filterParams.BenefitCategoryId;
        }
        return params;
    }, []);
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter({
        customParamsHandle
    });
    const { data, isLoading, ...pagination } = useBenefitPagination(requestFilterParams);
    const { remove } = useBenefitMutation();
    const deleteBenefit = useCallback(async (benefitId) => {
        await remove(benefitId)
            .then(() => success({
            title: 'Benefício deletado!',
            message: 'Benefício deletado com sucesso.',
            id: 'BENEFIT_DELETE_SUCCESS'
        }))
            .catch(err => errorCallback(err, 'BENEFIT_DELETE_FAILURE'));
    }, [remove, success, errorCallback]);
    const onDeleteBenefit = useCallback((benefitId) => {
        confirm({
            title: 'Você tem certeza?',
            description: (React.createElement("p", null,
                "O benef\u00EDcio ser\u00E1 deletado. ",
                React.createElement("br", null),
                " Essa a\u00E7\u00E3o \u00E9 irrevers\u00EDvel!")),
            confirmText: 'Sim, deletar',
            cancelText: 'Não, cancelar',
            onConfirm: async () => deleteBenefit(benefitId)
        });
    }, [confirm, deleteBenefit]);
    const searchFields = useMemo(() => [
        {
            Value: 'Name',
            Description: 'Nome'
        }
    ], []);
    const columns = useMemo(() => [
        {
            Header: 'Nome',
            accessor: 'Name'
        },
        {
            Header: 'Situação',
            accessor: 'Active',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold', value ? 'text-green-light' : 'text-red-light') }, value ? 'ATIVO' : 'INATIVO'))
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" },
                    (canRead || canUpdate) && (React.createElement(Link, { to: `/benefits/${value}` },
                        React.createElement(Tooltip, { id: `benefit-${value}-edit`, text: canUpdate ? 'Editar' : 'Visualizar' },
                            React.createElement(Button, { icon: canUpdate ? 'edit' : 'eye', color: "info", rounded: true })))),
                    canDelete && (React.createElement(Tooltip, { id: `benefit-${value}-remove`, text: "Deletar" },
                        React.createElement(Button, { className: "ml-2", icon: "trash", color: "danger", title: "Deletar", onClick: () => onDeleteBenefit(value), rounded: true })))));
            }
        }
    ], [canRead, canUpdate, canDelete, onDeleteBenefit]);
    useDocumentTitle('Benefícios');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Benef\u00EDcios" }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            canCreate && (React.createElement(Link, { to: "/benefits/new" },
                React.createElement(Button, { text: "Novo Benef\u00EDcio", icon: "add", color: "info", animationHover: "pop" }))),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Benef\u00EDcios", formConfig: { searchFields, filterParams, withFields: true, withSituation: true }, handleFilter: setParams, customFilters: BenefitTableFilterForm }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhum benef\u00EDcio a ser exibido" })));
};
export default BenefitList;
