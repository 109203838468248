import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Icon, Tooltip } from 'components';
import { SolicitationStateReasonLabel, SolicitationStatus } from 'types/enum';
import { FormatterUtils } from 'utils';
import './SolicitationStateItem.scss';
const SolicitationStateItem = ({ state }) => {
    const stateIcon = useMemo(() => {
        switch (state.Status.State) {
            case SolicitationStatus.IN_PROGRESS:
                return 'clock';
            case SolicitationStatus.IN_REVISION:
                return 'revision';
            case SolicitationStatus.IN_APPROVAL:
                return 'warning';
            case SolicitationStatus.REPROVED:
                return 'error';
            case SolicitationStatus.CANCELLED:
                return 'error';
            case SolicitationStatus.FINISHED:
                return 'success';
            default:
                return 'new';
        }
    }, [state.Status.State]);
    const helperLabel = useMemo(() => {
        switch (state.Status.State) {
            case SolicitationStatus.FINISHED:
            case SolicitationStatus.IN_REVISION:
            case SolicitationStatus.IN_APPROVAL:
                return SolicitationStateReasonLabel[state.StateReason];
            default:
                return null;
        }
    }, [state.Status.State, state.StateReason]);
    return (React.createElement("div", { className: clsx('solicitation-state-item', `solicitation-state-${state.Status.State}`) },
        React.createElement("div", { className: "solicitation-state-icon" },
            React.createElement(Icon, { name: stateIcon, size: 24 })),
        React.createElement("div", { className: "solicitation-state-details" },
            React.createElement("div", { className: "solicitation-state-title" },
                React.createElement("p", null, state.Status.Name),
                !!helperLabel && (React.createElement(Tooltip, { id: `solicitation-state-${state.Id}`, text: helperLabel },
                    React.createElement(Icon, { name: "warning", size: 14, className: "solicitation-state-tooltip" })))),
            React.createElement("small", null, FormatterUtils.dateTime(state.CreatedAt)))));
};
export default SolicitationStateItem;
