import { Validation } from 'utils';
export const PlanFormSchema = Validation.object().shape({
    Name: Validation.string().required('Informe o nome'),
    PlanRanges: Validation.array().of(Validation.object().shape({
        AgeFrom: Validation.number()
            .required('Informe a idade')
            .min(1, 'Informe a idade')
            .when('AgeTo', {
            is: value => value > 0,
            then: Validation.number().lessThan(Validation.ref('AgeTo'), 'Informe um valor válido')
        }),
        AgeTo: Validation.number().required('Informe a idade').min(1, 'Informe a idade'),
        DailyAmount: Validation.number().required('Informe a quantidade de diárias').min(1, 'Informe um valor válido'),
        Value: Validation.number().required('Informe um valor').min(1, 'Informe um valor')
    })),
    Active: Validation.bool().required('Informe o status')
});
