import React from 'react';
import { Card } from 'components';
import { useBenefitConsumptionMonthlyBalanceMutation, useModulePermissionsByScope } from 'hooks';
import { Scope } from 'types/enum';
import DashboardActionListItem from './DashboardActionListItem';
const DashboardActionList = () => {
    const { calculate: onClickCalculateBenefitConsumption, isCalculating } = useBenefitConsumptionMonthlyBalanceMutation();
    const { canExecute: canExecuteBenefitConsumptionCalculation } = useModulePermissionsByScope(Scope.BENEFIT_CONSUMPTION_MONTHLY_BALANCE);
    const hasNoAction = !canExecuteBenefitConsumptionCalculation;
    return (React.createElement(Card, { className: "mt-8" },
        React.createElement("h2", { className: "text-lg font-semibold text-gray dark:text-gray-50 mb-4" }, "A\u00E7\u00F5es r\u00E1pidas"),
        hasNoAction && React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400" }, "A\u00E7\u00F5es r\u00E1pidas indispon\u00EDveis."),
        React.createElement("div", { className: "grid grid-cols-4 gap-4" }, canExecuteBenefitConsumptionCalculation && (React.createElement(DashboardActionListItem, { icon: "benefit", title: "Calcular consumos", description: "Calcular os consumos do m\u00EAs dos associados", onClick: onClickCalculateBenefitConsumption, disabled: isCalculating, loading: isCalculating })))));
};
export default DashboardActionList;
