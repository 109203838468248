import { useMemo } from 'react';
import { authenticationService } from 'services';
import { ValidationUtils } from 'utils';
export const buildExportQuery = (endpoint, params = {}) => {
    const authenticationToken = authenticationService.getToken();
    const queryParams = new URLSearchParams();
    Object.keys(params)
        .filter(key => ValidationUtils.isNotNullOrUndefined(params[key]) && ValidationUtils.isNotEmptyList(params[key]))
        .forEach(key => {
        if (Array.isArray(params[key])) {
            params[key].forEach(element => {
                queryParams.append(key, element);
            });
            return;
        }
        queryParams.append(key, params[key]);
    });
    return `${ENVIRONMENT.API}/${endpoint}?token=${authenticationToken}&${queryParams.toString()}`;
};
export const useExportQuery = (endpoint, params) => {
    const query = useMemo(() => buildExportQuery(endpoint, params), [params, endpoint]);
    return query;
};
