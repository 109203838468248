import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Icon } from 'components';
import { useModulePermissions, useTableFilter, useDocumentTitle, useBenefitConsumptionMonthlyBalancePagination } from 'hooks';
import { FinancialStatementPaymentMethodLabel, FinancialStatementPaymentStatusLabel } from 'types/enum/financial/FinancialStatementPaymentEnum';
import { FormatterUtils, ValidationUtils } from 'utils';
import { FinancialStatementsFilterForm } from '../components';
import FinancialStatementModal from '../components/DetailsModal/FinancialStatementsModal';
const FinancialStatementsList = () => {
    const { canCreate, canRead, canUpdate } = useModulePermissions();
    const customParamsHandle = useCallback((filterParams) => {
        const params = {};
        params.NonNullable = 'FinancialStatements';
        if (filterParams.DateRange?.length) {
            const [startDate, endDate] = filterParams.DateRange;
            if (ValidationUtils.isNotNullOrUndefined(startDate) && ValidationUtils.isNotNullOrUndefined(endDate)) {
                params.StartDate = startDate?.utc().format();
                params.EndDate = endDate?.utc().format();
            }
        }
        if (filterParams.RegistrationCode) {
            params.RegistrationCode = filterParams.RegistrationCode;
        }
        if (filterParams.PersonDocument) {
            params.PersonDocument = filterParams.PersonDocument;
        }
        if (filterParams.CallName) {
            params.CallName = filterParams.CallName;
        }
        if (filterParams.PaymentMethod) {
            params.PaymentMethod = filterParams.PaymentMethod;
        }
        if (filterParams.PaymentStatus) {
            params.PaymentStatus = filterParams.PaymentStatus;
        }
        return params;
    }, []);
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter({
        customParamsHandle
    });
    useEffect(() => {
        console.log(requestFilterParams);
    }, [requestFilterParams]);
    const { data, isLoading, refetch, ...pagination } = useBenefitConsumptionMonthlyBalancePagination(requestFilterParams);
    const columns = useMemo(() => [
        {
            Header: 'Código',
            accessor: 'Beneficiary',
            id: 'RegistrationCode',
            Cell: ({ cell: { value } }) => React.createElement("span", { className: clsx('font-semibold') }, value.RegistrationCode ?? '')
        },
        {
            Header: 'Associado',
            accessor: 'Beneficiary',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement(Link, { to: `/beneficiaries/${value.Id}`, target: "_blank" },
                React.createElement("span", { className: "flex items-center font-semibold text-gray-light dark:text-gray-50" },
                    value.Name,
                    React.createElement(Icon, { name: "new-tab", size: 14, className: "text-blue-light ml-1" }))))
        },
        {
            Header: 'Método de Pagamento',
            accessor: 'FinancialStatement',
            id: 'PaymentMethod',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold') }, FinancialStatementPaymentMethodLabel[value.PaymentMethod] ?? '-'))
        },
        {
            Header: 'Valor',
            accessor: 'Balance',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => React.createElement("span", { className: clsx('font-semibold') }, FormatterUtils.money(-value))
        },
        {
            Header: 'Status',
            accessor: 'FinancialStatement',
            id: 'PaymentStatus',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold') }, FinancialStatementPaymentStatusLabel[value.PaymentStatus]))
        },
        {
            Header: 'Data Cadastro',
            accessor: 'CreatedAt',
            Cell: ({ cell: { value } }) => FormatterUtils.dateTime(value)
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value }, row: { original: consumption } }) => {
                return (React.createElement("div", { className: "flex items-center" }, (canRead || canUpdate) && (React.createElement(FinancialStatementModal, { financialStatement: consumption.FinancialStatement, consumptionIdList: consumption.BenefitConsumptionIdList, refetch: refetch }))));
            }
        }
    ], [canRead, canUpdate, refetch]);
    useDocumentTitle('Extrato Financeiro');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Extrato Financeiro" }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Extratos Financeiros", formConfig: { filterParams }, handleFilter: setParams, customFilters: FinancialStatementsFilterForm }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhum extrato a ser exibido" })));
};
export default FinancialStatementsList;
