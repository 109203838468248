import React from 'react';
import { Col, Row } from 'react-grid-system';
import { DateRangeField, FormField, Select, TextField } from 'components';
import { convertStateEnumListToOptionList, solicitationStateCancelledReasonOptions, solicitationStateFinishedReasonOptions } from 'types/enum';
const solicitationStateReasonSelectOptions = convertStateEnumListToOptionList([
    ...solicitationStateFinishedReasonOptions,
    ...solicitationStateCancelledReasonOptions
]);
const SolicitationLeftOverMoneyTableFilterForm = ({ values, setFieldValue, handleChange }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "PersonName", label: "Nome do Associado", value: values.PersonName ?? '', onChange: handleChange }))),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "PersonCallName", label: "Nome Guerra", value: values.PersonCallName ?? '', onChange: handleChange })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "SolicitationStateReason", label: "Motivo", options: solicitationStateReasonSelectOptions, value: solicitationStateReasonSelectOptions?.find(option => option.value === values.SolicitationStateReason), onChange: value => setFieldValue('SolicitationStateReason', value.value), getOptionLabel: option => option.label, getOptionValue: option => option.value }))),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(DateRangeField, { startDate: values.DateRange[0], endDate: values.DateRange[1], onDatesChange: ({ startDate, endDate }) => setFieldValue('DateRange', [startDate, endDate]), isOutsideRange: () => false }))))));
};
export default SolicitationLeftOverMoneyTableFilterForm;
