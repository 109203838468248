import React from 'react';
import { View as PdfView } from '@react-pdf/renderer';
export const Flex = ({ marginTop, marginBottom, marginRight, marginLeft, style, flexDirection = 'row', flexWrap = 'wrap', alignItems, justifyContent, children }) => (React.createElement(PdfView, { style: {
        marginTop,
        marginBottom,
        marginRight,
        marginLeft,
        flexDirection,
        flexWrap,
        alignItems,
        justifyContent,
        ...(style ?? {})
    } }, children));
