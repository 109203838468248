export function validateCpf(value) {
    const cpf = String(value).replace(/\D+/g, '');
    if (cpf.length !== 11) {
        return false;
    }
    const isSameNumber = cpf.match(/^(\d)\1{10}$/);
    if (isSameNumber) {
        return false;
    }
    const brokenCpf = cpf.match(/^(\d{9})(\d{2})$/);
    const withoutDigits = brokenCpf[1].split('');
    const digits = brokenCpf[2];
    const tempFirstDigit = withoutDigits.map(multiplyDigits(10)).reduce(sumDigits, 0);
    const firstDigit = getVerificationDigit(tempFirstDigit);
    const tempSecondDigit = withoutDigits.concat(String(firstDigit)).map(multiplyDigits(11)).reduce(sumDigits, 0);
    const secondDigit = getVerificationDigit(tempSecondDigit);
    return `${firstDigit}${secondDigit}` === digits;
}
function multiplyDigits(factor) {
    return function (digit, index) {
        return Number(digit) * (factor - index);
    };
}
function sumDigits(first, second) {
    return first + second;
}
function getVerificationDigit(value) {
    const rest = value % 11;
    return rest < 2 ? 0 : 11 - rest;
}
