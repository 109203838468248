import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useModulePermissions, useTableFilter, useDocumentTitle, useOperationBasePagination, useOperationBaseMutation } from 'hooks';
const OperationBaseList = () => {
    const { canCreate, canRead, canUpdate, canDelete } = useModulePermissions();
    const { success, confirm, errorCallback } = useAlert();
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter();
    const { data, isLoading, ...pagination } = useOperationBasePagination(requestFilterParams);
    const { remove } = useOperationBaseMutation();
    const deleteOperationBase = useCallback(async (operationBaseId) => {
        await remove(operationBaseId)
            .then(() => success({
            title: 'Base deletada!',
            message: 'Base deletada com sucesso.',
            id: 'OPERATION_BASE_DELETE_SUCCESS'
        }))
            .catch(err => errorCallback(err, 'OPERATION_BASE_DELETE_FAILURE'));
    }, [remove, success, errorCallback]);
    const onDeleteOperationBase = useCallback((operationBaseId) => {
        confirm({
            title: 'Você tem certeza?',
            description: (React.createElement("p", null,
                "A base ser\u00E1 deletada. ",
                React.createElement("br", null),
                " Essa a\u00E7\u00E3o \u00E9 irrevers\u00EDvel!")),
            confirmText: 'Sim, deletar',
            cancelText: 'Não, cancelar',
            onConfirm: async () => deleteOperationBase(operationBaseId)
        });
    }, [confirm, deleteOperationBase]);
    const searchFields = useMemo(() => [
        {
            Value: 'Name',
            Description: 'Nome'
        },
        {
            Value: 'IATA',
            Description: 'IATA'
        },
        {
            Value: 'ICAO',
            Description: 'ICAO'
        },
        {
            Value: 'City',
            Description: 'Cidade'
        },
        {
            Value: 'FS',
            Description: 'UF'
        }
    ], []);
    const columns = useMemo(() => [
        {
            Header: 'Nome',
            accessor: 'Name'
        },
        {
            Header: 'Localização',
            accessor: 'Id',
            id: 'Location',
            disableSortBy: true,
            Cell: ({ row: { original: values } }) => (React.createElement(React.Fragment, null,
                React.createElement("p", null, values.City),
                React.createElement("p", null, values.FS)))
        },
        {
            Header: 'Situação',
            accessor: 'Active',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold', value ? 'text-green-light' : 'text-red-light') }, value ? 'ATIVA' : 'INATIVA'))
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" },
                    (canRead || canUpdate) && (React.createElement(Link, { to: `/operational/operation-bases/${value}` },
                        React.createElement(Tooltip, { id: `operation-base-${value}-edit`, text: canUpdate ? 'Editar' : 'Visualizar' },
                            React.createElement(Button, { icon: canUpdate ? 'edit' : 'eye', color: "info", rounded: true })))),
                    canDelete && (React.createElement(Tooltip, { id: `operation-base-${value}-remove`, text: "Deletar" },
                        React.createElement(Button, { className: "ml-2", icon: "trash", color: "danger", title: "Deletar", onClick: () => onDeleteOperationBase(value), rounded: true })))));
            }
        }
    ], [canRead, canUpdate, canDelete, onDeleteOperationBase]);
    useDocumentTitle('Bases de Operação');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Bases de Opera\u00E7\u00E3o" }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            canCreate && (React.createElement(Link, { to: "/operational/operation-bases/new" },
                React.createElement(Button, { text: "Nova Base", icon: "add", color: "info", animationHover: "pop" }))),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Bases de Opera\u00E7\u00E3o", formConfig: { searchFields, filterParams, withFields: true, withSituation: true }, handleFilter: setParams }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhuma base a ser exibida" })));
};
export default OperationBaseList;
