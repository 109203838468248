import React, { useCallback, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { TextField, Button, FormField, FormFieldError, Form, MaskedTextField, PercentageField, FormikValidationHandler, useFormikValidationHandler } from 'components';
import { useFormik } from 'formik';
import { useModulePermissionsByScope } from 'hooks';
import { Scope } from 'types/enum';
import { FormatterUtils } from 'utils';
import { convertToPremiumRecipientDto } from './BeneficiaryPremiumRecipientForm.converter';
import { PremiumRecipientSchema } from './BeneficiaryPremiumRecipientForm.schema';
const defaultValues = {
    Id: 0,
    RegistrationCode: '',
    Name: '',
    Email: '',
    CellPhone: '',
    CPF: '',
    CreatedAt: '',
    UpdatedAt: '',
    PremiumPercentage: undefined
};
const BeneficiaryPremiumRecipientForm = ({ premiumRecipient, onSubmitPremiumRecipient }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissionsByScope(Scope.DEPENDENT);
    const onSubmit = useCallback(async (premiumRecipientValues) => {
        const premiumRecipientData = convertToPremiumRecipientDto(premiumRecipientValues);
        return onSubmitPremiumRecipient(premiumRecipientData);
    }, [onSubmitPremiumRecipient]);
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => PremiumRecipientSchema,
        onSubmit,
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleChange, handleSubmit, isSubmitting, setValues } = formikContext;
    const onInitForm = useCallback(() => {
        if (!premiumRecipient?.Id)
            return;
        const premiumRecipientData = {
            Id: premiumRecipient.Id,
            RegistrationCode: premiumRecipient.RegistrationCode,
            Name: premiumRecipient.Name,
            Email: premiumRecipient.Email || '',
            CellPhone: premiumRecipient.CellPhone || '',
            CPF: premiumRecipient.CPF || '',
            PremiumPercentage: premiumRecipient.PremiumPercentage || undefined,
            CreatedAt: FormatterUtils.dateTime(premiumRecipient.CreatedAt),
            UpdatedAt: FormatterUtils.dateTime(premiumRecipient.UpdatedAt)
        };
        setValues(premiumRecipientData);
    }, [premiumRecipient, setValues]);
    useEffect(onInitForm, [onInitForm]);
    return (React.createElement(Form, { name: "beneficiaryForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, null,
            !!premiumRecipient?.Id && (React.createElement(Col, { sm: 2 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "RegistrationCode", label: "C\u00F3digo de registro", value: values.RegistrationCode, disabled: true })))),
            React.createElement(Col, { sm: premiumRecipient?.Id ? 10 : 12 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Name", label: "Nome", value: values.Name, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Name" }))),
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Email", label: "E-mail", value: values.Email, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Email" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "cpf", name: "CPF", label: "CPF", value: values.CPF, onChange: value => setFieldValue('CPF', value) }),
                    React.createElement(FormFieldError, { name: "CPF" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "cellphone", name: "CellPhone", label: "Celular", value: values.CellPhone, onChange: value => setFieldValue('CellPhone', value) }),
                    React.createElement(FormFieldError, { name: "CellPhone" }))),
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(PercentageField, { name: "PremiumPercentage", label: "Percentual de premia\u00E7\u00E3o", onChange: value => setFieldValue('PremiumPercentage', value), value: values.PremiumPercentage }),
                    React.createElement(FormFieldError, { name: "PremiumPercentage" })))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Salvar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default BeneficiaryPremiumRecipientForm;
