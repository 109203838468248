import React, { useCallback, useEffect, useState } from 'react';
import { Button, Icon } from 'components';
import { useModulePermissions, useScopeList } from 'hooks';
import { TextUtils } from 'utils';
import { actionScopeLabel, moduleScopeLabel } from './UserPermissionForm.constants';
import { mapUserScopes } from './UserPermissionForm.helper';
const UserPermissionForm = ({ user, handleSubmit: handleSubmitUserPermission }) => {
    const { canMutate } = useModulePermissions(user?.Id);
    const [scopes, isLoadingScopes] = useScopeList();
    const [userScopes, setUserScopes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const onSubmitScopes = useCallback(async () => {
        setIsLoading(true);
        const scopes = userScopes.flatMap(x => x.Scopes.filter(y => y.Value).map(y => y.ScopeId));
        await handleSubmitUserPermission(scopes);
        setIsLoading(false);
    }, [userScopes, handleSubmitUserPermission]);
    const onSelectScope = useCallback((module, scopeId = null) => {
        setUserScopes(userScopes => userScopes.map(userScope => {
            if (userScope.Module !== module)
                return userScope;
            return {
                Module: userScope.Module,
                Scopes: userScope.Scopes.map(scope => {
                    if (!scopeId)
                        return { ...scope, Value: true };
                    if (scope.ScopeId !== scopeId)
                        return scope;
                    return { ...scope, Value: !scope.Value };
                })
            };
        }));
    }, []);
    const onLoadScopes = useCallback(() => {
        if (!scopes.length)
            return;
        setUserScopes(mapUserScopes(scopes, user));
    }, [user, scopes]);
    useEffect(onLoadScopes, [onLoadScopes]);
    if (isLoadingScopes) {
        return React.createElement(Icon, { name: "spinner", size: 35, className: "text-gray-light dark:text-gray-400" });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("table", { className: "min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700" },
            React.createElement("thead", { className: "bg-slate-100 dark:bg-slate-dark" },
                React.createElement("tr", null,
                    React.createElement("th", { scope: "col", className: "py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-light dark:text-gray-400" }, "M\u00F3dulo"),
                    React.createElement("th", { scope: "col", className: "py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-light dark:text-gray-400" }, "Permiss\u00F5es"))),
            React.createElement("tbody", { className: "bg-white divide-y divide-gray-200 dark:bg-slate dark:divide-gray-700" }, userScopes.map((module, i) => (React.createElement("tr", { key: i, className: "hover:bg-slate-100 dark:hover:bg-slate-dark" },
                React.createElement("td", { className: "py-4 px-6 text-sm font-medium text-gray-light whitespace-nowrap dark:text-gray-50" }, moduleScopeLabel[module.Module]),
                React.createElement("td", { className: "p-4 w-4" },
                    React.createElement("div", { className: "flex" },
                        module.Scopes.map(scope => (React.createElement("div", { key: scope.ScopeId.toString(), className: "flex items-center mr-4" },
                            React.createElement("input", { id: "inline-checkbox", type: "checkbox", value: scope.ScopeId, checked: !!scope.Value, onChange: () => onSelectScope(module.Module, scope.ScopeId), className: "w-4 h-4 text-blue-light bg-gray-100 rounded border-gray-300 focus:ring-blue-light dark:focus:ring-blue-light dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" }),
                            React.createElement("label", { htmlFor: "inline-checkbox", className: "ml-2 text-xs font-medium text-gray-light dark:text-gray-50" }, TextUtils.capitalize(actionScopeLabel[scope.Action] ?? scope.Action))))),
                        React.createElement("div", { className: "flex items-center ml-auto" },
                            React.createElement("input", { id: "inline-checkbox", type: "checkbox", value: module.Module, checked: module.Scopes.every(x => x.Value), onChange: () => onSelectScope(module.Module), className: "w-4 h-4 text-blue-light bg-gray-100 rounded border-gray-300 focus:ring-blue-light dark:focus:ring-blue-light dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" }),
                            React.createElement("label", { htmlFor: "inline-checkbox", className: "ml-2 text-xs font-medium text-gray-light dark:text-gray-50" }, "Todos"))))))))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Enviar", color: "success", type: "submit", loading: isLoading, onClick: onSubmitScopes, animationHover: "pop" })))));
};
export default UserPermissionForm;
