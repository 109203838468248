import { useCallback, useState } from 'react';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services';
import { ValidationUtils } from 'utils';
export var ReportExportEndpoint;
(function (ReportExportEndpoint) {
    ReportExportEndpoint["SOLICITATION_DETAILED"] = "solicitation-detailed";
    ReportExportEndpoint["SOLICITATION_MONTHLY_RECEIPT"] = "solicitation-monthly-receipt";
    ReportExportEndpoint["BENEFIT_CONSUMPTION_MONTHLY_BALANCE"] = "benefit-consumption-monthly-balance";
    ReportExportEndpoint["BENEFICIARY_FINANCIAL_HISTORY"] = "beneficiary-financial-history";
})(ReportExportEndpoint || (ReportExportEndpoint = {}));
export const useReportExportMutation = (endpoint) => {
    const httpService = makeHttpService();
    const { success, error } = useAlert();
    const exportReport = useCallback(async (input) => {
        const data = new FormData();
        Object.keys(input)
            .filter(key => ValidationUtils.isNotNullOrUndefined(input[key]))
            .forEach(key => {
            data.append(key, input[key]);
        });
        return httpService
            .post(`/report/${endpoint}/export`, data)
            .then(() => success({
            id: 'REPORT_EXPORT_SUCCESS',
            title: 'Relatório exportado!',
            message: 'O relatório foi exportado com sucesso.'
        }))
            .catch(() => error({ id: 'REPORT_EXPORT_FAILED', message: 'Houve um erro ao exportar o relatório.' }));
    }, [httpService, endpoint, success, error]);
    const [isExporting, setIsExporting] = useState(false);
    const onExportReport = useCallback(async (input) => {
        setIsExporting(true);
        await exportReport(input);
        setIsExporting(false);
    }, [exportReport]);
    return [onExportReport, isExporting];
};
