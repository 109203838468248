import { FormatterUtils } from 'utils';
export const convertToSolicitationDto = (solicitationCreationData) => {
    return {
        BeneficiaryId: solicitationCreationData.Beneficiary?.Id,
        SolicitationDeseases: solicitationCreationData.Deseases.map(x => ({ DeseaseId: x.Id })),
        StartDate: FormatterUtils.momentDate(solicitationCreationData.StartDate)?.format(),
        INSSDate: FormatterUtils.momentDate(solicitationCreationData.INSSDate)?.format(),
        ScheduleWithdraw: FormatterUtils.momentDate(solicitationCreationData.ScheduleWithdraw)?.format(),
        SolicitationTreatmentMethod: solicitationCreationData.SolicitationTreatmentMethod,
        SolicitationPaymentFlow: {
            SolicitationPaymentFlowInstallments: solicitationCreationData.SolicitationPaymentFlowInstallments.map(x => ({
                DailyRateAmount: x
            }))
        },
        WorkAccident: solicitationCreationData.WorkAccident
    };
};
