import { ScopeAction } from 'types/enum';
const getUserScopesPrefix = (user) => {
    const SCOPE_ACTIONS = Object.values(ScopeAction);
    const userScopes = user.UserScopes.map(x => x.Scope.Name);
    const normalizedUserScopes = userScopes.map(userScope => {
        SCOPE_ACTIONS.forEach(action => {
            userScope = userScope.toLocaleLowerCase().replaceAll(action, '');
        });
        return userScope;
    });
    const uniqueUserScopes = [...new Set(normalizedUserScopes)];
    return uniqueUserScopes;
};
const hasPermissionInScope = (userScopes, moduleScopePrefix, action) => {
    if (userScopes.find(userScope => userScope.includes('administrator')))
        return true;
    return userScopes.some(userScope => userScope.toLowerCase() === `${moduleScopePrefix.toLowerCase()}${action}`);
};
export const ScopeUtils = {
    getUserScopesPrefix,
    hasPermissionInScope
};
