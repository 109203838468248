import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { SOLICITATION_DOCUMENT_LIST_KEY } from './useSolicitationDocumentConfig';
export const useSolicitationDocumentList = (solicitationId, shouldFetch = true) => {
    const { errorCallback } = useAlert();
    const getSolicitationDocuments = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/solicitation-document/${solicitationId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'SOLICITATION_DOCUMENT_GET_ALL_FAILURE');
            return [];
        });
    }, [errorCallback, solicitationId]);
    const { data, isLoading } = useQuery([SOLICITATION_DOCUMENT_LIST_KEY, solicitationId, shouldFetch], getSolicitationDocuments, {
        enabled: !!shouldFetch && !!solicitationId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    const memoizedData = useMemo(() => data ?? [], [data]);
    return [memoizedData, isLoading];
};
