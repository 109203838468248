import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'components';
import { SolicitationCard } from '../Card';
const SolicitationReportCard = ({ solicitationId }) => {
    const ButtonActionElement = (React.createElement("div", { className: "flex justify-center flex-col lg:flex-row" },
        React.createElement(Link, { to: `/cam/solicitations/${solicitationId}/detailed-report`, target: "_blank" },
            React.createElement(Button, { text: "Relat\u00F3rio Detalhado", color: "info", animationHover: "pop", className: "mr-2 mb-2 lg:mb-0" })),
        React.createElement(Link, { to: `/cam/solicitations/${solicitationId}/monthly-receipt-report`, target: "_blank" },
            React.createElement(Button, { text: "Comprovante de Pagamento Mensal", color: "info", variant: "outlined", animationHover: "pop", className: "mr-2 mb-2 lg:mb-0" })),
        React.createElement(Link, { to: `/cam/solicitations/${solicitationId}/approval-report`, target: "_blank" },
            React.createElement(Button, { text: "Relat\u00F3rio de Aprova\u00E7\u00F5es", color: "info", animationHover: "pop", className: "mr-2 mb-2 lg:mb-0" }))));
    return (React.createElement(SolicitationCard, { title: "Relat\u00F3rios", classes: {
            textColumn: 'mb-2 lg:mb-0',
            buttonColumn: 'justify-center lg:justify-end'
        }, customButtom: ButtonActionElement, showButtonAction: true }));
};
export default SolicitationReportCard;
