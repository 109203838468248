import React, { useCallback } from 'react';
import { Row, Col } from 'react-grid-system';
import { TextField, Button, FormField, FormFieldError, CurrencyField } from 'components';
import { useFormikContext } from 'formik';
const PlanRangeItemForm = ({ planRange, index }) => {
    const { values, setFieldValue } = useFormikContext();
    const persistEditedField = useCallback((index) => {
        planRange.Id && setFieldValue(`PlanRanges[${index}].IsEdited`, true);
    }, [planRange, setFieldValue]);
    const onChangeAgeFrom = useCallback((e) => {
        setFieldValue(`PlanRanges[${index}].AgeFrom`, e.target.value);
        persistEditedField(index);
    }, [index, persistEditedField, setFieldValue]);
    const onChangeAgeTo = useCallback((e) => {
        setFieldValue(`PlanRanges[${index}].AgeTo`, e.target.value);
        persistEditedField(index);
    }, [index, persistEditedField, setFieldValue]);
    const onChangeDailyAmount = useCallback((e) => {
        setFieldValue(`PlanRanges[${index}].DailyAmount`, e.target.value);
        persistEditedField(index);
    }, [index, persistEditedField, setFieldValue]);
    const onChangeValue = useCallback((value) => {
        setFieldValue(`PlanRanges[${index}].Value`, value);
        persistEditedField(index);
    }, [index, persistEditedField, setFieldValue]);
    const canDeletePlanRange = values.PlanRanges.filter(x => !x.IsDeleted).length > 1;
    return (React.createElement("div", null,
        React.createElement(Row, { align: "center" },
            React.createElement(Col, null,
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, lg: 6 },
                        React.createElement(FormField, null,
                            React.createElement(TextField, { name: `PlanRanges[${index}].AgeFrom`, label: "Idade de", value: planRange.AgeFrom, onChange: onChangeAgeFrom, type: "tel", pattern: "[0-9]*" }),
                            React.createElement(FormFieldError, { name: `PlanRanges[${index}].AgeFrom` }))),
                    React.createElement(Col, { xs: 12, lg: 6 },
                        React.createElement(FormField, null,
                            React.createElement(TextField, { name: `PlanRanges[${index}].AgeTo`, label: "Idade at\u00E9", value: planRange.AgeTo, onChange: onChangeAgeTo, type: "tel", pattern: "[0-9]*" }),
                            React.createElement(FormFieldError, { name: `PlanRanges[${index}].AgeTo` })))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, lg: 6 },
                        React.createElement(FormField, null,
                            React.createElement(TextField, { name: `PlanRanges[${index}].DailyAmount`, label: "Qnt. de di\u00E1rias", value: planRange.DailyAmount, onChange: onChangeDailyAmount, type: "tel", pattern: "[0-9]*" }),
                            React.createElement(FormFieldError, { name: `PlanRanges[${index}].DailyAmount` }))),
                    React.createElement(Col, { xs: 12, lg: 6 },
                        React.createElement(FormField, null,
                            React.createElement(CurrencyField, { name: `PlanRanges[${index}].Value`, label: "Valor", onChange: onChangeValue, value: planRange.Value }),
                            React.createElement(FormFieldError, { name: `PlanRanges[${index}].Value` }))))),
            canDeletePlanRange && (React.createElement(Col, { sm: "content" },
                React.createElement(Button, { icon: "trash", color: "danger", onClick: () => setFieldValue(`PlanRanges[${index}].IsDeleted`, true), style: { marginTop: 5 }, rounded: true }))))));
};
export default PlanRangeItemForm;
