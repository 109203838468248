import React, { useCallback, useRef } from 'react';
import { Row, Col } from 'react-grid-system';
import { Button, FormField, FormFieldError, Form, useFormikValidationHandler, FormikValidationHandler, Select } from 'components';
import { useFormik } from 'formik';
import { useBeneficiaryList, useModulePermissions, useSolicitationByBeneficiaryQuery } from 'hooks';
import { RecompositionInequalityFormSchema } from './RecompositionInequalityForm.schema';
const defaultValues = {
    BeneficiaryId: null,
    SolicitationId: null
};
const RecompositionInequalityForm = ({ handleSubmit: handleSubmitRecompositionInequality }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions();
    const solicitationInputRef = useRef();
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => RecompositionInequalityFormSchema,
        onSubmit: async (recompositionInequalityValues) => handleSubmitRecompositionInequality(recompositionInequalityValues.SolicitationId),
        validateOnChange: canValidate
    });
    const { values, setFieldValue, handleSubmit, isSubmitting } = formikContext;
    const [beneficiaries, isLoadingBeneficiaries] = useBeneficiaryList();
    const [solicitations, isLoadingSolicitations] = useSolicitationByBeneficiaryQuery(values.BeneficiaryId);
    const onBeneficiaryChange = useCallback((value) => {
        solicitationInputRef.current?.clearValue();
        setFieldValue('BeneficiaryId', value?.Id ?? null);
    }, [solicitationInputRef, setFieldValue]);
    const onSolicitationChange = useCallback((value) => {
        setFieldValue('SolicitationId', value?.Id ?? null);
    }, [setFieldValue]);
    return (React.createElement(Form, { name: "recompositionInequalityForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "Beneficiary", label: "Associado", options: beneficiaries, value: beneficiaries?.find(beneficiary => beneficiary.Id === values.BeneficiaryId) ?? undefined, onChange: onBeneficiaryChange, getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingBeneficiaries, isClearable: true }),
                    React.createElement(FormFieldError, { name: "BeneficiaryId" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { selectRef: solicitationInputRef, name: "Solicitation", label: "Requerimento", options: solicitations, value: solicitations?.find(solicitation => solicitation.Id === values.SolicitationId) ?? undefined, onChange: onSolicitationChange, getOptionLabel: option => option.RegistrationCode, getOptionValue: option => option.Id.toString(), isLoading: isLoadingSolicitations, isDisabled: !values.BeneficiaryId, isClearable: true }),
                    React.createElement(FormFieldError, { name: "SolicitationId" })))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Enviar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default RecompositionInequalityForm;
