import React, { useCallback, useState } from 'react';
import { Button, Modal, Select } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useSolicitationMutation } from 'hooks';
import { convertStateEnumListToOptionList, solicitationStateCancelledReasonOptions, SolicitationStatus } from 'types/enum';
const solicitationStateCancelledReasonSelectOptions = convertStateEnumListToOptionList(solicitationStateCancelledReasonOptions);
const SolicitationCancelDialog = ({ solicitationId }) => {
    const { success, errorCallback } = useAlert();
    const [showDialog, setShowDialog] = useState(false);
    const [reason, setReason] = useState(null);
    const { createState, isCreatingSolicitationState } = useSolicitationMutation();
    const toggleDialog = useCallback(() => setShowDialog(showDialog => !showDialog), []);
    const cancelSolicitation = useCallback(async () => {
        const solicitationStateData = {
            SolicitationId: solicitationId,
            StatusId: SolicitationStatus.CANCELLED,
            StateReason: reason
        };
        createState(solicitationStateData, {
            onSuccess: () => {
                success({
                    title: 'Requerimento cancelado!',
                    message: 'Requerimento cancelado com sucesso.',
                    id: 'SOLICITATION_CANCEL_SUCCESS'
                });
                toggleDialog();
            },
            onError: err => errorCallback(err, 'SOLICITATION_CANCEL_FAILURE')
        });
    }, [createState, success, toggleDialog, errorCallback, solicitationId, reason]);
    const onSelectReason = useCallback((value) => {
        const selectedValue = value;
        setReason(selectedValue.value);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { text: "Cancelar Requerimento", color: "danger", animationHover: "pop", onClick: toggleDialog }),
        React.createElement(Modal, { show: showDialog, size: "alert", closable: false, title: "Cancelar Requerimento" },
            React.createElement("p", { className: "text-gray dark:text-gray-50 mb-4" },
                "Voc\u00EA tem certeza que deseja cancelar o requerimento? ",
                React.createElement("br", null),
                " Essa a\u00E7\u00E3o \u00E9 irrevers\u00EDvel!"),
            React.createElement(Select, { name: "StateReason", label: "Escolha o motivo", options: solicitationStateCancelledReasonSelectOptions, value: solicitationStateCancelledReasonSelectOptions?.find((x) => x.value === reason), onChange: onSelectReason, getOptionLabel: option => option.label, getOptionValue: option => option.label.toString() }),
            React.createElement("div", { className: "flex justify-end mt-4" },
                React.createElement(Button, { color: "danger", className: "mr-2", loading: isCreatingSolicitationState, disabled: !reason, onClick: cancelSolicitation, text: "Cancelar" }),
                React.createElement(Button, { color: "success", onClick: toggleDialog, disabled: isCreatingSolicitationState, text: "Voltar" })))));
};
export default SolicitationCancelDialog;
