import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppLayout } from 'components';
import { PrivateRouter } from 'modules';
import { PrivateRoute } from 'routes/components';
const PrivateRoutes = () => {
    return (React.createElement(AppLayout, null,
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/", element: React.createElement(PrivateRoute, null) }, PrivateRouter.map((route, key) => (React.createElement(Route, { key: key, path: route.path, element: route.element })))))));
};
export { PrivateRoutes };
