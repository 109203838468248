import React, { forwardRef, useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import logo from 'assets/images/logo.png';
import { Card, Icon } from 'components';
import { useSolicitationApprovalList, useSolicitationDurationQuery } from 'hooks';
import moment from 'moment-timezone';
import { FormatterUtils } from 'utils';
import './SolicitationApprovalReportContent.scss';
const SolicitationApprovalReportContent = ({ solicitation }, ref) => {
    const [solicitationDuration, isLoadingSolicitationDuration] = useSolicitationDurationQuery(solicitation?.Id);
    const [solicitationApprovals, isLoadingSolicitationApprovals] = useSolicitationApprovalList(solicitation?.Id);
    const solicitationDeseasesLabel = useMemo(() => solicitation.SolicitationDeseases.map(x => x.Desease?.Code).join(','), [solicitation.SolicitationDeseases]);
    const solicitationApprovalsLabel = useMemo(() => {
        return solicitationApprovals.reduce((acc, cur) => {
            if (cur.SolicitationStateApprovalVotes?.length) {
                acc.push(...cur.SolicitationStateApprovalVotes.map(x => ({
                    Person: x.User,
                    Vote: x.Vote
                })));
            }
            return acc;
        }, []);
    }, [solicitationApprovals]);
    const dateNow = useMemo(() => moment().format('D [de] MMMM [de] YYYY'), []);
    const isLoading = isLoadingSolicitationDuration || isLoadingSolicitationApprovals;
    if (isLoading) {
        return (React.createElement(Card, { ref: ref, className: "detailed-report-content-wrapper" },
            React.createElement("section", { className: "detailed-report-content" },
                React.createElement("div", { className: "detailed-report-content-header" },
                    React.createElement("div", { className: "detailed-report-content-header-title" },
                        React.createElement("span", null, dateNow),
                        React.createElement("h1", null, "Processo CAM - Conv\u00EAnio de Aux\u00EDlio M\u00FAtuo"),
                        React.createElement("h2", { className: "mb-2" }, "Relat\u00F3rio de Aprova\u00E7\u00F5es"),
                        React.createElement("h2", null,
                            "Requerimento ",
                            solicitation.RegistrationCode)),
                    React.createElement("div", { className: "detailed-report-content-header-logo" },
                        React.createElement("img", { src: logo }))),
                React.createElement(Icon, { name: "spinner", size: 35, className: "block text-gray-light dark:text-gray-400 mx-auto" }))));
    }
    return (React.createElement(Card, { ref: ref, className: "approval-report-content-wrapper" },
        React.createElement("section", { className: "approval-report-content" },
            React.createElement("div", { className: "approval-report-content-header" },
                React.createElement("div", { className: "approval-report-content-header-title" },
                    React.createElement("span", null, dateNow),
                    React.createElement("h1", null, "Processo CAM - Conv\u00EAnio de Aux\u00EDlio M\u00FAtuo"),
                    React.createElement("h2", { className: "mb-2" }, "Relat\u00F3rio de Aprova\u00E7\u00F5es"),
                    React.createElement("h2", null,
                        "Requerimento ",
                        solicitation.RegistrationCode)),
                React.createElement("div", { className: "approval-report-content-header-logo" },
                    React.createElement("img", { src: logo }))),
            React.createElement("div", { className: "approval-report-content-body" },
                React.createElement("div", { className: "approval-report-content-box" },
                    React.createElement("h3", { className: "mb-2" }, "Detalhes"),
                    React.createElement(Row, null,
                        React.createElement(Col, { sm: 4 },
                            React.createElement("div", { className: "approval-report-content-item" },
                                React.createElement("label", null, "Nome Guerra"),
                                React.createElement("p", null, solicitation.Beneficiary.CallName))),
                        React.createElement(Col, { sm: 4 },
                            React.createElement("div", { className: "approval-report-content-item" },
                                React.createElement("label", null, "Nome Completo"),
                                React.createElement("p", null, solicitation.Beneficiary.Name))),
                        React.createElement(Col, { sm: 4 },
                            React.createElement("div", { className: "approval-report-content-item" },
                                React.createElement("label", null, "Cargo"),
                                React.createElement("p", null, solicitation.Beneficiary.JobDescription?.Name ?? ''))),
                        React.createElement(Col, { sm: 4 },
                            React.createElement("div", { className: "approval-report-content-item" },
                                React.createElement("label", null, "Acidente de Trabalho"),
                                React.createElement("p", null, solicitation.WorkAccident ? 'Sim' : 'Não'))),
                        React.createElement(Col, { sm: 4 },
                            React.createElement("div", { className: "approval-report-content-item" },
                                React.createElement("label", null, "C\u00F3digo CID"),
                                React.createElement("p", null, solicitationDeseasesLabel)))),
                    React.createElement(Row, null,
                        React.createElement(Col, { sm: 4 },
                            React.createElement("div", { className: "approval-report-content-item" },
                                React.createElement("label", null, "Per\u00EDodo"),
                                React.createElement("p", null,
                                    FormatterUtils.date(solicitation.StartDate),
                                    " -",
                                    ' ',
                                    solicitation.EndDate
                                        ? FormatterUtils.date(solicitation.EndDate)
                                        : 'Em andamento'))),
                        React.createElement(Col, { sm: 4 },
                            React.createElement("div", { className: "approval-report-content-item" },
                                React.createElement("label", null, "Prazo pelo Processo CAM"),
                                React.createElement("p", null,
                                    solicitationDuration.TotalDurationDays,
                                    " dias"))))),
                React.createElement("div", { className: "approval-report-content-box dark-background mt-4" },
                    React.createElement("h3", { className: "mb-2" }, "Aprova\u00E7\u00F5es"),
                    solicitationApprovals.map((x, i) => (React.createElement("div", { key: i.toString(), className: "approval-report-content-item-group" },
                        React.createElement("label", null,
                            "Aprova\u00E7\u00E3o ",
                            i + 1,
                            " - ",
                            FormatterUtils.dateTime(x.CreatedAt)),
                        x.SolicitationStateApprovalVotes.map(approval => (React.createElement("div", { key: approval.SolicitationStateApprovalId.toString(), className: "approval-report-content-item-value" },
                            React.createElement("label", null, approval.User.Name),
                            React.createElement("p", null, approval.Vote ? 'Aprovado' : 'Reprovado'))))))))))));
};
export default forwardRef(SolicitationApprovalReportContent);
