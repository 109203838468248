import { Validation } from 'utils';
export const MonthlyBasePercentageFormSchema = Validation.object().shape({
    JobDescriptionId: Validation.number().required('Escolha um cargo').min(1, 'Escolha um cargo'),
    MonthYear: Validation.object().required('Informe o mês'),
    Percentage: Validation.number()
        .required('Informe a porcentagem')
        .min(1, 'Informe um valor maior do que 0')
        .max(100, 'Informe um valor menor do que 100'),
    Active: Validation.bool().required('Informe o status')
});
