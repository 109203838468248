import React, { useCallback, useState } from 'react';
import { Icon, Modal } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useModulePermissionsByScope, useSolicitationDocumentList, useSolicitationDocumentMutation } from 'hooks';
import { Scope } from 'types/enum';
import { SolicitationCard } from '../Card';
import { SolicitationAttachmentDropzone } from './AttachmentDropzone';
import { SolicitationAttachmentItemList } from './AttachmentItemList';
import './SolicitationAttachmentCard.scss';
const SolicitationAttachmentCard = ({ solicitationId, isDisabled }) => {
    const { canList, canCreate, canDelete } = useModulePermissionsByScope(Scope.SOLICITATION_DOCUMENT);
    const [attachments, isLoading] = useSolicitationDocumentList(solicitationId, canList);
    const [showAttachmentUpload, setShowAttachmentUpload] = useState(false);
    const toggleAttachmentUploadModal = useCallback(() => setShowAttachmentUpload(showAttachmentUpload => !showAttachmentUpload), []);
    const { create, isCreating, remove, isRemoving } = useSolicitationDocumentMutation(solicitationId);
    const { confirm, success, errorCallback } = useAlert();
    const attachFileToSolicitation = useCallback(async (uploadedFile) => {
        uploadedFile.SolicitationId = solicitationId;
        create(uploadedFile, {
            onSuccess: () => success({
                title: 'Documento salvo!',
                message: 'Documento salvo com sucesso.',
                id: 'SOLICITATION_ATTACHMENT_POST_SUCCESS'
            }),
            onError: err => errorCallback(err, 'SOLICITATION_ATTACHMENT_POST_FAILURE')
        });
    }, [create, solicitationId, success, errorCallback]);
    const onUploadedAttachments = useCallback(async (attachments) => {
        await Promise.all(attachments.map(attachFileToSolicitation));
        toggleAttachmentUploadModal();
    }, [attachFileToSolicitation, toggleAttachmentUploadModal]);
    const deleteAttachment = useCallback(async (attachmentId) => {
        return remove(attachmentId, {
            onSuccess: () => success({
                title: 'Documento deletado!',
                message: 'Documento deletado com sucesso.',
                id: 'SOLICITATION_ATTACHMENT_DELETE_SUCCESS'
            }),
            onError: err => errorCallback(err, 'SOLICITATION_ATTACHMENT_DELETE_FAILURE')
        });
    }, [remove, success, errorCallback]);
    const onRemoveAttachment = useCallback((attachment) => {
        confirm({
            title: 'Você tem certeza?',
            description: (React.createElement("p", null,
                "O documento ser\u00E1 deletado. ",
                React.createElement("br", null),
                " Essa a\u00E7\u00E3o \u00E9 irrevers\u00EDvel!")),
            confirmText: 'Sim, deletar',
            cancelText: 'Não, cancelar',
            onConfirm: () => deleteAttachment(attachment.Id)
        });
    }, [confirm, deleteAttachment]);
    if (isLoading || isCreating || isRemoving) {
        return (React.createElement(SolicitationCard, { title: "Documentos", className: "h-full", buttonActionLabel: "Novo documento", buttonAction: toggleAttachmentUploadModal, showButtonAction: !isDisabled && canCreate },
            React.createElement(Icon, { name: "spinner", size: 35, className: "text-gray-light dark:text-gray-400" })));
    }
    if (!canList) {
        return (React.createElement(SolicitationCard, { title: "Documentos", className: "h-full", buttonActionLabel: "Novo documento", buttonAction: toggleAttachmentUploadModal, showButtonAction: !isDisabled && canCreate },
            React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400 mt-2" }, "Voc\u00EA n\u00E3o tem permiss\u00E3o para ver esse conte\u00FAdo.")));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(SolicitationCard, { title: "Documentos", className: "h-full", buttonActionLabel: "Novo documento", buttonAction: toggleAttachmentUploadModal, showButtonAction: !isDisabled && canCreate }, attachments?.length ? (React.createElement(SolicitationAttachmentItemList, { attachments: attachments, onRemoveAttachment: onRemoveAttachment, isDisabled: isDisabled, canRemove: canDelete })) : (React.createElement("p", { className: "text-xs ext-gray-light dark:text-gray-400 mt-2" }, "Nenhum documento anexado."))),
        React.createElement(Modal, { show: showAttachmentUpload, handleClose: toggleAttachmentUploadModal, title: "Anexar novo documento", closable: true },
            React.createElement(SolicitationAttachmentDropzone, { onUploadedAttachments: onUploadedAttachments }))));
};
export default SolicitationAttachmentCard;
