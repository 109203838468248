import React, { createElement, cloneElement, isValidElement, useCallback } from 'react';
import clsx from 'clsx';
import { Icon, FormConsumer } from 'components';
import { Tooltip } from 'components/Tooltip';
import { getIn, useFormikContext } from 'formik';
import './TextField.scss';
const TextField = ({ variant = 'filled', rightIcon, rightIconTooltip, rightActionDisabled, rightAction, element = 'input', loading, error, preventAutoComplete, asText, ...props }) => {
    const { errors } = useFormikContext() || {};
    const hasError = error || !!(errors && getIn(errors, props.name));
    const value = props.value;
    let floating = false;
    if (typeof value === 'string') {
        floating = !!value && value.replaceAll(' ', '') !== '';
    }
    else if (typeof value === 'number') {
        floating = value !== null && value !== undefined;
    }
    if (preventAutoComplete) {
        const autoCompleteName = 'new-password';
        props.autoComplete = autoCompleteName;
        props.onFocus = event => {
            event.target.setAttribute('autocomplete', autoCompleteName);
        };
    }
    const handleRightAction = useCallback((disabled) => {
        if (typeof rightAction !== 'function')
            return null;
        if (disabled || rightActionDisabled)
            return null;
        rightAction();
    }, [rightAction, rightActionDisabled]);
    const RightIconComponent = useCallback((disabled) => (React.createElement("span", { className: clsx('text-field-right-icon', rightAction && 'text-field-right-action', (rightActionDisabled || disabled) && 'text-field-right-action-disabled'), onClick: () => handleRightAction(disabled) }, rightIcon && isValidElement(rightIcon) ? (cloneElement(rightIcon)) : (React.createElement(Icon, { name: rightIcon, size: 20 })))), [handleRightAction, rightAction, rightActionDisabled, rightIcon]);
    const LoadingComponent = useCallback(() => (React.createElement("span", { className: "text-field-right-icon" },
        React.createElement(Icon, { name: "spinner", size: 20, className: "loading" }))), []);
    const renderRightElement = useCallback((disabled) => {
        if (loading)
            return LoadingComponent();
        if (!rightIcon)
            return null;
        if (rightIconTooltip) {
            return (React.createElement(Tooltip, { id: `textField${props.name}`, text: rightIconTooltip }, RightIconComponent(disabled)));
        }
        return RightIconComponent(disabled);
    }, [loading, rightIcon, rightIconTooltip, LoadingComponent, RightIconComponent, props.name]);
    return (React.createElement(FormConsumer, null, ({ disabled }) => (React.createElement("div", { className: clsx('text-field-wrapper', floating && 'text-field-floating', hasError && 'text-field-error', (disabled || props.disabled) && 'text-field-disabled', element === 'textarea' && 'text-field-textarea', asText && 'text-field-as-text') },
        createElement(element, {
            className: clsx('text-field-control', variant && `text-field-variant-${variant}`),
            disabled: disabled || props.disabled,
            ...props
        }),
        renderRightElement(disabled),
        React.createElement("label", null, props.label)))));
};
export default TextField;
