import { useCallback } from 'react';
import { makeHttpService } from 'services';
export const useFileUpload = () => {
    const httpService = makeHttpService();
    const uploadFile = useCallback(async (fileData, fileSource) => {
        const formData = new FormData();
        formData.append('formFile', fileData);
        return httpService.post('/file', formData, {
            params: {
                fileSource
            }
        });
    }, [httpService]);
    const deleteFile = useCallback(async (fileUrl) => {
        return httpService.delete(`/file?filePath=${fileUrl}`);
    }, [httpService]);
    return [uploadFile, deleteFile];
};
