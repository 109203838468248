import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader, Card } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useDocumentTitle, useSolicitationMutation } from 'hooks';
import { SolicitationCreationForm } from '../components';
const SolicitationInternal = () => {
    const { success, errorCallback } = useAlert();
    const navigate = useNavigate();
    const { create } = useSolicitationMutation();
    async function submitSolicitation(solicitationData) {
        await create(solicitationData, {
            onSuccess: res => {
                success({
                    title: 'Requerimento cadastrado!',
                    message: 'Requerimento cadastrado com sucesso.',
                    id: 'SOLICITATION_POST_SUCCESS'
                });
                navigate(`/cam/solicitations/${res.Id}`);
            },
            onError: err => errorCallback(err, 'SOLICITATION_POST_FAILURE')
        });
    }
    const pageTitle = useMemo(() => 'Novo Requerimento', []);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Requerimentos',
            Url: '/cam/solicitations'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Novo Requerimento", breadcrumb: breadcrumb }),
        React.createElement(Card, { id: "solicitation-creation-card" },
            React.createElement(SolicitationCreationForm, { handleSubmit: submitSolicitation }))));
};
export default SolicitationInternal;
