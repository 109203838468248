import { useCallback } from 'react';
import { useAlert } from 'contexts/alert.context';
import { useQueryPagination } from 'hooks/queryPagination';
import { makeHttpService } from 'services/http';
import { BENEFIT_CONSUMPTION_PAGINATION_QUERY_KEY } from './useBenefitConsumptionConfig';
export const useBenefitConsumptionPagination = (filterParams) => {
    const { errorCallback } = useAlert();
    const prepareParameters = useCallback((pageSize, pageSkip, sort) => {
        return {
            PerPage: pageSize,
            Skip: pageSkip,
            OrderBy: sort?.sortBy || 'CreatedAt',
            OrderDescending: sort?.sortAsc ?? true,
            ...filterParams
        };
    }, [filterParams]);
    const getBenefitConsumptions = useCallback(async ({ pageSize, pageSkip, sort, signal }) => {
        const params = prepareParameters(pageSize, pageSkip, sort);
        return makeHttpService()
            .get('/benefit-consumption/by-parameters', {
            params,
            signal
        })
            .catch(error => {
            errorCallback(error, 'BENEFIT_CONSUMPTION_GET_BY_PARAMETERS_FAILURE');
            return {};
        });
    }, [errorCallback, prepareParameters]);
    return useQueryPagination([BENEFIT_CONSUMPTION_PAGINATION_QUERY_KEY, filterParams], getBenefitConsumptions);
};
