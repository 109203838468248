import React, { useCallback, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { TextField, Button, FormField, FormFieldError, Form, SituationField, Select, CurrencyField, useFormikValidationHandler, FormikValidationHandler } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useFormik } from 'formik';
import { useJobDescriptionList, useModulePermissions, usePlanList } from 'hooks';
import { FormatterUtils } from 'utils';
import { convertToDailyRateDto } from './DailyRateForm.converter';
import { DailyRateFormSchema } from './DailyRateForm.schema';
const defaultValues = {
    Id: 0,
    Name: '',
    JobDescriptionId: null,
    PlanId: null,
    AgeFrom: 0,
    AgeTo: 0,
    CreatedAt: '',
    UpdatedAt: '',
    Active: true
};
const DailyRateForm = ({ dailyRate, isLoading, handleSubmit: onSubmitDailyRate }) => {
    const { confirm } = useAlert();
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions(dailyRate?.Id);
    const [plans, isLoadingPlans] = usePlanList();
    const [jobDescriptions, isLoadingJobDescriptions] = useJobDescriptionList();
    const onSubmit = useCallback(async (dailyRateValues, { setSubmitting }) => {
        confirm({
            title: 'Atenção!',
            description: (React.createElement("p", null,
                "Essa a\u00E7\u00E3o poder\u00E1 recalcular todas as parcelas relacionadas a este registro. ",
                React.createElement("br", null),
                "Deseja continuar?")),
            confirmText: 'Sim, continuar',
            cancelText: 'Não, cancelar',
            onConfirm: async () => {
                setSubmitting(true);
                const dailyRateData = convertToDailyRateDto(dailyRateValues);
                await onSubmitDailyRate(dailyRateData);
                setSubmitting(false);
            },
            onCancel: () => setSubmitting(false)
        });
    }, [confirm, onSubmitDailyRate]);
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => DailyRateFormSchema,
        onSubmit,
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleChange, handleSubmit, isSubmitting, setValues } = formikContext;
    useEffect(() => {
        if (!dailyRate?.Id)
            return;
        const dailyRateData = {
            Id: dailyRate.Id,
            Name: dailyRate.Name,
            PlanId: dailyRate.PlanId,
            JobDescriptionId: dailyRate.JobDescriptionId,
            AgeFrom: dailyRate.AgeFrom,
            AgeTo: dailyRate.AgeTo,
            ContributionValue: dailyRate.ContributionValue,
            Active: dailyRate.Active ?? true,
            CreatedAt: FormatterUtils.dateTime(dailyRate.CreatedAt),
            UpdatedAt: FormatterUtils.dateTime(dailyRate.UpdatedAt)
        };
        setValues(dailyRateData);
    }, [dailyRate, setValues]);
    if (isLoading)
        return null;
    return (React.createElement(Form, { name: "dailyRateForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Name", label: "Nome", value: values.Name, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Name" })))),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "PlanId", label: "Escolha o plano", options: plans, value: plans?.find((plan) => plan.Id === values.PlanId), onChange: option => setFieldValue('PlanId', option.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingPlans }),
                    React.createElement(FormFieldError, { name: "PlanId" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "JobDescriptionId", label: "Escolha o cargo", options: jobDescriptions, value: jobDescriptions?.find((jobDescription) => jobDescription.Id === values.JobDescriptionId), onChange: option => setFieldValue('JobDescriptionId', option.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingJobDescriptions }),
                    React.createElement(FormFieldError, { name: "JobDescriptionId" })))),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(CurrencyField, { name: "ContributionValue", label: "Di\u00E1ria de alimenta\u00E7\u00E3o", value: values.ContributionValue, onChange: value => setFieldValue('ContributionValue', value) }),
                    React.createElement(FormFieldError, { name: "ContributionValue" })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Situa\u00E7\u00E3o da di\u00E1ria", handleChange: isActive => setFieldValue('Active', isActive), value: values.Active, falseLabel: "Inativa", trueLabel: "Ativa" })))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Enviar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default DailyRateForm;
