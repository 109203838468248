import moment from 'moment';
import { SolicitationStatus } from 'types/enum';
import { ArrayUtils } from '../extensions/array.utils';
const getSolicitationStateTextColor = (statusId) => {
    switch (statusId) {
        case SolicitationStatus.CANCELLED:
            return 'text-red-light';
        case SolicitationStatus.REPROVED:
            return 'text-red-light';
        case SolicitationStatus.FINISHED:
            return 'text-green-light';
        case SolicitationStatus.IN_PROGRESS:
            return 'text-blue-light';
        case SolicitationStatus.IN_REVISION:
            return 'text-blue-light';
        case SolicitationStatus.IN_APPROVAL:
            return 'text-orange-light';
        default:
            return 'text-gray-light dark:text-gray-50';
    }
};
const getLastState = (solicitationStates) => {
    return ArrayUtils.sortDesc(solicitationStates, 'CreatedAt')[0];
};
const getState = (solicitationStates, statusId) => {
    return solicitationStates.find(x => x.StatusId === statusId);
};
const getDuration = (solicitation) => {
    const startDate = moment(solicitation.StartDate);
    const endDate = moment(solicitation.EndDate);
    const durationInDays = solicitation.EndDate ? endDate.diff(startDate, 'days') : 0;
    return [startDate, endDate, durationInDays];
};
export const SolicitationUtils = {
    getSolicitationStateTextColor,
    getLastState,
    getState,
    getDuration
};
