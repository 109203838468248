import React, { memo, useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useAuth } from 'contexts/auth.context';
import { ModuleUtils } from 'utils';
import SidebarItem from './SidebarItem';
import SidebarSubItem from './SidebarSubItem';
import './Sidebar.scss';
const Sidebar = ({ sidebarOpened }) => {
    const [hoveredModule, setHoveredModule] = useState({});
    const [selectedModule, setSelectedModule] = useState({});
    const [selectedSubModules, setSelectedSubModules] = useState([]);
    const { modules } = useAuth();
    const { pathname } = useLocation();
    useEffect(() => {
        const cleanUrl = ModuleUtils.clearUrl(pathname);
        const selectedModule = modules.find(module => {
            if (module.Url === cleanUrl || (module.Children && module.Children.find(child => child.Url === cleanUrl)))
                return module;
        });
        selectedModule && setSelectedModule(selectedModule);
    }, [modules, pathname]);
    return (React.createElement("div", { className: clsx('sidebar-wrapper', sidebarOpened && 'opened'), onMouseLeave: () => {
            !!selectedSubModules.length && setSelectedSubModules([]);
            Object.keys(hoveredModule).length && setHoveredModule({});
        } },
        React.createElement("div", { className: "sidebar-menu-wrapper" },
            React.createElement(PerfectScrollbar, { className: "content-scroll", options: { wheelSpeed: 0.2 } },
                React.createElement("ul", { className: "sidebar-menu" }, modules.length
                    ? modules.map((module, index) => (React.createElement(SidebarItem, { key: index, module: module, active: selectedModule.Id === module.Id, onMouseEnter: () => {
                            if (module.Id !== hoveredModule.Id) {
                                if (module.Children && module.Children.length)
                                    setSelectedSubModules(module.Children);
                                else
                                    setSelectedSubModules([]);
                            }
                            setHoveredModule(module);
                        } })))
                    : null))),
        React.createElement("div", { className: clsx('sidebar-submenu-wrapper', !!selectedSubModules.length && 'opened') },
            React.createElement(PerfectScrollbar, { className: "content-scroll", options: { wheelSpeed: 0.2 } },
                React.createElement("ul", { className: "sidebar-submenu" }, selectedSubModules.length
                    ? selectedSubModules.map((child, index) => {
                        const submodule = child;
                        return React.createElement(SidebarSubItem, { key: index, submodule: submodule });
                    })
                    : null)))));
};
export default memo(Sidebar);
