import { Validation } from 'utils';
export const BeneficiaryProfessionalDataSchema = Validation.object().shape({
    PlanId: Validation.string().required('Informe o plano').nullable(),
    JobDescriptionId: Validation.string().required('Informe o cargo').nullable(),
    OperationBaseId: Validation.string().required('Informe a base de operação').nullable(),
    AircraftModelId: Validation.string().required('Informe o modelo da aeronave').nullable(),
    BeneficiaryTypeId: Validation.string().required('Informe o tipo').nullable(),
    // ANACRegistration: Validation.string()
    //   .required('Informe o registro da ANAC'),
    // CHTRegistration: Validation.string()
    //   .required('Informe o registro da CHT'),
    // CompanyStartDate: Validation.string()
    //   .required('Informe a data de início na empresa')
    //   .date('Informe uma data válida'),
    CMACertification: Validation.bool().nullable(),
    CMACertificateExpirationDate: Validation.string().when('CMACertification', {
        is: (value) => !!value,
        then: Validation.string().required('Informe a data de expiração').date('Informe uma data válida')
    }),
    AssociationStartDate: Validation.string()
        .required('Informe a data de início da associação')
        .date('Informe uma data válida'),
    HasGracePeriod: Validation.bool().nullable()
});
