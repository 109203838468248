import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { COMPENSATION_RATE_QUERY_KEY } from './useCompensationRateConfig';
export const useCompensationRateQuery = (compensationRateId) => {
    const { errorCallback } = useAlert();
    const navigate = useNavigate();
    const getCompensationRate = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/compensation-rate/${compensationRateId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'COMPENSATION_RATE_GET_FAILURE');
            navigate('/cam/compensation-rates');
            throw error;
        });
    }, [errorCallback, compensationRateId, navigate]);
    const { data, isLoading } = useQuery([COMPENSATION_RATE_QUERY_KEY, compensationRateId], getCompensationRate, {
        enabled: !!compensationRateId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
