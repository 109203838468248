import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ForgotPassword } from 'modules/ForgotPassword';
import { Login } from 'modules/Login';
import { CommonRoute } from 'routes/components';
const AuthRoutes = () => {
    return (React.createElement(Routes, null,
        React.createElement(Route, { path: "/login", element: React.createElement(Login, null) }),
        React.createElement(Route, { path: "/recover-password", element: React.createElement(ForgotPassword, null) }),
        React.createElement(Route, { path: "*", element: React.createElement(CommonRoute, null) })));
};
export { AuthRoutes };
