import React from 'react';
import { Button } from 'components';
import { useExportQuery, useModulePermissionsByScope } from 'hooks';
const ExportButtonItem = ({ title, url, scope, params }) => {
    const { canRead } = useModulePermissionsByScope(scope);
    const exportQuery = useExportQuery(url, params);
    return (React.createElement("a", { href: exportQuery, target: "_blank", rel: "noreferrer", className: "max-w-sm w-full" },
        React.createElement(Button, { text: title, icon: "download", color: "info", variant: "outlined", animationHover: "pop", className: "w-full", disabled: !canRead })));
};
export default ExportButtonItem;
