import { Validation } from 'utils';
export const UserSchema = Validation.object().shape({
    Name: Validation.string().required('Informe o nome'),
    Code: Validation.string().required('Informe o código'),
    Username: Validation.string().required('Informe o usuário'),
    UserTypeId: Validation.string().required('Informe o tipo').nullable()
});
export const UserChangePasswordSchema = Validation.object().shape({
    Password: Validation.string().when('NewPassword', {
        is: (value) => !!(value && value.length),
        then: Validation.string().required('Informe a senha atual')
    }),
    NewPassword: Validation.string().password(),
    ConfirmPassword: Validation.string().when('NewPassword', {
        is: (value) => !!(value && value.length),
        then: Validation.string()
            .required('Confirme a senha')
            .password()
            .oneOf([Validation.ref('NewPassword'), null], 'As senhas não são iguais')
    })
});
export const UserCreatePasswordSchema = Validation.object().shape({
    NewPassword: Validation.string().password().required('Informe a senha'),
    ConfirmPassword: Validation.string()
        .required('Confirme a senha')
        .password()
        .oneOf([Validation.ref('NewPassword'), null], 'As senhas não são iguais')
});
