import React from 'react';
import CountUp from 'react-countup';
import { Card } from 'components';
const DashboardCard = ({ title, subtitle, value }) => {
    return (React.createElement(Card, { className: "flex-1" },
        subtitle && React.createElement("p", { className: "text-gray dark:text-gray-400 text-sm font-light mb-1" }, subtitle),
        React.createElement("p", { className: "text-gray dark:text-gray-50 text-lg font-semibold" }, title),
        React.createElement("h2", { className: "text-5xl font-semibold text-blue-dark dark:text-blue-light mt-2" },
            React.createElement(CountUp, { end: value, separator: "." }))));
};
export default DashboardCard;
