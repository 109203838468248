import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Loading, PageHeader } from 'components';
import { useDocumentTitle, useSolicitationQuery } from 'hooks';
import { SolicitationDetailedReportContent } from './components';
const SolicitationDetailedReport = () => {
    const solicitationIdRoute = parseInt(useParams()?.solicitationId ?? '0');
    const [solicitation, isLoading] = useSolicitationQuery(solicitationIdRoute);
    const pageTitle = useMemo(() => `Relatório Detalhado do Requerimento #${solicitationIdRoute}`, [solicitationIdRoute]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Requerimentos',
            Url: '/cam/solicitations'
        },
        {
            Title: `#${solicitationIdRoute}`,
            Url: `/cam/solicitations/${solicitationIdRoute}`
        },
        {
            Title: 'Relatório Detalhado'
        }
    ], [solicitationIdRoute]);
    useDocumentTitle(pageTitle);
    if (isLoading || !solicitation?.Id) {
        return (React.createElement(React.Fragment, null,
            React.createElement(PageHeader, { title: pageTitle, breadcrumb: breadcrumb }),
            React.createElement(Loading, { show: true })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: pageTitle, breadcrumb: breadcrumb }),
        React.createElement(SolicitationDetailedReportContent, { solicitation: solicitation })));
};
export default SolicitationDetailedReport;
