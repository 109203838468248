import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
export const useFormikValidationHandler = () => {
    const [canValidate, setCanValidate] = useState(false);
    return [canValidate, setCanValidate];
};
const FormikValidationHandler = ({ setCanValidate }) => {
    const [submitted, setSubmitted] = useState(false);
    const formikContext = useFormikContext();
    useEffect(() => {
        if (!formikContext)
            return;
        if (!submitted && formikContext.submitCount > 0) {
            setSubmitted(true);
            setCanValidate(true);
        }
    }, [submitted, formikContext, setCanValidate]);
    return null;
};
export default FormikValidationHandler;
