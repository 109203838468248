import doc from 'assets/images/file-extensions/doc.svg';
import jpg from 'assets/images/file-extensions/jpg.svg';
import pdf from 'assets/images/file-extensions/pdf.svg';
import png from 'assets/images/file-extensions/png.svg';
export var FileExtension;
(function (FileExtension) {
    FileExtension["PDF"] = "pdf";
    FileExtension["DOC"] = "doc";
    FileExtension["PNG"] = "png";
    FileExtension["JPG"] = "jpg";
    FileExtension["JPEG"] = "jpeg";
})(FileExtension || (FileExtension = {}));
export const FileExtensionIcon = {
    [FileExtension.DOC]: doc,
    [FileExtension.JPG]: jpg,
    [FileExtension.JPEG]: jpg,
    [FileExtension.PDF]: pdf,
    [FileExtension.PNG]: png
};
