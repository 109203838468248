import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services';
import { REPORT_BENEFICIARY_FINANCIAL_HISTORY_QUERY_KEY } from './useReportConfig';
export const useReportBeneficiaryFinancialHistoryQuery = (shouldFetch, params) => {
    const { errorCallback } = useAlert();
    const getBeneficiaryFinancialHistory = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get('/report/beneficiary-financial-history', {
            params,
            signal
        })
            .catch(error => {
            errorCallback(error, 'REPORT_BENEFICIARY_FINANCIAL_HISTORY_FAILURE');
            return [];
        });
    }, [errorCallback, params]);
    const { data, isFetched } = useQuery([REPORT_BENEFICIARY_FINANCIAL_HISTORY_QUERY_KEY, params], getBeneficiaryFinancialHistory, {
        enabled: shouldFetch
    });
    const memoizedData = useMemo(() => data ?? [], [data]);
    return [memoizedData, isFetched];
};
