import React from 'react';
import { View as PdfView } from '@react-pdf/renderer';
const SPACING = 8;
const GRID_TOTAL = 12;
export const Grid = ({ marginTop, marginBottom, style, flexDirection = 'row', flexWrap = 'wrap', alignItems, justifyContent, children }) => (React.createElement(PdfView, { style: {
        marginTop,
        marginBottom,
        marginHorizontal: -SPACING,
        flexDirection,
        flexWrap,
        alignItems,
        justifyContent,
        ...(style ?? {})
    } }, children));
export const GridItem = ({ marginTop, marginBottom, marginRight, marginLeft, style, size = GRID_TOTAL, children }) => {
    const columnSize = (100 * size) / GRID_TOTAL;
    return (React.createElement(PdfView, { style: {
            marginTop,
            marginBottom,
            marginRight,
            marginLeft,
            padding: SPACING,
            flex: `0 0 ${columnSize}%`,
            ...(style ?? {})
        } }, children));
};
