import React from 'react';
import { BenefitInternal, BenefitList } from './screens';
const BenefitModule = [
    {
        path: '/benefits',
        element: React.createElement(BenefitList, null)
    },
    {
        path: '/benefits/new',
        element: React.createElement(BenefitInternal, null)
    },
    {
        path: '/benefits/:benefitId',
        element: React.createElement(BenefitInternal, null)
    }
];
export { BenefitModule };
