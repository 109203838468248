import React, { useCallback } from 'react';
import NumberFormat from 'react-number-format';
import { FormConsumer } from 'components';
import { TextField } from 'components/TextField';
const PercentageField = props => {
    const { onChange, ...restProps } = props;
    const onValueChange = useCallback((values) => {
        if (!onChange)
            return;
        onChange(values.floatValue);
    }, [onChange]);
    return (React.createElement(FormConsumer, null, ({ disabled }) => (React.createElement(NumberFormat, { type: "text", displayType: "input", decimalScale: 2, fixedDecimalScale: true, allowNegative: false, suffix: '%', disabled: disabled, customInput: TextField, onValueChange: onValueChange, ...restProps }))));
};
export default PercentageField;
