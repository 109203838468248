const objectPropertyLike = (source, searchValue, ...keys) => {
    const valuesToSearch = keys.map(x => {
        return source[x];
    });
    return valuesToSearch.some(value => {
        return new RegExp(`.*${searchValue.toLowerCase()}.*`).test(value.toLowerCase());
    });
};
export const QueryUtils = {
    objectPropertyLike
};
