import { AuthenticationConstants } from 'configs';
export class AuthenticationService {
    storageService;
    httpService;
    token;
    refreshToken;
    tokenExpiration;
    constructor(storageService, httpService) {
        this.storageService = storageService;
        this.httpService = httpService;
        this.token = '';
        this.refreshToken = '';
        this.tokenExpiration = null;
        return this;
    }
    async load() {
        await this.loadAuthenticationData();
        if (this.isTokenExpired() && this.refreshToken)
            await this.handleRefreshToken(this.refreshToken);
    }
    async login(authenticationData) {
        const params = new URLSearchParams();
        Object.keys(authenticationData).forEach(key => {
            const value = authenticationData[key];
            if (!value) {
                return;
            }
            params.append(key, value.toString());
        });
        return this.httpService.post('authentication/login', params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
    getToken() {
        return this.token;
    }
    isTokenExpired() {
        return !this.tokenExpiration || this.tokenExpiration.getTime() <= Date.now();
    }
    async setToken({ token, refreshToken, tokenExpiration }) {
        this.token = token;
        this.refreshToken = refreshToken;
        this.tokenExpiration = tokenExpiration;
        await this.storageService.set(AuthenticationConstants.ACCESS_TOKEN, token);
        await this.storageService.set(AuthenticationConstants.REFRESH_TOKEN, refreshToken);
        await this.storageService.set(AuthenticationConstants.ACCESS_TOKEN_EXPIRATION, tokenExpiration.toISOString());
    }
    async logout() {
        this.token = '';
        this.refreshToken = '';
        this.tokenExpiration = null;
        await this.storageService.delete(AuthenticationConstants.ACCESS_TOKEN);
        await this.storageService.delete(AuthenticationConstants.ACCESS_TOKEN_EXPIRATION);
        await this.storageService.delete(AuthenticationConstants.REFRESH_TOKEN);
    }
    async handleRefreshToken(refreshToken) {
        const params = { refreshToken };
        await this.httpService
            .get('authentication/refresh-token', {
            params,
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
            .then(async ({ Token: token, RefreshToken: refreshToken, ExpiresAt: expiresAt }) => {
            await this.setToken({
                token,
                refreshToken,
                tokenExpiration: new Date(expiresAt)
            });
        });
    }
    async loadAuthenticationData() {
        const token = await this.storageService.get(AuthenticationConstants.ACCESS_TOKEN);
        const refreshToken = await this.storageService.get(AuthenticationConstants.REFRESH_TOKEN);
        const tokenExpiration = await this.storageService.get(AuthenticationConstants.ACCESS_TOKEN_EXPIRATION);
        this.token = token || '';
        this.refreshToken = refreshToken || '';
        this.tokenExpiration = tokenExpiration ? new Date(tokenExpiration) : null;
    }
}
