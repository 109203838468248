import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import pdfIcon from 'assets/images/file-extensions/pdf.svg';
import { Button } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useFileUpload } from 'hooks';
import { FileSource } from 'types/enum';
import { SolicitationAttachmentItemForm } from '../AttachmentItemForm';
import './SolicitationAttachmentDropzone.scss';
const SolicitationAttachmentDropzone = ({ onUploadedAttachments }) => {
    const [files, setFiles] = useState([]);
    const { error } = useAlert();
    const [isUploading, setIsUploading] = useState(false);
    const [upload] = useFileUpload();
    const onSelectFileType = useCallback((file, fileType) => {
        setFiles(files => files.map(x => {
            if (x.name === file.name)
                return Object.assign(x, { fileType });
            return x;
        }));
    }, []);
    const getFileThumbnail = useCallback((file) => {
        switch (file.type) {
            case 'application/pdf':
                return pdfIcon;
            default:
                return URL.createObjectURL(file);
        }
    }, []);
    const onFileAccept = useCallback((newFiles) => {
        setFiles(files => [
            ...files,
            ...newFiles.map(file => Object.assign(file, {
                preview: getFileThumbnail(file)
            }))
        ]);
    }, [getFileThumbnail]);
    const onFileReject = useCallback((files) => {
        if (!files.length)
            return;
        error({
            title: 'Arquivo inválido',
            message: 'Apenas os formatos PNG, JPG e PDF são aceitos.',
            id: 'SOLICITATION_ATTACHMENT_ERROR'
        });
    }, [error]);
    const uploadFile = useCallback(async (file) => {
        const fileUrl = await upload(file, FileSource.SOLICITATION).catch(() => {
            error({
                title: 'Erro ao salvar arquivo',
                message: `Não foi possível salvar o arquivo ${file.name}.`,
                id: `SOLICITATION_ATTACHMENT_${file.name.toUpperCase()}_ERROR`
            });
            return null;
        });
        return {
            FileName: file.name,
            FileUrl: fileUrl,
            FileType: file.fileType
        };
    }, [upload, error]);
    const onSubmitFiles = useCallback(async () => {
        setIsUploading(true);
        await Promise.all(files.map(uploadFile)).then(res => onUploadedAttachments(res.filter(x => x)));
    }, [files, uploadFile, onUploadedAttachments]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'application/pdf': ['.pdf']
        },
        noDragEventsBubbling: true,
        disabled: isUploading,
        onDropAccepted: onFileAccept,
        onDropRejected: onFileReject,
        useFsAccessApi: false
    });
    const canSubmitFiles = useMemo(() => !!files.length && files.every(x => !!x.fileType), [files]);
    useEffect(() => {
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);
    return (React.createElement("div", { className: "solicitation-attachment-dropzone" },
        React.createElement("div", { className: "solicitation-attachment-dropzone-wrapper" },
            React.createElement("input", { ...getInputProps() }),
            React.createElement("div", { ...getRootProps({ className: 'solicitation-attachment-dropzone-content' }) },
                React.createElement(Button, { size: "large", text: "Selecionar arquivo", color: "info" }),
                React.createElement("small", null, "ou arraste e solte os arquivos aqui."))),
        !!files.length && (React.createElement("div", { className: "solicitation-attachment-dropzone-preview-content" },
            React.createElement("h3", null, "Arquivos selecionados:"),
            React.createElement("div", { className: "solicitation-attachment-item-list" }, files.map(file => (React.createElement(SolicitationAttachmentItemForm, { key: file.name, file: file, onSelectFileType: onSelectFileType })))),
            React.createElement(Button, { size: "large", text: "Enviar", color: "success", disabled: !canSubmitFiles, loading: isUploading, onClick: onSubmitFiles })))));
};
export default SolicitationAttachmentDropzone;
