import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { DASHBOARD_RESUME_QUERY_KEY } from './useDashboardConfig';
export const useDashboardQuery = () => {
    const { errorCallback } = useAlert();
    const getDashboardResume = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get('/dashboard/resume', {
            signal
        })
            .catch(error => {
            errorCallback(error, 'DASHBOARD_RESUME_GET_FAILURE');
            throw error;
        });
    }, [errorCallback]);
    const { data, isLoading } = useQuery([DASHBOARD_RESUME_QUERY_KEY], getDashboardResume, {
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
