import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip } from 'components';
import { useModulePermissions, useTableFilter, useDocumentTitle, useMonthlyBasePercentagePagination } from 'hooks';
import moment from 'moment-timezone';
import { FormatterUtils } from 'utils';
import { MonthlyBasePercentageTableFilterForm } from '../components';
const MonthlyBasePercentageList = () => {
    const { canCreate, canRead, canUpdate } = useModulePermissions();
    const customParamsHandle = useCallback((filterParams) => {
        const params = {};
        const monthFieldValue = filterParams.MonthYear;
        if (monthFieldValue) {
            const monthYearValue = new Date(monthFieldValue.year, monthFieldValue.month - 1, 1);
            monthYearValue.setHours(0, 0, 0);
            params.SearchField = 'MonthYear';
            params.SearchQuery = monthYearValue.toLocaleDateString('en-CA');
        }
        if (filterParams.JobDescriptionId) {
            params.SearchField = 'JobDescriptionId';
            params.SearchQuery = filterParams.JobDescriptionId;
        }
        return params;
    }, []);
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter({
        customParamsHandle
    });
    const { data, isLoading, ...pagination } = useMonthlyBasePercentagePagination(requestFilterParams);
    const columns = useMemo(() => [
        {
            Header: 'Mês/Ano',
            accessor: 'MonthYear',
            Cell: ({ cell: { value } }) => moment(value).format('MM/YYYY')
        },
        {
            Header: 'Cargo',
            accessor: 'JobDescription',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: "font-semibold text-gray-light dark:text-gray-50" }, value?.Name ?? ''))
        },
        {
            Header: 'Porcentagem',
            accessor: 'Percentage',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => FormatterUtils.percentage(value)
        },
        {
            Header: 'Situação',
            accessor: 'Active',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold', value ? 'text-green-light' : 'text-red-light') }, value ? 'ATIVA' : 'INATIVA'))
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" }, (canRead || canUpdate) && (React.createElement(Link, { to: `/operational/monthly-percentages/${value}` },
                    React.createElement(Tooltip, { id: `monthly-percentage-${value}-edit`, text: canUpdate ? 'Editar' : 'Visualizar' },
                        React.createElement(Button, { icon: canUpdate ? 'edit' : 'eye', color: "info", rounded: true }))))));
            }
        }
    ], [canRead, canUpdate]);
    useDocumentTitle('Porcentagens Mensais');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Porcentagens Mensais" }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            canCreate && (React.createElement(Link, { to: "/operational/monthly-percentages/new" },
                React.createElement(Button, { text: "Nova Porcentagem", icon: "add", color: "info", animationHover: "pop" }))),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Porcentagens Mensais", formConfig: { filterParams }, handleFilter: setParams, customFilters: MonthlyBasePercentageTableFilterForm }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhuma porcentagem a ser exibida" })));
};
export default MonthlyBasePercentageList;
