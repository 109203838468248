import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip, Icon } from 'components';
import { useModulePermissions, useTableFilter, useDocumentTitle, useBenefitConsumptionMonthlyBalancePagination, useExportQuery } from 'hooks';
import moment from 'moment-timezone';
import { FormatterUtils } from 'utils';
import { BenefitConsumptionMonthlyBalanceDetailsModal, BenefitConsumptionMonthlyBalanceExportContent, BenefitConsumptionMonthlyBalanceTableFilterForm } from './components';
const BenefitConsumptionMonthlyBalanceReport = () => {
    const { canRead } = useModulePermissions();
    const customParamsHandle = useCallback((filterParams) => {
        const params = {};
        if (filterParams.BenefitId) {
            params.BenefitId = filterParams.BenefitId;
        }
        if (filterParams.PersonDocument) {
            params.PersonDocument = filterParams.PersonDocument;
        }
        if (filterParams.PersonCallName) {
            params.SearchField = 'CallName';
            params.SearchQuery = filterParams.PersonCallName;
        }
        return params;
    }, []);
    const startDate = moment().utc().startOf('month');
    const endDate = moment().utc().endOf('month');
    const initialParams = {
        DateRange: [startDate, endDate]
    };
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter({
        initialParams,
        customParamsHandle
    });
    const { data, isLoading, ...pagination } = useBenefitConsumptionMonthlyBalancePagination(requestFilterParams);
    const exportExcelQuery = useExportQuery('benefit-consumption-monthly-balance/by-parameters/download', requestFilterParams);
    const [selectedBenefitConsumptionMonthlyBalance, setSelectedBenefitConsumptionMonthlyBalance] = useState(null);
    const onSelectBenefitConsumptionMonthlyBalance = useCallback((selectedBenefitConsumptionMonthlyBalance) => setSelectedBenefitConsumptionMonthlyBalance(selectedBenefitConsumptionMonthlyBalance), []);
    const onRemoveSelectedBenefitConsumptionMonthlyBalance = useCallback(() => setSelectedBenefitConsumptionMonthlyBalance(null), []);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Relatórios'
        },
        {
            Title: 'Relatório de Balanço Mensal'
        }
    ], []);
    const columns = useMemo(() => [
        {
            Header: 'Mês/Ano',
            accessor: 'MonthYearReference',
            Cell: ({ cell: { value } }) => FormatterUtils.date(value, 'MMM. [de] yyyy')
        },
        {
            Header: 'Associado',
            accessor: 'Beneficiary',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement(Link, { to: `/beneficiaries/${value?.Id}`, target: "_blank" },
                    React.createElement("span", { className: "flex items-center font-semibold text-gray-light dark:text-gray-50" },
                        value.Name,
                        React.createElement(Icon, { name: "new-tab", size: 14, className: "text-blue-light ml-1" }))));
            }
        },
        {
            Header: 'Valor Processo CAM',
            accessor: 'CAMIncomeValue',
            Cell: ({ cell: { value } }) => FormatterUtils.money(value)
        },
        {
            Header: 'Valor Consumo',
            accessor: 'ConsumedBenefitsTotalValue',
            Cell: ({ cell: { value } }) => FormatterUtils.money(value)
        },
        {
            Header: 'Saldo',
            accessor: 'Balance',
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold', value > 0 && 'text-green-light', value < 0 && 'text-red-light') }, FormatterUtils.money(value)))
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value }, row: { original: values } }) => {
                return (React.createElement("div", { className: "flex items-center" },
                    canRead && (React.createElement(Tooltip, { id: `benefit-consumption-${value}-details`, text: "Ver detalhes dos consumos" },
                        React.createElement(Button, { icon: "eye", color: "info", rounded: true, onClick: () => onSelectBenefitConsumptionMonthlyBalance(values) }))),
                    canRead && React.createElement(BenefitConsumptionMonthlyBalanceExportContent, { benefitConsumptionMonthlyBalance: values })));
            }
        }
    ], [canRead, onSelectBenefitConsumptionMonthlyBalance]);
    useDocumentTitle('Relatório de Balanço Mensal');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Relat\u00F3rio de Balan\u00E7o Mensal", breadcrumb: breadcrumb }),
        React.createElement("div", { className: clsx('flex mb-4 justify-between') },
            React.createElement("a", { href: exportExcelQuery, target: "_blank", rel: "noreferrer" },
                React.createElement(Button, { text: "Exportar", icon: "download", color: "info", animationHover: "pop" })),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Relat\u00F3rio", formConfig: { filterParams }, handleFilter: setParams, customFilters: BenefitConsumptionMonthlyBalanceTableFilterForm }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhum consumo a ser exibido" }),
        React.createElement(BenefitConsumptionMonthlyBalanceDetailsModal, { selectedMonthlyBalance: selectedBenefitConsumptionMonthlyBalance, onCloseModal: onRemoveSelectedBenefitConsumptionMonthlyBalance })));
};
export default BenefitConsumptionMonthlyBalanceReport;
