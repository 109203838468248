import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { Icon, FormConsumer } from 'components';
import './Button.scss';
const Button = ({ text, icon, iconSize, color, disabled, loading, variant = 'filled', size = 'medium', rounded, animationHover, className, type = 'button', ...props }, ref) => {
    const NAMESPACE = 'button';
    return (React.createElement(FormConsumer, null, ({ disabled: isFormDisabled }) => (React.createElement("button", { ref: ref, className: clsx(NAMESPACE, !!color && NAMESPACE + '-' + color, !!variant && NAMESPACE + '-' + variant, !!size && NAMESPACE + '-' + size, !!rounded && NAMESPACE + '-rounded', !!(disabled || isFormDisabled || loading) && NAMESPACE + '-disabled', !!loading && NAMESPACE + '-loading', !!animationHover && NAMESPACE + '-animation-' + animationHover, className && className), disabled: disabled || isFormDisabled || loading, type: type, ...props },
        !!icon && React.createElement(Icon, { name: icon, size: iconSize || 15, style: { marginRight: text && 5 } }),
        !!text && React.createElement("span", null, text),
        loading && React.createElement(Icon, { name: "spinner", size: 25, className: "loading" })))));
};
export default forwardRef(Button);
