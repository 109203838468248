import React, { useMemo } from 'react';
import { Loading } from 'components';
import { useDocumentTitle, useBeneficiaryQuery } from 'hooks';
import { BeneficiaryHistoryItem } from './HistoryItem';
const BeneficiaryHistoryTab = ({ beneficiaryId }) => {
    const [beneficiary, isLoading] = useBeneficiaryQuery(beneficiaryId);
    const pageTitle = useMemo(() => 'Histórico do Associado', []);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(Loading, { show: isLoading }),
        beneficiary?.BeneficiaryModificationHistory?.length ? (beneficiary.BeneficiaryModificationHistory.map(x => (React.createElement("ol", { key: x.Id.toString(), className: "relative border-l border-gray-200 dark:border-gray-700" },
            React.createElement(BeneficiaryHistoryItem, { beneficiaryModification: x }))))) : (React.createElement("p", { className: "text-gray-light dark:text-gray-400 text-sm mt-2" }, "Nenhuma altera\u00E7\u00E3o encontrada."))));
};
export default BeneficiaryHistoryTab;
