import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col } from 'react-grid-system';
import { Button, FormField, FormFieldError, Form, useFormikValidationHandler, FormikValidationHandler, Select, FormFieldSeparator, Table, CurrencyField, MonthField, TextField } from 'components';
import { useFormik } from 'formik';
import { useBenefitList, useModulePermissions } from 'hooks';
import { benefitConsumptionTypeOptions } from 'types/enum';
import { convertToBenefitConsumptionBatchDto } from './BenefitConsumptionBatchForm.converter';
import { BenefitConsumptionBatchFormSchema } from './BenefitConsumptionBatchForm.schema';
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;
const defaultValues = {
    BenefitId: null,
    BatchLines: [],
    MonthYearReference: {
        month: currentMonth,
        year: currentYear
    }
};
const BenefitConsumptionBatchForm = ({ batchLines, handleSubmit: handleSubmitBenefitConsumptionBatch }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions();
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => BenefitConsumptionBatchFormSchema,
        onSubmit: async (benefitConsumptionBatchValues) => handleSubmitBenefitConsumptionBatch(convertToBenefitConsumptionBatchDto(benefitConsumptionBatchValues)),
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, errors, setFieldValue, handleSubmit, isSubmitting, setValues } = formikContext;
    const [benefits, isLoadingBenefits] = useBenefitList();
    const onInitForm = useCallback(() => {
        if (!batchLines?.length)
            return;
        const benefitConsumptionBatchData = {
            ...defaultValues,
            BatchLines: batchLines
        };
        setValues(benefitConsumptionBatchData);
    }, [batchLines, setValues]);
    const columns = useMemo(() => [
        {
            Header: 'Matrícula',
            accessor: 'BeneficiaryRegistration',
            Cell: ({ cell: { value }, row: { index } }) => {
                return (React.createElement(React.Fragment, null,
                    React.createElement(TextField, { name: `BatchLines[${index}].BeneficiaryRegistration`, label: "Matr\u00EDcula", value: value, onChange: value => {
                            setFieldValue(`BatchLines[${index}].BeneficiaryRegistration`, value);
                        } }),
                    React.createElement(FormFieldError, { name: `BatchLines[${index}].BeneficiaryRegistration` })));
            }
        },
        {
            Header: 'Nome',
            accessor: 'BeneficiaryName',
            Cell: ({ cell: { value }, row: { index } }) => {
                return (React.createElement(React.Fragment, null,
                    React.createElement(TextField, { name: `BatchLines[${index}].BeneficiaryName`, label: "Nome", value: value, onChange: value => {
                            setFieldValue(`BatchLines[${index}].BeneficiaryName`, value);
                        } }),
                    React.createElement(FormFieldError, { name: `BatchLines[${index}].BeneficiaryName` })));
            }
        },
        {
            Header: 'Valor',
            accessor: 'Value',
            Cell: ({ cell: { value }, row: { index } }) => {
                return (React.createElement(React.Fragment, null,
                    React.createElement(CurrencyField, { name: `BatchLines[${index}].Value`, label: "Valor", onChange: value => {
                            setFieldValue(`BatchLines[${index}].Value`, value);
                        }, value: value }),
                    React.createElement(FormFieldError, { name: `BatchLines[${index}].Value` })));
            }
        },
        {
            Header: 'Tipo',
            accessor: 'Type',
            Cell: ({ cell: { value }, row: { index } }) => {
                return (React.createElement(React.Fragment, null,
                    React.createElement(Select, { name: "StatusId", label: "Tipo", options: benefitConsumptionTypeOptions, value: benefitConsumptionTypeOptions.find(option => option.value === value), onChange: value => setFieldValue(`BatchLines[${index}].Type`, value.value), getOptionLabel: option => option.label, getOptionValue: option => option.value }),
                    React.createElement(FormFieldError, { name: `BatchLines[${index}].Type` })));
            }
        }
    ], [setFieldValue]);
    useEffect(onInitForm, [onInitForm]);
    return (React.createElement(Form, { name: "benefitConsumptionBatchForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "BenefitId", label: "Benef\u00EDcio", options: benefits, value: benefits?.find(beneficiary => beneficiary.Id === values.BenefitId), onChange: value => setFieldValue('BenefitId', value.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingBenefits }),
                    React.createElement(FormFieldError, { name: "BenefitId" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(MonthField, { name: "MonthYearReference", label: "M\u00EAs", value: values.MonthYearReference, onChange: value => setFieldValue('MonthYearReference', value) }),
                    React.createElement(FormFieldError, { name: "MonthYearReference" })))),
        React.createElement(FormFieldSeparator, { text: "Consumos", className: "mt-3", icon: "benefit" }),
        React.createElement(Table, { data: values.BatchLines, columns: columns, noResultsMessage: "Nenhuma revis\u00E3o a ser exibida", cardClassName: "mt-4", disableSortBy: true }),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Enviar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default BenefitConsumptionBatchForm;
