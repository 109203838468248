import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col } from 'react-grid-system';
import { Button, FormField, FormFieldError, Form, useFormikValidationHandler, FormikValidationHandler, BeneficiarySelect, Select, MaskedTextField } from 'components';
import { useFormik } from 'formik';
import { useAwayReasonList, useModulePermissions } from 'hooks';
import moment from 'moment';
import { DateFormat } from 'types/enum';
import { FormatterUtils } from 'utils';
import { convertToAwayWithoutSolicitationDto } from './AwayWithoutSolicitationForm.converter';
import { AwayWithoutSolicitationFormSchema } from './AwayWithoutSolicitationForm.schema';
const defaultValues = {
    Id: 0,
    Beneficiary: null,
    AwayStartDate: '',
    AwayEndDate: '',
    AwayReasonId: null
};
const AwayWithoutSolicitationForm = ({ awayWithoutSolicitation, isLoading, handleSubmit: onSubmitForm }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions(awayWithoutSolicitation?.Id);
    const [awayReasons, isLoadingAwayReasons] = useAwayReasonList();
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => AwayWithoutSolicitationFormSchema,
        onSubmit: async (values) => onSubmitForm(convertToAwayWithoutSolicitationDto(values)),
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleSubmit, isSubmitting, setValues } = formikContext;
    useEffect(() => {
        if (!awayWithoutSolicitation?.Id)
            return;
        const awayWithoutSolicitationData = {
            Id: awayWithoutSolicitation.Id,
            Beneficiary: awayWithoutSolicitation.Beneficiary,
            AwayStartDate: FormatterUtils.date(awayWithoutSolicitation.AwayStartDate, DateFormat.DATE_PT_BR),
            AwayEndDate: awayWithoutSolicitation.AwayEndDate
                ? FormatterUtils.date(awayWithoutSolicitation.AwayEndDate, DateFormat.DATE_PT_BR)
                : '',
            AwayReasonId: awayWithoutSolicitation.AwayReasonId
        };
        setValues(awayWithoutSolicitationData);
    }, [awayWithoutSolicitation, setValues]);
    const onSelectBeneficiary = useCallback((value) => setFieldValue('Beneficiary', value), [
        setFieldValue
    ]);
    const isEnded = useMemo(() => {
        if (!awayWithoutSolicitation?.Id)
            return false;
        if (!awayWithoutSolicitation?.AwayEndDate)
            return false;
        const now = moment();
        const endDate = moment(awayWithoutSolicitation.AwayEndDate);
        return now.isAfter(endDate);
    }, [awayWithoutSolicitation]);
    const isCreating = !awayWithoutSolicitation?.Id;
    const hasBeneficiarySelected = !!values.Beneficiary?.Id;
    if (isLoading)
        return null;
    return (React.createElement(Form, { name: "awayWithoutSolicitationForm", onSubmit: handleSubmit, disabled: !canMutate || isEnded, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, null,
            isCreating && (React.createElement(React.Fragment, null,
                React.createElement(Col, { sm: 12 },
                    React.createElement(FormField, null,
                        React.createElement(BeneficiarySelect, { value: values.Beneficiary, onChange: onSelectBeneficiary }),
                        React.createElement(FormFieldError, { name: "Beneficiary" }))),
                hasBeneficiarySelected && (React.createElement(Col, { sm: 12 },
                    React.createElement("div", { className: "bg-slate-100 dark:bg-slate-dark p-3 mb-3" },
                        React.createElement("p", { className: "text-gray-light dark:text-gray-400 text-xs mb-2" }, "Confirme os dados do associado selecionado:"),
                        React.createElement("p", { className: "text-gray dark:text-gray-50 text-sm font-semibold" }, values.Beneficiary.Name),
                        React.createElement("p", { className: "text-gray dark:text-gray-50 text-sm" }, values.Beneficiary.JobDescription?.Name),
                        React.createElement("p", { className: "text-gray dark:text-gray-50 text-sm" }, values.Beneficiary.Plan?.Name),
                        React.createElement("p", { className: "text-gray dark:text-gray-50 text-sm" }, FormatterUtils.cpf(values.Beneficiary.CPF)),
                        React.createElement("p", { className: "text-gray dark:text-gray-50 text-sm" }, FormatterUtils.phone(values.Beneficiary.CellPhone))))))),
            !isCreating && hasBeneficiarySelected && (React.createElement(Col, { sm: 12 },
                React.createElement("div", { className: "bg-slate-100 dark:bg-slate-dark p-3 mb-3" },
                    React.createElement("p", { className: "text-gray-light dark:text-gray-400 text-xs mb-2" }, "Dados do associado:"),
                    React.createElement("p", { className: "text-gray dark:text-gray-50 text-sm font-semibold" }, values.Beneficiary.Name),
                    React.createElement("p", { className: "text-gray dark:text-gray-50 text-sm" }, values.Beneficiary.JobDescription?.Name),
                    React.createElement("p", { className: "text-gray dark:text-gray-50 text-sm" }, values.Beneficiary.Plan?.Name),
                    React.createElement("p", { className: "text-gray dark:text-gray-50 text-sm" }, FormatterUtils.cpf(values.Beneficiary.CPF)),
                    React.createElement("p", { className: "text-gray dark:text-gray-50 text-sm" }, FormatterUtils.phone(values.Beneficiary.CellPhone))))),
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "AwayReasonId", label: "Motivo de Afastamento", options: awayReasons, value: awayReasons?.find(x => x.Id === values.AwayReasonId), onChange: value => setFieldValue('AwayReasonId', value.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingAwayReasons }),
                    React.createElement(FormFieldError, { name: "AwayReasonId" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "date", name: "AwayStartDate", label: "Data de in\u00EDcio", value: values.AwayStartDate, onChange: value => setFieldValue('AwayStartDate', value) }),
                    React.createElement(FormFieldError, { name: "AwayStartDate" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "date", name: "AwayEndDate", label: "Data de fim", value: values.AwayEndDate, onChange: value => setFieldValue('AwayEndDate', value) }),
                    React.createElement(FormFieldError, { name: "AwayEndDate" })))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Enviar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default AwayWithoutSolicitationForm;
