import React, { useMemo } from 'react';
import clsx from 'clsx';
import './SolicitationDetailsCardItem.scss';
const SolicitationDetailsCardItem = ({ label, value, link, boolValue, boolColors = ['text-green', 'text-red'], multiValue, valueClassName }) => {
    const renderValueLabel = useMemo(() => {
        if (boolValue)
            return value ? 'SIM' : 'NÃO';
        if (link)
            return (React.createElement("a", { href: link, target: "_blank", rel: "noreferrer" }, value));
        return value;
    }, [boolValue, link, value]);
    const [truthyColor, falsyColor] = boolColors;
    return (React.createElement("div", { className: clsx('solicitation-details-card-item flex justify-between items-center') },
        React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400" }, label),
        multiValue ? (React.createElement("div", { className: "multi-value" }, value.map((x, i) => (React.createElement("p", { key: i, className: clsx('text-multi-value', valueClassName && valueClassName) }, x))))) : (React.createElement("p", { className: clsx('text-sm font-semibold', !boolValue && !link && !valueClassName && 'text-gray dark:text-gray-50', boolValue && (!value ? falsyColor : truthyColor), link && 'text-blue-light', valueClassName && valueClassName) }, renderValueLabel))));
};
export default SolicitationDetailsCardItem;
