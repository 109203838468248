import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useModulePermissions, useTableFilter, useDocumentTitle, useAircraftModelPagination, useAircraftModelMutation } from 'hooks';
const AircraftModelList = () => {
    const { canCreate, canRead, canUpdate, canDelete } = useModulePermissions();
    const { success, confirm, errorCallback } = useAlert();
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter();
    const { data, isLoading, ...pagination } = useAircraftModelPagination(requestFilterParams);
    const { remove } = useAircraftModelMutation();
    const deleteAircraftModel = useCallback(async (aircraftModelId) => {
        await remove(aircraftModelId)
            .then(() => success({
            title: 'Modelo deletado!',
            message: 'Modelo deletado com sucesso.',
            id: 'AIRCRAFT_MODEL_DELETE_SUCCESS'
        }))
            .catch(err => errorCallback(err, 'AIRCRAFT_MODEL_DELETE_FAILURE'));
    }, [remove, success, errorCallback]);
    const onDeleteAircraftModel = useCallback((aircraftModelId) => {
        confirm({
            title: 'Você tem certeza?',
            description: (React.createElement("p", null,
                "O modelo ser\u00E1 deletado. ",
                React.createElement("br", null),
                " Essa a\u00E7\u00E3o \u00E9 irrevers\u00EDvel!")),
            confirmText: 'Sim, deletar',
            cancelText: 'Não, cancelar',
            onConfirm: async () => deleteAircraftModel(aircraftModelId)
        });
    }, [confirm, deleteAircraftModel]);
    const searchFields = useMemo(() => [
        {
            Value: 'Prefix',
            Description: 'Prefixo'
        },
        {
            Value: 'Aircraft',
            Description: 'Aeronave'
        },
        {
            Value: 'SerialNumber',
            Description: 'Número de série'
        }
    ], []);
    const columns = useMemo(() => [
        {
            Header: 'Modelo',
            accessor: 'Model'
        },
        {
            Header: 'Aeronave',
            accessor: 'Aircraft'
        },
        {
            Header: 'Prefixo',
            accessor: 'Prefix'
        },
        {
            Header: 'Situação',
            accessor: 'Active',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold', value ? 'text-green-light' : 'text-red-light') }, value ? 'ATIVO' : 'INATIVO'))
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" },
                    (canRead || canUpdate) && (React.createElement(Link, { to: `/operational/aircraft-models/${value}` },
                        React.createElement(Tooltip, { id: `aircraft-models-${value}-edit`, text: canUpdate ? 'Editar' : 'Visualizar' },
                            React.createElement(Button, { icon: canUpdate ? 'edit' : 'eye', color: "info", rounded: true })))),
                    canDelete && (React.createElement(Tooltip, { id: `aircraft-models-${value}-remove`, text: "Deletar" },
                        React.createElement(Button, { className: "ml-2", icon: "trash", color: "danger", title: "Deletar", onClick: () => onDeleteAircraftModel(value), rounded: true })))));
            }
        }
    ], [canRead, canUpdate, canDelete, onDeleteAircraftModel]);
    useDocumentTitle('Modelos de Aeronave');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Modelos de Aeronave" }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            canCreate && (React.createElement(Link, { to: "/operational/aircraft-models/new" },
                React.createElement(Button, { text: "Novo Modelo", icon: "add", color: "info", animationHover: "pop" }))),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Modelos de Aeronave", formConfig: { searchFields, filterParams, withFields: true, withSituation: true }, handleFilter: setParams }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhum modelo a ser exibido" })));
};
export default AircraftModelList;
