import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { BENEFICIARY_RECOMMENDATION_QUERY_KEY } from './useBeneficiaryRecommendationConfig';
export const useBeneficiaryRecommendationMutation = () => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createBeneficiaryRecommendation = useCallback(async (beneficiaryRecommendationData) => {
        return httpService.post('/beneficiary-recommendation', beneficiaryRecommendationData);
    }, [httpService]);
    const updateBeneficiaryRecommendation = useCallback(async (beneficiaryRecommendationData) => {
        return httpService.put('/beneficiary-recommendation', beneficiaryRecommendationData);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createBeneficiaryRecommendation, {
        onSuccess: () => {
            queryClient.invalidateQueries(BENEFICIARY_RECOMMENDATION_QUERY_KEY);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateBeneficiaryRecommendation, {
        onSuccess: () => {
            queryClient.invalidateQueries(BENEFICIARY_RECOMMENDATION_QUERY_KEY);
        }
    });
    return { create, update, isCreating, isUpdating };
};
