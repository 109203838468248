import React, { useCallback } from 'react';
import { Select } from 'components/Select';
import { FormatterUtils, ValidationUtils } from 'utils';
import { useBeneficiarySelectPagination } from './useBeneficiarySelectPagination';
const BeneficiarySelect = ({ value, onChange: handleOnChange }) => {
    const { loadOptions } = useBeneficiarySelectPagination();
    const onChange = useCallback((value) => {
        handleOnChange(value);
    }, [handleOnChange]);
    const formatBeneficiaryOptionLabel = useCallback((beneficiary) => {
        return (React.createElement(React.Fragment, null,
            beneficiary.Name,
            " ",
            React.createElement("br", null),
            React.createElement("small", null,
                "CPF: ",
                ValidationUtils.cpf(beneficiary.CPF) ? FormatterUtils.cpf(beneficiary.CPF) : 'Não Informado')));
    }, []);
    return (React.createElement(Select, { name: "Beneficiary", label: "Associado", value: value, loadOptions: loadOptions, onChange: onChange, getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), formatOptionLabel: formatBeneficiaryOptionLabel, isAsync: true }));
};
export default BeneficiarySelect;
