import React, { useCallback, useState } from 'react';
import { Button, Form, FormField, FormFieldError, FormikValidationHandler, MaskedTextField, Modal, Select, useFormikValidationHandler } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useFormik } from 'formik';
import { useSolicitationMutation } from 'hooks';
import moment from 'moment';
import { convertStateEnumListToOptionList, solicitationStateFinishedReasonOptions, SolicitationStateReason, SolicitationStatus } from 'types/enum';
import { FormatterUtils, Validation } from 'utils';
const solicitationStateCancelledReasonSelectOptions = convertStateEnumListToOptionList(solicitationStateFinishedReasonOptions);
const today = moment();
const SolicitationFinishDialogFormSchema = Validation.object().shape({
    Reason: Validation.string().required('Informe a razão'),
    EndDate: Validation.string().when('Reason', {
        is: (value) => parseInt(value) === SolicitationStateReason.FINISHED_SUCCESSFUL_ON_PERIODIC_REVISION,
        then: Validation.string().dateBeforeOrSame('Informe uma data válida', today)
    })
});
const SolicitationFinishDialog = ({ solicitationId }) => {
    const { success, errorCallback } = useAlert();
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const [showDialog, setShowDialog] = useState(false);
    const { createState, isCreatingSolicitationState } = useSolicitationMutation();
    const toggleDialog = useCallback(() => setShowDialog(showDialog => !showDialog), []);
    const finishSolicitation = useCallback(async (values) => {
        const solicitationStateData = {
            SolicitationId: solicitationId,
            StatusId: SolicitationStatus.FINISHED,
            SolicitationStateEndDate: values.EndDate ? FormatterUtils.momentDate(values.EndDate)?.format() : undefined,
            StateReason: values.Reason
        };
        await createState(solicitationStateData, {
            onSuccess: () => {
                success({
                    title: 'Requerimento finalizado!',
                    message: 'Requerimento finalizado com sucesso.',
                    id: 'SOLICITATION_FINISH_SUCCESS'
                });
                toggleDialog();
            },
            onError: err => errorCallback(err, 'SOLICITATION_FINISH_FAILURE')
        });
    }, [createState, success, toggleDialog, errorCallback, solicitationId]);
    const formikContext = useFormik({
        initialValues: {
            Reason: null,
            EndDate: ''
        },
        validationSchema: () => SolicitationFinishDialogFormSchema,
        onSubmit: finishSolicitation,
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleSubmit } = formikContext;
    const onSelectReason = useCallback((value) => {
        const selectedValue = value;
        setFieldValue('Reason', selectedValue.value);
    }, [setFieldValue]);
    const isFinishedSuccessfullyOnPeriodicRevision = values.Reason === SolicitationStateReason.FINISHED_SUCCESSFUL_ON_PERIODIC_REVISION;
    const canFinish = isFinishedSuccessfullyOnPeriodicRevision
        ? Boolean(values.Reason && values.EndDate)
        : Boolean(values.Reason);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { text: "Finalizar Requerimento", color: "success", animationHover: "pop", onClick: toggleDialog, className: "mr-2 mb-2 lg:mb-0" }),
        React.createElement(Modal, { show: showDialog, size: "alert", closable: false, title: "Finalizar Requerimento" },
            React.createElement(Form, { name: "solicitationFinishForm", onSubmit: handleSubmit, formikContext: formikContext },
                React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
                React.createElement("p", { className: "text-gray dark:text-gray-50 mb-4" }, "Voc\u00EA tem certeza que deseja finalizar a solicita\u00E7\u00E3o?"),
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "StateReason", label: "Escolha o motivo", options: solicitationStateCancelledReasonSelectOptions, value: solicitationStateCancelledReasonSelectOptions?.find((x) => x.value === values.Reason), onChange: onSelectReason, getOptionLabel: option => option.label, getOptionValue: option => option.label.toString() })),
                isFinishedSuccessfullyOnPeriodicRevision && (React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { name: "EndDate", maskType: "date", label: "Data da alta m\u00E9dica", value: values.EndDate, onChange: value => setFieldValue('EndDate', value) }),
                    React.createElement(FormFieldError, { name: "EndDate" }))),
                React.createElement("div", { className: "flex justify-end mt-4" },
                    React.createElement(Button, { type: "submit", color: "success", className: "mr-2", loading: isCreatingSolicitationState, disabled: !canFinish, text: "Finalizar" }),
                    React.createElement(Button, { color: "danger", onClick: toggleDialog, disabled: isCreatingSolicitationState, text: "Voltar" }))))));
};
export default SolicitationFinishDialog;
