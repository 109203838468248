import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { JOB_DESCRIPTION_LIST_QUERY_KEY, JOB_DESCRIPTION_PAGINATION_QUERY_KEY, JOB_DESCRIPTION_QUERY_KEY } from './useJobDescriptionConfig';
export const useJobDescriptionMutation = (jobDescriptionId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createJobDescription = useCallback(async (jobDescriptionData) => {
        return httpService.post('/job-description', jobDescriptionData);
    }, [httpService]);
    const updateJobDescription = useCallback(async (jobDescriptionData) => {
        return httpService.put('/job-description', jobDescriptionData);
    }, [httpService]);
    const removeJobDescription = useCallback(async (jobDescriptionId) => {
        return httpService.delete(`/job-description?id=${jobDescriptionId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createJobDescription, {
        onSuccess: () => {
            queryClient.invalidateQueries(JOB_DESCRIPTION_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries(JOB_DESCRIPTION_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateJobDescription, {
        onSuccess: () => {
            queryClient.invalidateQueries(JOB_DESCRIPTION_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries([JOB_DESCRIPTION_QUERY_KEY, jobDescriptionId]);
            queryClient.invalidateQueries(JOB_DESCRIPTION_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removeJobDescription, {
        onSuccess: (_, jobDescriptionId) => {
            queryClient.invalidateQueries(JOB_DESCRIPTION_PAGINATION_QUERY_KEY);
            queryClient.removeQueries([JOB_DESCRIPTION_QUERY_KEY, jobDescriptionId]);
            queryClient.invalidateQueries(JOB_DESCRIPTION_LIST_QUERY_KEY);
        }
    });
    return { create, update, remove, isCreating, isUpdating, isRemoving };
};
