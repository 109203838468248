import React, { createContext } from 'react';
import FormikOnError from 'components/FormikOnError/FormikOnError';
import { FormikProvider } from 'formik';
const FormContext = createContext({ disabled: false });
const Form = ({ name, onSubmit, disabled = false, children, formikContext, ...props }) => {
    return (React.createElement(FormContext.Provider, { value: { disabled } }, formikContext ? (React.createElement(FormikProvider, { value: formikContext },
        React.createElement("form", { name: name, onSubmit: onSubmit, ...props },
            React.createElement(FormikOnError, null, children)))) : (React.createElement("form", { name: name, onSubmit: onSubmit }, children))));
};
const FormConsumer = ({ children }) => {
    return React.createElement(FormContext.Consumer, null, ({ disabled }) => children({ disabled }));
};
export { Form, FormConsumer };
