import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useSolicitationMutation } from 'hooks';
const SolicitationExtendDialog = ({ solicitationId }) => {
    const { success, errorCallback } = useAlert();
    const [showDialog, setShowDialog] = useState(false);
    const { extend, isExtending } = useSolicitationMutation();
    const toggleDialog = useCallback(() => setShowDialog(showDialog => !showDialog), []);
    const extendSolicitation = useCallback(async () => {
        const payload = { SolicitationId: solicitationId };
        extend(payload, {
            onSuccess: () => {
                success({
                    title: 'Requerimento prorrogado!',
                    message: 'Requerimento prorrogado com sucesso.',
                    id: 'SOLICITATION_EXTEND_SUCCESS'
                });
                toggleDialog();
            },
            onError: err => errorCallback(err, 'SOLICITATION_EXTEND_FAILURE')
        });
    }, [extend, success, toggleDialog, errorCallback, solicitationId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { text: "Prorrogar Requerimento", color: "info", className: "ml-2", animationHover: "pop", onClick: toggleDialog }),
        React.createElement(Modal, { show: showDialog, size: "alert", closable: false, title: "Prorrogar Requerimento" },
            React.createElement("p", { className: "text-gray dark:text-gray-50 mb-4" },
                "O requerimento ser\u00E1 prorrogado em 90 dias. Voc\u00EA tem certeza que deseja prorrogar o requerimento? ",
                React.createElement("br", null),
                " Essa a\u00E7\u00E3o \u00E9 irrevers\u00EDvel!"),
            React.createElement("div", { className: "flex justify-end mt-4" },
                React.createElement(Button, { color: "danger", className: "mr-2", loading: isExtending, onClick: extendSolicitation, text: "Prorrogar" }),
                React.createElement(Button, { color: "success", onClick: toggleDialog, disabled: isExtending, text: "Voltar" })))));
};
export default SolicitationExtendDialog;
