import React, { useState } from 'react';
import { Row, Col } from 'react-grid-system';
import { TextField, FormField, FormFieldError, FormFieldSeparator } from 'components';
const PersonPasswordForm = ({ isCreation, formikContext }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { values, handleChange } = formikContext;
    return (React.createElement(React.Fragment, null,
        React.createElement(FormFieldSeparator, { icon: "password", text: `${isCreation ? 'Definir' : 'Trocar'} Senha` }),
        React.createElement(Row, null,
            !isCreation && (React.createElement(Col, { xs: 4 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Password", label: "Senha atual", value: values.Password, onChange: handleChange, type: showPassword ? 'text' : 'password', preventAutoComplete: true, rightIcon: !showPassword ? 'eye' : 'eye-closed', rightAction: () => setShowPassword(!showPassword) }),
                    React.createElement(FormFieldError, { name: "Password" })))),
            React.createElement(Col, { xs: !isCreation ? 4 : 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "NewPassword", label: `${isCreation ? 'Informe a' : 'nova'} senha`, value: values.NewPassword, onChange: handleChange, type: showNewPassword ? 'text' : 'password', preventAutoComplete: true, rightIcon: !showNewPassword ? 'eye' : 'eye-closed', rightAction: () => setShowNewPassword(!showNewPassword) }),
                    React.createElement(FormFieldError, { name: "NewPassword" }))),
            React.createElement(Col, { xs: !isCreation ? 4 : 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "ConfirmPassword", label: `${isCreation ? 'Confirme a' : 'Confirme a nova'} senha`, value: values.ConfirmPassword, onChange: handleChange, type: showConfirmPassword ? 'text' : 'password', preventAutoComplete: true, rightIcon: !showConfirmPassword ? 'eye' : 'eye-closed', rightAction: () => setShowConfirmPassword(!showConfirmPassword) }),
                    React.createElement(FormFieldError, { name: "ConfirmPassword" }))))));
};
export default PersonPasswordForm;
