export class DefaultScopeDTO {
    static Administrator = [
        {
            Id: 71,
            Name: 'administratorList'
        },
        {
            Id: 72,
            Name: 'administratorRead'
        },
        {
            Id: 73,
            Name: 'administratorCreate'
        },
        {
            Id: 74,
            Name: 'administratorUpdate'
        },
        {
            Id: 75,
            Name: 'administratorDelete'
        }
    ];
}
