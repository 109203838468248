import React from 'react';
import { SolicitationCreation, SolicitationList, SolicitationManagement } from './screens';
const SolicitationModule = [
    {
        path: '/cam/solicitations',
        element: React.createElement(SolicitationList, null)
    },
    {
        path: '/cam/solicitations/new',
        element: React.createElement(SolicitationCreation, null)
    },
    {
        path: '/cam/solicitations/:solicitationId',
        element: React.createElement(SolicitationManagement, null)
    }
];
export { SolicitationModule };
