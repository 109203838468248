import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { ApiException, StatusNumber } from 'types/error';
export const useZipCode = () => {
    const [queryZipCode, setQueryZipCode] = useState(null);
    const getAddressByZipCode = useCallback(async () => {
        return axios
            .get(`https://viacep.com.br/ws/${queryZipCode}/json`)
            .then(res => {
            if (!res) {
                throw new ApiException(StatusNumber.BAD_REQUEST, 'Não foi possível buscar o CEP.');
            }
            const { data } = res;
            if (data.erro) {
                throw new ApiException(StatusNumber.BAD_REQUEST, 'CEP inválido.');
            }
            if (data.status === 400) {
                throw new ApiException(StatusNumber.BAD_REQUEST, 'Não foi possível buscar o CEP.');
            }
            return {
                Street: data.logradouro,
                Neighborhood: data.bairro,
                City: data.localidade,
                State: data.uf,
                ZipCode: data.cep.replace(/-/g, '')
            };
        });
    }, [queryZipCode]);
    const { data, isLoading } = useQuery(['zipCode', queryZipCode], getAddressByZipCode, {
        enabled: Boolean(queryZipCode)
    });
    const isValidZipCode = useCallback((zipCode) => zipCode && zipCode.replace(/\D/g, '').length === 8, []);
    const setZipCode = useCallback((zipCode) => {
        if (!isValidZipCode(zipCode)) {
            return;
        }
        setQueryZipCode(zipCode.replace(/\D/g, ''));
    }, [isValidZipCode]);
    return [data, isLoading, setZipCode];
};
