export default {
    darkMode: 'class',
    content: [
        './src/**/*.{js,jsx,ts,tsx}'
    ],
    theme: {
        extend: {
            colors: {
                blue: {
                    light: '#00D9FF',
                    DEFAULT: '#2746E6',
                    dark: '#2B2B69'
                },
                red: {
                    light: '#EC0B43',
                    DEFAULT: '#EF271B',
                    dark: '#D00000'
                },
                orange: {
                    light: '#EEAD48',
                    DEFAULT: '#eca231',
                    dark: '#BA6F00'
                },
                green: {
                    light: '#AFE059',
                    DEFAULT: '#94C246',
                    dark: '#608F0E'
                },
                gray: {
                    light: '#676767',
                    DEFAULT: '#555555',
                    dark: '#333333'
                },
                slate: {
                    DEFAULT: '#16192A',
                    dark: '#0F111D'
                }
            }
        }
    },
    plugins: []
};
