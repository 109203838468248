import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Loading, Card } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useDocumentTitle, usePlanQuery, usePlanMutation } from 'hooks';
import moment from 'moment-timezone';
import { PlanForm } from '../components';
const PlanInternal = () => {
    const planIdRoute = parseInt(useParams()?.planId ?? '0');
    const { success, errorCallback } = useAlert();
    const navigate = useNavigate();
    const [plan, isLoading] = usePlanQuery(planIdRoute);
    const { create, update } = usePlanMutation(planIdRoute);
    async function submitPlan(planData) {
        const planDataSubmit = { ...planData };
        planDataSubmit.CreatedAt = moment().format();
        planDataSubmit.UpdatedAt = null;
        if (planData.Id) {
            return update(planDataSubmit, {
                onSuccess: () => success({
                    title: 'Plano atualizado!',
                    message: 'Dados do plano atualizados com sucesso.',
                    id: 'PLAN_PUT_SUCCESS'
                }),
                onError: err => errorCallback(err, 'PLAN_PUT_FAILURE')
            });
        }
        else {
            return create(planDataSubmit, {
                onSuccess: res => {
                    success({ title: 'Plano cadastrado!', message: 'Plano cadastrado com sucesso.', id: 'PLAN_POST_SUCCESS' });
                    navigate(`/cam/plans/${res.Id}`);
                },
                onError: err => errorCallback(err, 'PLAN_POST_FAILURE')
            });
        }
    }
    const pageTitle = useMemo(() => `${planIdRoute ? 'Visualizar' : 'Novo'} Plano`, [planIdRoute]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Planos',
            Url: '/cam/plans'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Planos", breadcrumb: breadcrumb }),
        React.createElement(Loading, { show: isLoading }),
        React.createElement(Card, { id: "plan-card" },
            React.createElement(PlanForm, { plan: plan, isLoading: isLoading, handleSubmit: submitPlan }))));
};
export default PlanInternal;
