export var BenefitConsumptionType;
(function (BenefitConsumptionType) {
    BenefitConsumptionType[BenefitConsumptionType["PURCHASE"] = 1] = "PURCHASE";
    BenefitConsumptionType[BenefitConsumptionType["DEVOLUTION"] = 2] = "DEVOLUTION";
})(BenefitConsumptionType || (BenefitConsumptionType = {}));
export const BenefitConsumptionTypeLabel = {
    [BenefitConsumptionType.PURCHASE]: 'Compra',
    [BenefitConsumptionType.DEVOLUTION]: 'Devolução'
};
export const benefitConsumptionTypeOptions = Object.keys(BenefitConsumptionTypeLabel).map(status => ({
    label: BenefitConsumptionTypeLabel[status],
    value: parseInt(status)
}));
