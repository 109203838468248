import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Icon, Table, Tooltip } from 'components';
import { useSolicitationRevisionList } from 'hooks';
import moment from 'moment-timezone';
import { SolicitationStateReasonLabel } from 'types/enum';
import { SolicitationUtils, TextUtils } from 'utils';
import { SolicitationCard } from '../Card';
import { SolicitationRevisionAction } from './RevisionAction';
const SolicitationRevisionCard = ({ solicitationId, solicitationStates }) => {
    const [revisions, isLoadingRevisions] = useSolicitationRevisionList(solicitationId);
    const solicitationCurrentState = useMemo(() => SolicitationUtils.getLastState(solicitationStates), [
        solicitationStates
    ]);
    const columns = useMemo(() => [
        {
            Header: 'Data',
            accessor: 'StartDate',
            Cell: ({ cell: { value }, row: { original: values } }) => {
                const isRevisionOutOfPeriod = !values.IsNormativeToRevisionDays;
                return (React.createElement(React.Fragment, null,
                    TextUtils.capitalizeFirstLetter(moment(value).format('MMMM [de] YYYY')),
                    isRevisionOutOfPeriod && (React.createElement(Tooltip, { id: `solicitation-revision-${values.Id}-date`, text: "Essa revis\u00E3o ser\u00E1 realizada antes da data prevista devido a dura\u00E7\u00E3o da solicita\u00E7\u00E3o" },
                        React.createElement(Icon, { name: "warning", size: 14, className: "ml-1 self-center text-gray-light dark:text-gray-400" })))));
            }
        },
        {
            Header: 'Status',
            accessor: 'SolicitationRevisionState',
            Cell: ({ cell: { value } }) => (React.createElement(React.Fragment, null,
                React.createElement("span", { className: clsx(SolicitationUtils.getSolicitationStateTextColor(value?.StatusId)) }, value?.Status?.Name ?? '-'),
                value?.StateReason && (React.createElement("small", { className: "block mt-1 text-gray-light dark:text-gray-400" }, SolicitationStateReasonLabel[value.StateReason]))))
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            Cell: ({ cell: { row: { original: values } } }) => (React.createElement(SolicitationRevisionAction, { solicitationRevision: values, solicitationCurrentState: solicitationCurrentState }))
        }
    ], [solicitationCurrentState]);
    if (isLoadingRevisions) {
        return (React.createElement(SolicitationCard, { title: "Revis\u00F5es", className: "h-full" },
            React.createElement(Icon, { name: "spinner", size: 35, className: "text-gray-light dark:text-gray-400" })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(SolicitationCard, { title: "Revis\u00F5es", className: "h-full" },
            React.createElement(Table, { data: revisions, columns: columns, noResultsMessage: "Nenhuma revis\u00E3o a ser exibida", cardClassName: "mt-4", disableSortBy: true }))));
};
export default SolicitationRevisionCard;
