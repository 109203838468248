import moment from 'moment';
export function validateDate(dateStr) {
    const cleanDateStr = String(dateStr).replace(/\D+/g, '');
    const momentDate = moment(dateStr, 'DD/MM/YYYY', true);
    return cleanDateStr.length === 8 && momentDate.isValid();
}
export function validateDateBefore(dateStr, dateToCompare) {
    const momentDate = moment(dateStr, 'DD/MM/YYYY');
    const momentDateToCompare = moment(dateToCompare, 'DD/MM/YYYY');
    return momentDate.isBefore(momentDateToCompare);
}
export function validateDateSameOrBefore(dateStr, dateToCompare) {
    const momentDate = moment(dateStr, 'DD/MM/YYYY');
    const momentDateToCompare = moment(dateToCompare, 'DD/MM/YYYY');
    return momentDate.isSameOrBefore(momentDateToCompare);
}
export function validateDateAfter(dateStr, dateToCompare) {
    const momentDate = moment(dateStr, 'DD/MM/YYYY');
    const momentDateToCompare = moment(dateToCompare, 'DD/MM/YYYY');
    return momentDate.isAfter(momentDateToCompare);
}
