import React, { createContext, useContext, useEffect } from 'react';
import { theme } from 'configs';
import { useLocalStorage } from 'hooks';
const ThemeContext = createContext({});
export const ThemeProvider = ({ children }) => {
    const [darkMode, setDarkMode] = useLocalStorage('darkMode', null);
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    useEffect(() => {
        let willUseDarkTheme = darkMode;
        if (willUseDarkTheme === null)
            willUseDarkTheme = !!prefersDarkMode;
        willUseDarkTheme
            ? document.documentElement.classList.add('dark')
            : document.documentElement.classList.remove('dark');
        setDarkMode(willUseDarkTheme);
    }, [darkMode, prefersDarkMode, setDarkMode]);
    return React.createElement(ThemeContext.Provider, { value: { theme, darkMode, setDarkMode } }, children);
};
export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (context === undefined)
        throw new Error('useTheme must be used within a ThemeProvider.');
    return context;
};
