export var StatusNumber;
(function (StatusNumber) {
    StatusNumber[StatusNumber["OK"] = 200] = "OK";
    StatusNumber[StatusNumber["CREATED"] = 201] = "CREATED";
    StatusNumber[StatusNumber["BAD_REQUEST"] = 400] = "BAD_REQUEST";
    StatusNumber[StatusNumber["UNAUTHORIZED"] = 401] = "UNAUTHORIZED";
    StatusNumber[StatusNumber["FORBIDDEN"] = 403] = "FORBIDDEN";
    StatusNumber[StatusNumber["NOT_FOUND"] = 404] = "NOT_FOUND";
    StatusNumber[StatusNumber["CONFLICT"] = 409] = "CONFLICT";
    StatusNumber[StatusNumber["UNPROCESSABLE_ENTITY"] = 422] = "UNPROCESSABLE_ENTITY";
    StatusNumber[StatusNumber["CLIENT_CLOSED_REQUEST"] = 499] = "CLIENT_CLOSED_REQUEST";
})(StatusNumber || (StatusNumber = {}));
