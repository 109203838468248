import React from 'react';
import { BenefitConsumptionBatchCreation, BenefitConsumptionBatchList } from './screens';
const BenefitConsumptionBatchModule = [
    {
        path: '/benefits/consumptions/batches',
        element: React.createElement(BenefitConsumptionBatchList, null)
    },
    {
        path: '/benefits/consumptions/batches/new',
        element: React.createElement(BenefitConsumptionBatchCreation, null)
    }
];
export { BenefitConsumptionBatchModule };
