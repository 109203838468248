import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { makeHttpService } from 'services';
import { SOLICITATION_DURATION_QUERY_KEY } from './useSolicitationConfig';
export const useSolicitationDurationQuery = (solicitationId) => {
    const getSolicitationDuration = useCallback(async ({ signal }) => {
        return makeHttpService().get(`/solicitation/${solicitationId}/duration`, {
            signal
        });
    }, [solicitationId]);
    const { data, isLoading } = useQuery([SOLICITATION_DURATION_QUERY_KEY, solicitationId], getSolicitationDuration, {
        enabled: !!solicitationId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    const memoizedData = useMemo(() => data ?? {}, [data]);
    return [memoizedData, isLoading];
};
