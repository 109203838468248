import React from 'react';
import { Link } from 'react-router-dom';
import { TextField, Form, ButtonAnimated, FormField, FormFieldError, useFormikValidationHandler, FormikValidationHandler } from 'components';
import { useFormik } from 'formik';
import { Validation } from 'utils';
const RequestRecoverPasswordSchema = Validation.object().shape({
    UserName: Validation.string().required('Digite o e-mail')
});
const defaultValues = {
    UserName: ''
};
const ForgotPasswordRequestForm = props => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => RequestRecoverPasswordSchema,
        onSubmit: async (data) => {
            return props.handleSubmit(data.UserName);
        },
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, handleChange, handleSubmit, isSubmitting } = formikContext;
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: "auth-description" }, "Para fazer uma solicita\u00E7\u00E3o de redefini\u00E7\u00E3o de senha, informe o e-mail cadastrado abaixo:"),
        React.createElement(Form, { name: "ForgotPasswordRequestForm", onSubmit: handleSubmit, disabled: false, formikContext: formikContext, className: "w-100" },
            React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
            React.createElement(FormField, null,
                React.createElement(TextField, { name: "UserName", label: "E-mail", value: values.UserName, onChange: handleChange, variant: "outlined" }),
                React.createElement(FormFieldError, { name: "UserName" })),
            React.createElement("div", { className: "flex flex-col items-center mt-8" },
                React.createElement(ButtonAnimated, { loading: isSubmitting, text: "Recuperar senha" }),
                React.createElement(Link, { to: "/login", className: "mt-2" },
                    React.createElement("span", { className: "text-xs inline-block" }, "Voltar para login"))))));
};
export default ForgotPasswordRequestForm;
