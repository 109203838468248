import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col } from 'react-grid-system';
import clsx from 'clsx';
import { TextField, Button, FormField, FormFieldError, Form, MaskedTextField, Select, SituationField, FormFieldSeparator, FormikValidationHandler, useFormikValidationHandler } from 'components';
import { useFormik } from 'formik';
import { useModulePermissions, useAircraftModelList, useJobDescriptionList, useOperationBaseList, usePlanList, useBeneficiaryTypeList } from 'hooks';
import moment from 'moment-timezone';
import { FormatterUtils, QueryUtils, ValidationUtils } from 'utils';
import { BeneficiaryProfessionalDataSchema } from './BeneficiaryProfessionalDataForm.schema';
const defaultValues = {
    Id: 0,
    JobDescriptionId: null,
    PlanId: null,
    OperationBaseId: null,
    AircraftModelId: null,
    BeneficiaryTypeId: null,
    CompanyStartDate: '',
    AssociationStartDate: '',
    ANACRegistration: '',
    CHTRegistration: '',
    CMACertification: false,
    CMACertificateExpirationDate: '',
    HasGracePeriod: false
};
const BeneficiaryProfessionalDataForm = ({ beneficiary, isLoading, onSubmitBeneficiary }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions(beneficiary?.Id);
    const [plans, isLoadingPlans] = usePlanList();
    const [jobDescriptions, isLoadingJobDescriptions] = useJobDescriptionList();
    const [operationBases, isLoadingOperationBases] = useOperationBaseList();
    const [aircraftModels, isLoadingAircrafts] = useAircraftModelList();
    const [beneficiaryTypes, isLoadingBeneficiaryTypes] = useBeneficiaryTypeList();
    const onSubmit = useCallback(async (beneficiaryValues) => onSubmitBeneficiary(beneficiaryValues), [onSubmitBeneficiary]);
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => BeneficiaryProfessionalDataSchema,
        onSubmit,
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleChange, handleSubmit, isSubmitting, setValues } = formikContext;
    const onInitForm = useCallback(() => {
        if (ValidationUtils.isEmptyObject(beneficiary))
            return;
        const beneficiaryData = {
            Id: beneficiary.Id,
            JobDescriptionId: beneficiary.JobDescriptionId || null,
            OperationBaseId: beneficiary.OperationBaseId || null,
            AircraftModelId: beneficiary.AircraftModelId || null,
            BeneficiaryTypeId: beneficiary.BeneficiaryTypeId || null,
            PlanId: beneficiary.PlanId || null,
            CompanyStartDate: FormatterUtils.date(beneficiary.CompanyStartDate),
            AssociationStartDate: FormatterUtils.date(beneficiary.AssociationStartDate),
            ANACRegistration: beneficiary.ANACRegistration || '',
            CHTRegistration: beneficiary.CHTRegistration || '',
            CMACertification: !!beneficiary.CMACertificateExpirationDate || null,
            CMACertificateExpirationDate: beneficiary.CMACertificateExpirationDate
                ? FormatterUtils.date(beneficiary.CMACertificateExpirationDate)
                : '',
            HasGracePeriod: !!beneficiary.HasGracePeriod
        };
        setValues(beneficiaryData);
    }, [beneficiary, setValues]);
    const handleCMACertificationChange = useCallback((hasCMA) => {
        setFieldValue('CMACertification', hasCMA);
        if (!hasCMA)
            setFieldValue('CMACertificateExpirationDate', '');
    }, [setFieldValue]);
    const handleHasGracePeriodChange = useCallback((hasGracePeriod) => {
        setFieldValue('HasGracePeriod', hasGracePeriod);
    }, [setFieldValue]);
    const gracePeriod = useMemo(() => {
        if (!values.AssociationStartDate)
            return;
        const startDate = FormatterUtils.momentDate(values.AssociationStartDate);
        const endDate = moment();
        if (!startDate?.isValid() || !endDate?.isValid())
            return;
        const now = moment();
        const monthsActive = now.diff(startDate, 'months');
        const monthsToExpire = endDate.diff(now, 'months');
        return [monthsActive, monthsToExpire];
    }, [values.AssociationStartDate]);
    const gracePeriodHelperText = useMemo(() => {
        if (!gracePeriod)
            return '';
        const [monthsActive] = gracePeriod;
        return `O associado está a ${monthsActive} meses associado.`;
    }, [gracePeriod]);
    const filterAircraftModelOption = useCallback((option, inputValue) => QueryUtils.objectPropertyLike(option.data, inputValue, 'Aircraft', 'Prefix', 'SerialNumber'), []);
    const formatAircraftModelOptionLabel = useCallback((aircraftModel) => {
        return (React.createElement(React.Fragment, null,
            aircraftModel.Aircraft,
            " ",
            React.createElement("br", null),
            React.createElement("small", null,
                aircraftModel.Prefix,
                " - ",
                aircraftModel.SerialNumber)));
    }, []);
    const selectedBeneficiaryType = beneficiaryTypes?.find((beneficiaryType) => beneficiaryType.Id === values.BeneficiaryTypeId);
    useEffect(onInitForm, [onInitForm]);
    if (isLoading)
        return null;
    return (React.createElement(Form, { name: "BeneficiaryProfessionalDataForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, null,
            React.createElement(Col, { lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "BeneficiaryTypeId", label: "Escolha o tipo", options: beneficiaryTypes, value: selectedBeneficiaryType, onChange: option => setFieldValue('BeneficiaryTypeId', option.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingBeneficiaryTypes }),
                    React.createElement(FormFieldError, { name: "BeneficiaryTypeId" }),
                    Boolean(selectedBeneficiaryType?.Id) && (React.createElement(React.Fragment, null,
                        React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400 mt-2" }, "Permiss\u00F5es do tipo de benefici\u00E1rio:"),
                        React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400 mt-1" },
                            "Plano:\u00A0",
                            React.createElement("span", { className: clsx('font-semibold', selectedBeneficiaryType?.CanUsePlan ? 'text-green-light' : 'text-red-light') }, !selectedBeneficiaryType?.CanUsePlan ? 'Não' : 'Sim')),
                        React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400 mt-1" },
                            "Requerimento Processo CAM:\u00A0",
                            React.createElement("span", { className: clsx('font-semibold', selectedBeneficiaryType?.CanCreateCAMRequest ? 'text-green-light' : 'text-red-light') }, !selectedBeneficiaryType?.CanCreateCAMRequest ? 'Não' : 'Sim')))))),
            React.createElement(Col, { lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "PlanId", label: "Escolha o plano", options: plans, value: plans?.find((plan) => plan.Id === values.PlanId), onChange: option => setFieldValue('PlanId', option.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingPlans }),
                    React.createElement(FormFieldError, { name: "PlanId" }))),
            React.createElement(Col, { lg: 4 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "JobDescriptionId", label: "Escolha o cargo", options: jobDescriptions, value: jobDescriptions?.find((jobDescription) => jobDescription.Id === values.JobDescriptionId), onChange: option => setFieldValue('JobDescriptionId', option.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingJobDescriptions }),
                    React.createElement(FormFieldError, { name: "JobDescriptionId" }))),
            React.createElement(Col, { lg: 4 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "OperationBaseId", label: "Escolha a base", options: operationBases, value: operationBases?.find((operationBase) => operationBase.Id === values.OperationBaseId), onChange: option => setFieldValue('OperationBaseId', option.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingOperationBases }),
                    React.createElement(FormFieldError, { name: "OperationBaseId" }))),
            React.createElement(Col, { lg: 4 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "AircraftModelId", label: "Escolha a aeronave", options: aircraftModels, value: aircraftModels?.find((aircraftModel) => aircraftModel.Id === values.AircraftModelId), onChange: option => setFieldValue('AircraftModelId', option.Id), formatOptionLabel: formatAircraftModelOptionLabel, filterOption: filterAircraftModelOption, getOptionValue: option => option.Id.toString(), isLoading: isLoadingAircrafts }),
                    React.createElement(FormFieldError, { name: "AircraftModelId" }))),
            React.createElement(Col, { lg: 4 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "ANACRegistration", label: "Registro ANAC", value: values.ANACRegistration, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "ANACRegistration" }))),
            React.createElement(Col, { lg: 4 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "CHTRegistration", label: "Registro CHT", value: values.CHTRegistration, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "CHTRegistration" }))),
            React.createElement(Col, { lg: 4 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "date", name: "CompanyStartDate", label: "Data in\u00EDcio empresa", value: values.CompanyStartDate, onChange: value => setFieldValue('CompanyStartDate', value) }),
                    React.createElement(FormFieldError, { name: "CompanyStartDate" })))),
        React.createElement(FormFieldSeparator, { text: "Certifica\u00E7\u00E3o CMA", className: "mt-3", icon: "certificate" }),
        React.createElement(Row, null,
            React.createElement(Col, { lg: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "", handleChange: hasCMA => handleCMACertificationChange(hasCMA), value: values.CMACertification, falseLabel: "N\u00E3o Possui", trueLabel: "Possui" }))),
            React.createElement(Col, { lg: 4 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "date", name: "CMACertificateExpirationDate", label: "Data expira\u00E7\u00E3o CMA", value: values.CMACertificateExpirationDate, onChange: value => setFieldValue('CMACertificateExpirationDate', value), disabled: !values.CMACertification }),
                    React.createElement(FormFieldError, { name: "CMACertificateExpirationDate" })))),
        React.createElement(FormFieldSeparator, { text: "Car\u00EAncia Processo CAM", className: "mt-3", icon: "calendar" }),
        React.createElement(Row, null,
            React.createElement(Col, { lg: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "", handleChange: hasGracePeriod => handleHasGracePeriodChange(hasGracePeriod), value: values.HasGracePeriod, falseLabel: "N\u00E3o Possui", trueLabel: "Possui" }))),
            React.createElement(Col, { lg: 12 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "date", name: "AssociationStartDate", label: "Data in\u00EDcio ATL", value: values.AssociationStartDate, onChange: value => setFieldValue('AssociationStartDate', value), rightIcon: "warning", rightIconTooltip: "Esse campo \u00E9 utilizado para calcular h\u00E1 quanto tempo o associado est\u00E1 associado a ATL" }),
                    React.createElement(FormFieldError, { name: "AssociationStartDate" }),
                    Boolean(gracePeriodHelperText) && (React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400 mt-2" }, gracePeriodHelperText))))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: values.Id ? 'Atualizar' : 'Continuar', color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default BeneficiaryProfessionalDataForm;
