import React, { useState, useRef, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useWindowDimensions } from 'hooks';
import './DropdownMenu.scss';
const commonStyle = { willChange: 'transform', top: '0px', left: '0px' };
const DropdownMenu = ({ children, anchorElem, showOnHover, initOpen = false }) => {
    const { width } = useWindowDimensions();
    const [show, toggle] = useState(false);
    const [style, setStyle] = useState(commonStyle);
    const dropdownRef = useRef(null);
    const dropdownMenuRef = useRef(null);
    function calculatePosition(dropdownDOM, dropdownMenuDOM) {
        if (!show)
            return commonStyle;
        if (dropdownDOM === undefined || dropdownMenuDOM === undefined)
            return commonStyle;
        const right = width - dropdownDOM?.right;
        const left = dropdownDOM?.left;
        if (left >= dropdownMenuDOM.width)
            return { right: '0' };
        else if (right >= dropdownMenuDOM.width)
            return { left: '0%' };
        return commonStyle;
    }
    useEffect(() => {
        function handleClickOutside(event) {
            if (!dropdownMenuRef.current?.contains(event.target))
                toggle(false);
        }
        document.addEventListener('mousedown', handleClickOutside);
        initOpen && toggle(true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [initOpen]);
    useEffect(() => {
        const dropdownDOM = dropdownRef.current?.getBoundingClientRect();
        const dropdownMenuDOM = dropdownMenuRef.current?.getBoundingClientRect();
        setStyle(() => calculatePosition(dropdownDOM, dropdownMenuDOM));
    }, [show]);
    return (React.createElement("div", { ref: dropdownRef, className: "dropdown", onMouseLeave: () => !initOpen && showOnHover && toggle(false) },
        React.createElement("div", { className: "dropdown-toggle", onClick: () => toggle(true), onMouseEnter: () => !initOpen && showOnHover && toggle(true) }, anchorElem),
        React.createElement(TransitionGroup, { component: null }, show && (React.createElement(CSSTransition, { classNames: "dropdown-menu", timeout: 400 },
            React.createElement("div", { ref: dropdownMenuRef, className: "dropdown-menu", style: style, onMouseLeave: () => !initOpen && showOnHover && toggle(false) }, children))))));
};
export default DropdownMenu;
