import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip } from 'components';
import { useModulePermissions, useTableFilter, useDocumentTitle, usePlanPagination } from 'hooks';
const PlanList = () => {
    const { canCreate, canRead, canUpdate } = useModulePermissions();
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter();
    const { data, isLoading, ...pagination } = usePlanPagination(requestFilterParams);
    const searchFields = useMemo(() => [
        {
            Value: 'Name',
            Description: 'Nome'
        }
    ], []);
    const columns = useMemo(() => [
        {
            Header: 'Nome',
            accessor: 'Name'
        },
        {
            Header: 'Situação',
            accessor: 'Active',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold', value ? 'text-green-light' : 'text-red-light') }, value ? 'ATIVO' : 'INATIVO'))
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" }, (canRead || canUpdate) && (React.createElement(Link, { to: `/cam/plans/${value}` },
                    React.createElement(Tooltip, { id: `plan-${value}-edit`, text: canUpdate ? 'Editar' : 'Visualizar' },
                        React.createElement(Button, { icon: canUpdate ? 'edit' : 'eye', color: "info", rounded: true }))))));
            }
        }
    ], [canRead, canUpdate]);
    useDocumentTitle('Planos');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Planos" }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            canCreate && (React.createElement(Link, { to: "/cam/plans/new" },
                React.createElement(Button, { text: "Novo Plano", icon: "add", color: "info", animationHover: "pop" }))),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Planos", formConfig: { searchFields, filterParams, withFields: true, withSituation: true }, handleFilter: setParams }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhum plano a ser exibido" })));
};
export default PlanList;
