import React, { useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import { useParams } from 'react-router-dom';
import { PageHeader, Loading } from 'components';
import { useDocumentTitle, useRecompositionInequalityQuery } from 'hooks';
import { RecompositionInequalityDetailsCard, RecompositionInequalityResumeCard } from '../components';
const RecompositionInequalityInternal = () => {
    const recompositionInequalityIdRoute = parseInt(useParams()?.recompositionInequalityId ?? '0');
    const [recompositionInequality, isLoading] = useRecompositionInequalityQuery(recompositionInequalityIdRoute);
    const pageTitle = useMemo(() => 'Visualizar RDV', []);
    const breadcrumb = useMemo(() => [
        {
            Title: 'RDV',
            Url: '/cam/recomposition-inequalities'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    if (isLoading) {
        return (React.createElement(React.Fragment, null,
            React.createElement(PageHeader, { title: "RDV", breadcrumb: breadcrumb }),
            React.createElement(Loading, { show: true })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "RDV", breadcrumb: breadcrumb }),
        React.createElement(Row, { gutterWidth: 25 },
            React.createElement(Col, { xl: 6 },
                React.createElement(RecompositionInequalityDetailsCard, { recompositionInequality: recompositionInequality })),
            React.createElement(Col, { xl: 6 },
                React.createElement(RecompositionInequalityResumeCard, { recompositionInequality: recompositionInequality })))));
};
export default RecompositionInequalityInternal;
