import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader, StepperForm } from 'components';
import { useModulePermissionsByScope } from 'hooks';
import { Scope } from 'types/enum';
import { BeneficiaryBenefitTab, BeneficiaryDependentTab, BeneficiaryHistoryTab, BeneficiaryPersonalTab, BeneficiaryPremiumRecipientTab } from '../components';
import { BeneficiaryFormStep } from './BeneficiariesInternal.types';
const BeneficiariesInternal = () => {
    const beneficiaryIdRoute = parseInt(useParams()?.beneficiaryId ?? '0');
    const { canList: canListDependent } = useModulePermissionsByScope(Scope.DEPENDENT);
    const BENEFICIARY_FORM_STEPS = useMemo(() => [
        {
            key: BeneficiaryFormStep.PERSONAL_DATA,
            title: 'Dados',
            description: 'Dados do associado',
            icon: 'id-card',
            content: React.createElement(BeneficiaryPersonalTab, { beneficiaryId: beneficiaryIdRoute })
        },
        {
            key: BeneficiaryFormStep.DEPENDENT,
            title: 'Dependentes',
            description: 'Dependentes cadastrados',
            icon: 'dependent',
            disabled: !beneficiaryIdRoute || !canListDependent,
            content: React.createElement(BeneficiaryDependentTab, { beneficiaryId: beneficiaryIdRoute })
        },
        {
            key: BeneficiaryFormStep.BENEFIT,
            title: 'Benefícios',
            description: 'Benefícios associados',
            icon: 'benefit',
            disabled: !beneficiaryIdRoute,
            content: React.createElement(BeneficiaryBenefitTab, { beneficiaryId: beneficiaryIdRoute })
        },
        {
            key: BeneficiaryFormStep.PREMIUM_RECIPIENT,
            title: 'Premiados',
            description: 'Premiados cadastrados',
            icon: 'money-bag',
            disabled: !beneficiaryIdRoute,
            content: React.createElement(BeneficiaryPremiumRecipientTab, { beneficiaryId: beneficiaryIdRoute })
        },
        {
            key: BeneficiaryFormStep.HISTORY,
            title: 'Histórico de alterações',
            description: 'Histórico de alterações de dados',
            icon: 'money-bag',
            disabled: !beneficiaryIdRoute,
            content: React.createElement(BeneficiaryHistoryTab, { beneficiaryId: beneficiaryIdRoute })
        }
    ], [beneficiaryIdRoute, canListDependent]);
    const pageTitle = useMemo(() => `${beneficiaryIdRoute ? 'Visualizar' : 'Novo'} Associado`, [beneficiaryIdRoute]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Associados',
            Url: '/beneficiaries'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Associados", breadcrumb: breadcrumb }),
        React.createElement(StepperForm, { items: BENEFICIARY_FORM_STEPS })));
};
export default BeneficiariesInternal;
