import { useCallback } from 'react';
import { useAlert } from 'contexts/alert.context';
import { useQueryPagination } from 'hooks/queryPagination';
import { makeHttpService } from 'services/http';
import { BENEFICIARY_PAGINATION_QUERY_KEY } from './useBeneficiaryConfig';
export const useBeneficiaryPagination = (filterParams) => {
    const { errorCallback } = useAlert();
    const prepareParameters = useCallback((pageSize, pageSkip, sort) => {
        return {
            PerPage: pageSize,
            Skip: pageSkip,
            OrderBy: sort?.sortBy || 'CreatedAt',
            OrderDescending: sort?.sortAsc ?? true,
            ...filterParams
        };
    }, [filterParams]);
    const getBeneficiaryCategories = useCallback(async ({ pageSize, pageSkip, sort, signal }) => {
        const params = prepareParameters(pageSize, pageSkip, sort);
        return makeHttpService()
            .get('/beneficiary/by-parameters', {
            params,
            signal
        })
            .catch(error => {
            errorCallback(error, 'BENEFICIARY_GET_BY_PARAMETERS_FAILURE');
            return {};
        });
    }, [errorCallback, prepareParameters]);
    return useQueryPagination([BENEFICIARY_PAGINATION_QUERY_KEY, filterParams], getBeneficiaryCategories);
};
