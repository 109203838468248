import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services';
import { REPORT_MONTHLY_RECEIVABLE_QUERY_KEY } from './useReportConfig';
export const useReportMonthlyReceivableQuery = (filterParams) => {
    const { errorCallback } = useAlert();
    const getBirthdayReport = useCallback(async ({ signal }) => {
        const params = {
            ...filterParams
        };
        return makeHttpService()
            .get('/report/monthly-receivables', {
            params,
            signal
        })
            .catch(error => {
            errorCallback(error, 'REPORT_MONTHLY_RECEIVABLE_FAILURE');
            return {};
        });
    }, [filterParams, errorCallback]);
    const { data, isLoading } = useQuery([REPORT_MONTHLY_RECEIVABLE_QUERY_KEY, filterParams], getBirthdayReport, {
        enabled: !!filterParams?.StartDate,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    const memoizedData = useMemo(() => data ?? {}, [data]);
    return [memoizedData, isLoading];
};
