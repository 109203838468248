import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Row, Col } from 'react-grid-system';
import { Button, FormField, FormFieldError, Form, FormikValidationHandler, useFormikValidationHandler, Select, CurrencyField, MaskedTextField, TextField } from 'components';
import { useFormik } from 'formik';
import { useBeneficiaryList, useBenefitList, useDependentList, useModulePermissions } from 'hooks';
import moment from 'moment-timezone';
import { FormatterUtils } from 'utils';
import { convertToBenefitConsumptionDto } from './BenefitConsumptionForm.converter';
import { BenefitConsumptionFormSchema } from './BenefitConsumptionForm.schema';
const defaultValues = {
    Id: 0,
    BeneficiaryId: null,
    DependentId: null,
    BenefitId: null,
    Date: '',
    Value: 0,
    Observations: ''
};
const BenefitConsumptionForm = ({ benefitConsumption, isLoading, handleSubmit: submitBenefitConsumption }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions(benefitConsumption?.Id);
    const [beneficiaries, isLoadingBeneficiaries] = useBeneficiaryList({ Active: true });
    const [benefits, isLoadingBenefits] = useBenefitList();
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => BenefitConsumptionFormSchema,
        onSubmit: async (benefitConsumptionData) => submitBenefitConsumption(convertToBenefitConsumptionDto(benefitConsumptionData)),
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleSubmit, isSubmitting, setValues, handleChange } = formikContext;
    const [dependents, isLoadingDependents] = useDependentList(values.BeneficiaryId);
    const dependentInputRef = useRef();
    const canEditBenefitConsumption = useMemo(() => {
        if (!canMutate)
            return false;
        if (!benefitConsumption?.Id)
            return true;
        const monthYearReference = moment(benefitConsumption?.MonthYearReference, 'YYYY-MM-DD');
        const now = moment();
        return monthYearReference.isSameOrAfter(now, 'month');
    }, [benefitConsumption, canMutate]);
    const onBeneficiaryChange = useCallback((value) => {
        dependentInputRef.current?.clearValue();
        setFieldValue('BeneficiaryId', value?.Id ?? null);
    }, [dependentInputRef, setFieldValue]);
    const onDependentChange = useCallback((value) => {
        setFieldValue('DependentId', value?.Id ?? null);
    }, [setFieldValue]);
    useEffect(() => {
        if (!benefitConsumption?.Id)
            return;
        const benefitConsumptionData = {
            Id: benefitConsumption.Id,
            BeneficiaryId: benefitConsumption.BeneficiaryId,
            DependentId: benefitConsumption.DependentId,
            BenefitId: benefitConsumption.BenefitId,
            Date: FormatterUtils.date(benefitConsumption.Date),
            Value: benefitConsumption.Value,
            Observations: benefitConsumption.Observations
        };
        setValues(benefitConsumptionData);
    }, [benefitConsumption, setValues]);
    if (isLoading)
        return null;
    return (React.createElement(Form, { name: "benefitConsumptionForm", onSubmit: handleSubmit, disabled: !canEditBenefitConsumption, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "BenefitId", label: "Benef\u00EDcio", options: benefits, value: benefits?.find(benefit => benefit.Id === values.BenefitId), onChange: value => setFieldValue('BenefitId', value.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingBenefits }),
                    React.createElement(FormFieldError, { name: "BenefitId" })))),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "BeneficiaryId", label: "Associado", options: beneficiaries, value: beneficiaries?.find(beneficiary => beneficiary.Id === values.BeneficiaryId), onChange: onBeneficiaryChange, getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingBeneficiaries }),
                    React.createElement(FormFieldError, { name: "BeneficiaryId" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { selectRef: dependentInputRef, name: "DependentId", label: "Dependente", options: dependents, value: dependents?.find(dependent => dependent.Id === values.DependentId), onChange: onDependentChange, getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingDependents, isDisabled: !values.BeneficiaryId })))),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(CurrencyField, { name: "Value", label: "Valor", onChange: value => setFieldValue('Value', value), value: values.Value }),
                    React.createElement(FormFieldError, { name: "Value" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "date", name: "Date", label: "Data", value: values.Date || '', onChange: value => setFieldValue('Date', value) }),
                    React.createElement(FormFieldError, { name: "Date" }))),
            React.createElement(Col, { xs: 12 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Observations", label: "Observa\u00E7\u00F5es", element: "textarea", onChange: handleChange, rows: 5, value: values.Observations || '' }),
                    React.createElement(FormFieldError, { name: "Observations" })))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Enviar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default BenefitConsumptionForm;
