import React from 'react';
import { Col, Row } from 'react-grid-system';
import { DateRangeField, FormField, MaskedTextField, Select, TextField } from 'components';
import { useAwayReasonList } from 'hooks';
const AwayWithoutSolicitationTableFilterForm = ({ values, handleChange, setFieldValue }) => {
    const [awayReasons, isLoadingAwayReasons] = useAwayReasonList();
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "cpf", name: "PersonDocument", label: "CPF do associado", value: values.PersonDocument ?? '', onChange: value => setFieldValue('PersonDocument', value) }))),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "CallName", label: "Nome de guerra do associado", value: values.CallName ?? '', onChange: handleChange })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "AwayReasonId", label: "Motivo", options: awayReasons, value: awayReasons?.find(x => x.Id === values.AwayReasonId), onChange: value => setFieldValue('AwayReasonId', value.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingAwayReasons }))),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(DateRangeField, { startDate: values.DateRange[0], endDate: values.DateRange[1], onDatesChange: ({ startDate, endDate }) => setFieldValue('DateRange', [startDate, endDate]), isOutsideRange: () => false }))))));
};
export default AwayWithoutSolicitationTableFilterForm;
