import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'components';
import { useModulePermissionsByScope, useReportExportQuery } from 'hooks';
import { Scope } from 'types/enum';
const BenefitConsumptionLatamExportModal = ({ filterParams }) => {
    const { canRead } = useModulePermissionsByScope(Scope.BENEFITS_LATAM_REPORT);
    const exportExcelQuery = useReportExportQuery('benefits-for-latam-forward-excel', filterParams);
    const exportCompleteExcelQuery = useReportExportQuery('benefits-for-latam-forward-excel-complete', filterParams);
    const [showModal, setShowModal] = useState(false);
    const toggleModal = useCallback(() => setShowModal(showModal => !showModal), []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { text: "Exportar", icon: "download", color: "info", animationHover: "pop", onClick: toggleModal }),
        React.createElement(Modal, { show: showModal, handleClose: toggleModal, title: "Exportar", closable: true },
            React.createElement("h3", { className: "text-base text-gray-800 dark:text-gray-50 text-center mb-4" }, "Escolha o modelo de relat\u00F3rio:"),
            React.createElement("div", { className: "flex flex-col gap-4 items-center" },
                React.createElement("a", { href: exportExcelQuery, target: "_blank", rel: "noreferrer", className: "max-w-sm w-full" },
                    React.createElement(Button, { text: "Exportar Relat\u00F3rio", icon: "download", color: "info", variant: "outlined", animationHover: "pop", className: "w-full", disabled: !canRead })),
                React.createElement("a", { href: exportCompleteExcelQuery, target: "_blank", rel: "noreferrer", className: "max-w-sm w-full" },
                    React.createElement(Button, { text: "Exportar Relat\u00F3rio Completo", icon: "download", color: "info", variant: "outlined", animationHover: "pop", className: "w-full", disabled: !canRead }))))));
};
export default BenefitConsumptionLatamExportModal;
