import React from 'react';
import SimpleReactLightbox from 'simple-react-lightbox';
import { AlertProvider } from './alert.context';
import { AuthProvider } from './auth.context';
import { ModuleProvider } from './module.context';
import { ThemeProvider } from './theme.context';
export const AppProvider = ({ children }) => (React.createElement(AlertProvider, null,
    React.createElement(ThemeProvider, null,
        React.createElement(AuthProvider, null,
            React.createElement(ModuleProvider, null,
                React.createElement(SimpleReactLightbox, null, children))))));
