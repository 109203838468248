import { FormatterUtils } from 'utils';
export const convertToBenefitConsumptionDto = (benefitConsumptionValues) => {
    return {
        Id: benefitConsumptionValues.Id,
        BenefitId: benefitConsumptionValues.BenefitId,
        DependentId: benefitConsumptionValues.DependentId,
        BeneficiaryId: benefitConsumptionValues.BeneficiaryId,
        Date: FormatterUtils.momentDate(benefitConsumptionValues.Date).format(),
        Value: benefitConsumptionValues.Value,
        Observations: benefitConsumptionValues.Observations
    };
};
