import React from 'react';
import { Col, Row } from 'react-grid-system';
import { FormField, MaskedTextField, TextField } from 'components';
const RecompositionInequalityTableFilterForm = ({ values, setFieldValue, handleChange }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "PersonCallName", label: "Nome Guerra", value: values.PersonCallName ?? '', onChange: handleChange }))),
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "cpf", name: "PersonDocument", label: "CPF do associado", value: values.PersonDocument ?? '', onChange: value => setFieldValue('PersonDocument', value) }))),
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "SolicitationCode", label: "C\u00F3digo da solicita\u00E7\u00E3o", value: values.SolicitationCode ?? '', onChange: handleChange }))))));
};
export default RecompositionInequalityTableFilterForm;
