import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { Icon, Tooltip } from 'components';
import moment from 'moment-timezone';
import { FormatterUtils } from 'utils';
import './PaymentFlowInstallment.scss';
const PaymentFlowInstallment = ({ paymentFlowInstallment, onSelectInstallment: handleSelectInstallment }) => {
    const totalValue = useMemo(() => paymentFlowInstallment.WorkAccident
        ? paymentFlowInstallment.TotalValueAfterAccidentPercentage
        : paymentFlowInstallment.TotalValueAfterMonthlyPercentage, [paymentFlowInstallment]);
    const formattedMonth = useMemo(() => moment(paymentFlowInstallment.MonthYear).format('MMM/YY'), [
        paymentFlowInstallment
    ]);
    const formattedTotalValue = useMemo(() => (totalValue > 0 ? FormatterUtils.money(totalValue) : 'Valor indisponível'), [totalValue]);
    const onSelectInstallment = useCallback(() => handleSelectInstallment(paymentFlowInstallment), [
        handleSelectInstallment,
        paymentFlowInstallment
    ]);
    return (React.createElement("div", { className: "payment-flow-installment" },
        React.createElement("label", { className: "block text-xs text-gray-light dark:text-gray-400 mb-2" },
            "Parcela ",
            formattedMonth),
        React.createElement("div", { className: "payment-flow-installment-value" },
            paymentFlowInstallment.CancelledBeforePayment ? (React.createElement("div", { className: "flex items-center" },
                React.createElement("span", { className: "text-xs text-gray-light line-through dark:text-gray-400 mr-1" }, FormatterUtils.money(totalValue)),
                React.createElement("span", { className: "text-sm font-bold text-gray-light dark:text-gray-400" }, FormatterUtils.money(0)))) : (React.createElement("p", { className: clsx(paymentFlowInstallment.TotalValueAfterMonthlyPercentage && 'has-value') }, formattedTotalValue)),
            React.createElement("button", { onClick: onSelectInstallment },
                React.createElement(Tooltip, { id: `payment-flow-installment-${paymentFlowInstallment.Id.toString()}`, text: "Detalhes" },
                    React.createElement(Icon, { name: "warning", size: 14 }))))));
};
export default PaymentFlowInstallment;
