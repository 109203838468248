import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { DateRangeField, FormField, MaskedTextField, Select, SituationField, TextField } from 'components';
import { FinancialStatementPaymentMethodEnum, FinancialStatementPaymentMethodLabel, FinancialStatementPaymentStatusEnum, FinancialStatementPaymentStatusLabel } from 'types/enum/financial/FinancialStatementPaymentEnum';
const FinancialStatementsFilterForm = ({ values, setFieldValue, handleChange }) => {
    const [paymentMethod, setPaymentMethod] = useState(null);
    const onPaymentMethodChange = useCallback((value) => {
        const selectedValue = value;
        setPaymentMethod(selectedValue.value);
        setFieldValue('PaymentMethod', selectedValue.value);
    }, [setFieldValue]);
    const solicitationPaymentMethodOptions = useMemo(() => [
        {
            label: FinancialStatementPaymentMethodLabel[FinancialStatementPaymentMethodEnum.PAYMENT_SLIP],
            value: FinancialStatementPaymentMethodEnum.PAYMENT_SLIP
        },
        {
            label: FinancialStatementPaymentMethodLabel[FinancialStatementPaymentMethodEnum.DEPOSIT_ATL_ACCOUNT],
            value: FinancialStatementPaymentMethodEnum.DEPOSIT_ATL_ACCOUNT
        }
    ], []);
    const solicitationPaymentStatusOptions = useMemo(() => [
        {
            name: FinancialStatementPaymentStatusLabel[FinancialStatementPaymentStatusEnum.OPEN],
            value: FinancialStatementPaymentStatusEnum.OPEN,
            color: 'info',
            textColor: 'white'
        },
        {
            name: FinancialStatementPaymentStatusLabel[FinancialStatementPaymentStatusEnum.PAID],
            value: FinancialStatementPaymentStatusEnum.PAID,
            color: 'info',
            textColor: 'white'
        }
    ], []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 3 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "RegistrationCode", label: "C\u00F3digo de registro", value: values.RegistrationCode ?? '', onChange: handleChange }))),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(DateRangeField, { startDate: values.DateRange[0], endDate: values.DateRange[1], onDatesChange: ({ startDate, endDate }) => setFieldValue('DateRange', [startDate, endDate]), isOutsideRange: () => false })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "cpf", name: "PersonDocument", label: "CPF do associado", value: values.PersonDocument ?? '', onChange: value => setFieldValue('PersonDocument', value) }))),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "CallName", label: "Nome de guerra do associado", value: values.CallName ?? '', onChange: handleChange })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "PaymentMethod", label: "M\u00E9todo de pagamento", options: solicitationPaymentMethodOptions, value: solicitationPaymentMethodOptions?.find(x => x.value === paymentMethod), onChange: onPaymentMethodChange, getOptionLabel: option => option.label, getOptionValue: option => option.label })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Status do Pagamento", handleChange: value => setFieldValue('PaymentStatus', value), value: values.PaymentStatus, options: solicitationPaymentStatusOptions }))))));
};
export default FinancialStatementsFilterForm;
