import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Loading, Card } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useDocumentTitle, useMonthlyBasePercentageQuery, useMonthlyBasePercentageMutation } from 'hooks';
import moment from 'moment-timezone';
import { MonthlyBasePercentageForm } from '../components';
const MonthlyBasePercentageInternal = () => {
    const monthlyPercentageIdRoute = parseInt(useParams()?.monthlyPercentageId ?? '0');
    const { success, errorCallback } = useAlert();
    const navigate = useNavigate();
    const [monthlyBasePercentage, isLoading] = useMonthlyBasePercentageQuery(monthlyPercentageIdRoute);
    const { create, update } = useMonthlyBasePercentageMutation(monthlyPercentageIdRoute);
    async function submitMonthlyBasePercentage(monthlyBasePercentageData) {
        const monthlyBasePercentageDataSubmit = { ...monthlyBasePercentageData };
        monthlyBasePercentageDataSubmit.CreatedAt = moment().format();
        monthlyBasePercentageDataSubmit.UpdatedAt = null;
        if (monthlyBasePercentageData.Id) {
            await update(monthlyBasePercentageDataSubmit, {
                onSuccess: () => success({
                    title: 'Porcentagem mensal atualizada!',
                    message: 'Dados da porcentagem mensal atualizados com sucesso.',
                    id: 'MONTHLY_BASE_PERCENTAGE_PUT_SUCCESS'
                }),
                onError: err => errorCallback(err, 'MONTHLY_BASE_PERCENTAGE_PUT_FAILURE')
            });
        }
        else {
            await create(monthlyBasePercentageDataSubmit, {
                onSuccess: res => {
                    success({
                        title: 'Porcentagem mensal cadastrada!',
                        message: 'Porcentagem mensal cadastrada com sucesso.',
                        id: 'MONTHLY_BASE_PERCENTAGE_POST_SUCCESS'
                    });
                    navigate(`/operational/monthly-percentages/${res.Id}`);
                },
                onError: err => errorCallback(err, 'MONTHLY_BASE_PERCENTAGE_POST_FAILURE')
            });
        }
    }
    const pageTitle = useMemo(() => `${monthlyPercentageIdRoute ? 'Visualizar' : 'Nova'} Porcentagem Mensal`, [
        monthlyPercentageIdRoute
    ]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Porcentagens Mensais',
            Url: '/operational/monthly-percentages'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Porcentagens Mensais", breadcrumb: breadcrumb }),
        React.createElement(Loading, { show: isLoading }),
        React.createElement(Card, { id: "monthly-percentage-card" },
            React.createElement(MonthlyBasePercentageForm, { monthlyBasePercentage: monthlyBasePercentage, isLoading: isLoading, handleSubmit: submitMonthlyBasePercentage }))));
};
export default MonthlyBasePercentageInternal;
