import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { BENEFICIARY_LIST_QUERY_KEY, BENEFICIARY_PAGINATION_QUERY_KEY, BENEFICIARY_QUERY_KEY } from './useBeneficiaryConfig';
export const useBeneficiaryMutation = (beneficiaryId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createBeneficiary = useCallback(async (beneficiaryData) => {
        return httpService.post('/beneficiary', beneficiaryData);
    }, [httpService]);
    const updateBeneficiary = useCallback(async (beneficiaryData) => {
        return httpService.put('/beneficiary', beneficiaryData);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createBeneficiary, {
        onSuccess: () => {
            queryClient.invalidateQueries(BENEFICIARY_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries(BENEFICIARY_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateBeneficiary, {
        onSuccess: () => {
            queryClient.invalidateQueries(BENEFICIARY_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries([BENEFICIARY_QUERY_KEY, beneficiaryId]);
            queryClient.invalidateQueries(BENEFICIARY_LIST_QUERY_KEY);
        }
    });
    return { create, update, isCreating, isUpdating };
};
