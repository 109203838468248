import React from 'react';
import { UsersInternal, UsersList } from './screens';
const UsersModule = [
    {
        path: '/users',
        element: React.createElement(UsersList, null)
    },
    {
        path: '/users/new',
        element: React.createElement(UsersInternal, null)
    },
    {
        path: '/users/:userId',
        element: React.createElement(UsersInternal, null)
    }
];
export { UsersModule };
