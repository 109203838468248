import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loading, PageHeader, Select } from 'components';
import { useDocumentTitle, useSolicitationQuery } from 'hooks';
import moment from 'moment-timezone';
import { TextUtils } from 'utils';
import { MonthlyReceiptContent } from './components';
import './MonthlyReceiptReport.scss';
const MonthlyReceiptReport = () => {
    const solicitationIdRoute = parseInt(useParams()?.solicitationId ?? '0');
    const [solicitation, isLoading] = useSolicitationQuery(solicitationIdRoute);
    const [month, setMonth] = useState(null);
    const solicitationPaymentFlow = useMemo(() => solicitation?.SolicitationPaymentFlowHistory?.length
        ? solicitation?.SolicitationPaymentFlowHistory[0]
        : {}, [solicitation?.SolicitationPaymentFlowHistory]);
    const selectedPaymentFlowInstallment = useMemo(() => {
        return solicitationPaymentFlow?.SolicitationPaymentFlowInstallments?.find(x => {
            const monthYearMoment = moment(x.MonthYear);
            return parseInt(monthYearMoment.format('M')) === month;
        });
    }, [solicitationPaymentFlow, month]);
    const monthOptions = useMemo(() => {
        return solicitationPaymentFlow?.SolicitationPaymentFlowInstallments?.map(x => {
            const monthYearMoment = moment(x.MonthYear);
            return {
                label: TextUtils.capitalizeFirstLetter(monthYearMoment.format('MMMM [de] YYYY')),
                value: parseInt(monthYearMoment.format('M'))
            };
        });
    }, [solicitationPaymentFlow]);
    const hasMonthSelected = Boolean(month && selectedPaymentFlowInstallment?.Id);
    const pageTitle = useMemo(() => `Recibo Mensal do Requerimento #${solicitationIdRoute}`, [solicitationIdRoute]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Requerimentos',
            Url: '/cam/solicitations'
        },
        {
            Title: `#${solicitationIdRoute}`,
            Url: `/cam/solicitations/${solicitationIdRoute}`
        },
        {
            Title: 'Recibo Mensal'
        }
    ], [solicitationIdRoute]);
    const RightContent = useMemo(() => (React.createElement("div", { className: "receipet-month-selector" },
        React.createElement(Select, { name: "Month", label: "Escolha o m\u00EAs", options: monthOptions, value: monthOptions?.find((x) => x.value === month), onChange: option => setMonth(option.value), getOptionLabel: option => option.label, getOptionValue: option => option.label.toString() }))), [monthOptions, month]);
    useDocumentTitle(pageTitle);
    if (isLoading || !solicitation?.Id) {
        return (React.createElement(React.Fragment, null,
            React.createElement(PageHeader, { title: pageTitle, breadcrumb: breadcrumb }),
            React.createElement(Loading, { show: true })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: pageTitle, breadcrumb: breadcrumb, rightContent: RightContent }),
        hasMonthSelected ? (React.createElement(MonthlyReceiptContent, { solicitation: solicitation, solicitationPaymentFlowInstallment: selectedPaymentFlowInstallment })) : (React.createElement("p", { className: "text-center text-gray-light dark:text-gray-400 text-xs" }, "Selecione um m\u00EAs para gerar o recibo."))));
};
export default MonthlyReceiptReport;
