import React from 'react';
import { DeseaseInternal, DeseaseList } from './screens';
const DeseaseModule = [
    {
        path: '/cam/deseases',
        element: React.createElement(DeseaseList, null)
    },
    {
        path: '/cam/deseases/new',
        element: React.createElement(DeseaseInternal, null)
    },
    {
        path: '/cam/deseases/:deseaseId',
        element: React.createElement(DeseaseInternal, null)
    }
];
export { DeseaseModule };
