import React, { createContext, useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMeQuery, useQueryString } from 'hooks';
import { authenticationService } from 'services/authentication';
import { makeHttpService } from 'services/http';
import { UserTypeEnum } from 'types/dtos';
import { ApiException, StatusNumber } from 'types/error';
import { ModuleUtils } from 'utils';
import { useAlert } from './alert.context';
import { useTheme } from './theme.context';
const AuthContext = createContext({});
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [modules, setModules] = useState([]);
    const [isLogged, setIsLogged] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [hasSession, setHasSession] = useState(false);
    const { errorCallback } = useAlert();
    const { darkMode } = useTheme();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const query = useQueryString();
    const logout = useCallback(async () => {
        await authenticationService.logout();
        setHasSession(false);
        setIsLogged(false);
        setModules([]);
        setUser({});
        navigate('/login');
    }, [navigate]);
    const login = useCallback((authenticationRequestData) => {
        return authenticationService
            .login(authenticationRequestData)
            .then(response => {
            authenticationService.setToken({
                token: response.Token,
                refreshToken: response.RefreshToken,
                tokenExpiration: new Date(response.ExpiresAt)
            });
            setHasSession(true);
            darkMode && document.body.classList.add('darker');
        })
            .catch(error => {
            errorCallback(error, 'LOGIN_FAILED');
            logout();
        });
    }, [errorCallback, darkMode, logout]);
    const prepareUser = useCallback((userData) => {
        const user = {
            ...userData,
            FirstName: userData.Name.split(' ')[0],
            IsAdministrator: userData.UserTypeId === UserTypeEnum.Administrator,
            IsUser: userData.UserTypeId === UserTypeEnum.User
        };
        return user;
    }, []);
    const getModules = useCallback(() => {
        return new Promise(resolve => {
            makeHttpService()
                .get('/module')
                .then((response) => {
                let modules = [...response];
                // Set module permissions, url and icon
                modules = modules.map(module => {
                    return {
                        ...module,
                        Url: ModuleUtils.getModuleUrl(module.Id),
                        Icon: ModuleUtils.getModuleIcon(module.Id)
                    };
                });
                // Set parent modules children
                let parentModules = modules.filter(module => module.ParentModuleId === 0);
                parentModules.forEach(parentModule => {
                    let children = modules.filter(module => module.ParentModuleId === parentModule.Id);
                    children = children.sort((a, b) => a.Name.localeCompare(b.Name));
                    parentModule.Children = children;
                });
                parentModules = parentModules.sort((a, b) => a.Name.localeCompare(b.Name));
                parentModules.unshift({
                    Id: 0,
                    ParentModuleId: null,
                    Name: 'Dashboard',
                    ScopePrefix: '',
                    Url: ModuleUtils.getModuleUrl(0),
                    Icon: ModuleUtils.getModuleIcon(0),
                    Order: 1,
                    Active: true
                });
                resolve(parentModules);
            })
                .catch(() => new ApiException(StatusNumber.BAD_REQUEST, 'Erro ao listar módulos, tente novamente'));
        });
    }, []);
    const shouldFetchMeData = hasSession && !isLogged;
    const [userMe] = useMeQuery(shouldFetchMeData);
    const onGetUserData = useCallback(async () => {
        if (!userMe?.Id)
            return;
        try {
            const user = prepareUser(userMe);
            const modules = await getModules();
            setUser(user);
            setModules(modules);
            setIsLogged(true);
            setIsLoading(false);
        }
        catch (error) {
            errorCallback(error, 'LOAD_USER_FAILURE');
        }
    }, [userMe, prepareUser, getModules, errorCallback]);
    const onUserDataPrepared = useCallback(async () => {
        if (!isLogged)
            return;
        const isOnLoginPage = pathname === '/login';
        if (!isOnLoginPage) {
            return;
        }
        const redirectTo = query.get('returnUrl') ?? '/dashboard';
        navigate(redirectTo);
    }, [isLogged, navigate, query, pathname]);
    const initAuthentication = useCallback(async () => {
        try {
            await authenticationService.load();
        }
        catch (error) {
            await authenticationService.logout();
        }
        finally {
            const hasSession = !authenticationService.isTokenExpired();
            setHasSession(hasSession);
            if (!hasSession)
                setIsLoading(false);
        }
    }, []);
    useEffect(() => {
        onGetUserData();
    }, [onGetUserData]);
    useEffect(() => {
        onUserDataPrepared();
    }, [onUserDataPrepared]);
    useEffect(() => {
        initAuthentication();
    }, [initAuthentication]);
    const authValues = useMemo(() => ({
        user,
        modules,
        hasSession,
        isLogged,
        isLoading
    }), [user, modules, hasSession, isLogged, isLoading]);
    return React.createElement(AuthContext.Provider, { value: { ...authValues, login, logout } }, children);
};
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined)
        throw new Error('useAuth must be used within an AuthProvider.');
    return context;
};
