import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { RECOMPOSITION_INEQUALITY_QUERY_KEY } from './useRecompositionInequalityConfig';
export const useRecompositionInequalityMutation = () => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createRecompositionInequality = useCallback(async (solicitationId) => {
        return httpService.post(`/recomposition-inequality/solicitation/${solicitationId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createRecompositionInequality, {
        onSuccess: () => {
            queryClient.invalidateQueries(RECOMPOSITION_INEQUALITY_QUERY_KEY);
        }
    });
    return {
        create,
        isCreating
    };
};
