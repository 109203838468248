import React from 'react';
import { Col, Row } from 'react-grid-system';
import { FormField, Select } from 'components';
import { useBenefitCategoryList } from 'hooks';
const BenefitTableFilterForm = ({ values, setFieldValue }) => {
    const [benefitCategories, isLoadingBenefitCategories] = useBenefitCategoryList();
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 12 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "BenefitCategoryId", label: "Categoria", options: benefitCategories, value: benefitCategories?.find(x => x.Id === values.BenefitCategoryId), onChange: value => setFieldValue('BenefitCategoryId', value.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingBenefitCategories }))))));
};
export default BenefitTableFilterForm;
