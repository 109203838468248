import React, { useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import { DateRangeField, FormField, Select, TextField } from 'components';
import { useBenefitList } from 'hooks';
import { BenefitConsumptionStatusLabel } from 'types/enum';
const BenefitConsumptionBatchTableFilterForm = ({ values, setFieldValue, handleChange }) => {
    const [benefits, isLoadingBenefits] = useBenefitList();
    const situationFieldOptions = useMemo(() => {
        return Object.keys(BenefitConsumptionStatusLabel).map(status => ({
            label: BenefitConsumptionStatusLabel[status],
            value: status
        }));
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "BenefitId", label: "Benef\u00EDcio", options: benefits, value: benefits?.find(x => x.Id === values.BenefitId), onChange: value => setFieldValue('BenefitId', value.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingBenefits }))),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "FileName", label: "Nome do Arquivo", value: values.FileName ?? '', onChange: handleChange }))),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "StatusId", label: "Status", options: situationFieldOptions, value: situationFieldOptions?.filter(option => values.Statuses?.some(x => option.value === x?.value)), onChange: value => setFieldValue('Statuses', value), getOptionLabel: option => option.label, getOptionValue: option => option.value, isMulti: true }))),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(DateRangeField, { startDate: values.DateRange[0], endDate: values.DateRange[1], onDatesChange: ({ startDate, endDate }) => setFieldValue('DateRange', [startDate, endDate]), isOutsideRange: () => false }))))));
};
export default BenefitConsumptionBatchTableFilterForm;
