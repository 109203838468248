import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, PageHeader, Tooltip, TableFilter } from 'components';
import { useModulePermissions, useDocumentTitle, useReportBirthdayQuery, useTableFilter, useReportExportQuery } from 'hooks';
import moment from 'moment-timezone';
import { FormatterUtils } from 'utils';
import { BirthdayReportTableFilterForm } from './components';
const BirthdayReport = () => {
    const { canRead } = useModulePermissions();
    const customParamsHandle = useCallback((filterParams) => {
        const params = {};
        const monthFieldValue = filterParams.MonthYear;
        if (monthFieldValue) {
            const monthYearValue = new Date(monthFieldValue.year, monthFieldValue.month - 1, 1);
            monthYearValue.setHours(0, 0, 0);
            params.SearchField = 'MonthYear';
            params.StartDate = monthYearValue.toLocaleDateString('en-CA');
        }
        if (filterParams.PersonDocument) {
            params.PersonDocument = filterParams.PersonDocument;
        }
        if (filterParams.AssociateCode) {
            params.SearchField = 'AssociateCode';
            params.SearchQuery = filterParams.AssociateCode;
        }
        if (filterParams.CallName) {
            params.SearchField = 'CallName';
            params.SearchQuery = filterParams.CallName;
        }
        return params;
    }, []);
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter({
        customParamsHandle
    });
    const { data, isLoading, isFirstCall, ...pagination } = useReportBirthdayQuery(requestFilterParams);
    const exportExcelQuery = useReportExportQuery('birthdays-excel', requestFilterParams);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Relatórios'
        },
        {
            Title: 'Relatório de Aniversariantes'
        }
    ], []);
    const columns = useMemo(() => [
        {
            Header: 'Nome',
            accessor: 'Name',
            Cell: ({ cell: { value }, row: { original: values } }) => (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "flex items-center font-semibold text-gray-light dark:text-gray-50" }, value),
                React.createElement("small", { className: "block text-xs text-gray-light dark:text-gray-400" }, values.DependentId ? 'Dependente' : 'Associado')))
        },
        {
            Header: 'Idade',
            accessor: 'Birthday',
            id: 'Age',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                const now = moment();
                const birthday = moment(value);
                const age = now.diff(birthday, 'years');
                return age;
            }
        },
        {
            Header: 'Data Nascimento',
            accessor: 'Birthday',
            Cell: ({ cell: { value } }) => FormatterUtils.date(value, 'DD/MM/YYYY')
        },
        {
            Header: 'Situação',
            accessor: 'Active',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold', value ? 'text-green-light' : 'text-red-light') }, value ? 'ATIVO' : 'INATIVO'))
        },
        {
            Header: 'Ações',
            accessor: 'BeneficiaryId',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" }, canRead && (React.createElement(Link, { to: `/beneficiaries/${value}` },
                    React.createElement(Tooltip, { id: `beneficiaries-${value}-edit`, text: "Visualizar" },
                        React.createElement(Button, { icon: "eye", color: "info", rounded: true }))))));
            }
        }
    ], [canRead]);
    useDocumentTitle('Relatório de Aniversariantes');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Relat\u00F3rio de Aniversariantes", breadcrumb: breadcrumb }),
        React.createElement("div", { className: clsx('flex justify-between mb-4') },
            isFirstCall ? (React.createElement(Button, { text: "Exportar", icon: "download", color: "info", animationHover: "pop", disabled: true })) : (React.createElement("a", { href: exportExcelQuery, target: "_blank", rel: "noreferrer" },
                React.createElement(Button, { text: "Exportar", icon: "download", color: "info", animationHover: "pop" }))),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Relat\u00F3rio", formConfig: { filterParams }, handleFilter: setParams, customFilters: BirthdayReportTableFilterForm }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, isFirstCall: isFirstCall, ...pagination, noResultsMessage: "Nenhum aniversariante a ser exibido" })));
};
export default BirthdayReport;
