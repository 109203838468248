import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { BENEFICIARY_RECOMMENDATION_QUERY_KEY } from './useBeneficiaryRecommendationConfig';
export const useBeneficiaryRecommendationQuery = (beneficiaryId) => {
    const { errorCallback } = useAlert();
    const getBeneficiaryRecommendation = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/beneficiary-recommendation/by-beneficiary/${beneficiaryId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'BENEFICIARY_RECOMMENDATION_GET_FAILURE');
            throw error;
        });
    }, [errorCallback, beneficiaryId]);
    const { data, isLoading } = useQuery([BENEFICIARY_RECOMMENDATION_QUERY_KEY, beneficiaryId], getBeneficiaryRecommendation, {
        enabled: !!beneficiaryId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
