import React, { useCallback, useState } from 'react';
import { Icon, Modal, ScrollView } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useModulePermissionsByScope, useSolicitationNoteList, useSolicitationNoteMutation } from 'hooks';
import { Scope } from 'types/enum';
import { SolicitationCard } from '../Card';
import { SolicitationNoteForm } from './NoteForm';
import { SolicitationNoteItemList } from './NoteItemList';
const SolicitationNoteCard = ({ solicitationId, isDisabled }) => {
    const { canList, canCreate } = useModulePermissionsByScope(Scope.SOLICITATION_NOTE);
    const [notes, isLoading] = useSolicitationNoteList(solicitationId, canList);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const toggleNoteModalModal = useCallback(() => setShowNoteModal(showNoteModal => !showNoteModal), []);
    const { create, isCreating, remove, isRemoving } = useSolicitationNoteMutation(solicitationId);
    const { confirm, success, errorCallback } = useAlert();
    const onSubmitNote = useCallback(async (note) => {
        const solicitationNoteData = {
            Note: note,
            SolicitationId: solicitationId
        };
        create(solicitationNoteData, {
            onSuccess: () => {
                success({ title: 'Nota salva!', message: 'Nota salva com sucesso.', id: 'SOLICITATION_NOTE_POST_SUCCESS' });
                toggleNoteModalModal();
            },
            onError: err => errorCallback(err, 'SOLICITATION_NOTE_POST_FAILURE')
        });
    }, [create, solicitationId, success, errorCallback, toggleNoteModalModal]);
    const deleteNote = useCallback(async (noteId) => {
        return remove(noteId, {
            onSuccess: () => success({
                title: 'Nota deletada!',
                message: 'Nota deletada com sucesso.',
                id: 'SOLICITATION_NOTE_DELETE_SUCCESS'
            }),
            onError: err => errorCallback(err, 'SOLICITATION_NOTE_DELETE_FAILURE')
        });
    }, [remove, success, errorCallback]);
    const onRemoveNote = useCallback((note) => {
        confirm({
            title: 'Você tem certeza?',
            description: (React.createElement("p", null,
                "A nota ser\u00E1 deletada. ",
                React.createElement("br", null),
                " Essa a\u00E7\u00E3o \u00E9 irrevers\u00EDvel!")),
            confirmText: 'Sim, deletar',
            cancelText: 'Não, cancelar',
            onConfirm: () => deleteNote(note.Id)
        });
    }, [confirm, deleteNote]);
    if (isLoading || isRemoving) {
        return (React.createElement(SolicitationCard, { title: "Notas", className: "h-full", buttonActionLabel: "Nova nota", buttonAction: toggleNoteModalModal, showButtonAction: !isDisabled && canCreate },
            React.createElement(Icon, { name: "spinner", size: 35, className: "text-gray-light dark:text-gray-400" })));
    }
    if (!canList) {
        return (React.createElement(SolicitationCard, { title: "Notas", className: "h-full", buttonActionLabel: "Nova nota", buttonAction: toggleNoteModalModal, showButtonAction: !isDisabled && canCreate },
            React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400 mt-2" }, "Voc\u00EA n\u00E3o tem permiss\u00E3o para ver esse conte\u00FAdo.")));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(SolicitationCard, { title: "Notas", className: "h-full", buttonActionLabel: "Nova nota", buttonAction: toggleNoteModalModal, showButtonAction: !isDisabled && canCreate },
            React.createElement("div", { className: "solicitation-note-card-content-wrapper mt-2" }, notes?.length ? (React.createElement(ScrollView, { maxHeight: 300 },
                React.createElement(SolicitationNoteItemList, { notes: notes, onRemoveNote: onRemoveNote, isDisabled: isDisabled }))) : (React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400" }, "Nenhuma nota encontrada.")))),
        React.createElement(Modal, { show: showNoteModal, handleClose: toggleNoteModalModal, title: "Adicionar nova nota", closable: true },
            React.createElement(SolicitationNoteForm, { onSubmitNote: onSubmitNote, isLoading: isCreating }))));
};
export default SolicitationNoteCard;
