import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Button } from 'components/Button';
const BASE_ELEMENT_CLASS = 'stepper-form-header-';
const StepperFormHeader = ({ item, active, onPressItem }) => {
    const onPress = useCallback(() => !item.disabled && onPressItem(item.key), [onPressItem, item]);
    return (React.createElement("li", { className: clsx(BASE_ELEMENT_CLASS + 'list-item', active && 'active', item.disabled && 'disabled'), onClick: onPress },
        React.createElement("div", { className: clsx(BASE_ELEMENT_CLASS + 'list-item-text') },
            React.createElement("h3", null, item.title),
            React.createElement("p", null, item.description)),
        React.createElement(Button, { size: "large", icon: item.icon, disabled: item.disabled, color: "info", animationHover: "pop", rounded: true })));
};
export default StepperFormHeader;
