import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TextField, Form, ButtonAnimated, FormField, FormFieldError, useFormikValidationHandler, FormikValidationHandler } from 'components';
import { useFormik } from 'formik';
import { RecoverPasswordSchema } from './RecoverPasswordForm.schema';
const defaultValues = {
    Password: '',
    ConfirmPassword: ''
};
const RecoverPasswordForm = ({ onSubmit }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const [showPassword, togglePassword] = useState(false);
    const [showConfirmPassword, toggleConfirmPassword] = useState(false);
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => RecoverPasswordSchema,
        onSubmit: async (data) => {
            return onSubmit(data);
        },
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, handleChange, handleSubmit, isSubmitting } = formikContext;
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: "auth-description" }, "Informe sua nova senha:"),
        React.createElement(Form, { name: "RecoverPasswordForm", onSubmit: handleSubmit, disabled: false, formikContext: formikContext, className: "w-100" },
            React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
            React.createElement(FormField, null,
                React.createElement(TextField, { name: "Password", label: "Nova senha", value: values.Password, onChange: handleChange, variant: "outlined", type: showPassword ? 'text' : 'password', rightIcon: showPassword ? 'eye-crossed' : 'eye', rightAction: () => togglePassword(curShowPassword => !curShowPassword) }),
                React.createElement(FormFieldError, { name: "Password" })),
            React.createElement(FormField, null,
                React.createElement(TextField, { name: "ConfirmPassword", label: "Confirme a senha", value: values.ConfirmPassword, onChange: handleChange, variant: "outlined", type: showConfirmPassword ? 'text' : 'password', rightIcon: showConfirmPassword ? 'eye-crossed' : 'eye', rightAction: () => toggleConfirmPassword(curShowConfirmPassword => !curShowConfirmPassword) }),
                React.createElement(FormFieldError, { name: "ConfirmPassword" })),
            React.createElement("div", { className: "flex flex-col items-center mt-8" },
                React.createElement(ButtonAnimated, { loading: isSubmitting, text: "Alterar senha" }),
                React.createElement(Link, { to: "/login", className: "mt-2" },
                    React.createElement("span", { className: "text-xs inline-block" }, "Voltar para login"))))));
};
export default RecoverPasswordForm;
