import React, { useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { TextField, Button, FormField, FormFieldError, Form, SituationField, useFormikValidationHandler, FormikValidationHandler } from 'components';
import { useFormik } from 'formik';
import { useModulePermissions } from 'hooks';
import { FormatterUtils } from 'utils';
import { AircraftModelFormSchema } from './AircraftModelForm.schema';
const defaultValues = {
    Id: 0,
    Model: '',
    Aircraft: '',
    Prefix: '',
    SerialNumber: '',
    CreatedAt: '',
    UpdatedAt: '',
    Active: true
};
const AircraftModelForm = ({ aircraftModel, isLoading, ...props }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions(aircraftModel?.Id);
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => AircraftModelFormSchema,
        onSubmit: async (aircraftModelData) => {
            return props.handleSubmit && props.handleSubmit(aircraftModelData);
        },
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleChange, handleSubmit, isSubmitting, setValues } = formikContext;
    useEffect(() => {
        if (!aircraftModel?.Id)
            return;
        const aircraftModelData = {
            Id: aircraftModel.Id,
            Model: aircraftModel.Model,
            Aircraft: aircraftModel.Aircraft,
            Prefix: aircraftModel.Prefix,
            SerialNumber: aircraftModel.SerialNumber,
            Active: aircraftModel.Active ?? true,
            CreatedAt: FormatterUtils.dateTime(aircraftModel.CreatedAt),
            UpdatedAt: FormatterUtils.dateTime(aircraftModel.UpdatedAt)
        };
        setValues(aircraftModelData);
    }, [aircraftModel, setValues]);
    if (isLoading)
        return null;
    return (React.createElement(Form, { name: "aircraftModelForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Model", label: "Modelo", value: values.Model, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Model" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Aircraft", label: "Aeronave", value: values.Aircraft, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Aircraft" })))),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Prefix", label: "Prefixo", value: values.Prefix, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Prefix" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "SerialNumber", label: "N\u00FAmero de S\u00E9rie", value: values.SerialNumber, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "SerialNumber" })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Situa\u00E7\u00E3o do modelo", handleChange: isActive => setFieldValue('Active', isActive), value: values.Active, falseLabel: "Inativo", trueLabel: "Ativo" })))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Enviar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default AircraftModelForm;
