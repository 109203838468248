import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { ArrayUtils } from 'utils';
import { SOLICITATION_REVISION_LIST_KEY } from './useSolicitationRevisionConfig';
export const useSolicitationRevisionList = (solicitationId) => {
    const { errorCallback } = useAlert();
    const getSolicitationRevisions = useCallback(async ({ signal }) => {
        const params = {
            SearchField: 'SolicitationId',
            SearchQuery: solicitationId.toString()
        };
        return makeHttpService()
            .get('/solicitation-revision/by-parameters', {
            params,
            signal
        })
            .then(x => x.Registers)
            .catch(error => {
            errorCallback(error, 'SOLICITATION_REVISION_GET_ALL_FAILURE');
            return [];
        });
    }, [errorCallback, solicitationId]);
    const { data, isLoading } = useQuery([SOLICITATION_REVISION_LIST_KEY, solicitationId], getSolicitationRevisions, {
        enabled: !!solicitationId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    const memoizedData = useMemo(() => data ? ArrayUtils.sortAsc(data, 'StartDate') : [], [data]);
    return [memoizedData, isLoading];
};
