import React, { useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormField, MaskedTextField, Select, TextField } from 'components';
import { useBeneficiaryTypeList, useJobDescriptionList } from 'hooks';
const BeneficiaryTableFilterForm = ({ values, handleChange, setFieldValue }) => {
    const [jobDescriptions, isLoadingJobDescriptions] = useJobDescriptionList();
    const [beneficiaryTypes, isLoadingBeneficiaryTypes] = useBeneficiaryTypeList();
    const searchFields = useMemo(() => [
        {
            Value: 'Name',
            Description: 'Nome'
        },
        {
            Value: 'CallName',
            Description: 'Nome Guerra'
        },
        {
            Value: 'ANACRegistration',
            Description: 'ANAC'
        }
    ], []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, lg: 4 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "cpf", name: "PersonDocument", label: "CPF", value: values.PersonDocument ?? '', onChange: value => setFieldValue('PersonDocument', value) }))),
            React.createElement(Col, { xs: 12, lg: 4 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "JobDescriptionId", label: "Cargo", options: jobDescriptions, value: jobDescriptions?.find(x => x.Id === values.JobDescriptionId), onChange: value => setFieldValue('JobDescriptionId', value.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingJobDescriptions }))),
            React.createElement(Col, { xs: 12, lg: 4 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "BeneficiaryTypeId", label: "Tipo", options: beneficiaryTypes, value: beneficiaryTypes?.find(x => x.Id === values.BeneficiaryTypeId), onChange: value => setFieldValue('BeneficiaryTypeId', value.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingBeneficiaryTypes })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "SearchField", label: "Escolha um campo", options: searchFields, value: searchFields.find((searchField) => searchField.Value === values.SearchField?.Value), onChange: value => setFieldValue('SearchField', value), getOptionLabel: option => option.Description, getOptionValue: option => option.Value, isClearable: true }))),
            React.createElement(Col, { xs: 12, md: 8 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "SearchQuery", label: "Digite a sua busca", value: values.SearchQuery, onChange: handleChange }))))));
};
export default BeneficiaryTableFilterForm;
