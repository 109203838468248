import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Loading, Card } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useDocumentTitle, useBenefitConsumptionQuery, useBenefitConsumptionMutation } from 'hooks';
import moment from 'moment-timezone';
import { BenefitConsumptionForm } from '../components';
const BenefitConsumptionInternal = () => {
    const benefitConsumptionIdRoute = parseInt(useParams()?.benefitConsumptionId ?? '0');
    const { success, errorCallback } = useAlert();
    const navigate = useNavigate();
    const [benefitConsumption, isLoading] = useBenefitConsumptionQuery(benefitConsumptionIdRoute);
    const { create, update } = useBenefitConsumptionMutation(benefitConsumptionIdRoute);
    async function submitBenefitConsumption(benefitConsumptionData) {
        const benefitConsumptionDataSubmit = { ...benefitConsumptionData };
        benefitConsumptionDataSubmit.CreatedAt = moment().format();
        benefitConsumptionDataSubmit.UpdatedAt = null;
        if (benefitConsumptionData.Id) {
            await update(benefitConsumptionDataSubmit)
                .then(() => success({
                title: 'Consumo atualizado!',
                message: 'Consumo de benefício atualizado com sucesso.',
                id: 'BENEFIT_CONSUMPTION_PUT_SUCCESS'
            }))
                .catch(err => errorCallback(err, 'BENEFIT_CONSUMPTION_PUT_FAILURE'));
        }
        else {
            await create(benefitConsumptionDataSubmit)
                .then(res => {
                success({
                    title: 'Consumo cadastrado!',
                    message: 'Consumo de benefício cadastrado com sucesso.',
                    id: 'BENEFIT_CONSUMPTION_PUT_SUCCESS'
                });
                navigate(`/benefits/consumptions/${res.Id}`);
            })
                .catch(err => errorCallback(err, 'BENEFIT_CONSUMPTION_PUT_FAILURE'));
        }
    }
    const pageTitle = useMemo(() => `${benefitConsumptionIdRoute ? 'Visualizar' : 'Novo'} Consumo`, [
        benefitConsumptionIdRoute
    ]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Benefícios',
            Url: '/benefits'
        },
        {
            Title: 'Consumos',
            Url: '/benefits/consumptions'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Consumos de Benef\u00EDcios", breadcrumb: breadcrumb }),
        React.createElement(Loading, { show: isLoading }),
        React.createElement(Card, { id: "benefit-consumption-card" },
            React.createElement(BenefitConsumptionForm, { benefitConsumption: benefitConsumption, isLoading: isLoading, handleSubmit: submitBenefitConsumption }))));
};
export default BenefitConsumptionInternal;
