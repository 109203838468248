import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TextField } from 'components/TextField';
import { MonthFieldModal } from './MonthFieldModal';
const currentYear = new Date().getFullYear();
const MonthField = ({ name, label, value: initialValue, years, onChange, mountOnCurrentMonth }) => {
    const monthFieldModalRef = useRef(null);
    const [value, setValue] = useState(initialValue);
    const yearsOptions = useMemo(() => years ?? [currentYear], [years]);
    const formattedValue = useMemo(() => {
        if (!value)
            return '';
        const date = new Date(value?.year, value?.month - 1, 1);
        const formattedDate = date.toLocaleString('default', {
            year: 'numeric',
            month: 'long'
        });
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    }, [value]);
    const onClickField = useCallback(() => monthFieldModalRef.current?.show(), [monthFieldModalRef]);
    const onChangeValue = useCallback((year, month) => {
        const value = {
            month,
            year
        };
        setValue(value);
        onChange(value);
        monthFieldModalRef.current?.close();
    }, [onChange]);
    useEffect(() => {
        if (initialValue) {
            setValue(initialValue);
            return;
        }
        if (mountOnCurrentMonth) {
            const now = new Date();
            const value = {
                month: now.getMonth() + 1,
                year: now.getFullYear()
            };
            setValue(value);
            onChange(value);
        }
    }, [initialValue, onChange, mountOnCurrentMonth]);
    return (React.createElement(React.Fragment, null,
        React.createElement(TextField, { name: name, label: label, value: formattedValue, onClick: onClickField, readOnly: true }),
        React.createElement(MonthFieldModal, { ref: monthFieldModalRef, years: yearsOptions, value: value, onChange: onChangeValue })));
};
export default MonthField;
