import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { Button, FormConsumer } from 'components';
import './SituationField.scss';
const SituationField = ({ label, trueLabel = 'Ativo', falseLabel = 'Inativo', options, defaultOption, value, handleChange, disabled }) => {
    const situationFieldOptions = useMemo(() => {
        const defaultOptions = [
            {
                name: trueLabel,
                color: 'success',
                textColor: 'green',
                value: true
            },
            {
                name: falseLabel,
                color: 'danger',
                textColor: 'red',
                value: false
            }
        ];
        if (!options)
            return defaultOptions;
        return options;
    }, [options, trueLabel, falseLabel]);
    const isOptionActive = useCallback((option) => {
        if (value === null || value === undefined)
            return defaultOption === option.value;
        return option.value === value;
    }, [value, defaultOption]);
    const activeOption = useMemo(() => situationFieldOptions?.find((option) => option.value === value), [
        situationFieldOptions,
        value
    ]);
    return (React.createElement(FormConsumer, null, ({ disabled: isFormDisabled }) => (React.createElement("div", { className: "situation-field-wrapper" },
        label && React.createElement("label", null, label),
        React.createElement("div", { className: "situation-buttons" }, isFormDisabled || disabled ? (React.createElement("p", { className: clsx('fs-14 uppercase font-semibold', `text-${activeOption?.textColor}`) }, activeOption?.name)) : (React.createElement(React.Fragment, null, situationFieldOptions?.map((option, index) => (React.createElement(Button, { type: "button", key: index, text: option.name, color: isOptionActive(option) ? option.color : 'default', onClick: () => handleChange(option.value) }))))))))));
};
export default SituationField;
