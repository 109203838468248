import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { makeHttpService } from 'services';
export const useCities = (stateId, fetchData = true) => {
    const httpService = makeHttpService();
    const [cities, setCities] = useState([]);
    const getCities = useCallback(() => {
        return httpService.get(`/address/states/${stateId}/cities`);
    }, [httpService, stateId]);
    const { data, isLoading } = useQuery(['cities', stateId], getCities, {
        enabled: !!stateId && fetchData
    });
    useEffect(() => {
        if (data)
            setCities(data);
    }, [data]);
    return [cities, isLoading];
};
