import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { COMPENSATION_RATE_PAGINATION_QUERY_KEY, COMPENSATION_RATE_QUERY_KEY } from './useCompensationRateConfig';
export const useCompensationRateMutation = (compensationRateId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createCompensationRate = useCallback(async (compensationRateData) => {
        return httpService.post('/compensation-rate', compensationRateData);
    }, [httpService]);
    const updateCompensationRate = useCallback(async (compensationRateData) => {
        return httpService.put('/compensation-rate', compensationRateData);
    }, [httpService]);
    const removeCompensationRate = useCallback(async (compensationRateId) => {
        return httpService.delete(`/compensation-rate?id=${compensationRateId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createCompensationRate, {
        onSuccess: () => {
            queryClient.invalidateQueries(COMPENSATION_RATE_PAGINATION_QUERY_KEY);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateCompensationRate, {
        onSuccess: () => {
            queryClient.invalidateQueries(COMPENSATION_RATE_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries([COMPENSATION_RATE_QUERY_KEY, compensationRateId]);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removeCompensationRate, {
        onSuccess: (_, compensationRateId) => {
            queryClient.invalidateQueries(COMPENSATION_RATE_PAGINATION_QUERY_KEY);
            queryClient.removeQueries([COMPENSATION_RATE_QUERY_KEY, compensationRateId]);
        }
    });
    return { create, update, remove, isCreating, isUpdating, isRemoving };
};
