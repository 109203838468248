import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { PREMIUM_RECIPIENT_LIST_QUERY_KEY, PREMIUM_RECIPIENT_QUERY_KEY } from './usePremiumRecipientConfig';
export const usePremiumRecipientMutation = (beneficiaryId, premiumRecipientId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createPremiumRecipient = useCallback(async (premiumRecipientData) => {
        return httpService.post('/premium-recipient', premiumRecipientData);
    }, [httpService]);
    const updatePremiumRecipient = useCallback(async (premiumRecipientData) => {
        return httpService.put('/premium-recipient', premiumRecipientData);
    }, [httpService]);
    const removePremiumRecipient = useCallback(async (premiumRecipientId) => {
        return httpService.delete(`/premium-recipient?id=${premiumRecipientId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createPremiumRecipient, {
        onSuccess: () => {
            queryClient.invalidateQueries([PREMIUM_RECIPIENT_LIST_QUERY_KEY, beneficiaryId]);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updatePremiumRecipient, {
        onSuccess: () => {
            queryClient.invalidateQueries([PREMIUM_RECIPIENT_QUERY_KEY, premiumRecipientId]);
            queryClient.invalidateQueries([PREMIUM_RECIPIENT_LIST_QUERY_KEY, beneficiaryId]);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removePremiumRecipient, {
        onSuccess: (_, premiumRecipientId) => {
            queryClient.removeQueries([PREMIUM_RECIPIENT_QUERY_KEY, premiumRecipientId]);
            queryClient.invalidateQueries([PREMIUM_RECIPIENT_LIST_QUERY_KEY, beneficiaryId]);
        }
    });
    return { create, update, remove, isCreating, isUpdating, isRemoving };
};
