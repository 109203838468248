import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import benefitConsumptionBatchFileModel from 'assets/files/benefit-consumption-batch-model.csv';
import { Button } from 'components';
import { useAlert } from 'contexts/alert.context';
import './BenefitConsumptionBatchDropzone.scss';
const MEGABYTES_MULTIPLIER = 1e6;
const BenefitConsumptionBatchDropzone = ({ onSelectFile }) => {
    const { error } = useAlert();
    const onFileAccept = useCallback((files) => {
        if (!files.length)
            return;
        const [selectedFile] = files;
        onSelectFile(selectedFile);
    }, [onSelectFile]);
    const onFileReject = useCallback((files) => {
        if (!files.length)
            return;
        error({
            title: 'Arquivo inválido',
            message: 'O arquivo selecionado é inválido.',
            id: 'BENEFIT_CONSUMPTION_BATCH_UPLOAD_ERROR'
        });
    }, [error]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'text/csv': ['.csv']
        },
        maxSize: 10 * MEGABYTES_MULTIPLIER,
        onDropAccepted: onFileAccept,
        onDropRejected: onFileReject,
        useFsAccessApi: false,
        multiple: false
    });
    return (React.createElement("div", { className: "benefit-consumption-batch-dropzone" },
        React.createElement("h2", { className: "text-lg text-gray dark:text-gray-50 text-center" }, "Consumo de Benef\u00EDcios"),
        React.createElement("ol", { className: "my-4" },
            React.createElement("li", null,
                "O arquivo deve seguir o",
                ' ',
                React.createElement("a", { className: "text-blue-light", href: benefitConsumptionBatchFileModel },
                    React.createElement("strong", null, "modelo oficial de importa\u00E7\u00E3o de consumos de benef\u00EDcios"))),
            React.createElement("li", null,
                "O arquivo deve ser enviado no formato ",
                React.createElement("strong", null, ".csv")),
            React.createElement("li", null,
                "O tamanho m\u00E1ximo permitido \u00E9 de ",
                React.createElement("strong", null, "2 MB"))),
        React.createElement("div", { className: "benefit-consumption-batch-dropzone-wrapper" },
            React.createElement("input", { ...getInputProps() }),
            React.createElement("div", { ...getRootProps({ className: 'benefit-consumption-batch-dropzone-content' }) },
                React.createElement(Button, { size: "large", text: "Selecionar arquivo", color: "info" }),
                React.createElement("small", null, "ou arraste e solte o arquivo aqui.")))));
};
export default BenefitConsumptionBatchDropzone;
