import React, { useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import { DateRangeField, FormField, MaskedTextField, Select, TextField } from 'components';
import { SolicitationStatus } from 'types/enum';
const SolicitationRevisionTableFilterForm = ({ values, setFieldValue, handleChange }) => {
    const situationFieldOptions = useMemo(() => {
        return [
            { label: 'Todas', value: null },
            { label: 'Aguardando início', value: SolicitationStatus.WAITING_START },
            { label: 'Finalizada', value: SolicitationStatus.FINISHED },
            { label: 'Cancelada', value: SolicitationStatus.CANCELLED }
        ];
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "PersonCallName", label: "Nome Guerra", value: values.PersonCallName ?? '', onChange: handleChange }))),
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "cpf", name: "PersonDocument", label: "CPF do associado", value: values.PersonDocument ?? '', onChange: value => setFieldValue('PersonDocument', value) }))),
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "SolicitationCode", label: "C\u00F3digo da solicita\u00E7\u00E3o", value: values.SolicitationCode ?? '', onChange: handleChange })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "StatusId", label: "Status", options: situationFieldOptions, value: situationFieldOptions?.filter(option => values.Statuses?.some(x => option.value === x?.value)), onChange: value => setFieldValue('Statuses', value), getOptionLabel: option => option.label, getOptionValue: option => option.value, isMulti: true }))),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(DateRangeField, { startDate: values.DateRange[0], endDate: values.DateRange[1], onDatesChange: ({ startDate, endDate }) => setFieldValue('DateRange', [startDate, endDate]), isOutsideRange: () => false }))))));
};
export default SolicitationRevisionTableFilterForm;
