import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Icon } from 'components';
import { useTableFilter, useDocumentTitle, useReportBenefitLatamQuery } from 'hooks';
import { FormatterUtils } from 'utils';
import { BenefitConsumptionLatamExportModal, BenefitConsumptionLatamTableFilterForm } from './components';
const BenefitConsumptionLatamReport = () => {
    const customParamsHandle = useCallback((filterParams) => {
        const params = {};
        if (filterParams.BenefitId) {
            params.BenefitId = filterParams.BenefitId;
        }
        if (filterParams.PersonDocument) {
            params.PersonDocument = filterParams.PersonDocument;
        }
        if (filterParams.PersonCallName) {
            params.SearchField = 'CallName';
            params.SearchQuery = filterParams.PersonCallName;
        }
        if (filterParams.PersonName) {
            params.SearchField = 'PersonName';
            params.SearchQuery = filterParams.PersonName;
        }
        const monthFieldValue = filterParams.MonthYear;
        if (monthFieldValue) {
            const monthYearValue = new Date(monthFieldValue.year, monthFieldValue.month - 1, 1);
            monthYearValue.setHours(0, 0, 0);
            params.StartDate = monthYearValue.toLocaleDateString('en-CA');
        }
        return params;
    }, []);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const defaultFilterParams = {
        MonthYear: { year: currentYear, month: currentMonth }
    };
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter({
        customParamsHandle,
        initialParams: defaultFilterParams
    });
    const { data, isLoading, ...pagination } = useReportBenefitLatamQuery(requestFilterParams);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Relatórios'
        },
        {
            Title: 'Relatório de Consumos LATAM'
        }
    ], []);
    const columns = useMemo(() => [
        {
            Header: 'Consumo',
            accessor: 'Id',
            id: 'BenefitId',
            Cell: ({ row: { original: values } }) => (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "font-semibold text-gray dark:text-gray-50" }, values?.Benefit?.Name ?? values?.Plan?.Name),
                React.createElement("small", { className: "block text-xs text-gray-light dark:text-gray-400" }, values.BenefitId ? 'Benefício' : 'Plano')))
        },
        {
            Header: 'Associado/Dependente',
            accessor: 'Id',
            id: 'BeneficiaryDependent',
            disableSortBy: true,
            Cell: ({ row: { original: values } }) => {
                const beneficiaryId = values.BeneficiaryId ?? values.Dependent?.BeneficiaryId;
                return (React.createElement(Link, { to: `/beneficiaries/${beneficiaryId}`, target: "_blank" },
                    React.createElement("span", { className: "flex items-center font-semibold text-gray-light dark:text-gray-50" },
                        values?.Dependent?.Name ?? values?.Beneficiary?.Name,
                        React.createElement(Icon, { name: "new-tab", size: 14, className: "text-blue-light ml-1" })),
                    React.createElement("small", { className: "block text-xs text-gray-light dark:text-gray-400" }, values.DependentId ? 'Dependente' : 'Associado')));
            }
        },
        {
            Header: 'Valor Consumo',
            accessor: 'Value',
            Cell: ({ cell: { value } }) => FormatterUtils.money(value)
        },
        {
            Header: 'Data',
            accessor: 'Date',
            Cell: ({ cell: { value } }) => FormatterUtils.date(value)
        }
    ], []);
    useDocumentTitle('Relatório de Consumos LATAM');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Relat\u00F3rio de Consumos LATAM", breadcrumb: breadcrumb }),
        React.createElement("div", { className: clsx('flex justify-between mb-4') },
            React.createElement(BenefitConsumptionLatamExportModal, { filterParams: requestFilterParams }),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Relat\u00F3rio", formConfig: { filterParams }, handleFilter: setParams, customFilters: BenefitConsumptionLatamTableFilterForm }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhum consumo a ser exibido" })));
};
export default BenefitConsumptionLatamReport;
