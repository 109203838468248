import React, { useCallback, useEffect, useState } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { Button } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useBeneficiaryMutation, useFileUpload } from 'hooks';
import moment from 'moment-timezone';
import { FileSource } from 'types/enum';
import './PremiumRecipientContractFile.scss';
const PremiumRecipientContractFile = ({ beneficiary }) => {
    const { success, error, errorCallback } = useAlert();
    const [selectedFile, setSelectedFile] = useState();
    const [isUploading, setIsUploading] = useState(false);
    const [upload, remove] = useFileUpload();
    const { update } = useBeneficiaryMutation(beneficiary.Id);
    const onFileAccept = useCallback((newFiles) => {
        const [selectedFile] = newFiles;
        const fileWithPreview = Object.assign(selectedFile, {
            preview: ''
        });
        setSelectedFile(fileWithPreview);
    }, []);
    const onFileReject = useCallback((files) => {
        if (!files.length)
            return;
        const [fileRejected] = files;
        const isSizeError = fileRejected.errors.some(x => x.code === ErrorCode.FileTooLarge);
        const errorMessage = isSizeError ? 'O tamanho máximo é de 1mb.' : 'Apenas os formatos PNG e JPG são aceitos.';
        error({
            title: 'Arquivo inválido',
            message: errorMessage,
            id: 'FILE_UPLOAD_ERROR'
        });
    }, [error]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'application/pdf': ['.pdf']
        },
        maxSize: 1 * 1e6,
        multiple: false,
        noDragEventsBubbling: true,
        disabled: isUploading,
        onDropAccepted: onFileAccept,
        onDropRejected: onFileReject,
        useFsAccessApi: false
    });
    const updateBeneficiaryPremiumRecipientContract = useCallback(async (fileUrl = '') => {
        const beneficiaryUpdateData = {
            ...beneficiary,
            PremiumRecipientContractFile: fileUrl,
            UpdatedAt: moment().format()
        };
        await update(beneficiaryUpdateData)
            .then(() => success({
            title: 'Contrato dos premiados atualizado!',
            message: 'O contrato dos premiados foi atualizado com sucesso.',
            id: 'BENEFICIARY_PUT_PREMIUM_RECIPIENT_CONTRACT_SUCCESS'
        }))
            .catch(async (err) => {
            const fileName = beneficiary.PremiumRecipientContractFile.split('/').at(-1);
            await remove(fileName);
            errorCallback(err, 'BENEFICIARY_PUT_PREMIUM_RECIPIENT_CONTRACT_FAILURE');
        });
    }, [beneficiary, update, remove, success, errorCallback]);
    const onSubmitFile = useCallback(async () => {
        try {
            setIsUploading(true);
            const fileUrl = await upload(selectedFile, FileSource.PREMIUM_RECIPIENT_CONTRACT);
            await updateBeneficiaryPremiumRecipientContract(fileUrl);
        }
        catch (error) {
            errorCallback(error, 'BENEFICIARY_PUT_PREMIUM_RECIPIENT_CONTRACT_FAILURE');
        }
        finally {
            setIsUploading(false);
        }
    }, [selectedFile, upload, updateBeneficiaryPremiumRecipientContract, errorCallback]);
    const onDeleteFile = useCallback(async () => {
        try {
            setIsUploading(true);
            const fileName = beneficiary.PremiumRecipientContractFile.split('/').at(-1);
            await remove(fileName);
            await updateBeneficiaryPremiumRecipientContract('');
        }
        catch (error) {
            errorCallback(error, 'BENEFICIARY_PUT_PREMIUM_RECIPIENT_CONTRACT_FAILURE');
        }
        finally {
            setIsUploading(false);
        }
    }, [beneficiary, remove, updateBeneficiaryPremiumRecipientContract, errorCallback]);
    const onRemoveFile = useCallback(async () => {
        setSelectedFile(undefined);
        if (!beneficiary.PremiumRecipientContractFile)
            return;
        await onDeleteFile();
    }, [beneficiary, onDeleteFile]);
    const onInit = useCallback(() => {
        if (!beneficiary.PremiumRecipientContractFile)
            return;
        const fileName = beneficiary.PremiumRecipientContractFile.split('/').at(-1);
        setSelectedFile({
            name: fileName,
            preview: beneficiary.PremiumRecipientContractFile
        });
    }, [beneficiary]);
    useEffect(onInit, [onInit]);
    const hasSelectedFile = Boolean(selectedFile?.name);
    return (React.createElement("div", { className: "premium-recipient-contract-file flex items-center border-b border-solid border-gray-200 dark:border-gray-700 pb-8 mb-8" }, hasSelectedFile ? (React.createElement("div", { className: "dropzone-preview-content" },
        React.createElement("p", { className: "text-xs font-normal text-gray-light dark:text-gray-400" }, "Arquivo selecionado:"),
        React.createElement("a", { href: selectedFile?.preview, className: "text-base lowercase font-light text-gray-light dark:text-gray-50 hover:text-blue dark:hover:text-blue-light transition-colors cursor-pointer", target: "_blank", rel: "noreferrer" }, selectedFile?.name),
        React.createElement("div", { className: "flex gap-2 mt-4" },
            React.createElement(Button, { color: "success", text: "Salvar", size: "medium", disabled: isUploading, loading: isUploading, onClick: onSubmitFile }),
            React.createElement(Button, { color: "danger", variant: "outlined", text: "Apagar", size: "medium", disabled: isUploading, onClick: onRemoveFile })))) : (React.createElement("div", { className: "dropzone-wrapper" },
        React.createElement("input", { ...getInputProps() }),
        React.createElement("div", { ...getRootProps({ className: 'dropzone-content' }) },
            React.createElement(Button, { size: "large", text: "Selecionar arquivo", color: "info" }),
            React.createElement("small", null, "ou arraste e solte o arquivo aqui."))))));
};
export default PremiumRecipientContractFile;
