import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip, Icon, ExportButton } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useModulePermissions, useTableFilter, useDocumentTitle, useAwayWithoutSolicitationPagination, useAwayWithoutSolicitationMutation } from 'hooks';
import moment from 'moment';
import { Scope } from 'types/enum';
import { FormatterUtils } from 'utils';
import { AwayWithoutSolicitationTableFilterForm } from '../components';
const AwayWithoutSolicitationList = () => {
    const { canCreate, canRead, canUpdate, canDelete } = useModulePermissions();
    const { success, confirm, errorCallback } = useAlert();
    const customParamsHandle = useCallback((filterParams) => {
        const params = {
            Active: filterParams.Active
        };
        if (filterParams.PersonDocument) {
            params.PersonDocument = filterParams.PersonDocument;
        }
        if (filterParams.CallName) {
            params.SearchField = 'CallName';
            params.SearchQuery = filterParams.CallName;
        }
        if (filterParams.AwayReasonId) {
            params.SearchField = 'AwayReasonId';
            params.SearchQuery = filterParams.AwayReasonId;
        }
        return params;
    }, []);
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter({
        customParamsHandle
    });
    const { data, isLoading, ...pagination } = useAwayWithoutSolicitationPagination(requestFilterParams);
    const { remove } = useAwayWithoutSolicitationMutation();
    const deleteAwayWithoutSolicitation = useCallback(async (aircraftModelId) => {
        await remove(aircraftModelId)
            .then(() => success({
            title: 'Afastado deletado!',
            message: 'Afastado deletado com sucesso.',
            id: 'AWAY_WITHOUT_SOLICITATION_DELETE_SUCCESS'
        }))
            .catch(err => errorCallback(err, 'AWAY_WITHOUT_SOLICITATION_DELETE_FAILURE'));
    }, [remove, success, errorCallback]);
    const onDeleteAwayWithoutSolicitation = useCallback((aircraftModelId) => {
        confirm({
            title: 'Você tem certeza?',
            description: (React.createElement("p", null,
                "O afastado ser\u00E1 deletado. ",
                React.createElement("br", null),
                " Essa a\u00E7\u00E3o \u00E9 irrevers\u00EDvel!")),
            confirmText: 'Sim, deletar',
            cancelText: 'Não, cancelar',
            onConfirm: async () => deleteAwayWithoutSolicitation(aircraftModelId)
        });
    }, [confirm, deleteAwayWithoutSolicitation]);
    const columns = useMemo(() => [
        {
            Header: 'Código',
            accessor: 'Id'
        },
        {
            Header: 'Associado',
            accessor: 'Beneficiary',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement(Link, { to: `/beneficiaries/${value.Id}`, target: "_blank" },
                React.createElement("span", { className: "flex items-center font-semibold text-gray-light dark:text-gray-50" },
                    value.Name,
                    React.createElement(Icon, { name: "new-tab", size: 14, className: "text-blue-light ml-1" }))))
        },
        {
            Header: 'Motivo',
            accessor: 'AwayReason',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => value.Name
        },
        {
            Header: 'Data Início',
            accessor: 'AwayStartDate',
            Cell: ({ cell: { value } }) => FormatterUtils.date(value)
        },
        {
            Header: 'Data Fim',
            accessor: 'AwayEndDate',
            Cell: ({ cell: { value } }) => (value ? FormatterUtils.date(value) : '-')
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value }, row: { original: values } }) => {
                const now = moment();
                const endDate = values.AwayEndDate ? moment(values.AwayEndDate) : null;
                const isEnded = values.AwayEndDate ? now.isAfter(endDate) : false;
                return (React.createElement("div", { className: "flex items-center" },
                    (canRead || canUpdate) && (React.createElement(Link, { to: `/cam/aways-without-solicitation/${value}` },
                        React.createElement(Tooltip, { id: `aways-without-solicitation-${value}-edit`, text: canUpdate && !isEnded ? 'Editar' : 'Visualizar' },
                            React.createElement(Button, { icon: canUpdate && !isEnded ? 'edit' : 'eye', color: "info", rounded: true })))),
                    canDelete && !isEnded && (React.createElement(Tooltip, { id: `aways-without-solicitation-${value}-remove`, text: "Deletar" },
                        React.createElement(Button, { className: "ml-2", icon: "trash", color: "danger", title: "Deletar", onClick: () => onDeleteAwayWithoutSolicitation(value), rounded: true })))));
            }
        }
    ], [canRead, canUpdate, canDelete, onDeleteAwayWithoutSolicitation]);
    useDocumentTitle('Afastados');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Afastados" }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            canCreate && (React.createElement(Link, { to: "/cam/aways-without-solicitation/new" },
                React.createElement(Button, { text: "Novo Afastado", icon: "add", color: "info", animationHover: "pop" }))),
            React.createElement("div", { className: "flex gap-2" },
                canRead && (React.createElement(ExportButton, { params: requestFilterParams, config: [
                        {
                            title: 'Extrato de afastados',
                            url: 'report/away-without-solicitation-excel',
                            scope: Scope.AWAY_WITHOUT_SOLICITATION
                        },
                        {
                            title: 'Extrato de consumos',
                            url: 'report/away-without-solicitation-consumptions-excel',
                            scope: Scope.BENEFIT_CONSUMPTION
                        }
                    ], isMultiple: true })),
                React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters }))),
        React.createElement(TableFilter, { show: showFilters, title: "Afastados", formConfig: { filterParams }, handleFilter: setParams, customFilters: AwayWithoutSolicitationTableFilterForm }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhum afastado a ser exibido" })));
};
export default AwayWithoutSolicitationList;
