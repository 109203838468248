import React, { useMemo } from 'react';
import { PdfContent, PdfContentElements } from 'components';
import moment from 'moment-timezone';
import { FormatterUtils } from 'utils';
const BenefitConsumptionMonthlyBalanceExportContentPdf = ({ benefitConsumptionMonthlyBalance, benefitConsumptions }) => {
    const dateNow = useMemo(() => moment().format('D [de] MMMM [de] YYYY'), []);
    const monthYearReference = useMemo(() => moment(benefitConsumptionMonthlyBalance.MonthYearReference).format('MM[/]YYYY'), [benefitConsumptionMonthlyBalance]);
    const BeneficiaryDetailsCard = (React.createElement(PdfContentElements.Box, null,
        React.createElement(PdfContentElements.Grid, null,
            React.createElement(PdfContentElements.GridItem, { size: 4, marginBottom: 4 },
                React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Nome Guerra"),
                React.createElement(PdfContentElements.TextBase, null, benefitConsumptionMonthlyBalance.Beneficiary.CallName)),
            React.createElement(PdfContentElements.GridItem, { size: 4, marginBottom: 4 },
                React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Nome Completo"),
                React.createElement(PdfContentElements.TextBase, null, benefitConsumptionMonthlyBalance.Beneficiary.Name)),
            React.createElement(PdfContentElements.GridItem, { size: 4, marginBottom: 4 },
                React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Cargo"),
                React.createElement(PdfContentElements.TextBase, null, benefitConsumptionMonthlyBalance.Beneficiary.JobDescription?.Name ?? '')))));
    const BenefitConsumptionCard = (React.createElement(PdfContentElements.Box, { marginTop: 12 },
        React.createElement(PdfContentElements.View, { marginBottom: 8 },
            React.createElement(PdfContentElements.TextBold, null, "Consumos")),
        React.createElement(PdfContentElements.Table, { columns: [
                {
                    header: 'Evento',
                    value: benefitConsumption => (React.createElement(React.Fragment, null,
                        React.createElement(PdfContentElements.TextMedium, null, benefitConsumption?.Benefit?.Name ?? benefitConsumption?.Plan?.Name),
                        React.createElement(PdfContentElements.TextBase, { fontSize: 9, style: { color: '#6F6F6F' } }, benefitConsumption.BenefitId ? 'Benefício' : 'Plano'))),
                    style: { column: { flex: 2 } }
                },
                {
                    header: 'Data',
                    value: benefitConsumption => (React.createElement(PdfContentElements.TextBase, { fontSize: 10 }, FormatterUtils.dateTime(benefitConsumption.CreatedAt)))
                },
                {
                    header: 'Valor',
                    value: benefitConsumption => (React.createElement(PdfContentElements.TextMedium, null, FormatterUtils.money(benefitConsumption.Value))),
                    style: { column: { alignItems: 'flex-end' } }
                }
            ], data: benefitConsumptions, style: { row: { alignItems: 'center' } } })));
    const BenefitConsumptionValueCard = (React.createElement(PdfContentElements.View, { marginTop: 12 },
        React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
            React.createElement(PdfContentElements.TextBase, { fontSize: 9 }, "Valor Processo CAM"),
            React.createElement(PdfContentElements.TextBold, { fontSize: 14 }, FormatterUtils.money(benefitConsumptionMonthlyBalance.CAMIncomeValue))),
        React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
            React.createElement(PdfContentElements.TextBase, { fontSize: 9 }, "Valor Consumo"),
            React.createElement(PdfContentElements.TextBold, { fontSize: 14 }, FormatterUtils.money(benefitConsumptionMonthlyBalance.ConsumedBenefitsTotalValue))),
        React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
            React.createElement(PdfContentElements.TextBase, { fontSize: 9 }, "Saldo"),
            React.createElement(PdfContentElements.TextBold, { fontSize: 14, style: {
                    color: benefitConsumptionMonthlyBalance.Balance > 0 ? '#AFE059' : '#EC0B43'
                } }, FormatterUtils.money(benefitConsumptionMonthlyBalance.Balance)))));
    const BenefitConsumptionMonthlyBalanceReportBody = (React.createElement(React.Fragment, null,
        BeneficiaryDetailsCard,
        BenefitConsumptionCard,
        BenefitConsumptionValueCard));
    return (React.createElement(PdfContent, { title: "Relat\u00F3rio de Balan\u00E7o Mensal", subtitle: `Mês Ref. ${monthYearReference}`, caption: dateNow, body: BenefitConsumptionMonthlyBalanceReportBody }));
};
export default BenefitConsumptionMonthlyBalanceExportContentPdf;
