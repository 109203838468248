import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { BENEFICIARY_QUERY_KEY } from './useBeneficiaryConfig';
export const useBeneficiaryQuery = (beneficiaryId) => {
    const { errorCallback } = useAlert();
    const navigate = useNavigate();
    const getBeneficiary = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/beneficiary/${beneficiaryId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'BENEFICIARY_GET_FAILURE');
            navigate('/beneficiaries');
            throw error;
        });
    }, [errorCallback, beneficiaryId, navigate]);
    const { data, isLoading } = useQuery([BENEFICIARY_QUERY_KEY, beneficiaryId], getBeneficiary, {
        enabled: !!beneficiaryId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
