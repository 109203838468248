import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Icon } from 'components';
import { useModulePermissions, useTableFilter, useDocumentTitle, useSolicitationPagination } from 'hooks';
import { SolicitationTreatmentMethodLabel } from 'types/enum';
import { FormatterUtils, SolicitationUtils, ValidationUtils } from 'utils';
import { AwayByCamFilterForm } from '../components';
const AwayByCamList = () => {
    const { canCreate } = useModulePermissions();
    const customParamsHandle = useCallback((filterParams) => {
        const params = {};
        if (filterParams.RegistrationCode) {
            params.SearchField = 'RegistrationCode';
            params.SearchQuery = filterParams.RegistrationCode;
        }
        if (filterParams.CallName) {
            params.SearchField = 'CallName';
            params.SearchQuery = filterParams.CallName;
        }
        if (filterParams.PersonDocument) {
            params.PersonDocument = filterParams.PersonDocument;
        }
        params.SolicitationStatusIds = [3];
        if (ValidationUtils.isNotNullOrUndefined(filterParams.SolicitationTreatmentMethod)) {
            params.SolicitationTreatmentMethod = filterParams.SolicitationTreatmentMethod;
        }
        if (ValidationUtils.isNotNullOrUndefined(filterParams.IsWorkAccident)) {
            params.IsWorkAccident = !filterParams.IsWorkAccident;
        }
        return params;
    }, []);
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter({
        customParamsHandle
    });
    const { data, isLoading, ...pagination } = useSolicitationPagination(requestFilterParams);
    const columns = useMemo(() => [
        {
            Header: 'Código',
            accessor: 'RegistrationCode'
        },
        {
            Header: 'Associado',
            accessor: 'Beneficiary',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement(Link, { to: `/beneficiaries/${value.Id}`, target: "_blank" },
                React.createElement("span", { className: "flex items-center font-semibold text-gray-light dark:text-gray-50" },
                    value.Name,
                    React.createElement(Icon, { name: "new-tab", size: 14, className: "text-blue-light ml-1" }))))
        },
        {
            Header: 'Método Tratamento',
            accessor: 'SolicitationTreatmentMethod',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => SolicitationTreatmentMethodLabel[value]
        },
        {
            Header: 'Status',
            accessor: 'SolicitationState',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold', SolicitationUtils.getSolicitationStateTextColor(value?.StatusId)) }, value?.Status?.Name ?? '-'))
        },
        {
            Header: 'Data Cadastro',
            accessor: 'CreatedAt',
            Cell: ({ cell: { value } }) => FormatterUtils.dateTime(value)
        }
    ], []);
    useDocumentTitle('Afastados pelo CAM');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Afastados pelo CAM" }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Requerimentos", formConfig: { filterParams }, handleFilter: setParams, customFilters: AwayByCamFilterForm }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhuma solicita\u00E7\u00E3o a ser exibida" })));
};
export default AwayByCamList;
