import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { BENEFIT_CONSUMPTION_BATCH_PAGINATION_QUERY_KEY } from './useBenefitConsumptionBatchConfig';
export const useBenefitConsumptionBatchMutation = () => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createBenefitConsumptionBatch = useCallback(async (benefitConsumptionBatchData) => {
        return httpService.post('/benefit-consumption-batch', benefitConsumptionBatchData);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createBenefitConsumptionBatch, {
        onSuccess: () => {
            queryClient.invalidateQueries(BENEFIT_CONSUMPTION_BATCH_PAGINATION_QUERY_KEY);
        }
    });
    return { create, isCreating };
};
