import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Card, Icon } from 'components';
import { FormatterUtils } from 'utils';
import './RecompositionInequalityDetailsCard.scss';
const RecompositionInequalityDetailsCard = ({ recompositionInequality }) => {
    return (React.createElement(Card, { className: "h-full" },
        React.createElement("h2", { className: "text-lg font-semibold text-gray dark:text-gray-50 mb-4" }, "Detalhes"),
        React.createElement("div", { className: clsx('recomposition-inequality-details-card-item flex justify-between items-center') },
            React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400" }, "Associado"),
            React.createElement(Link, { to: `/beneficiaries/${recompositionInequality?.Solicitation?.BeneficiaryId}`, target: "_blank" },
                React.createElement("p", { className: clsx('text-gray dark:text-gray-50 font-semibold') },
                    recompositionInequality.Solicitation?.Beneficiary?.Name,
                    React.createElement(Icon, { name: "new-tab", size: 14 })))),
        React.createElement("div", { className: clsx('recomposition-inequality-details-card-item flex justify-between items-center') },
            React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400" }, "Requerimento"),
            React.createElement(Link, { to: `/beneficiaries/${recompositionInequality?.SolicitationId}`, target: "_blank" },
                React.createElement("p", { className: clsx('text-gray dark:text-gray-50 font-semibold') },
                    recompositionInequality.Solicitation?.RegistrationCode,
                    React.createElement(Icon, { name: "new-tab", size: 14 })))),
        React.createElement("div", { className: clsx('recomposition-inequality-details-card-item flex justify-between items-center') },
            React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400" }, "Data Cria\u00E7\u00E3o"),
            React.createElement("p", { className: clsx('text-gray dark:text-gray-50') }, FormatterUtils.dateTime(recompositionInequality.CreatedAt)))));
};
export default memo(RecompositionInequalityDetailsCard);
