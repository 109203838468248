import React, { useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { TextField, Button, FormField, FormFieldError, Form, SituationField, useFormikValidationHandler, FormikValidationHandler } from 'components';
import { useFormik } from 'formik';
import { useModulePermissions } from 'hooks';
import { FormatterUtils } from 'utils';
import { OperationBaseFormSchema } from './OperationBaseForm.schema';
const defaultValues = {
    Id: 0,
    Name: '',
    IATA: '',
    ICAO: '',
    City: '',
    FS: '',
    CreatedAt: '',
    UpdatedAt: '',
    Active: true
};
const OperationBaseForm = ({ operationBase, isLoading, ...props }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions(operationBase?.Id);
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => OperationBaseFormSchema,
        onSubmit: async (operationBaseData) => {
            return props.handleSubmit && props.handleSubmit(operationBaseData);
        },
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleChange, handleSubmit, isSubmitting, setValues } = formikContext;
    useEffect(() => {
        if (!operationBase?.Id)
            return;
        const operationBaseData = {
            Id: operationBase.Id,
            Name: operationBase.Name,
            IATA: operationBase.IATA,
            ICAO: operationBase.ICAO,
            City: operationBase.City,
            FS: operationBase.FS,
            Active: operationBase.Active ?? true,
            CreatedAt: FormatterUtils.dateTime(operationBase.CreatedAt),
            UpdatedAt: FormatterUtils.dateTime(operationBase.UpdatedAt)
        };
        setValues(operationBaseData);
    }, [operationBase, setValues]);
    if (isLoading)
        return null;
    return (React.createElement(Form, { name: "operationBaseForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Name", label: "Nome", value: values.Name, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Name" }))),
            React.createElement(Col, { sm: 3 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "IATA", label: "IATA", value: values.IATA, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "IATA" }))),
            React.createElement(Col, { sm: 3 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "ICAO", label: "ICAO", value: values.ICAO, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "ICAO" })))),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "City", label: "Cidade", value: values.City, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "City" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "FS", label: "UF", value: values.FS, onChange: handleChange, maxLength: 2 }),
                    React.createElement(FormFieldError, { name: "FS" })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Situa\u00E7\u00E3o da base", handleChange: isActive => setFieldValue('Active', isActive), value: values.Active, falseLabel: "Inativo", trueLabel: "Ativo" })))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Enviar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default OperationBaseForm;
