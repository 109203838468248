import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Loading, Card } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useDocumentTitle, useDailyRateQuery, useDailyRateMutation } from 'hooks';
import moment from 'moment-timezone';
import { DailyRateForm } from '../components';
const DailyRateInternal = () => {
    const dailyRateIdRoute = parseInt(useParams()?.dailyRateId ?? '0');
    const { success, errorCallback } = useAlert();
    const navigate = useNavigate();
    const [dailyRate, isLoading] = useDailyRateQuery(dailyRateIdRoute);
    const { create, update } = useDailyRateMutation(dailyRateIdRoute);
    async function submitDailyRate(dailyRateData) {
        const dailyRateDataSubmit = { ...dailyRateData };
        dailyRateDataSubmit.CreatedAt = moment().format();
        dailyRateDataSubmit.UpdatedAt = null;
        if (dailyRateData.Id) {
            await update(dailyRateDataSubmit)
                .then(() => success({
                title: 'Diária atualizada!',
                message: 'Dados da diária atualizados com sucesso.',
                id: 'DAILY_RATE_PUT_SUCCESS'
            }))
                .catch(err => errorCallback(err, 'DAILY_RATE_PUT_FAILURE'));
        }
        else {
            await create(dailyRateDataSubmit)
                .then(res => {
                success({
                    title: 'Diária cadastrada!',
                    message: 'Diária cadastrada com sucesso.',
                    id: 'DAILY_RATE_POST_SUCCESS'
                });
                navigate(`/cam/daily-rates/${res.Id}`);
            })
                .catch(err => errorCallback(err, 'DAILY_RATE_POST_FAILURE'));
        }
    }
    const pageTitle = useMemo(() => `${dailyRateIdRoute ? 'Visualizar' : 'Nova'} Diária`, [dailyRateIdRoute]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Diárias',
            Url: '/cam/daily-rates'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Di\u00E1rias", breadcrumb: breadcrumb }),
        React.createElement(Loading, { show: isLoading }),
        React.createElement(Card, { id: "daily-rate-card" },
            React.createElement(DailyRateForm, { dailyRate: dailyRate, isLoading: isLoading, handleSubmit: submitDailyRate }))));
};
export default DailyRateInternal;
