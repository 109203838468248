import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Icon } from 'components/Icon';
import './Pagination.scss';
const Pagination = ({ perPage, currentPage, numberOfPages, withPageSize = true, pageSizes = [1, 2, 5, 10, 15], isLoading = false, canNextPage, canPreviousPage, handleGoToPage, handlePreviousPage, handleNextPage, handleChangePageSize, handlePagination }) => {
    const localPageIndex = useMemo(() => currentPage - 1, [currentPage]);
    const pageOptions = useMemo(() => {
        const pages = [];
        for (let index = 0; index < numberOfPages; index++) {
            pages.push(index);
        }
        return pages;
    }, [numberOfPages]);
    const displayedPages = useMemo(() => {
        const maxDisplayed = 5;
        let start = 1;
        let end;
        start = Math.max(start, currentPage - Math.abs(Math.floor(maxDisplayed / 2)));
        end = start + maxDisplayed;
        if (end > pageOptions.length) {
            end = pageOptions.length + 1;
        }
        const pages = [];
        for (let index = start; index < end; index++) {
            pages.push(index);
        }
        return pages;
    }, [pageOptions, currentPage]);
    const customCanNextPage = () => {
        if (canNextPage === undefined)
            canNextPage = currentPage < numberOfPages;
        return canNextPage;
    };
    const customCanPreviousPage = () => {
        if (canPreviousPage === undefined)
            canPreviousPage = currentPage > 1;
        return canPreviousPage;
    };
    const onGoToPage = (pageIndex) => {
        if (handlePagination) {
            handlePagination(pageIndex, perPage);
            return;
        }
        handleGoToPage(pageIndex);
    };
    const onPreviousPage = () => {
        if (handlePagination) {
            handlePagination(localPageIndex - 1, perPage);
            return;
        }
        handlePreviousPage();
    };
    const onNextPage = () => {
        if (handlePagination) {
            handlePagination(localPageIndex + 1, perPage);
            return;
        }
        handleNextPage();
    };
    const onChangePageSize = (pageSize) => {
        if (handlePagination) {
            handlePagination(0, perPage);
            return;
        }
        handleChangePageSize && handleChangePageSize(pageSize);
    };
    return (React.createElement("div", { className: "custom-pagination" },
        !!displayedPages.length && (React.createElement("nav", { className: "pagination-nav" },
            React.createElement("ul", { className: "flex justify-center" },
                React.createElement("li", { className: clsx('page-item', (!customCanPreviousPage() || isLoading) && 'disabled'), onClick: () => customCanPreviousPage() && !isLoading && onGoToPage(0), title: "Primeira P\u00E1gina" },
                    React.createElement("div", { className: "page-link", "aria-label": "Primeira" },
                        React.createElement("span", { "aria-hidden": "true" },
                            React.createElement(Icon, { name: "chevron-left", size: 15, className: "mr--2" }),
                            React.createElement(Icon, { name: "chevron-left", size: 15 })),
                        React.createElement("span", { className: "sr-only" }, "Primeira"))),
                React.createElement("li", { className: clsx('page-item', (!customCanPreviousPage() || isLoading) && 'disabled'), onClick: () => !isLoading && customCanPreviousPage() && onPreviousPage(), title: "P\u00E1gina Anterior" },
                    React.createElement("div", { className: "page-link", "aria-label": "Anterior" },
                        React.createElement("span", { "aria-hidden": "true" },
                            React.createElement(Icon, { name: "chevron-left", size: 15 })),
                        React.createElement("span", { className: "sr-only" }, "Anterior"))),
                displayedPages.map((dPage, index) => (React.createElement("li", { key: index, className: clsx('page-item', dPage === currentPage && 'active', isLoading && 'disabled'), onClick: () => !isLoading && dPage !== currentPage && onGoToPage(dPage - 1), title: `Página ${dPage}` },
                    React.createElement("div", { className: "page-link" }, dPage)))),
                React.createElement("li", { className: clsx('page-item', (!customCanNextPage() || isLoading) && 'disabled'), onClick: () => !isLoading && customCanNextPage() && onNextPage() },
                    React.createElement("div", { className: "page-link", "aria-label": "Pr\u00F3ximo" },
                        React.createElement("span", { "aria-hidden": "true" },
                            React.createElement(Icon, { name: "chevron-right", size: 15 })),
                        React.createElement("span", { className: "sr-only" }, "Pr\u00F3ximo"))),
                React.createElement("li", { className: clsx('page-item', (!customCanNextPage() || isLoading) && 'disabled'), onClick: () => !isLoading && customCanNextPage() && onGoToPage(numberOfPages - 1), title: "\u00DAltima P\u00E1gina" },
                    React.createElement("div", { className: "page-link", "aria-label": "\u00DAltima" },
                        React.createElement("span", { "aria-hidden": "true" },
                            React.createElement(Icon, { name: "chevron-right", size: 15, className: "mr--2" }),
                            React.createElement(Icon, { name: "chevron-right", size: 15 })),
                        React.createElement("span", { className: "sr-only" }, "\u00DAltima")))),
            React.createElement("p", { className: "text-center" },
                "Voc\u00EA est\u00E1 na p\u00E1gina ",
                currentPage,
                " de ",
                pageOptions.length))),
        withPageSize && !!pageOptions.length && (React.createElement("div", { className: "pagination-results" },
            React.createElement("select", { value: perPage, onChange: e => onChangePageSize(Number(e.target.value)), disabled: isLoading }, pageSizes.map(pageSize => (React.createElement("option", { key: pageSize, value: pageSize },
                pageSize,
                " resultados"))))))));
};
export default Pagination;
