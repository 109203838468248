import React from 'react';
import { Icon, Tooltip } from 'components';
import { FormatterUtils } from 'utils';
import './SolicitationNoteItemList.scss';
const SolicitationNoteItemList = ({ notes, onRemoveNote, isDisabled }) => {
    return (React.createElement("section", { className: "solicitation-note-list" }, notes.map(note => (React.createElement("div", { key: note.Id.toString(), className: "solicitation-note-list-item" },
        !isDisabled && (React.createElement(Tooltip, { id: `solicitation-note-list-item-${note.Id}-remove`, text: "Deletar" },
            React.createElement("button", { className: "solicitation-note-list-item-delete", title: "Deletar", onClick: () => onRemoveNote(note) },
                React.createElement(Icon, { name: "close", size: 10 })))),
        React.createElement("div", { className: "solicitation-note-list-item-content" },
            React.createElement("p", null, note.Note)),
        React.createElement("div", { className: "solicitation-note-list-item-date" },
            React.createElement(Icon, { name: "calendar", size: 15 }),
            React.createElement("small", null, FormatterUtils.dateTime(note.CreatedAt))))))));
};
export default SolicitationNoteItemList;
