const slugify = (value) => {
    const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
    const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');
    return value
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w-]+/g, '') // Remove all non-word characters
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
};
const camelCaseToSlug = (value) => {
    return value.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};
const camelCaseToCapitalize = (value) => {
    return capitalize(value.replace(/([a-z])([A-Z])/g, '$1 $2'));
};
const removeChars = ({ str, separator = '/' }, ...charsToBeRemoved) => {
    const strSplitted = str.split(separator);
    if (!strSplitted.length)
        return str;
    const strsToBeRemoved = charsToBeRemoved
        .filter((char) => {
        if (typeof char === 'string' || char instanceof Array)
            return char;
    })
        .flat();
    const regexToBeTested = charsToBeRemoved.filter((char) => char instanceof RegExp);
    const finalStr = strSplitted
        .map(str => {
        if (!strsToBeRemoved.includes(str))
            return str;
    })
        .map(str => {
        if (regexToBeTested.length && !regexToBeTested.find((regex) => regex.test(str)))
            return str;
    })
        .filter(x => !!x);
    return finalStr.join(separator);
};
const capitalize = (string) => {
    return string
        .split(' ')
        .map(word => {
        return word[0].toUpperCase() + word.substring(1);
    })
        .join(' ');
};
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
const normalize = (string) => {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
export const TextUtils = {
    slugify,
    camelCaseToSlug,
    camelCaseToCapitalize,
    removeChars,
    capitalize,
    capitalizeFirstLetter,
    normalize
};
