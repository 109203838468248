import React, { useCallback, useEffect, useMemo } from 'react';
import { usePDF } from '@react-pdf/renderer';
import { Button, Card, Icon, PdfPreview } from 'components';
import { ReportExportEndpoint, useReportExportMutation, useSolicitationDocumentList, useSolicitationDurationQuery, useSolicitationRevisionList } from 'hooks';
import SolicitationDetailedReportContentPdf from './SolicitationDetailedReportContentPdf';
const SolicitationDetailedReportContent = ({ solicitation }) => {
    const [solicitationDuration, isLoadingSolicitationDuration] = useSolicitationDurationQuery(solicitation?.Id);
    const [solicitationDocuments, isLoadingSolicitationDocuments] = useSolicitationDocumentList(solicitation?.Id);
    const [solicitationRevisions, isLoadingSolicitationRevisions] = useSolicitationRevisionList(solicitation?.Id);
    const solicitationPaymentFlow = useMemo(() => {
        if (!solicitation.SolicitationPaymentFlowHistory?.length)
            return;
        return solicitation.SolicitationPaymentFlowHistory[0];
    }, [solicitation]);
    const solicitationPaymentFlowInstallment = useMemo(() => {
        if (!solicitationPaymentFlow)
            return;
        if (!solicitationPaymentFlow.SolicitationPaymentFlowInstallments?.length)
            return;
        return solicitationPaymentFlow.SolicitationPaymentFlowInstallments[0];
    }, [solicitationPaymentFlow]);
    const PdfDocument = (React.createElement(SolicitationDetailedReportContentPdf, { solicitation: solicitation, solicitationDocuments: solicitationDocuments, solicitationDuration: solicitationDuration, solicitationRevisions: solicitationRevisions }));
    const [pdfInstance, updatePdfInstance] = usePDF({ document: PdfDocument });
    const pdfFileName = `solicitation-${solicitation.RegistrationCode}-detailed-report.pdf`;
    const [exportReport, isExporting] = useReportExportMutation(ReportExportEndpoint.SOLICITATION_DETAILED);
    const onExportReportClick = useCallback(async () => {
        await exportReport({
            file: pdfInstance.blob,
            solicitationId: solicitation.Id
        });
    }, [solicitation, pdfInstance, exportReport]);
    const isLoading = isLoadingSolicitationDuration ||
        isLoadingSolicitationDocuments ||
        isLoadingSolicitationRevisions ||
        !solicitationPaymentFlowInstallment ||
        pdfInstance?.loading;
    useEffect(() => {
        if (isLoading)
            return;
        updatePdfInstance();
    }, [isLoading, updatePdfInstance]);
    if (isLoading) {
        return (React.createElement(Card, { className: "detailed-report-content-wrapper" },
            React.createElement(Icon, { name: "spinner", size: 35, className: "block text-gray-light dark:text-gray-400 mx-auto" })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex justify-end mb-4" },
            React.createElement("a", { href: pdfInstance.url, download: pdfFileName },
                React.createElement(Button, { text: "Imprimir", icon: "printer", color: "info", animationHover: "pop" })),
            React.createElement(Button, { text: "Enviar por e-mail", icon: "mail", color: "secondary", animationHover: "pop", onClick: onExportReportClick, loading: isExporting, className: "ml-2" })),
        React.createElement(PdfPreview, { pdfInstance: pdfInstance })));
};
export default SolicitationDetailedReportContent;
