import React, { useEffect, memo, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import clsx from 'clsx';
import './Modal.scss';
const Modal = memo(({ title, show, closable = true, scroll = true, className, handleClose, size = 'md', children }) => {
    const handleKeyDown = useCallback((ev) => {
        if (ev.key === 'Escape' && closable && handleClose && show)
            handleClose();
    }, [show, closable, handleClose]);
    useEffect(() => {
        show ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto');
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.body.style.overflow = 'auto';
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [show, handleKeyDown]);
    const modalElement = (React.createElement(TransitionGroup, { component: null }, show && (React.createElement(CSSTransition, { classNames: "modal", timeout: 400 },
        React.createElement("div", { className: "modal" },
            React.createElement("div", { className: clsx('modal-content', `modal-${size}`, !title && 'no-header', !!className && className) },
                closable && (React.createElement("button", { className: "modal-close", onClick: handleClose },
                    React.createElement("span", null, "\u00D7"))),
                title && (React.createElement("div", { className: "modal-header" },
                    React.createElement("h1", null, title))),
                React.createElement("div", { className: "modal-body-wrapper" }, scroll ? (React.createElement(PerfectScrollbar, { className: "modal-scroll content-scroll", options: { wheelSpeed: 0.15 } },
                    React.createElement("div", { className: "modal-body-content" },
                        React.createElement("div", { className: "modal-body" }, children)))) : (React.createElement("div", { className: "modal-body" }, children)))))))));
    return ReactDOM.createPortal(modalElement, document.body);
});
export default Modal;
