import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { PLAN_QUERY_KEY } from './usePlanConfig';
export const usePlanQuery = (planId) => {
    const { errorCallback } = useAlert();
    const navigate = useNavigate();
    const getPlan = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/plan/${planId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'PLAN_GET_FAILURE');
            navigate('/cam/plans');
            throw error;
        });
    }, [errorCallback, planId, navigate]);
    const { data, isLoading } = useQuery([PLAN_QUERY_KEY, planId], getPlan, {
        enabled: !!planId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
