import { ScopeAction, ScopeModule } from 'types/enum';
export const moduleScopeLabel = {
    [ScopeModule.AIRCRAFT_MODEL]: 'Modelos de Aeronave',
    [ScopeModule.BANK]: 'Bancos',
    [ScopeModule.BENEFICIARY]: 'Associados',
    [ScopeModule.BENEFIT]: 'Benefícios',
    [ScopeModule.BENEFIT_CATEGORY]: 'Categorias de Benefícios',
    [ScopeModule.BENEFIT_CONSUMPTION]: 'Consumos de Benefícios',
    [ScopeModule.BENEFIT_CONSUMPTION_BATCH]: 'Importação de Consumos de Benefícios',
    [ScopeModule.BENEFIT_CONSUMPTION_MONTHLY_BALANCE]: 'Relatório de Balanço Mensal',
    [ScopeModule.BENEFIT_CONSUMPTION_MONTHLY_BALANCE_MANUAL_CALCULATION]: 'Cálculo Manual Consumos de Benefício',
    [ScopeModule.COMPENSATION_RATE]: 'Carência (Escalonamento)',
    [ScopeModule.DAILY_RATE]: 'Diárias',
    [ScopeModule.DEPENDENT]: 'Dependentes',
    [ScopeModule.DESEASE]: 'Anexo 10',
    [ScopeModule.JOB_DESCRIPTION]: 'Cargos',
    [ScopeModule.MONTHLY_BASE_PERCENTAGE]: 'Porcentagens Mensais',
    [ScopeModule.OPERATION_BASE]: 'Bases de Operação',
    [ScopeModule.PLAN]: 'Planos',
    [ScopeModule.PREMIUM_RECIPIENT]: 'Premiados',
    [ScopeModule.RECOMPOSITION_INEQUALITY]: 'RDV',
    [ScopeModule.REPORT]: 'Relatórios',
    [ScopeModule.SOLICITATION]: 'Requerimentos',
    [ScopeModule.SOLICITATION_DOCUMENT]: 'Anexos dos Requerimentos',
    [ScopeModule.SOLICITATION_NOTE]: 'Notas dos Requerimentos',
    [ScopeModule.SOLICITATION_PAYMENT_FLOW]: 'Fluxo de Pagamento dos Requerimentos',
    [ScopeModule.SOLICITATION_REVISION]: 'Revisão dos Requerimentos',
    [ScopeModule.USER]: 'Usuários',
    [ScopeModule.SOLICITATION_LEFT_OVER_MONEY]: 'Caixa CAM',
    [ScopeModule.AWAY_WITHOUT_SOLICITATION]: 'Afastados',
    [ScopeModule.FINANCIAL]: 'Financeiro',
    [ScopeModule.FINANCIAL_STATEMENT]: 'Extrato Financeiro',
    [ScopeModule.ALERTS]: 'Alertas',
    [ScopeModule.AWAY_BY_CAM]: 'Afastados pelo CAM',
    [ScopeModule.AWAY_REASON]: 'Motivo Afastamento'
};
export const actionScopeLabel = {
    [ScopeAction.List]: 'Listar',
    [ScopeAction.Read]: 'Ler',
    [ScopeAction.Create]: 'Criar',
    [ScopeAction.Update]: 'Atualizar',
    [ScopeAction.Delete]: 'Deletar',
    [ScopeAction.Report]: 'Exportar',
    [ScopeAction.Execute]: 'Executar'
};
