import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip, Icon } from 'components';
import { useModulePermissions, useTableFilter, useDocumentTitle, useRecompositionInequalityPagination } from 'hooks';
import { FormatterUtils } from 'utils';
import { RecompositionInequalityTableFilterForm } from '../components';
const RecompositionInequalityList = () => {
    const { canCreate, canRead } = useModulePermissions();
    const customParamsHandle = useCallback((filterParams) => {
        const params = {};
        if (filterParams.PersonDocument) {
            params.PersonDocument = filterParams.PersonDocument;
        }
        if (filterParams.SolicitationCode) {
            params.SearchField = 'SolicitationCode';
            params.SearchQuery = filterParams.SolicitationCode;
        }
        if (filterParams.PersonCallName) {
            params.SearchField = 'CallName';
            params.SearchQuery = filterParams.PersonCallName;
        }
        return params;
    }, []);
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter({
        customParamsHandle
    });
    const { data, isLoading, ...pagination } = useRecompositionInequalityPagination(requestFilterParams);
    const columns = useMemo(() => [
        {
            Header: 'Associado',
            accessor: 'Solicitation',
            id: 'SolicitationBeneficiary',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement(Link, { to: `/beneficiaries/${value.Beneficiary.Id}`, target: "_blank" },
                React.createElement("span", { className: "flex items-center font-semibold text-gray-light dark:text-gray-50" },
                    value.Beneficiary.Name,
                    React.createElement(Icon, { name: "new-tab", size: 14, className: "text-blue-light ml-1" }))))
        },
        {
            Header: 'Requerimento',
            accessor: 'Solicitation',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement(Link, { to: `/cam/solicitations/${value.Id}`, target: "_blank" },
                    React.createElement("span", { className: "flex items-center font-semibold text-gray-light dark:text-gray-50" },
                        value.RegistrationCode,
                        React.createElement(Icon, { name: "new-tab", size: 14, className: "text-blue-light ml-1" }))));
            }
        },
        {
            Header: 'Data RDV',
            accessor: 'CreatedAt',
            Cell: ({ cell: { value } }) => FormatterUtils.date(value)
        },
        {
            Header: 'Data Requerimento',
            accessor: 'Solicitation',
            id: 'SolicitationStartDate',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => FormatterUtils.date(value.StartDate)
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" }, canRead && (React.createElement(Link, { to: `/cam/recomposition-inequalities/${value}` },
                    React.createElement(Tooltip, { id: `recomposition-inequality-${value}-view`, text: "Visualizar" },
                        React.createElement(Button, { icon: "eye", color: "info", rounded: true }))))));
            }
        }
    ], [canRead]);
    useDocumentTitle('RDV');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "RDV" }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            canCreate && (React.createElement(Link, { to: "/cam/recomposition-inequalities/new" },
                React.createElement(Button, { text: "Novo RDV", icon: "add", color: "info", animationHover: "pop" }))),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "RDV", formConfig: { filterParams }, handleFilter: setParams, customFilters: RecompositionInequalityTableFilterForm }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhum RDV a ser exibido" })));
};
export default RecompositionInequalityList;
