import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Loading, Card } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useDocumentTitle, useDeseaseQuery, useDeseaseMutation } from 'hooks';
import moment from 'moment-timezone';
import { DeseaseForm } from '../components';
const DeseaseInternal = () => {
    const deseaseIdRoute = parseInt(useParams()?.deseaseId ?? '0');
    const { success, errorCallback } = useAlert();
    const navigate = useNavigate();
    const [desease, isLoading] = useDeseaseQuery(deseaseIdRoute);
    const { create, update } = useDeseaseMutation(deseaseIdRoute);
    async function submitDesease(deseaseData) {
        const deseaseDataSubmit = { ...deseaseData };
        deseaseDataSubmit.CreatedAt = moment().format();
        deseaseDataSubmit.UpdatedAt = null;
        if (deseaseData.Id) {
            return update(deseaseDataSubmit, {
                onSuccess: () => success({
                    title: 'Anexo 10 atualizado!',
                    message: 'Dados do anexo 10 atualizados com sucesso.',
                    id: 'DESEASE_PUT_SUCCESS'
                }),
                onError: err => errorCallback(err, 'DESEASE_PUT_FAILURE')
            });
        }
        else {
            return create(deseaseDataSubmit, {
                onSuccess: res => {
                    success({
                        title: 'Anexo 10 cadastrado!',
                        message: 'Anexo 10 cadastrado com sucesso.',
                        id: 'DESEASE_POST_SUCCESS'
                    });
                    navigate(`/cam/deseases/${res.Id}`);
                },
                onError: err => errorCallback(err, 'DESEASE_POST_FAILURE')
            });
        }
    }
    const pageTitle = useMemo(() => `${deseaseIdRoute ? 'Visualizar' : 'Novo'} Anexo 10`, [deseaseIdRoute]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Anexo 10',
            Url: '/cam/deseases'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Anexo 10", breadcrumb: breadcrumb }),
        React.createElement(Loading, { show: isLoading }),
        React.createElement(Card, { id: "desease-card" },
            React.createElement(DeseaseForm, { desease: desease, isLoading: isLoading, handleSubmit: submitDesease }))));
};
export default DeseaseInternal;
