import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { ArrayUtils } from 'utils';
import { SOLICITATION_NOTE_LIST_KEY } from './useSolicitationNoteConfig';
export const useSolicitationNoteList = (solicitationId, shouldFetch) => {
    const { errorCallback } = useAlert();
    const getSolicitationNotes = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/solicitation-note/${solicitationId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'SOLICITATION_NOTE_GET_ALL_FAILURE');
            return [];
        });
    }, [errorCallback, solicitationId]);
    const { data, isLoading } = useQuery([SOLICITATION_NOTE_LIST_KEY, solicitationId, shouldFetch], getSolicitationNotes, {
        enabled: !!shouldFetch && !!solicitationId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    const memoizedData = useMemo(() => (data ? ArrayUtils.sortDesc(data, 'CreatedAt') : []), [data]);
    return [memoizedData, isLoading];
};
