import React, { useCallback, useState } from 'react';
import { Button, Modal, Select } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useSolicitationMutation } from 'hooks';
import { convertStateEnumListToOptionList, solicitationStateReviewReasonOptions, SolicitationStatus } from 'types/enum';
const solicitationStateCancelledReasonSelectOptions = convertStateEnumListToOptionList(solicitationStateReviewReasonOptions);
const SolicitationReviewDialog = ({ solicitationId }) => {
    const { success, errorCallback } = useAlert();
    const [showDialog, setShowDialog] = useState(false);
    const [reason, setReason] = useState(null);
    const { createState, isCreatingSolicitationState } = useSolicitationMutation();
    const toggleDialog = useCallback(() => setShowDialog(showDialog => !showDialog), []);
    const reviewSolicitation = useCallback(async () => {
        const solicitationStateData = {
            SolicitationId: solicitationId,
            StatusId: SolicitationStatus.IN_APPROVAL,
            StateReason: reason
        };
        createState(solicitationStateData, {
            onSuccess: () => {
                success({
                    title: 'Revisão iniciada!',
                    message: 'Revisão da solicitação iniciada com sucesso.',
                    id: 'SOLICITATION_REVIEW_START_SUCCESS'
                });
                toggleDialog();
            },
            onError: err => errorCallback(err, 'SOLICITATION_REVIEW_START_FAILURE')
        });
    }, [createState, success, toggleDialog, errorCallback, solicitationId, reason]);
    const onSelectReason = useCallback((value) => {
        const selectedValue = value;
        setReason(selectedValue.value);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { text: "Nova Aprova\u00E7\u00E3o do Requerimento", color: "warning", animationHover: "pop", onClick: toggleDialog, className: "mr-2 mb-2 lg:mb-0" }),
        React.createElement(Modal, { show: showDialog, size: "alert", closable: false, title: "Nova Aprova\u00E7\u00E3o do Requerimento" },
            React.createElement("p", { className: "text-gray dark:text-gray-50 mb-4" }, "Voc\u00EA tem certeza que deseja colocar essa solicita\u00E7\u00E3o para vota\u00E7\u00E3o?"),
            React.createElement(Select, { name: "StateReason", label: "Escolha o motivo", options: solicitationStateCancelledReasonSelectOptions, value: solicitationStateCancelledReasonSelectOptions?.find((x) => x.value === reason), onChange: onSelectReason, getOptionLabel: option => option.label, getOptionValue: option => option.label.toString() }),
            React.createElement("div", { className: "flex justify-end mt-4" },
                React.createElement(Button, { color: "success", className: "mr-2", loading: isCreatingSolicitationState, disabled: !reason, onClick: reviewSolicitation, text: "Iniciar" }),
                React.createElement(Button, { color: "danger", onClick: toggleDialog, disabled: isCreatingSolicitationState, text: "Voltar" })))));
};
export default SolicitationReviewDialog;
