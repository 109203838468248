import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { DAILY_RATE_LIST_QUERY_KEY, DAILY_RATE_PAGINATION_QUERY_KEY, DAILY_RATE_QUERY_KEY } from './useDailyRateConfig';
export const useDailyRateMutation = (dailyRateId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createDailyRate = useCallback(async (dailyRateData) => {
        return httpService.post('/daily-rate', dailyRateData);
    }, [httpService]);
    const updateDailyRate = useCallback(async (dailyRateData) => {
        return httpService.put('/daily-rate', dailyRateData);
    }, [httpService]);
    const removeDailyRate = useCallback(async (dailyRateId) => {
        return httpService.delete(`/daily-rate?id=${dailyRateId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createDailyRate, {
        onSuccess: () => {
            queryClient.invalidateQueries(DAILY_RATE_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries(DAILY_RATE_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateDailyRate, {
        onSuccess: () => {
            queryClient.invalidateQueries(DAILY_RATE_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries([DAILY_RATE_QUERY_KEY, dailyRateId]);
            queryClient.invalidateQueries(DAILY_RATE_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removeDailyRate, {
        onSuccess: (_, dailyRateId) => {
            queryClient.invalidateQueries(DAILY_RATE_PAGINATION_QUERY_KEY);
            queryClient.removeQueries([DAILY_RATE_QUERY_KEY, dailyRateId]);
            queryClient.invalidateQueries(DAILY_RATE_LIST_QUERY_KEY);
        }
    });
    return { create, update, remove, isCreating, isUpdating, isRemoving };
};
