import React, { useMemo } from 'react';
import { PdfContent, PdfContentElements } from 'components';
import _ from 'lodash';
import moment from 'moment-timezone';
import { FormatterUtils } from 'utils';
const BeneficiaryFinancialHistoryExportPdf = ({ beneficiary, beneficiaryFinancialHistory }) => {
    const groupedBeneficiaryFinancialHistory = useMemo(() => _(beneficiaryFinancialHistory)
        .groupBy('Date')
        .map((value, key) => ({
        Date: moment(key),
        Registers: value,
        TotalValue: value.reduce((acc, cur) => (acc += cur.BenefitConsumption.Value), 0) * -1
    }))
        .value(), [beneficiaryFinancialHistory]);
    const dateNow = useMemo(() => moment().format('D [de] MMMM [de] YYYY'), []);
    const BeneficiaryFinancialHistorySubtitle = (React.createElement(React.Fragment, null,
        React.createElement(PdfContentElements.Grid, null,
            React.createElement(PdfContentElements.GridItem, { size: 4 },
                React.createElement(PdfContentElements.TextBold, { fontSize: 12 }, beneficiary.CallName),
                React.createElement(PdfContentElements.TextBase, null, beneficiary.JobDescription.Name),
                React.createElement(PdfContentElements.TextBase, null, beneficiary.BeneficiaryType.Name)),
            React.createElement(PdfContentElements.GridItem, { size: 4 },
                React.createElement(PdfContentElements.TextBase, null, beneficiary.Name),
                React.createElement(PdfContentElements.TextBase, null, FormatterUtils.phone(beneficiary.CellPhone)),
                React.createElement(PdfContentElements.TextBase, null, FormatterUtils.zipCode(beneficiary.Address.ZipCode)),
                React.createElement(PdfContentElements.TextBase, null,
                    beneficiary.Address.Street,
                    ", ",
                    beneficiary.Address.Number,
                    " ",
                    beneficiary.Address.Complement),
                React.createElement(PdfContentElements.TextBase, null,
                    beneficiary.Address.Neighborhood,
                    ", ",
                    beneficiary.Address.City.Name,
                    " - ",
                    beneficiary.Address.State.Code),
                React.createElement(PdfContentElements.View, { marginTop: 4 },
                    React.createElement(PdfContentElements.TextBase, { fontSize: 9 },
                        "S\u00F3cio desde: ",
                        FormatterUtils.date(beneficiary.AssociationStartDate)))))));
    const BeneficiaryFinancialHistoryBody = (React.createElement(React.Fragment, null, groupedBeneficiaryFinancialHistory.map((x, i) => (React.createElement(PdfContentElements.View, { key: i, marginBottom: 32 },
        React.createElement(PdfContentElements.Table, { columns: [
                {
                    header: `Folha de ${x.Date.format('MM')} de ${x.Date.format('YYYY')}`,
                    value: register => (React.createElement(React.Fragment, null,
                        React.createElement(PdfContentElements.TextMedium, null, register.BenefitConsumption?.Benefit?.Name))),
                    footer: 'Total do Mês',
                    style: { column: { flex: 2 } }
                },
                {
                    header: 'Valor',
                    value: register => (React.createElement(PdfContentElements.TextMedium, null, FormatterUtils.money(register.BenefitConsumption.Value * -1))),
                    footer: FormatterUtils.money(x.TotalValue),
                    style: { column: { alignItems: 'flex-end' } }
                }
            ], data: x.Registers, style: {
                row: { alignItems: 'center' },
                header: { backgroundColor: '#E9E9E9' },
                footer: { backgroundColor: '#E9E9E9' }
            } }))))));
    return (React.createElement(PdfContent, { title: "Extrato de Tripulante", subtitle: BeneficiaryFinancialHistorySubtitle, caption: dateNow, body: BeneficiaryFinancialHistoryBody }));
};
export default BeneficiaryFinancialHistoryExportPdf;
