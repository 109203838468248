import React, { useCallback, useEffect, useMemo } from 'react';
import { usePDF } from '@react-pdf/renderer';
import { Button, Card, Icon, PdfPreview } from 'components';
import { ReportExportEndpoint, useBenefitConsumptionList, useReportExportMutation } from 'hooks';
import { FormatterUtils } from 'utils';
import MonthlyReceiptContentPdf from './MonthlyReceiptContentPdf';
const MonthlyReceiptContent = ({ solicitation, solicitationPaymentFlowInstallment }) => {
    const benefitConsumptionsFilterParams = useMemo(() => {
        const momentMonthYearReference = FormatterUtils.momentDate(solicitationPaymentFlowInstallment.MonthYear?.toString());
        const startDate = momentMonthYearReference.utc().startOf('month').format();
        const endDate = momentMonthYearReference.utc().endOf('month').format();
        return {
            PersonDocument: solicitation?.Beneficiary?.CPF,
            StartDate: startDate,
            EndDate: endDate
        };
    }, [solicitation, solicitationPaymentFlowInstallment]);
    const [benefitConsumptions, isLoadingBenefitConsumptions] = useBenefitConsumptionList(benefitConsumptionsFilterParams);
    const PdfDocument = (React.createElement(MonthlyReceiptContentPdf, { solicitation: solicitation, solicitationPaymentFlowInstallment: solicitationPaymentFlowInstallment, benefitConsumptions: benefitConsumptions }));
    const [pdfInstance, updatePdfInstance] = usePDF({ document: PdfDocument });
    const month = FormatterUtils.momentDate(solicitationPaymentFlowInstallment.MonthYear?.toString())?.format('MMM');
    const pdfFileName = `solicitation-${solicitation.RegistrationCode}-monthly-receipt-${month}.pdf`;
    const [exportReport, isExporting] = useReportExportMutation(ReportExportEndpoint.SOLICITATION_MONTHLY_RECEIPT);
    const onExportReportClick = useCallback(async () => {
        await exportReport({
            file: pdfInstance.blob,
            solicitationId: solicitation.Id,
            monthYear: solicitationPaymentFlowInstallment.MonthYear?.toString()
        });
    }, [solicitation, solicitationPaymentFlowInstallment, pdfInstance, exportReport]);
    const isLoading = isLoadingBenefitConsumptions;
    useEffect(() => {
        if (isLoading)
            return;
        updatePdfInstance();
    }, [isLoading, updatePdfInstance]);
    if (isLoading) {
        return (React.createElement(Card, { className: "monthly-receipt-content-wrapper" },
            React.createElement(Icon, { name: "spinner", size: 35, className: "block text-gray-light dark:text-gray-400 mx-auto" })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex justify-end mb-4" },
            React.createElement("a", { href: pdfInstance.url, download: pdfFileName },
                React.createElement(Button, { text: "Imprimir", icon: "printer", color: "info", animationHover: "pop" })),
            React.createElement(Button, { text: "Enviar por e-mail", icon: "mail", color: "secondary", animationHover: "pop", onClick: onExportReportClick, loading: isExporting, className: "ml-2" })),
        React.createElement(PdfPreview, { pdfInstance: pdfInstance })));
};
export default MonthlyReceiptContent;
