import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { makeHttpService } from 'services';
export const useStates = (fetchData = true) => {
    const httpService = makeHttpService();
    const [states, setStates] = useState([]);
    const getStates = useCallback(() => {
        return httpService.get('/address/states');
    }, [httpService]);
    const { data, isLoading } = useQuery(['states'], getStates, {
        enabled: fetchData
    });
    useEffect(() => {
        if (data)
            setStates(data);
    }, [data]);
    return [states, isLoading];
};
