import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip } from 'components';
import { useModulePermissions, useTableFilter, useDocumentTitle, useReportBeneficiaryByAgeQuery } from 'hooks';
import { FormatterUtils } from 'utils';
import { BeneficiaryAgeTableFilterForm } from './components';
const BeneficiaryAgeReport = () => {
    const { canRead } = useModulePermissions();
    const customParamsHandle = useCallback((filterParams) => {
        const params = {};
        if (filterParams.AgeHigher) {
            params.SearchField = 'AgeHigher';
            params.SearchQuery = filterParams.AgeHigher;
        }
        if (filterParams.PersonDocument) {
            params.PersonDocument = filterParams.PersonDocument;
        }
        if (filterParams.BeneficiaryTypeId) {
            params.BeneficiaryTypeId = filterParams.BeneficiaryTypeId;
        }
        if (filterParams.AssociateCode) {
            params.SearchField = 'AssociateCode';
            params.SearchQuery = filterParams.AssociateCode;
        }
        if (filterParams.CallName) {
            params.SearchField = 'CallName';
            params.SearchQuery = filterParams.CallName;
        }
        return params;
    }, []);
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter({
        customParamsHandle
    });
    const { data, isLoading, isFirstCall, ...pagination } = useReportBeneficiaryByAgeQuery(requestFilterParams);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Relatórios'
        },
        {
            Title: 'Relatório de Associados'
        }
    ], []);
    const columns = useMemo(() => [
        {
            Header: 'Nome',
            accessor: 'Name'
        },
        {
            Header: 'CPF',
            accessor: 'CPF',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => FormatterUtils.cpf(value)
        },
        {
            Header: 'Idade',
            accessor: 'Age',
            Cell: ({ cell: { value } }) => `${value} anos`
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" }, canRead && (React.createElement(Link, { to: `/beneficiaries/${value}` },
                    React.createElement(Tooltip, { id: `beneficiaries-${value}-edit`, text: "Visualizar" },
                        React.createElement(Button, { icon: "eye", color: "info", rounded: true }))))));
            }
        }
    ], [canRead]);
    useDocumentTitle('Relatório de Associados');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Relat\u00F3rio de Associados", breadcrumb: breadcrumb }),
        React.createElement("div", { className: clsx('flex mb-4 justify-end') },
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Relat\u00F3rio", formConfig: { filterParams }, handleFilter: setParams, customFilters: BeneficiaryAgeTableFilterForm }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, isFirstCall: isFirstCall, ...pagination, noResultsMessage: "Nenhum associado a ser exibido" })));
};
export default BeneficiaryAgeReport;
