import React, { useMemo } from 'react';
import { PdfContent, PdfContentElements } from 'components';
import { SolicitationPaymentFlowInstallmentDefaultValues } from 'configs';
import moment from 'moment';
import { FormatterUtils } from 'utils';
const MonthlyReceiptContentPdf = ({ solicitation, solicitationPaymentFlowInstallment, benefitConsumptions }) => {
    const [paymentFlowStartDate, paymentFlowEndDate] = useMemo(() => {
        const startDate = moment(solicitationPaymentFlowInstallment.StartDate);
        const endDate = moment(solicitationPaymentFlowInstallment.EndDate);
        return [startDate, endDate];
    }, [solicitationPaymentFlowInstallment]);
    const paymentFlowInstallmentDate = useMemo(() => moment(solicitationPaymentFlowInstallment.MonthYear).format('MM[/]YYYY'), [solicitationPaymentFlowInstallment]);
    const paymentFlowInstallmentSubtotalValue = useMemo(() => solicitationPaymentFlowInstallment.WorkAccident
        ? solicitationPaymentFlowInstallment.TotalValueAfterAccidentPercentage
        : solicitationPaymentFlowInstallment.TotalValueAfterMonthlyPercentage, [solicitationPaymentFlowInstallment]);
    const benefitConsumptionTotalValue = useMemo(() => benefitConsumptions.reduce((acc, cur) => (acc += cur.Value), 0) ?? 0, [benefitConsumptions]);
    const paymentFlowInstallmentTotalValue = useMemo(() => paymentFlowInstallmentSubtotalValue - benefitConsumptionTotalValue, [benefitConsumptionTotalValue, paymentFlowInstallmentSubtotalValue]);
    const dateNow = useMemo(() => moment().format('D [de] MMMM [de] YYYY'), []);
    const MonthlyReceiptReportSubtitle = (React.createElement(React.Fragment, null,
        React.createElement(PdfContentElements.TextBase, { fontSize: 12, style: { color: '#6F6F6F', marginBottom: 2 } },
            "Recibo ",
            paymentFlowInstallmentDate),
        React.createElement(PdfContentElements.TextBase, { fontSize: 12, style: { color: '#6F6F6F' } },
            "Requerimento ",
            solicitation.RegistrationCode)));
    const SolicitationDetailsCard = (React.createElement(PdfContentElements.Box, null,
        React.createElement(PdfContentElements.View, { marginBottom: 8 },
            React.createElement(PdfContentElements.TextBold, null, "Detalhes")),
        React.createElement(PdfContentElements.Grid, null,
            React.createElement(PdfContentElements.GridItem, { size: 4, marginBottom: 4 },
                React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Nome Guerra"),
                React.createElement(PdfContentElements.TextBase, null, solicitation.Beneficiary.CallName)),
            React.createElement(PdfContentElements.GridItem, { size: 4, marginBottom: 4 },
                React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Nome Completo"),
                React.createElement(PdfContentElements.TextBase, null, solicitation.Beneficiary.Name)),
            React.createElement(PdfContentElements.GridItem, { size: 4, marginBottom: 4 },
                React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Cargo"),
                React.createElement(PdfContentElements.TextBase, null, solicitation.Beneficiary.JobDescription?.Name ?? '')),
            React.createElement(PdfContentElements.GridItem, { size: 4, marginBottom: 4 },
                React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Motivo"),
                React.createElement(PdfContentElements.TextBase, null, solicitation.WorkAccident ? 'Acidente de Trabalho' : 'Anexo 10')),
            React.createElement(PdfContentElements.GridItem, { size: 4, marginBottom: 4 },
                React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Per\u00EDodo"),
                React.createElement(PdfContentElements.TextBase, null,
                    FormatterUtils.date(paymentFlowStartDate),
                    " - ",
                    FormatterUtils.date(paymentFlowEndDate))),
            React.createElement(PdfContentElements.GridItem, { size: 4, marginBottom: 4 },
                React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Prazo pelo Processo CAM"),
                React.createElement(PdfContentElements.TextBase, null,
                    solicitationPaymentFlowInstallment.DailyRateAmount,
                    " dias")))));
    const SolicitationResumeCard = (React.createElement(PdfContentElements.Box, { dark: true, marginTop: 12 },
        Boolean(solicitationPaymentFlowInstallment.TotalValueAfterAccidentPercentage) && (React.createElement(React.Fragment, null,
            React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
                React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Taxa Fixa de Acidente de Trabalho"),
                React.createElement(PdfContentElements.TextBase, null, FormatterUtils.percentage(SolicitationPaymentFlowInstallmentDefaultValues.DEFAULT_WORK_ACCIDENT_PERCENTAGE))))),
        React.createElement(PdfContentElements.Flex, { justifyContent: "space-between" },
            React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Subtotal"),
            React.createElement(PdfContentElements.TextBase, { fontSize: 12 }, FormatterUtils.money(paymentFlowInstallmentSubtotalValue)))));
    const BenefitConsumptionCard = (React.createElement(PdfContentElements.Box, { marginTop: 12 },
        React.createElement(PdfContentElements.View, { marginBottom: 8 },
            React.createElement(PdfContentElements.TextBold, null, "Consumos")),
        React.createElement(PdfContentElements.Table, { columns: [
                {
                    header: 'Evento',
                    value: benefitConsumption => (React.createElement(React.Fragment, null,
                        React.createElement(PdfContentElements.TextMedium, null, benefitConsumption?.Benefit?.Name ?? benefitConsumption?.Plan?.Name),
                        React.createElement(PdfContentElements.TextBase, { fontSize: 9, style: { color: '#6F6F6F' } }, benefitConsumption.BenefitId ? 'Benefício' : 'Plano'))),
                    style: { column: { flex: 2 } }
                },
                {
                    header: 'Data',
                    value: benefitConsumption => (React.createElement(PdfContentElements.TextBase, { fontSize: 10 }, FormatterUtils.dateTime(benefitConsumption.CreatedAt)))
                },
                {
                    header: 'Valor',
                    value: benefitConsumption => (React.createElement(PdfContentElements.TextMedium, null, FormatterUtils.money(benefitConsumption.Value))),
                    style: { column: { alignItems: 'flex-end' } }
                }
            ], data: benefitConsumptions, style: { row: { alignItems: 'center' } } }),
        React.createElement(PdfContentElements.View, { marginTop: 8, style: { paddingHorizontal: 8 } },
            React.createElement(PdfContentElements.Flex, { justifyContent: "space-between" },
                React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Subtotal"),
                React.createElement(PdfContentElements.TextMedium, null, FormatterUtils.money(paymentFlowInstallmentTotalValue))))));
    const SolicitationValueCard = (React.createElement(PdfContentElements.View, { marginTop: 12 },
        React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
            React.createElement(PdfContentElements.TextBase, { fontSize: 9 }, "Valor Final"),
            React.createElement(PdfContentElements.TextBold, { fontSize: 14 }, FormatterUtils.money(paymentFlowInstallmentTotalValue)))));
    const MonthlyReceiptReportBody = (React.createElement(React.Fragment, null,
        SolicitationDetailsCard,
        SolicitationResumeCard,
        BenefitConsumptionCard,
        SolicitationValueCard));
    return (React.createElement(PdfContent, { title: "Processo CAM - Conv\u00EAnio de Aux\u00EDlio M\u00FAtuo", subtitle: MonthlyReceiptReportSubtitle, caption: dateNow, body: MonthlyReceiptReportBody }));
};
export default MonthlyReceiptContentPdf;
