import React, { useMemo } from 'react';
import { Icon } from 'components';
import { useAuth } from 'contexts/auth.context';
import { useSolicitationApprovalQuery } from 'hooks';
import { SolicitationStatus } from 'types/enum';
import { SolicitationUtils } from 'utils';
import { SolicitationCard } from '../Card';
import { SolicitationCancelDialog } from './CancelDialog';
import { SolicitationExtendDialog } from './ExtendDialog';
import { SolicitationFinishDialog } from './FinishDialog';
import { SolicitationReviewDialog } from './ReviewDialog';
import { SolicitationVoteDialog } from './VoteDialog';
const SolicitationActionCard = ({ solicitationId, solicitationStates, isDisabled }) => {
    const { user } = useAuth();
    const solicitationStateToBeApproved = useMemo(() => solicitationStates.find(x => x.SolicitationStateApproval && !x.SolicitationStateApproval.Approved) ||
        {}, [solicitationStates]);
    const solicitationCurrentState = useMemo(() => SolicitationUtils.getLastState(solicitationStates), [
        solicitationStates
    ]);
    const isSolicitationCancelled = useMemo(() => solicitationCurrentState.StatusId === SolicitationStatus.CANCELLED, [
        solicitationCurrentState
    ]);
    const canFinishSolicitation = useMemo(() => [SolicitationStatus.IN_PROGRESS].includes(solicitationCurrentState.StatusId), [solicitationCurrentState]);
    const canCancelSolicitation = useMemo(() => [SolicitationStatus.IN_APPROVAL, SolicitationStatus.IN_PROGRESS, SolicitationStatus.IN_REVISION].includes(solicitationCurrentState.StatusId), [solicitationCurrentState]);
    const canStartNewSolicitationReview = useMemo(() => [SolicitationStatus.IN_PROGRESS].includes(solicitationCurrentState.StatusId), [solicitationCurrentState]);
    const canExtendSolicitation = useMemo(() => [SolicitationStatus.IN_PROGRESS].includes(solicitationCurrentState.StatusId), [solicitationCurrentState]);
    const [solicitationApproval, isLoadingSolicitationApproval] = useSolicitationApprovalQuery(solicitationStateToBeApproved?.Id);
    const canReviewSolicitation = useMemo(() => {
        if (!user.IsSolicitationApprover || isSolicitationCancelled)
            return false;
        return solicitationApproval.SolicitationStateApprovalVotes?.some(x => x.UserId === user.Id && x.Vote === null);
    }, [user, solicitationApproval, isSolicitationCancelled]);
    const hasActionsAvailable = canExtendSolicitation ||
        canFinishSolicitation ||
        canCancelSolicitation ||
        canStartNewSolicitationReview ||
        canReviewSolicitation;
    const ButtonActionElement = (React.createElement("div", { className: "flex justify-center flex-col lg:flex-row" },
        canReviewSolicitation && (React.createElement(SolicitationVoteDialog, { solicitationStateToBeApproved: solicitationStateToBeApproved })),
        canStartNewSolicitationReview && React.createElement(SolicitationReviewDialog, { solicitationId: solicitationId }),
        canFinishSolicitation && React.createElement(SolicitationFinishDialog, { solicitationId: solicitationId }),
        canCancelSolicitation && React.createElement(SolicitationCancelDialog, { solicitationId: solicitationId }),
        canExtendSolicitation && React.createElement(SolicitationExtendDialog, { solicitationId: solicitationId })));
    if (!hasActionsAvailable || isDisabled)
        return null;
    if (isLoadingSolicitationApproval)
        return (React.createElement(SolicitationCard, { title: "A\u00E7\u00F5es", className: "mb-4" },
            React.createElement(Icon, { name: "spinner", size: 35, className: "text-gray-light dark:text-gray-400" })));
    return (React.createElement(SolicitationCard, { title: "A\u00E7\u00F5es", className: "mb-4", classes: {
            textColumn: 'mb-2 lg:mb-0',
            buttonColumn: 'justify-center lg:justify-end'
        }, customButtom: ButtonActionElement, showButtonAction: true }));
};
export default SolicitationActionCard;
