import React from 'react';
import { Col, Row } from 'react-grid-system';
import { DateRangeField, FormField, MaskedTextField, TextField } from 'components';
const BenefitConsumptionMonthlyBalanceTableFilterForm = ({ values, setFieldValue, handleChange }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "PersonCallName", label: "Nome Guerra", value: values.PersonCallName ?? '', onChange: handleChange }))),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "cpf", name: "PersonDocument", label: "CPF do associado", value: values.PersonDocument ?? '', onChange: value => setFieldValue('PersonDocument', value) })))),
        React.createElement(Row, { justify: "end" },
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(DateRangeField, { startDate: values.DateRange[0], endDate: values.DateRange[1], onDatesChange: ({ startDate, endDate }) => setFieldValue('DateRange', [startDate, endDate]), isOutsideRange: () => false }))))));
};
export default BenefitConsumptionMonthlyBalanceTableFilterForm;
