import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { BANK_LIST_QUERY_KEY, BANK_PAGINATION_QUERY_KEY, BANK_QUERY_KEY } from './useBankConfig';
export const useBankMutation = (bankId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createBank = useCallback(async (bankData) => {
        return httpService.post('/bank', bankData);
    }, [httpService]);
    const updateBank = useCallback(async (bankData) => {
        return httpService.put('/bank', bankData);
    }, [httpService]);
    const removeBank = useCallback(async (bankId) => {
        return httpService.delete(`/bank?id=${bankId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createBank, {
        onSuccess: () => {
            queryClient.invalidateQueries(BANK_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries(BANK_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateBank, {
        onSuccess: () => {
            queryClient.invalidateQueries(BANK_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries([BANK_QUERY_KEY, bankId]);
            queryClient.invalidateQueries(BANK_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removeBank, {
        onSuccess: (_, bankId) => {
            queryClient.invalidateQueries(BANK_PAGINATION_QUERY_KEY);
            queryClient.removeQueries([BANK_QUERY_KEY, bankId]);
            queryClient.invalidateQueries(BANK_LIST_QUERY_KEY);
        }
    });
    return { create, update, remove, isCreating, isUpdating, isRemoving };
};
