import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { AIRCRAFT_MODEL_QUERY_KEY } from './useAircraftModelConfig';
export const useAircraftModelQuery = (aircraftModelId) => {
    const { errorCallback } = useAlert();
    const navigate = useNavigate();
    const getAircraftModel = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/aircraft-model/${aircraftModelId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'AIRCRAFT_MODEL_GET_FAILURE');
            navigate('/aircraft-models');
            throw error;
        });
    }, [errorCallback, aircraftModelId, navigate]);
    const { data, isLoading } = useQuery([AIRCRAFT_MODEL_QUERY_KEY, aircraftModelId], getAircraftModel, {
        enabled: !!aircraftModelId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
