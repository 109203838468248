import React from 'react';
import { AwayWithoutSolicitationInternal, AwayWithoutSolicitationList } from './screens';
const AwayWithoutSolicitationModule = [
    {
        path: '/cam/aways-without-solicitation',
        element: React.createElement(AwayWithoutSolicitationList, null)
    },
    {
        path: '/cam/aways-without-solicitation/new',
        element: React.createElement(AwayWithoutSolicitationInternal, null)
    },
    {
        path: '/cam/aways-without-solicitation/:awayWithoutSolicitationId',
        element: React.createElement(AwayWithoutSolicitationInternal, null)
    }
];
export { AwayWithoutSolicitationModule };
