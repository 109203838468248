import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Loading, Card, Button, Tooltip } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useDocumentTitle, useBenefitQuery, useBenefitMutation } from 'hooks';
import moment from 'moment-timezone';
import { BenefitForm } from '../components';
const BenefitInternal = () => {
    const benefitIdRoute = parseInt(useParams()?.benefitId ?? '0');
    const { success, errorCallback, confirm } = useAlert();
    const navigate = useNavigate();
    const [benefit, isLoading] = useBenefitQuery(benefitIdRoute);
    const { create, update, recalculate, isRecalculating } = useBenefitMutation(benefitIdRoute);
    async function submitBenefit(benefitData) {
        const benefitDataSubmit = { ...benefitData };
        benefitDataSubmit.CreatedAt = moment().format();
        benefitDataSubmit.UpdatedAt = null;
        if (benefitData.Id) {
            return update(benefitDataSubmit, {
                onSuccess: () => success({
                    title: 'Benefício atualizado!',
                    message: 'Dados do benefício atualizados com sucesso.',
                    id: 'BENEFIT_PUT_SUCCESS'
                }),
                onError: err => errorCallback(err, 'BENEFIT_PUT_FAILURE')
            });
        }
        else {
            return create(benefitDataSubmit, {
                onSuccess: res => {
                    success({
                        title: 'Benefício cadastrado!',
                        message: 'Benefício cadastrado com sucesso.',
                        id: 'BENEFIT_POST_SUCCESS'
                    });
                    navigate(`/benefits/${res.Id}`);
                },
                onError: err => errorCallback(err, 'BENEFIT_POST_FAILURE')
            });
        }
    }
    const recalculateBenefitConsumption = useCallback(() => {
        return recalculate(benefit?.Id)
            .then(() => success({
            title: 'Consumos do benefício recalculados!',
            message: 'Consumos recalculados com sucesso.',
            id: 'BENEFIT_CONSUMPTION_RECALCULATE_SUCCESS'
        }))
            .catch(err => errorCallback(err, 'BENEFIT_CONSUMPTION_RECALCULATE_FAILURE'));
    }, [success, errorCallback, recalculate, benefit]);
    const onRecalculateBenefitConsumptionClick = useCallback(() => {
        confirm({
            title: 'Você tem certeza?',
            description: (React.createElement("p", null,
                "Os consumos desse benef\u00EDcio ser\u00E1o recalculados para o m\u00EAs vigente. ",
                React.createElement("br", null),
                " Essa a\u00E7\u00E3o \u00E9 irrevers\u00EDvel!")),
            confirmText: 'Sim, recalcular',
            cancelText: 'Não, cancelar',
            onConfirm: recalculateBenefitConsumption
        });
    }, [confirm, recalculateBenefitConsumption]);
    const pageTitle = useMemo(() => `${benefitIdRoute ? 'Visualizar' : 'Novo'} Benefício`, [benefitIdRoute]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Benefícios',
            Url: '/benefits'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Benef\u00EDcios", breadcrumb: breadcrumb }),
        React.createElement(Loading, { show: isLoading || isRecalculating }),
        benefit?.Id && (React.createElement(Card, { className: "flex justify-end mb-4" },
            React.createElement(Tooltip, { id: "recalc-benefits", text: "Essa a\u00E7\u00E3o recalcula apenas os consumos do m\u00EAs atual" },
                React.createElement(Button, { text: "Recalcular Consumos", color: "info", animationHover: "pop", onClick: onRecalculateBenefitConsumptionClick })))),
        React.createElement(Card, { id: "benefit-card" },
            React.createElement(BenefitForm, { benefit: benefit, isLoading: isLoading, handleSubmit: submitBenefit }))));
};
export default BenefitInternal;
