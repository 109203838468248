import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip } from 'components';
import { useModulePermissions, useTableFilter, useDocumentTitle, useDailyRatePagination } from 'hooks';
import { FormatterUtils } from 'utils';
import { DailyRateTableFilterForm } from '../components/TableFilterForm';
const DailyRateList = () => {
    const { canCreate, canRead, canUpdate } = useModulePermissions();
    const customParamsHandle = useCallback((filterParams) => {
        const params = {};
        if (filterParams.JobDescriptionId) {
            params.JobDescriptionId = filterParams.JobDescriptionId;
        }
        if (filterParams.PlanId) {
            params.PlanId = filterParams.PlanId;
        }
        return params;
    }, []);
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter({
        customParamsHandle
    });
    const { data, isLoading, ...pagination } = useDailyRatePagination(requestFilterParams);
    const searchFields = useMemo(() => [
        {
            Value: 'Name',
            Description: 'Nome'
        }
    ], []);
    const columns = useMemo(() => [
        {
            Header: 'Nome',
            accessor: 'Name'
        },
        {
            Header: 'Cargo',
            accessor: 'JobDescription',
            Cell: ({ cell: { value } }) => value?.Name ?? ''
        },
        {
            Header: 'Plano',
            accessor: 'Plan',
            Cell: ({ cell: { value } }) => value?.Name ?? ''
        },
        {
            Header: 'Valor Contribuição',
            accessor: 'ContributionValue',
            Cell: ({ cell: { value } }) => FormatterUtils.money(value)
        },
        {
            Header: 'Situação',
            accessor: 'Active',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold', value ? 'text-green-light' : 'text-red-light') }, value ? 'ATIVA' : 'INATIVA'))
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" }, (canRead || canUpdate) && (React.createElement(Link, { to: `/cam/daily-rates/${value}` },
                    React.createElement(Tooltip, { id: `daily-rate-${value}-edit`, text: canUpdate ? 'Editar' : 'Visualizar' },
                        React.createElement(Button, { icon: canUpdate ? 'edit' : 'eye', color: "info", rounded: true }))))));
            }
        }
    ], [canRead, canUpdate]);
    useDocumentTitle('Diárias');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Di\u00E1rias" }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            canCreate && (React.createElement(Link, { to: "/cam/daily-rates/new" },
                React.createElement(Button, { text: "Nova Di\u00E1ria", icon: "add", color: "info", animationHover: "pop" }))),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Di\u00E1rias", formConfig: { searchFields, filterParams, withFields: true, withSituation: true }, handleFilter: setParams, customFilters: DailyRateTableFilterForm }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhuma di\u00E1ria a ser exibida" })));
};
export default DailyRateList;
