import { BenefitConsumptionType } from 'types/enum';
export const convertToBenefitConsumptionBatchDto = (benefitConsumptionBatchValues) => {
    const { MonthYearReference: monthYear } = benefitConsumptionBatchValues;
    const monthYearValue = new Date(monthYear.year, monthYear.month - 1, 1);
    return {
        BenefitId: benefitConsumptionBatchValues.BenefitId,
        BenefitConsumptionBatchLines: benefitConsumptionBatchValues.BatchLines.map(x => ({
            BeneficiaryName: x.BeneficiaryName,
            BeneficiaryRegistration: x.BeneficiaryRegistration,
            Type: x.Type,
            Value: x.Type === BenefitConsumptionType.DEVOLUTION ? -Math.abs(x.Value) : x.Value
        })),
        MonthYearReference: monthYearValue.toLocaleDateString('en-CA')
    };
};
