import React from 'react';
import clsx from 'clsx';
import { Button } from 'components';
import './ButtonAnimated.scss';
const ButtonAnimated = ({ loading, text }) => {
    return (React.createElement("div", { className: clsx('button-animated-wrapper', loading && 'loading') },
        React.createElement(Button, { className: clsx('button-animated'), type: "submit", text: text, disabled: loading, size: "large" }),
        React.createElement("svg", { className: "progress-circle", viewBox: "0 0 41 41" },
            React.createElement("defs", null,
                React.createElement("linearGradient", { id: "gradient", x1: "0%", y1: "0%", x2: "100%", y2: "0%" },
                    React.createElement("stop", { offset: "0%" }),
                    React.createElement("stop", { offset: "100%" }))),
            React.createElement("path", { d: "M38,20.5 C38,30.1685093 30.1685093,38 20.5,38" }))));
};
export default ButtonAnimated;
