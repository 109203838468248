import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Loading, StepperForm } from 'components';
import { useAlert } from 'contexts/alert.context';
import hash from 'crypto-js/sha256';
import { useDocumentTitle, useUserMutation, useUserQuery } from 'hooks';
import moment from 'moment-timezone';
import { UserTypeEnum } from 'types/dtos';
import { UserForm, UserPermissionForm } from '../components';
import { UserFormStep } from './UsersInternal.types';
const UsersInternal = () => {
    const userIdRoute = parseInt(useParams()?.userId ?? '0');
    const { success, errorCallback } = useAlert();
    const navigate = useNavigate();
    const [user, isLoading] = useUserQuery(userIdRoute);
    const { create, update } = useUserMutation(userIdRoute);
    const submitUser = useCallback((userData) => {
        const { Password: currentPassword, NewPassword: newPassword, ConfirmPassword: confirmPassword, ...userDataValues } = userData;
        const userDataSubmit = { ...userDataValues };
        userDataSubmit.CreatedAt = moment().format();
        userDataSubmit.UpdatedAt = null;
        if (userData.Id) {
            userDataSubmit.Password = hash(currentPassword).toString();
            userDataSubmit.NewPassword = hash(newPassword).toString();
            return update(userDataSubmit, {
                onSuccess: () => success({
                    title: 'Usuário atualizado!',
                    message: 'Dados do usuário atualizados com sucesso.',
                    id: 'USER_PUT_SUCCESS'
                }),
                onError: err => errorCallback(err, 'USER_PUT_FAILURE')
            });
        }
        else {
            userDataSubmit.Password = hash(newPassword).toString();
            return create(userDataSubmit, {
                onSuccess: res => {
                    success({
                        title: 'Usuário cadastrado!',
                        message: 'Usuário cadastrado com sucesso.',
                        id: 'USER_POST_SUCCESS'
                    });
                    navigate(`/users/${res.Id}`);
                },
                onError: err => errorCallback(err, 'USER_POST_FAILURE')
            });
        }
    }, [create, update, success, errorCallback, navigate]);
    const submitUserPermission = useCallback(async (scopes) => {
        const userDataSubmit = { ...user };
        userDataSubmit.UserScopes = scopes.map(scope => {
            return {
                UserId: user?.Id,
                ScopeId: scope
            };
        });
        await update(userDataSubmit, {
            onSuccess: () => success({
                title: 'Usuário atualizado!',
                message: 'Dados do usuário atualizados com sucesso.',
                id: 'USER_PUT_SUCCESS'
            }),
            onError: err => errorCallback(err, 'USER_PUT_FAILURE')
        });
    }, [user, update, success, errorCallback]);
    const isPermissionTabEnabled = userIdRoute && user?.UserTypeId !== UserTypeEnum.Administrator;
    const USER_FORM_STEPS = useMemo(() => [
        {
            key: UserFormStep.DATA,
            title: 'Dados',
            description: 'Dados do usuário',
            icon: 'id-card',
            content: (React.createElement(UserForm, { user: user, isLoading: isLoading, isCreation: !userIdRoute, handleSubmit: submitUser }))
        },
        {
            key: UserFormStep.SCOPES,
            title: 'Permissões',
            description: 'Permissões e acessos',
            icon: 'dependent',
            hide: !isPermissionTabEnabled,
            content: React.createElement(UserPermissionForm, { user: user, handleSubmit: submitUserPermission })
        }
    ], [userIdRoute, user, isLoading, submitUser, submitUserPermission, isPermissionTabEnabled]);
    const pageTitle = useMemo(() => `${userIdRoute ? 'Visualizar' : 'Novo'} Usuário`, [userIdRoute]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Usuários',
            Url: '/users'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Usu\u00E1rios", breadcrumb: breadcrumb }),
        React.createElement(Loading, { show: isLoading }),
        React.createElement(StepperForm, { items: USER_FORM_STEPS })));
};
export default UsersInternal;
