import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip, Icon } from 'components';
import { useAuth } from 'contexts/auth.context';
import { useModulePermissions, useTableFilter, useDocumentTitle, useSolicitationRevisionPagination, useExportQuery } from 'hooks';
import { SolicitationRevisionTableFilterForm } from 'modules/SolicitationRevision/components';
import moment from 'moment-timezone';
import { UserTypeEnum } from 'types/dtos';
import { SolicitationStatus } from 'types/enum';
import { SolicitationUtils, TextUtils } from 'utils';
const SolicitationRevisionList = () => {
    const { canRead, canUpdate } = useModulePermissions();
    const { user } = useAuth();
    const customParamsHandle = useCallback((filterParams) => {
        const params = {};
        if (filterParams.PersonDocument) {
            params.PersonDocument = filterParams.PersonDocument;
        }
        if (filterParams.SolicitationCode) {
            params.SearchField = 'SolicitationCode';
            params.SearchQuery = filterParams.SolicitationCode;
        }
        if (filterParams.PersonCallName) {
            params.SearchField = 'CallName';
            params.SearchQuery = filterParams.PersonCallName;
        }
        if (filterParams.Statuses) {
            params.SolicitationStatusIds = filterParams.Statuses
                .filter(x => x?.value)
                .map(x => x.value);
        }
        return params;
    }, []);
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter({
        customParamsHandle
    });
    const { data, isLoading, ...pagination } = useSolicitationRevisionPagination(requestFilterParams);
    const exportExcelQuery = useExportQuery('report/solicitation-revision-excel', requestFilterParams);
    const groupedData = useMemo(() => {
        return data.reduce((acc, cur, _, arr) => {
            if (!acc.some(x => x.Solicitation?.Id === cur.SolicitationId)) {
                acc.push({
                    Solicitation: cur.Solicitation,
                    SolicitationRevision: arr
                        .filter(x => x.SolicitationId === cur.SolicitationId)
                        .sort((a, b) => a.StartDate.localeCompare(b.StartDate))
                });
            }
            return acc;
        }, []);
    }, [data]);
    const canHandleRevision = useCallback((solicitationRevision) => {
        if (user.UserTypeId !== UserTypeEnum.Doctor)
            return false;
        if (![SolicitationStatus.WAITING_START].includes(solicitationRevision.SolicitationRevisionState?.StatusId))
            return false;
        if (solicitationRevision.DoctorUserId)
            return false;
        if (moment(solicitationRevision.StartDate).isAfter(moment.now(), 'month'))
            return false;
        return true;
    }, [user]);
    const columns = useMemo(() => [
        {
            Header: 'Associado',
            accessor: 'Solicitation',
            id: 'SolicitationBeneficiary',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => value.Beneficiary.Name
        },
        {
            Header: 'Requerimento',
            accessor: 'Solicitation',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement(Link, { to: `/cam/solicitations/${value.Id}`, target: "_blank" },
                    React.createElement("span", { className: "font-semibold text-blue-light" }, value.RegistrationCode)));
            }
        },
        {
            Header: 'Datas Previstas',
            accessor: 'SolicitationRevision',
            id: 'SolicitationRevisionDates',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => value.map(x => (React.createElement("span", { key: x.Id.toString(), className: "block mb-1" },
                TextUtils.capitalizeFirstLetter(moment(x.StartDate).format('MMMM [de] YYYY')),
                !x.IsNormativeToRevisionDays && (React.createElement(Tooltip, { id: `solicitation-revision-${x.Id}-date`, text: "Essa revis\u00E3o ser\u00E1 realizada antes da data prevista devido a dura\u00E7\u00E3o da solicita\u00E7\u00E3o" },
                    React.createElement(Icon, { name: "warning", size: 14, className: "ml-1 self-center text-gray-light dark:text-gray-400" }))))))
        },
        {
            Header: 'Status',
            accessor: 'SolicitationRevision',
            id: 'SolicitationRevisionStatus',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => value.map(x => (React.createElement("span", { key: x.Id.toString(), className: clsx('block mb-1', SolicitationUtils.getSolicitationStateTextColor(x.SolicitationRevisionState?.StatusId)) }, x.SolicitationRevisionState?.Status?.Name ?? '-')))
        },
        {
            Header: 'Ações',
            accessor: 'Solicitation',
            id: 'SolicitationAction',
            disableSortBy: true,
            Cell: ({ cell: { value, row: { original: values } } }) => {
                const canHandle = canUpdate && values.SolicitationRevision.some(x => canHandleRevision(x));
                return (React.createElement("div", { className: "flex items-center" }, canHandle && (React.createElement(Link, { to: `/cam/solicitations/${value.Id}` },
                    React.createElement(Tooltip, { id: `solicitation-${value}-edit`, text: canHandle ? 'Gerenciar' : 'Acompanhar' },
                        React.createElement(Button, { icon: canHandle ? 'user-success' : 'eye', color: "info", rounded: true }))))));
            }
        }
    ], [canHandleRevision, canUpdate]);
    useDocumentTitle('Revisão de Requerimentos');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Revis\u00E3o de Requerimentos" }),
        React.createElement("div", { className: clsx('flex mb-4', canRead ? 'justify-between' : 'justify-end') },
            canRead && (React.createElement("a", { href: exportExcelQuery, target: "_blank", rel: "noreferrer" },
                React.createElement(Button, { text: "Exportar", icon: "download", color: "info", animationHover: "pop" }))),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Revis\u00E3o de Requerimentos", formConfig: { filterParams }, handleFilter: setParams, customFilters: SolicitationRevisionTableFilterForm }),
        React.createElement(Table, { data: groupedData, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhuma revis\u00E3o a ser exibida" })));
};
export default SolicitationRevisionList;
