import { useCallback } from 'react';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { ValidationUtils } from 'utils';
const PER_PAGE = 10;
export const useBeneficiarySelectPagination = () => {
    const { errorCallback } = useAlert();
    const prepareParameters = useCallback((searchValue, currentPage) => {
        const pageSkip = (currentPage - 1) * PER_PAGE;
        const params = {
            PerPage: PER_PAGE,
            Skip: pageSkip,
            OrderBy: 'Name',
            OrderDescending: false
        };
        if (!searchValue)
            return params;
        const isSearchValueCpf = ValidationUtils.cpf(searchValue);
        if (isSearchValueCpf) {
            params.PersonDocument = searchValue;
        }
        else {
            params.PersonName = searchValue;
        }
        return params;
    }, []);
    const getBeneficiaries = useCallback(async (searchValue, currentPage) => {
        const params = prepareParameters(searchValue, currentPage);
        return makeHttpService()
            .get('/beneficiary/by-parameters', {
            params
        })
            .catch(error => {
            errorCallback(error, 'BENEFICIARY_GET_BY_PARAMETERS_FAILURE');
            return {};
        });
    }, [prepareParameters, errorCallback]);
    const loadOptions = async (searchValue, _, additional) => {
        const currentPage = additional?.page ?? 1;
        const { NumberOfPages: numberOfPages, Registers: registers } = await getBeneficiaries(searchValue, currentPage);
        const hasMore = numberOfPages > currentPage;
        return {
            options: registers,
            hasMore,
            additional: {
                page: currentPage + 1
            }
        };
    };
    return {
        loadOptions
    };
};
