import React, { useCallback, useMemo } from 'react';
import { Button, Tooltip } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useAuth } from 'contexts/auth.context';
import { useModulePermissionsByScope, useSolicitationRevisionMutation } from 'hooks';
import moment from 'moment-timezone';
import { UserTypeEnum } from 'types/dtos';
import { Scope, SolicitationStateReason, SolicitationStatus } from 'types/enum';
const SolicitationRevisionAction = ({ solicitationRevision, solicitationCurrentState }) => {
    const { canUpdate } = useModulePermissionsByScope(Scope.SOLICITATION_REVISION);
    const { success, confirm, errorCallback } = useAlert();
    const { user } = useAuth();
    const { attributeDoctorUser, addSolicitationRevisionState } = useSolicitationRevisionMutation(solicitationRevision.SolicitationId);
    const canHandleRevision = useMemo(() => {
        if (user.UserTypeId !== UserTypeEnum.Doctor)
            return false;
        if (![SolicitationStatus.IN_PROGRESS].includes(solicitationCurrentState?.StatusId))
            return false;
        if (![SolicitationStatus.WAITING_START].includes(solicitationRevision.SolicitationRevisionState?.StatusId))
            return false;
        if (solicitationRevision.DoctorUserId)
            return false;
        if (moment(solicitationRevision.StartDate).isAfter(moment.now(), 'month'))
            return false;
        return true;
    }, [solicitationRevision, solicitationCurrentState, user]);
    const canReviewRevision = useMemo(() => {
        if (user.UserTypeId !== UserTypeEnum.Doctor)
            return false;
        if (![SolicitationStatus.IN_REVISION].includes(solicitationRevision.SolicitationRevisionState?.StatusId))
            return false;
        if (!solicitationRevision.DoctorUserId)
            return false;
        return true;
    }, [solicitationRevision, user]);
    const attributeUser = useCallback(async () => {
        return attributeDoctorUser(solicitationRevision.Id)
            .then(() => {
            success({
                title: 'Revisão atualizada!',
                message: 'Revisão atualizada com sucesso.',
                id: 'SOLICITATION_REVISION_ATTRIBUTION_SUCCESS'
            });
        })
            .catch(err => errorCallback(err, 'SOLICITATION_REVISION_ATTRIBUTION_FAILURE'));
    }, [solicitationRevision, attributeDoctorUser, success, errorCallback]);
    const onAttributeUser = useCallback(() => {
        confirm({
            title: 'Você tem certeza?',
            description: (React.createElement("p", null,
                "Essa revis\u00E3o ser\u00E1 atribu\u00EDda a voc\u00EA para acompanhamento e aprova\u00E7\u00E3o. ",
                React.createElement("br", null),
                "Deseja continuar?")),
            confirmText: 'Sim, continuar',
            cancelText: 'Não, cancelar',
            onConfirm: () => attributeUser()
        });
    }, [confirm, attributeUser]);
    const reviewRevision = useCallback(async (isApproval) => {
        const solicitationRevisinStateData = {
            SolicitationRevisionId: solicitationRevision.Id,
            StatusId: SolicitationStatus.FINISHED,
            StateReason: isApproval
                ? SolicitationStateReason.FINISHED_DOCTOR_APPROVAL_ON_PERIODIC_REVISION
                : SolicitationStateReason.FINISHED_DOCTOR_REPROVAL_ON_PERIODIC_REVISION
        };
        return addSolicitationRevisionState(solicitationRevisinStateData)
            .then(() => {
            success({
                title: 'Revisão atualizada!',
                message: 'Revisão atualizada com sucesso.',
                id: 'SOLICITATION_REVISION_REVIEW_SUCCESS'
            });
        })
            .catch(err => errorCallback(err, 'SOLICITATION_REVISION_REVIEW_FAILURE'));
    }, [solicitationRevision, addSolicitationRevisionState, success, errorCallback]);
    const onReviewRevision = useCallback((isApproval) => {
        confirm({
            title: 'Você tem certeza?',
            description: (React.createElement("p", null,
                "A revis\u00E3o ser\u00E1 finalizada. ",
                React.createElement("br", null),
                "Deseja continuar?")),
            confirmText: 'Sim, continuar',
            cancelText: 'Não, cancelar',
            onConfirm: () => reviewRevision(isApproval)
        });
    }, [confirm, reviewRevision]);
    if (!canUpdate)
        return null;
    return (React.createElement("div", { className: "flex items-center" },
        canHandleRevision && (React.createElement(Tooltip, { id: `solicitation-revision-${solicitationRevision.Id}-handle`, text: "Atribuir" },
            React.createElement(Button, { icon: "user-success", color: "info", onClick: onAttributeUser, rounded: true }))),
        canReviewRevision && (React.createElement(React.Fragment, null,
            React.createElement(Tooltip, { id: `solicitation-revision-${solicitationRevision.Id}-approve`, text: "Aprovar" },
                React.createElement(Button, { icon: "like", color: "success", onClick: () => onReviewRevision(true), rounded: true })),
            React.createElement(Tooltip, { id: `solicitation-revision-${solicitationRevision.Id}-repprove`, text: "Reprovar" },
                React.createElement(Button, { icon: "dislike", color: "danger", onClick: () => onReviewRevision(false), className: "ml-1", rounded: true }))))));
};
export default SolicitationRevisionAction;
