import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Loading, Card } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useDocumentTitle, useOperationBaseQuery, useOperationBaseMutation } from 'hooks';
import moment from 'moment-timezone';
import { OperationBaseForm } from '../components';
const OperationBaseInternal = () => {
    const operationBaseIdRoute = parseInt(useParams()?.operationBaseId ?? '0');
    const { success, errorCallback } = useAlert();
    const navigate = useNavigate();
    const [operationBase, isLoading] = useOperationBaseQuery(operationBaseIdRoute);
    const { create, update } = useOperationBaseMutation(operationBaseIdRoute);
    async function submitOperationBase(operationBaseData) {
        const operationBaseDataSubmit = { ...operationBaseData };
        operationBaseDataSubmit.CreatedAt = moment().format();
        operationBaseDataSubmit.UpdatedAt = null;
        if (operationBaseData.Id) {
            return update(operationBaseDataSubmit, {
                onSuccess: () => success({
                    title: 'Base atualizada!',
                    message: 'Dados da base atualizados com sucesso.',
                    id: 'OPERATION_BASE_PUT_SUCCESS'
                }),
                onError: err => errorCallback(err, 'OPERATION_BASE_PUT_FAILURE')
            });
        }
        else {
            return create(operationBaseDataSubmit, {
                onSuccess: res => {
                    success({
                        title: 'Base cadastrada!',
                        message: 'Base cadastrada com sucesso.',
                        id: 'OPERATION_BASE_POST_SUCCESS'
                    });
                    navigate(`/operational/operation-bases/${res.Id}`);
                },
                onError: err => errorCallback(err, 'OPERATION_BASE_POST_FAILURE')
            });
        }
    }
    const pageTitle = useMemo(() => `${operationBaseIdRoute ? 'Visualizar' : 'Nova'} Base de Operação`, [
        operationBaseIdRoute
    ]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Bases de Operação',
            Url: '/operational/operation-bases'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Bases de Opera\u00E7\u00E3o", breadcrumb: breadcrumb }),
        React.createElement(Loading, { show: isLoading }),
        React.createElement(Card, { id: "operation-base-card" },
            React.createElement(OperationBaseForm, { operationBase: operationBase, isLoading: isLoading, handleSubmit: submitOperationBase }))));
};
export default OperationBaseInternal;
