import React, { useState } from 'react';
import 'react-dates/initialize';
import { DateRangePicker as DatePicker } from 'react-dates';
import { Icon } from 'components';
import './DateRangeField.scss';
const DateRangeField = ({ startDate, endDate, onDatesChange, isOutsideRange, anchorDirection = 'left' }) => {
    const [showDatePicker, toggleDatePicker] = useState(null);
    return (React.createElement(DatePicker, { displayFormat: "DD/MM/YYYY", startDate: startDate, startDateId: "startDateFilter", startDatePlaceholderText: "Data in\u00EDcio", endDate: endDate, endDateId: "endDateFilter", endDatePlaceholderText: "Data fim", onDatesChange: onDatesChange, focusedInput: showDatePicker, isOutsideRange: isOutsideRange, customArrowIcon: React.createElement(Icon, { name: "chevron-right", size: 16 }), hideKeyboardShortcutsPanel: true, onFocusChange: focusedInput => toggleDatePicker(focusedInput), anchorDirection: anchorDirection, withPortal: true }));
};
export default DateRangeField;
