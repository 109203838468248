import moment, { isMoment } from 'moment';
import { DateFormat } from 'types/enum';
const cpf = (value) => {
    return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};
const cnpj = (value) => {
    return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};
const document = (value) => {
    if (value.length === 11)
        return cpf(value);
    return cnpj(value);
};
const phone = (value) => {
    const clearedValue = value.replace(/\D/g, '');
    if (clearedValue.length === 11)
        return clearedValue.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');
    return clearedValue.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
};
const momentDate = (date) => {
    if (!date)
        return;
    const cleanedDate = date.replace(/-/g, '/');
    const isBrazilianDate = cleanedDate.split('/')[0].length === 2;
    return moment(date, isBrazilianDate ? 'DD/MM/YYYY' : '', true);
};
const dateTime = (date) => {
    const dateFormat = DateFormat.DATETIME_PT_BR;
    if (isMoment(date))
        return date.format(dateFormat);
    const momentDateObject = momentDate(date?.toString());
    if (!momentDateObject)
        return '';
    return momentDateObject.format(dateFormat);
};
const dateNow = (format) => {
    return moment().format(format ?? 'D [de] MMMM [de] YYYY');
};
const date = (date, format) => {
    const dateFormat = format ?? DateFormat.DATE_PT_BR;
    if (isMoment(date))
        return date.format(dateFormat);
    const momentDateObject = momentDate(date?.toString());
    if (!momentDateObject)
        return null;
    return momentDateObject.format(dateFormat);
};
const hour = (date) => {
    const momentDateObject = momentDate(date?.toString());
    if (!momentDateObject)
        return '';
    const format = DateFormat.TIME_PT_BR;
    return momentDateObject.format(format);
};
const number = (value, defaultValue = 0) => {
    return value ? parseInt(value, 10) : defaultValue;
};
const amount = (value) => {
    const formatter = Intl.NumberFormat('pt-br');
    return formatter.format(value);
};
const money = (value) => {
    if (value === null || value === undefined)
        return value;
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });
    return formatter.format(parseFloat(value));
};
const zipCode = (value) => {
    return value.replace(/^(\d{5})(\d{3})/, '$1-$2');
};
const percentage = (value) => {
    return value + '%';
};
export const FormatterUtils = {
    document,
    cpf,
    cnpj,
    phone,
    momentDate,
    date,
    dateTime,
    dateNow,
    hour,
    number,
    amount,
    money,
    zipCode,
    percentage
};
