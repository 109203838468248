import React, { useMemo } from 'react';
import ReactSelect, { components } from 'react-select';
import clsx from 'clsx';
import { FormConsumer } from 'components';
import { getIn, useFormikContext } from 'formik';
import './Select.scss';
import { AsyncPaginate } from 'react-select-async-paginate';
const baseStyles = {
    menuPortal: base => ({
        ...base,
        zIndex: 9999
    }),
    multiValue: (base, state) => {
        return state.isDisabled ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
        return state.isDisabled ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 } : base;
    },
    multiValueRemove: (base, state) => {
        return state.isDisabled ? { ...base, display: 'none' } : base;
    }
};
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (React.createElement(ValueContainer, { ...props },
        React.createElement(Placeholder, { ...props, isFocused: props.isFocused }, props.selectProps.placeholder),
        React.Children.map(children, child => (child && child.type !== Placeholder ? child : null))));
};
export default function Select(props) {
    const { selectRef, value, options = [], loadOptions, onChange: handleOnChange, getOptionValue, label, placeholder, emptyMessage = 'Sem resultados', className, styles, isMulti, isAsync, ...restProps } = props;
    const { errors } = useFormikContext() || {};
    const hasError = errors ? !!getIn(errors, restProps.name) : false;
    const selectValue = useMemo(() => {
        if (!value)
            return null;
        if (isMulti)
            return options.filter(option => value.includes(option));
        return options.find(option => option === value);
    }, [options, isMulti, value]);
    const onChange = (option) => {
        if (!handleOnChange)
            return;
        if (!option)
            return handleOnChange(null, {});
        return handleOnChange(option, {});
    };
    const commonProps = {
        className: clsx('select', hasError && 'select-error', !!className && className),
        classNamePrefix: 'select',
        loadingMessage: () => 'Carregando...',
        noOptionsMessage: () => emptyMessage,
        styles: (styles ? { ...baseStyles, ...styles } : baseStyles),
        menuPortalTarget: document.body,
        menuPosition: 'fixed',
        closeMenuOnScroll: true,
        placeholder: label || placeholder,
        components: { ValueContainer: CustomValueContainer },
        getOptionValue,
        isOptionDisabled: option => (option.Active === undefined ? false : !option.Active),
        isMulti,
        value: selectValue,
        onChange
    };
    return (React.createElement(FormConsumer, null, ({ disabled }) => isAsync ? (React.createElement(AsyncPaginate, { selectRef: selectRef, loadOptions: loadOptions, debounceTimeout: 300, ...commonProps, ...restProps, isDisabled: disabled || restProps.isDisabled })) : (React.createElement(ReactSelect, { ref: selectRef, options: options, ...commonProps, ...restProps, isDisabled: disabled || restProps.isDisabled }))));
}
