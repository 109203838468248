import React, { useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { TextField, Button, FormField, FormFieldError, Form, SituationField, useFormikValidationHandler, FormikValidationHandler } from 'components';
import { useFormik } from 'formik';
import { useModulePermissions } from 'hooks';
import { FormatterUtils } from 'utils';
import { JobDescriptionFormSchema } from './JobDescriptionForm.schema';
const defaultValues = {
    Id: 0,
    Name: '',
    CreatedAt: '',
    UpdatedAt: '',
    Active: true
};
const JobDescriptionForm = ({ jobDescription, isLoading, ...props }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions(jobDescription?.Id);
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => JobDescriptionFormSchema,
        onSubmit: async (jobDescriptionData) => {
            return props.handleSubmit && props.handleSubmit(jobDescriptionData);
        },
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleChange, handleSubmit, isSubmitting, setValues } = formikContext;
    useEffect(() => {
        if (!jobDescription?.Id)
            return;
        const jobDescriptionData = {
            Id: jobDescription.Id,
            Name: jobDescription.Name,
            Active: jobDescription.Active ?? true,
            CreatedAt: FormatterUtils.dateTime(jobDescription.CreatedAt),
            UpdatedAt: FormatterUtils.dateTime(jobDescription.UpdatedAt)
        };
        setValues(jobDescriptionData);
    }, [jobDescription, setValues]);
    if (isLoading)
        return null;
    return (React.createElement(Form, { name: "jobDescriptionForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Name", label: "Nome", value: values.Name, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Name" })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Situa\u00E7\u00E3o do cargo", handleChange: isActive => setFieldValue('Active', isActive), value: values.Active, falseLabel: "Inativo", trueLabel: "Ativo" })))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Enviar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default JobDescriptionForm;
