import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useModulePermissions, useTableFilter, useDocumentTitle, useDeseasePagination, useDeseaseMutation } from 'hooks';
const DeseaseList = () => {
    const { canCreate, canRead, canUpdate, canDelete } = useModulePermissions();
    const { success, confirm, errorCallback } = useAlert();
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter();
    const { data, isLoading, ...pagination } = useDeseasePagination(requestFilterParams);
    const { remove } = useDeseaseMutation();
    const deleteDesease = useCallback(async (deseaseId) => {
        await remove(deseaseId)
            .then(() => success({
            title: 'Anexo 10 deletado!',
            message: 'Anexo 10 deletado com sucesso.',
            id: 'DESEASE_DELETE_SUCCESS'
        }))
            .catch(err => errorCallback(err, 'DESEASE_DELETE_FAILURE'));
    }, [remove, success, errorCallback]);
    const onDeleteDesease = useCallback((deseaseId) => {
        confirm({
            title: 'Você tem certeza?',
            description: (React.createElement("p", null,
                "O anexo 10 ser\u00E1 deletado. ",
                React.createElement("br", null),
                " Essa a\u00E7\u00E3o \u00E9 irrevers\u00EDvel!")),
            confirmText: 'Sim, deletar',
            cancelText: 'Não, cancelar',
            onConfirm: async () => deleteDesease(deseaseId)
        });
    }, [confirm, deleteDesease]);
    const searchFields = useMemo(() => [
        {
            Value: 'Name',
            Description: 'Nome'
        },
        {
            Value: 'Code',
            Description: 'Código'
        }
    ], []);
    const columns = useMemo(() => [
        {
            Header: 'Nome',
            accessor: 'Name'
        },
        {
            Header: 'Situação',
            accessor: 'Active',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold', value ? 'text-green-light' : 'text-red-light') }, value ? 'ATIVA' : 'INATIVA'))
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" },
                    (canRead || canUpdate) && (React.createElement(Link, { to: `/cam/deseases/${value}` },
                        React.createElement(Tooltip, { id: `deseases-${value}-edit`, text: canUpdate ? 'Editar' : 'Visualizar' },
                            React.createElement(Button, { icon: canUpdate ? 'edit' : 'eye', color: "info", rounded: true })))),
                    canDelete && (React.createElement(Tooltip, { id: `deseases-${value}-remove`, text: "Deletar" },
                        React.createElement(Button, { className: "ml-2", icon: "trash", color: "danger", title: "Deletar", onClick: () => onDeleteDesease(value), rounded: true })))));
            }
        }
    ], [canRead, canUpdate, canDelete, onDeleteDesease]);
    useDocumentTitle('Anexo 10');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Anexo 10" }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            canCreate && (React.createElement(Link, { to: "/cam/deseases/new" },
                React.createElement(Button, { text: "Novo Anexo 10", icon: "add", color: "info", animationHover: "pop" }))),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Anexo 10", formConfig: { searchFields, filterParams, withFields: true, withSituation: true }, handleFilter: setParams }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhum anexo 10 a ser exibido" })));
};
export default DeseaseList;
