import React, { useCallback, useState } from 'react';
import { useDropzone, ErrorCode } from 'react-dropzone';
import { Button } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useBeneficiaryMutation, useFileUpload } from 'hooks';
import moment from 'moment-timezone';
import { FileSource } from 'types/enum';
const BeneficiaryPersonalPictureDropzone = ({ beneficiary, hasSelectedFile, onSelectFile, onRemoveFile }) => {
    const { success, error, errorCallback } = useAlert();
    const [isUploading, setIsUploading] = useState(false);
    const [upload, remove] = useFileUpload();
    const { update } = useBeneficiaryMutation(beneficiary.Id);
    const updateBeneficiaryPicture = useCallback(async (fileUrl = '') => {
        const beneficiaryUpdateData = {
            ...beneficiary,
            ProfilePictureUrl: fileUrl,
            UpdatedAt: moment().format()
        };
        await update(beneficiaryUpdateData)
            .then(() => success({
            title: 'Foto atualizada!',
            message: 'Foto do associado atualizada com sucesso.',
            id: 'BENEFICIARY_PUT_PROFILE_PICTURE_SUCCESS'
        }))
            .catch(async (err) => {
            const fileName = beneficiary.ProfilePictureUrl.split('/').pop();
            await remove(fileName);
            errorCallback(err, 'BENEFICIARY_PUT_PROFILE_PICTURE_FAILURE');
        });
    }, [beneficiary, update, remove, success, errorCallback]);
    const onSubmitPicture = useCallback(async (file) => {
        try {
            setIsUploading(true);
            const fileUrl = await upload(file, FileSource.USER);
            await updateBeneficiaryPicture(fileUrl);
        }
        catch (error) {
            errorCallback(error, 'BENEFICIARY_PUT_PROFILE_PICTURE_FAILURE');
        }
        finally {
            setIsUploading(false);
        }
    }, [updateBeneficiaryPicture, upload, errorCallback]);
    const onDeletePicture = useCallback(async () => {
        try {
            setIsUploading(true);
            const fileName = beneficiary.ProfilePictureUrl.split('/').pop();
            await remove(fileName);
            await updateBeneficiaryPicture();
            onRemoveFile();
        }
        catch (error) {
            errorCallback(error, 'BENEFICIARY_PUT_PROFILE_PICTURE_FAILURE');
        }
        finally {
            setIsUploading(false);
        }
    }, [beneficiary, updateBeneficiaryPicture, remove, errorCallback, onRemoveFile]);
    const onClickDeletePicture = useCallback((e) => {
        e.stopPropagation();
        onDeletePicture();
    }, [onDeletePicture]);
    const onFileAccept = useCallback((newFiles) => {
        const [selectedFile] = newFiles;
        const fileWithPreview = Object.assign(selectedFile, {
            preview: URL.createObjectURL(selectedFile)
        });
        onSelectFile(fileWithPreview);
        onSubmitPicture(fileWithPreview);
    }, [onSubmitPicture, onSelectFile]);
    const onFileReject = useCallback((files) => {
        if (!files.length)
            return;
        const [fileRejected] = files;
        const isSizeError = fileRejected.errors.some(x => x.code === ErrorCode.FileTooLarge);
        const errorMessage = isSizeError ? 'O tamanho máximo é de 1mb.' : 'Apenas os formatos PNG e JPG são aceitos.';
        error({
            title: 'Arquivo inválido',
            message: errorMessage,
            id: 'FILE_UPLOAD_ERROR'
        });
    }, [error]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg']
        },
        maxSize: 1 * 1e6,
        noDrag: true,
        noDragEventsBubbling: true,
        disabled: isUploading,
        onDropAccepted: onFileAccept,
        onDropRejected: onFileReject,
        useFsAccessApi: false
    });
    return (React.createElement("div", { ...getRootProps({ className: 'ml-2' }) },
        React.createElement("div", { className: "flex items-center" },
            React.createElement("input", { ...getInputProps() }),
            React.createElement(Button, { color: "info", text: "Alterar foto", size: "medium", disabled: isUploading, loading: isUploading }),
            React.createElement(Button, { color: "danger", variant: "outlined", text: "Apagar foto", size: "medium", className: "ml-2", onClick: onClickDeletePicture, disabled: isUploading || !hasSelectedFile })),
        React.createElement("small", { className: "text-xs font-normal text-gray-light dark:text-gray-400" },
            "O tamanho da foto deve ser no m\u00E1ximo ",
            React.createElement("strong", null, "1mb"),
            ". Formatos permitidos: ",
            React.createElement("strong", null, "png"),
            " and",
            ' ',
            React.createElement("strong", null, "jpg"),
            ".")));
};
export default BeneficiaryPersonalPictureDropzone;
