import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Select } from 'components';
import { FileType, FileTypeLabel } from 'types/enum';
import './SolicitationAttachmentItemForm.scss';
const fileTypeOptions = [
    {
        label: FileTypeLabel[FileType.MEDICAL_CERTIFICATE],
        value: FileType.MEDICAL_CERTIFICATE
    },
    {
        label: FileTypeLabel[FileType.LATAM_INSS_LETTER],
        value: FileType.LATAM_INSS_LETTER
    },
    {
        label: FileTypeLabel[FileType.LATAM_MEDICAL_CERTIFICATE],
        value: FileType.LATAM_MEDICAL_CERTIFICATE
    },
    {
        label: FileTypeLabel[FileType.EXAMS],
        value: FileType.EXAMS
    },
    {
        label: FileTypeLabel[FileType.INSS_DECISION],
        value: FileType.INSS_DECISION
    }
];
const SolicitationAttachmentItemForm = ({ file, onSelectFileType }) => {
    const fileExtension = useMemo(() => file.name.substring(file.name.lastIndexOf('.') + 1), [file.name]);
    const fileLabel = useMemo(() => {
        const fileName = file.name.split('.').slice(0, -1).join('.');
        const fileFullExtension = '.' + fileExtension;
        if (fileName.length <= 15)
            return fileName + fileFullExtension;
        return fileName.substring(0, 12) + '...' + fileFullExtension;
    }, [file.name, fileExtension]);
    return (React.createElement("div", { className: clsx('solicitation-attachment-item', fileExtension) },
        React.createElement("img", { src: file.preview, onLoad: () => {
                URL.revokeObjectURL(file.preview);
            } }),
        React.createElement("div", { className: "solicitation-attachment-item-inner" },
            React.createElement("label", null, fileLabel),
            React.createElement(Select, { name: "PlanId", label: "Escolha o tipo do arquivo", options: fileTypeOptions, value: fileTypeOptions?.find((fileType) => fileType.value === file.fileType), onChange: option => onSelectFileType(file, option.value), getOptionLabel: option => option.label, getOptionValue: option => option.label.toString() }))));
};
export default SolicitationAttachmentItemForm;
