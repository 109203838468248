import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { BENEFICIARY_BENEFIT_LIST_QUERY_KEY } from './useBeneficiaryBenefitConfig';
export const useBeneficiaryBenefitMutation = (beneficiaryId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createBeneficiaryBenefit = useCallback(async (beneficiaryBenefitData) => {
        return httpService.post('/beneficiary-benefit', beneficiaryBenefitData);
    }, [httpService]);
    const updateBeneficiaryBenefit = useCallback(async (beneficiaryBenefitData) => {
        return httpService.put('/beneficiary-benefit', beneficiaryBenefitData);
    }, [httpService]);
    const removeBeneficiaryBenefit = useCallback(async (beneficiaryBenefitId) => {
        return httpService.delete(`/beneficiary-benefit?id=${beneficiaryBenefitId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createBeneficiaryBenefit, {
        onSuccess: () => {
            queryClient.invalidateQueries([BENEFICIARY_BENEFIT_LIST_QUERY_KEY, beneficiaryId]);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateBeneficiaryBenefit, {
        onSuccess: () => {
            queryClient.invalidateQueries([BENEFICIARY_BENEFIT_LIST_QUERY_KEY, beneficiaryId]);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removeBeneficiaryBenefit, {
        onSuccess: () => {
            queryClient.invalidateQueries([BENEFICIARY_BENEFIT_LIST_QUERY_KEY, beneficiaryId]);
        }
    });
    return { create, update, remove, isCreating, isUpdating, isRemoving };
};
