import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { BENEFIT_LIST_QUERY_KEY } from './useBenefitConfig';
export const useBenefitList = ({ filter } = {}) => {
    const { errorCallback } = useAlert();
    const handleFilterResponse = useCallback((values) => {
        if (!values?.length)
            return [];
        if (!filter || typeof filter !== 'function')
            return values;
        return values.filter(value => filter(value));
    }, [filter]);
    const getBenefits = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get('/benefit/by-parameters', {
            signal
        })
            .then(res => res.Registers)
            .catch(error => {
            errorCallback(error, 'BENEFIT_GET_ALL_FAILURE');
            return [];
        });
    }, [errorCallback]);
    const { data, isLoading } = useQuery([BENEFIT_LIST_QUERY_KEY], getBenefits, {
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    const memoizedData = useMemo(() => handleFilterResponse(data), [data, handleFilterResponse]);
    return [memoizedData, isLoading];
};
