import React from 'react';
import { Col, Row } from 'react-grid-system';
import { FormField, Select } from 'components';
import { useJobDescriptionList, usePlanList } from 'hooks';
const DailyRateTableFilterForm = ({ values, setFieldValue }) => {
    const [jobDescriptions, isLoadingJobDescriptions] = useJobDescriptionList();
    const [plans, isLoadingPlans] = usePlanList();
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "JobDescriptionId", label: "Cargo", options: jobDescriptions, value: jobDescriptions?.find(x => x.Id === values.JobDescriptionId), onChange: value => setFieldValue('JobDescriptionId', value.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingJobDescriptions }))),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "PlanId", label: "Plano", options: plans, value: plans?.find(x => x.Id === values.PlanId), onChange: value => setFieldValue('PlanId', value.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingPlans }))))));
};
export default DailyRateTableFilterForm;
