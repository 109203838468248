import React, { useCallback, useMemo, useState } from 'react';
import ReactPDF from '@react-pdf/renderer';
import { Button, Tooltip } from 'components';
import { ReportExportEndpoint, useBenefitConsumptionList, useReportExportMutation } from 'hooks';
import { FormatterUtils } from 'utils';
import BenefitConsumptionMonthlyBalanceExportContentPdf from './BenefitConsumptionMonthlyBalanceExportContentPdf';
const BenefitConsumptionMonthlyBalanceExportContent = ({ benefitConsumptionMonthlyBalance }) => {
    const benefitConsumptionsFilterParams = useMemo(() => {
        if (!benefitConsumptionMonthlyBalance.MonthYearReference)
            return {};
        const momentMonthYearReference = FormatterUtils.momentDate(benefitConsumptionMonthlyBalance?.MonthYearReference?.toString());
        const startDate = momentMonthYearReference.utc().startOf('month').format();
        const endDate = momentMonthYearReference.utc().endOf('month').format();
        return {
            PersonDocument: benefitConsumptionMonthlyBalance?.Beneficiary?.CPF,
            StartDate: startDate,
            EndDate: endDate
        };
    }, [benefitConsumptionMonthlyBalance]);
    const [benefitConsumptions] = useBenefitConsumptionList(benefitConsumptionsFilterParams);
    const PdfDocument = useMemo(() => (React.createElement(BenefitConsumptionMonthlyBalanceExportContentPdf, { benefitConsumptionMonthlyBalance: benefitConsumptionMonthlyBalance, benefitConsumptions: benefitConsumptions })), [benefitConsumptionMonthlyBalance, benefitConsumptions]);
    const [exportReport, isExporting] = useReportExportMutation(ReportExportEndpoint.BENEFIT_CONSUMPTION_MONTHLY_BALANCE);
    const [isGenerating, setIsGenerating] = useState(false);
    const onExportReportClick = useCallback(async () => {
        setIsGenerating(true);
        const pdfInstance = await ReactPDF.pdf(PdfDocument).toBlob();
        await exportReport({
            file: pdfInstance,
            beneficiaryId: benefitConsumptionMonthlyBalance.BeneficiaryId,
            monthYear: benefitConsumptionMonthlyBalance.MonthYearReference?.toString()
        });
        setIsGenerating(false);
    }, [benefitConsumptionMonthlyBalance, exportReport, PdfDocument]);
    const isLoading = isGenerating || isExporting;
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { id: `benefit-consumption-${benefitConsumptionMonthlyBalance?.Id}-export`, text: "Enviar por e-mail" },
            React.createElement(Button, { icon: "mail", color: "secondary", onClick: onExportReportClick, loading: isLoading, className: "ml-2", rounded: true }))));
};
export default BenefitConsumptionMonthlyBalanceExportContent;
