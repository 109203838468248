import React, { cloneElement, isValidElement, memo } from 'react';
import ReactTooltip from 'react-tooltip';
const Tooltip = memo(({ id, children, text, place }) => {
    return (React.createElement(React.Fragment, null,
        cloneElement(children, {
            'data-tip': '',
            'data-for': id
        }),
        React.createElement(ReactTooltip, { place: place, id: id }, isValidElement(text) ? text : React.createElement("span", { className: "text-sm font-normal" }, text))));
});
export default Tooltip;
