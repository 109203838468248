import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { USER_QUERY_KEY } from './useUserConfig';
export const useUserQuery = (userId) => {
    const { errorCallback } = useAlert();
    const navigate = useNavigate();
    const getUser = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/user/${userId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'USER_GET_FAILURE');
            navigate('/users');
            throw error;
        });
    }, [errorCallback, userId, navigate]);
    const { data, isLoading } = useQuery([USER_QUERY_KEY, userId], getUser, {
        enabled: !!userId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
