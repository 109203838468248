import { Validation } from 'utils';
export const BeneficiaryPersonalDataSchema = Validation.object().shape({
    CallName: Validation.string().required('Informe o nome de guerra'),
    SocialName: Validation.string().required('Informe o nome social'),
    TreatmentPronoun: Validation.string().required('Informe o pronome de tratamento'),
    BadgeNumber: Validation.string().required('Informe o número do crachá'),
    AssociateCode: Validation.string().required('Informe o código BP'),
    GenderId: Validation.string().required('Informe o gênero').nullable(),
    RG: Validation.string().required('Informe o RG'),
    CPF: Validation.string().required('Informe o CPF').cpf('Informe um CPF válido'),
    Name: Validation.string().required('Informe o nome'),
    Email: Validation.string().required('Informe o e-mail').email('Informe um e-mail válido'),
    CellPhone: Validation.string().required('Informe o celular'),
    BirthDate: Validation.string().required('Informe a data').date('Informe uma data válida'),
    Address: Validation.object().shape({
        ZipCode: Validation.string().required('Informe o CEP').zipCode('Informe um CEP válido'),
        Street: Validation.string().required('Informe a rua'),
        Number: Validation.string().required('Informe o número'),
        Neighborhood: Validation.string().required('Informe o complemento'),
        StateId: Validation.string().required('Informe o estado').nullable(),
        CityId: Validation.string().required('Informe a cidade').nullable()
    })
});
