import React, { useCallback, useMemo } from 'react';
import { Row, Col } from 'react-grid-system';
import { Button, FormField, FormFieldError, Form, SituationField, useFormikValidationHandler, FormikValidationHandler, Select, MaskedTextField, FormFieldSeparator } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useFormik } from 'formik';
import { useBeneficiaryList, useDeseaseList, useModulePermissions } from 'hooks';
import { SolicitationTreatmentMethod, SolicitationTreatmentMethodLabel } from 'types/enum';
import { FormatterUtils, QueryUtils } from 'utils';
import { SolicitationPaymentFlowFormPresentation } from './PaymentFlowForm';
import { convertToSolicitationDto } from './SolicitationCreationForm.converter';
import { SolicitationCreationFormSchema } from './SolicitationCreationForm.schema';
const defaultValues = {
    Beneficiary: null,
    Deseases: [],
    WorkAccident: false,
    SolicitationTreatmentMethod: null,
    SolicitationPaymentFlowInstallments: [],
    ScheduleWithdraw: '',
    INSSDate: '',
    StartDate: ''
};
const SolicitationCreationForm = ({ handleSubmit: handleSubmitSolicitation }) => {
    const { error } = useAlert();
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions();
    const [beneficiaries, isLoadingBeneficiaries] = useBeneficiaryList();
    const [deseases, isLoadingDeseases] = useDeseaseList();
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => SolicitationCreationFormSchema,
        onSubmit: async (solicitationCreationData) => {
            return handleSubmitSolicitation(convertToSolicitationDto(solicitationCreationData));
        },
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleSubmit, isSubmitting } = formikContext;
    const onSelectDeseases = useCallback((value) => {
        setFieldValue('Deseases', value);
    }, [setFieldValue]);
    const deseaseDaysOff = useMemo(() => {
        if (!values.SolicitationTreatmentMethod || !values.Deseases.length)
            return 0;
        const maxDeseaseDaysOff = Math.max(...values.Deseases.map(x => values.SolicitationTreatmentMethod === SolicitationTreatmentMethod.SURGICAL
            ? x.DaysOffSurgical
            : x.DaysOffConservative));
        return maxDeseaseDaysOff;
    }, [values.Deseases, values.SolicitationTreatmentMethod]);
    const showDeseaseDaysOffLabel = useMemo(() => {
        if (!values.SolicitationTreatmentMethod || !values.Deseases.length)
            return false;
        return true;
    }, [values.Deseases, values.SolicitationTreatmentMethod]);
    const getDeseaseOptionLabel = useCallback((desease) => {
        return `${desease.Code} - ${desease.Name}`;
    }, []);
    const filterBeneficiaryOption = useCallback((option, inputValue) => QueryUtils.objectPropertyLike(option.data, inputValue, 'CPF', 'Name'), []);
    const formatBeneficiaryOptionLabel = useCallback((beneficiary) => {
        return (React.createElement(React.Fragment, null,
            beneficiary.Name,
            " ",
            React.createElement("br", null),
            React.createElement("small", null,
                "CPF: ",
                FormatterUtils.cpf(beneficiary.CPF))));
    }, []);
    const onSelectBeneficiary = useCallback((value) => {
        const selectedBeneficiary = value;
        if (!selectedBeneficiary.BeneficiaryType?.CanCreateCAMRequest) {
            error({
                title: 'Associado inválido!',
                message: 'O associado selecionado não tem permissão ao Processo CAM.',
                id: 'BENEFICIARY_WITHOUT_CAM_PERMISSION'
            });
            return;
        }
        setFieldValue('Beneficiary', value);
    }, [setFieldValue, error]);
    const solicitationTreatmentMethodOptions = useMemo(() => [
        {
            name: SolicitationTreatmentMethodLabel[SolicitationTreatmentMethod.CONSERVATIVE],
            value: SolicitationTreatmentMethod.CONSERVATIVE,
            color: 'info',
            textColor: 'white'
        },
        {
            name: SolicitationTreatmentMethodLabel[SolicitationTreatmentMethod.SURGICAL],
            value: SolicitationTreatmentMethod.SURGICAL,
            color: 'info',
            textColor: 'white'
        }
    ], []);
    const onSubmitPaymentFlowInstallment = useCallback((installmentValues) => {
        setFieldValue('SolicitationPaymentFlowInstallments', installmentValues);
    }, [setFieldValue]);
    return (React.createElement(Form, { name: "solicitationCreationForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(FormFieldSeparator, { text: "Dados do Requerimento", icon: "solicitation" }),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "Beneficiary", label: "Associado", options: beneficiaries, value: beneficiaries?.find(beneficiary => beneficiary.Id === values.Beneficiary?.Id), onChange: onSelectBeneficiary, getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), filterOption: filterBeneficiaryOption, formatOptionLabel: formatBeneficiaryOptionLabel, isLoading: isLoadingBeneficiaries }),
                    React.createElement(FormFieldError, { name: "Beneficiary" }))),
            !!values.Beneficiary?.Id && (React.createElement(Col, { sm: 12 },
                React.createElement("div", { className: "bg-slate-100 dark:bg-slate-dark p-3 mb-3" },
                    React.createElement("p", { className: "text-gray-light dark:text-gray-400 text-xs mb-2" }, "Confirme os dados do associado selecionado:"),
                    React.createElement("p", { className: "text-gray dark:text-gray-50 text-sm font-semibold" }, values.Beneficiary.Name),
                    React.createElement("p", { className: "text-gray dark:text-gray-50 text-sm" }, values.Beneficiary.JobDescription?.Name),
                    React.createElement("p", { className: "text-gray dark:text-gray-50 text-sm" }, values.Beneficiary.Plan?.Name),
                    React.createElement("p", { className: "text-gray dark:text-gray-50 text-sm" }, FormatterUtils.cpf(values.Beneficiary.CPF)),
                    React.createElement("p", { className: "text-gray dark:text-gray-50 text-sm" }, FormatterUtils.phone(values.Beneficiary.CellPhone))))),
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "Deseases", label: "Anexo 10", options: deseases, value: deseases?.filter(desease => values.Deseases.some(x => desease.Id === x?.Id)), onChange: onSelectDeseases, getOptionLabel: getDeseaseOptionLabel, getOptionValue: option => option.Id.toString(), isLoading: isLoadingDeseases, isMulti: true }),
                    React.createElement(FormFieldError, { name: "Deseases" }),
                    !!showDeseaseDaysOffLabel && (React.createElement("span", { className: "text-blue-light text-xs mt-1" },
                        "Essa solicita\u00E7\u00E3o ter\u00E1 um prazo m\u00E1ximo de ",
                        deseaseDaysOff,
                        " dias")))),
            React.createElement(Col, { sm: 4 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "date", name: "StartDate", label: "Data de in\u00EDcio", value: values.StartDate, onChange: value => setFieldValue('StartDate', value) }),
                    React.createElement(FormFieldError, { name: "StartDate" }))),
            React.createElement(Col, { sm: 4 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "date", name: "ScheduleWithdraw", label: "Data afastamento escala", value: values.ScheduleWithdraw, onChange: value => setFieldValue('ScheduleWithdraw', value) }),
                    React.createElement(FormFieldError, { name: "ScheduleWithdraw" }))),
            React.createElement(Col, { sm: 4 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "date", name: "INSSDate", label: "Data do INSS", value: values.INSSDate, onChange: value => setFieldValue('INSSDate', value) }),
                    React.createElement(FormFieldError, { name: "INSSDate" }))),
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "M\u00E9todo de tratamento", handleChange: value => setFieldValue('SolicitationTreatmentMethod', value), value: values.SolicitationTreatmentMethod, options: solicitationTreatmentMethodOptions }),
                    React.createElement(FormFieldError, { name: "SolicitationTreatmentMethod" }))),
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Acidente de trabalho?", handleChange: value => setFieldValue('WorkAccident', value), value: values.WorkAccident, falseLabel: "N\u00E3o", trueLabel: "Sim" })))),
        React.createElement(FormFieldSeparator, { text: "Configura\u00E7\u00E3o de Pagamento", icon: "money-bag" }),
        React.createElement(SolicitationPaymentFlowFormPresentation, { installmentValues: values.SolicitationPaymentFlowInstallments, formValues: values, onSubmit: onSubmitPaymentFlowInstallment }),
        React.createElement(FormFieldError, { name: "SolicitationPaymentFlowInstallments" }),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Confirmar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default SolicitationCreationForm;
