import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col } from 'react-grid-system';
import { Button, FormField, FormFieldError, Form, Select, FormikValidationHandler, useFormikValidationHandler, AlertBox, MaskedTextField, TextField } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useFormik } from 'formik';
import { useModulePermissions, useBeneficiaryList, useBeneficiaryRecommendationQuery, useBeneficiaryRecommendationMutation } from 'hooks';
import { FormatterUtils, QueryUtils, ValidationUtils } from 'utils';
import { BeneficiaryRecommendationSchema } from './BeneficiaryRecommendationForm.schema';
const defaultValues = {
    Id: 0,
    BeneficiaryId: null,
    RecommendedByBeneficiaryId: null,
    Name: '',
    CPF: ''
};
const BeneficiaryRecommendationForm = ({ beneficiaryId }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions(beneficiaryId);
    const { success, errorCallback } = useAlert();
    const [beneficiaries, isLoadingBeneficiaries] = useBeneficiaryList();
    const [beneficiaryRecommendation, isLoadingBeneficiaryRecommendation] = useBeneficiaryRecommendationQuery(beneficiaryId);
    const { create, update } = useBeneficiaryRecommendationMutation();
    const onSubmit = useCallback(async (beneficiaryRecommendationValues) => {
        const beneficiaryRecommendationData = {
            Id: beneficiaryRecommendationValues.Id,
            BeneficiaryId: beneficiaryId,
            RecommendedByBeneficiaryId: beneficiaryRecommendationValues.RecommendedByBeneficiaryId,
            Name: beneficiaryRecommendationValues.Name || '',
            CPF: beneficiaryRecommendationValues.CPF?.replace(/\D/g, '') ?? ''
        };
        if (beneficiaryRecommendationData.Id) {
            return update(beneficiaryRecommendationData)
                .then(() => success({
                title: 'Indicação atualizada!',
                message: 'Dados da indicação atualizados com sucesso.',
                id: 'BENEFICIARY_RECOMMENDATION_PUT_SUCCESS'
            }))
                .catch(err => errorCallback(err, 'BENEFICIARY_RECOMMENDATION_PUT_FAILURE'));
        }
        else {
            return create(beneficiaryRecommendationData)
                .then(() => success({
                title: 'Indicação cadastrada!',
                message: 'Dados da indicação cadastrados com sucesso.',
                id: 'BENEFICIARY_RECOMMENDATION_POST_SUCCESS'
            }))
                .catch(err => errorCallback(err, 'BENEFICIARY_RECOMMENDATION_POST_FAILURE'));
        }
    }, [create, update, beneficiaryId, success, errorCallback]);
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => BeneficiaryRecommendationSchema,
        onSubmit,
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleChange, handleSubmit, isSubmitting, setValues } = formikContext;
    const onInitForm = useCallback(() => {
        if (ValidationUtils.isEmptyObject(beneficiaryRecommendation))
            return;
        const beneficiaryData = {
            Id: beneficiaryRecommendation?.Id,
            BeneficiaryId: beneficiaryRecommendation?.BeneficiaryId ?? null,
            RecommendedByBeneficiaryId: beneficiaryRecommendation?.RecommendedByBeneficiaryId ?? null,
            Name: beneficiaryRecommendation?.Name ?? '',
            CPF: beneficiaryRecommendation?.CPF ?? ''
        };
        setValues(beneficiaryData);
    }, [beneficiaryRecommendation, setValues]);
    const filteredBeneficiaries = useMemo(() => beneficiaries.filter(x => x.Id !== beneficiaryId), [
        beneficiaries,
        beneficiaryId
    ]);
    const filterBeneficiaryOption = useCallback((option, inputValue) => QueryUtils.objectPropertyLike(option.data, inputValue, 'CPF', 'Name'), []);
    const formatBeneficiaryOptionLabel = useCallback((beneficiary) => {
        return (React.createElement(React.Fragment, null,
            beneficiary.Name,
            " ",
            React.createElement("br", null),
            React.createElement("small", null,
                "CPF: ",
                FormatterUtils.cpf(beneficiary.CPF))));
    }, []);
    useEffect(onInitForm, [onInitForm]);
    if (isLoadingBeneficiaryRecommendation)
        return null;
    return (React.createElement(Form, { name: "BeneficiaryRecommendationForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(AlertBox, { type: "info", description: "Selecione o associado ou informe os dados da pessoa que fez esta indica\u00E7\u00E3o.", className: "mb-6" }),
        React.createElement(Row, null,
            React.createElement(Col, { lg: 12 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "RecommendedByBeneficiaryId", label: "Associado", options: filteredBeneficiaries, value: filteredBeneficiaries?.find((beneficiary) => beneficiary.Id === values.RecommendedByBeneficiaryId), onChange: option => setFieldValue('RecommendedByBeneficiaryId', option.Id), formatOptionLabel: formatBeneficiaryOptionLabel, filterOption: filterBeneficiaryOption, getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingBeneficiaries, isDisabled: Boolean(values.Name || values.CPF) }),
                    React.createElement(FormFieldError, { name: "RecommendedByBeneficiaryId" })))),
        React.createElement(Row, null,
            React.createElement(Col, { lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Name", label: "Nome", value: values.Name, onChange: handleChange, disabled: Boolean(values.RecommendedByBeneficiaryId) }),
                    React.createElement(FormFieldError, { name: "Name" }))),
            React.createElement(Col, { lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "cpf", name: "CPF", label: "CPF", value: values.CPF, onChange: value => setFieldValue('CPF', value), disabled: Boolean(values.RecommendedByBeneficiaryId) }),
                    React.createElement(FormFieldError, { name: "CPF" })))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: values.Id ? 'Atualizar' : 'Salvar', color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default BeneficiaryRecommendationForm;
