import React from 'react';
import { Col, Row } from 'react-grid-system';
import { FormField, MonthField, Select } from 'components';
import { useJobDescriptionList } from 'hooks';
const MonthlyBasePercentageTableFilterForm = ({ values, setFieldValue }) => {
    const [jobDescriptions, isLoadingJobDescriptions] = useJobDescriptionList();
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 8 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "JobDescriptionId", label: "Cargo", options: jobDescriptions, value: jobDescriptions?.find(x => x.Id === values.JobDescriptionId), onChange: value => setFieldValue('JobDescriptionId', value.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingJobDescriptions }))),
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(MonthField, { name: "MonthYear", label: "Escolha o m\u00EAs", value: values?.MonthYear, onChange: value => setFieldValue('MonthYear', value) })))));
};
export default MonthlyBasePercentageTableFilterForm;
