import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Loading, Card } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useDocumentTitle, useAwayWithoutSolicitationQuery, useAwayWithoutSolicitationMutation } from 'hooks';
import moment from 'moment-timezone';
import { AwayWithoutSolicitationForm } from '../components';
const AwayWithoutSolicitationInternal = () => {
    const awayWithoutSolicitationIdRoute = parseInt(useParams()?.awayWithoutSolicitationId ?? '0');
    const { success, errorCallback } = useAlert();
    const navigate = useNavigate();
    const [awayWithoutSolicitation, isLoading] = useAwayWithoutSolicitationQuery(awayWithoutSolicitationIdRoute);
    const { create, update } = useAwayWithoutSolicitationMutation(awayWithoutSolicitationIdRoute);
    async function submitAwayWithoutSolicitation(awayWithoutSolicitationData) {
        const awayWithoutSolicitationDataSubmit = { ...awayWithoutSolicitationData };
        awayWithoutSolicitationDataSubmit.CreatedAt = moment().format();
        awayWithoutSolicitationDataSubmit.UpdatedAt = null;
        if (awayWithoutSolicitationData.Id) {
            return update(awayWithoutSolicitationDataSubmit, {
                onSuccess: () => success({
                    title: 'Afastado atualizado!',
                    message: 'Dados do afastado atualizados com sucesso.',
                    id: 'AWAY_WITHOUT_SOLICITATION_PUT_SUCCESS'
                }),
                onError: err => errorCallback(err, 'AWAY_WITHOUT_SOLICITATION_PUT_FAILURE')
            });
        }
        else {
            return create(awayWithoutSolicitationDataSubmit, {
                onSuccess: res => {
                    success({
                        title: 'Afastado cadastrado!',
                        message: 'Afastado cadastrado com sucesso.',
                        id: 'AWAY_WITHOUT_SOLICITATION_POST_SUCCESS'
                    });
                    navigate(`/cam/aways-without-solicitation/${res.Id}`);
                },
                onError: err => errorCallback(err, 'AWAY_WITHOUT_SOLICITATION_POST_FAILURE')
            });
        }
    }
    const pageTitle = useMemo(() => `${awayWithoutSolicitationIdRoute ? 'Visualizar' : 'Novo'} Afastado`, [
        awayWithoutSolicitationIdRoute
    ]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Afastados',
            Url: '/cam/aways-without-solicitation'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Afastados", breadcrumb: breadcrumb }),
        React.createElement(Loading, { show: isLoading }),
        React.createElement(Card, { id: "away-without-solicitation-card" },
            React.createElement(AwayWithoutSolicitationForm, { awayWithoutSolicitation: awayWithoutSolicitation, isLoading: isLoading, handleSubmit: submitAwayWithoutSolicitation }))));
};
export default AwayWithoutSolicitationInternal;
