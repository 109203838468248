import { sharedValidation, validateCpf, validateDate, validateDateAfter, validateDateBefore, validateDateSameOrBefore, validatePassword, validatePhone, validateZipCode } from './validations';
export const ValidationUtils = {
    cpf: validateCpf,
    password: validatePassword,
    zipCode: validateZipCode,
    phone: validatePhone,
    date: validateDate,
    dateBefore: validateDateBefore,
    dateSameOrBefore: validateDateSameOrBefore,
    dateAfter: validateDateAfter,
    ...sharedValidation
};
