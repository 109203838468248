import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';
import { PageHeader, Card, Loading } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useBenefitConsumptionBatchMutation, useDocumentTitle } from 'hooks';
import moment from 'moment-timezone';
import { BenefitConsumptionBatchFileHeader, benefitConsumptionTypeOptions } from 'types/enum';
import { ParserUtils, TextUtils } from 'utils';
import { BenefitConsumptionBatchDropzone, BenefitConsumptionBatchForm } from '../components';
const BenefitConsumptionBatchCreation = () => {
    const { success, errorCallback, error } = useAlert();
    const navigate = useNavigate();
    const { create } = useBenefitConsumptionBatchMutation();
    const [selectedFile, setSelectedFile] = useState();
    const [isParsingFile, setIsParsingFile] = useState(false);
    const [batchLines, setBatchLines] = useState([]);
    function formatBenefitConsumptionBatchLines(benefitConsumptionBatchData) {
        benefitConsumptionBatchData.BenefitConsumptionBatchLines.forEach(batchLine => {
            batchLine.BeneficiaryRegistration = batchLine.BeneficiaryRegistration;
            batchLine.BeneficiaryName = batchLine.BeneficiaryName;
            batchLine.Type = batchLine.Type;
            batchLine.Value = batchLine.Value;
            batchLine.Date = moment().format();
        });
        return benefitConsumptionBatchData;
    }
    async function submitBenefitConsumptionBatch(benefitConsumptionBatchData) {
        const benefitConsumptionBatchDataSubmit = {
            ...formatBenefitConsumptionBatchLines(benefitConsumptionBatchData),
            FileName: selectedFile?.name,
            CreatedAt: moment().format()
        };
        await create(benefitConsumptionBatchDataSubmit)
            .then(() => {
            success({
                title: 'Importação cadastrada!',
                message: 'Importação cadastrada com sucesso.',
                id: 'BENEFIT_CONSUMPTION_BATCH_POST_SUCCESS'
            });
            navigate('/benefits/consumptions/batches');
        })
            .catch(err => errorCallback(err, 'BENEFIT_CONSUMPTION_BATCH_POST_FAILURE'));
    }
    async function onSelectFile(file) {
        try {
            setIsParsingFile(true);
            const fileLines = await ParserUtils.parseCsv(file);
            if (!fileLines.length) {
                error({ message: 'Arquivo vazio.', id: 'BENEFIT_CONSUMPTION_BATCH_UPLOAD_FAILURE' });
                return;
            }
            setBatchLines(fileLines.map(line => {
                const typeRaw = line[BenefitConsumptionBatchFileHeader.TYPE];
                const type = benefitConsumptionTypeOptions.find(x => TextUtils.normalize(x.label).toLocaleLowerCase() === TextUtils.normalize(typeRaw).toLocaleLowerCase());
                return {
                    BeneficiaryRegistration: line[BenefitConsumptionBatchFileHeader.BENEFICIARY_REGISTRATION],
                    BeneficiaryName: line[BenefitConsumptionBatchFileHeader.BENEFICIARY_NAME],
                    Value: ParserUtils.parseCurrency(line[BenefitConsumptionBatchFileHeader.VALUE]),
                    Type: type?.value
                };
            }));
            setSelectedFile(file);
        }
        catch (error) {
            errorCallback(error, 'BENEFIT_CONSUMPTION_BATCH_UPLOAD_FAILURE');
        }
        finally {
            setIsParsingFile(false);
        }
    }
    const pageTitle = useMemo(() => 'Nova Importação', []);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Benefícios',
            Url: '/benefits'
        },
        {
            Title: 'Consumos',
            Url: '/benefits/consumptions'
        },
        {
            Title: 'Importações',
            Url: '/benefits/consumptions/batches'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    const hasSelectedFile = Boolean(selectedFile?.name && batchLines.length);
    const transition = useTransition(hasSelectedFile, {
        from: { position: 'absolute', opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        delay: 200
    });
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Importa\u00E7\u00E3o de Consumos de Benef\u00EDcios", breadcrumb: breadcrumb }),
        React.createElement(Loading, { show: isParsingFile }),
        transition(({ opacity }, value) => value ? (React.createElement(animated.div, { style: {
                width: '100%',
                position: 'absolute',
                opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] })
            } },
            React.createElement(Card, { id: "benefit-consumption-batch-card" },
                React.createElement(BenefitConsumptionBatchForm, { batchLines: batchLines, handleSubmit: submitBenefitConsumptionBatch })))) : (React.createElement(animated.div, { style: {
                width: '100%',
                position: 'absolute',
                opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] })
            } },
            React.createElement(Card, { id: "benefit-consumption-batch-card" },
                React.createElement(BenefitConsumptionBatchDropzone, { onSelectFile: onSelectFile })))))));
};
export default BenefitConsumptionBatchCreation;
