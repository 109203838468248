import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import logoWhite from 'assets/images/logo-white.png';
import logo from 'assets/images/logo.png';
import clsx from 'clsx';
import { DropdownMenu } from 'components/DropdownMenu';
import { Icon } from 'components/Icon';
import { Tooltip } from 'components/Tooltip';
import { useAuth } from 'contexts/auth.context';
import { useTheme } from 'contexts/theme.context';
import './Header.scss';
const Header = ({ sidebarOpened, onToggleSidebar }) => {
    const { user, logout } = useAuth();
    const { darkMode, setDarkMode } = useTheme();
    return (React.createElement("header", { className: "header-wrapper" },
        React.createElement("div", { className: "header-logo" },
            React.createElement("img", { src: darkMode ? logoWhite : logo, alt: "ATL", className: "img-fluid" })),
        React.createElement("div", { className: "header-left-actions" },
            React.createElement(Tooltip, { id: "sidebar", text: `${sidebarOpened ? 'Esconder' : 'Mostrar'} Sidebar` },
                React.createElement("span", { className: "toggle-sidebar", onClick: onToggleSidebar },
                    React.createElement(Icon, { name: "menu", size: 25 })))),
        React.createElement("div", { className: "header-right-actions" },
            React.createElement(Tooltip, { id: "darkMode", text: `${darkMode ? 'Acender' : 'Apagar'} Luz` },
                React.createElement("span", { className: "action-item", onClick: () => setDarkMode(!darkMode) },
                    React.createElement(Icon, { name: darkMode ? 'sun' : 'moon', size: 22 }))),
            React.createElement(Tooltip, { id: "logout", text: "Deslogar" },
                React.createElement("span", { className: "action-item", onClick: () => logout() },
                    React.createElement(Icon, { name: "exit", size: 22 }))),
            React.createElement(DropdownMenu, { showOnHover: true, anchorElem: React.createElement("div", { className: "flex items-center text-gray-light dark:text-gray-50 ml-3" },
                    React.createElement("p", { className: "text-sm mx-2" }, user.Name),
                    React.createElement(Icon, { name: "chevron-down", size: 12 })) },
                React.createElement(NavLink, { to: "/profile", className: ({ isActive }) => clsx('dropdown-menu-item dropdown-menu-item-link flex items-center justify-center', isActive && 'active') },
                    React.createElement("span", null, "Meu Perfil")),
                React.createElement("a", { className: "dropdown-menu-item dropdown-menu-item-link flex items-center justify-center cursor-pointer", onClick: () => logout() },
                    React.createElement("span", null, "Deslogar"))))));
};
export default memo(Header);
