import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { DESEASE_QUERY_KEY } from './useDeseaseConfig';
export const useDeseaseQuery = (deseaseId) => {
    const { errorCallback } = useAlert();
    const navigate = useNavigate();
    const getDesease = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/desease/${deseaseId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'DESEASE_GET_FAILURE');
            navigate('/operational/deseases');
            throw error;
        });
    }, [errorCallback, deseaseId, navigate]);
    const { data, isLoading } = useQuery([DESEASE_QUERY_KEY, deseaseId], getDesease, {
        enabled: !!deseaseId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
