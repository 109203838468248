import React, { useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { PageHeader, MonthField, Icon, Card, Button } from 'components';
import { useDocumentTitle, useReportExportQuery, useReportMonthlyReceivableQuery } from 'hooks';
import { FormatterUtils, TimeUtils } from 'utils';
const MonthlyReceivableReport = () => {
    const [selectedMonth, setSelectedMonth] = useState();
    const requestFilterParams = useMemo(() => {
        const params = {};
        if (!selectedMonth)
            return params;
        const startDate = `${selectedMonth?.year}-${selectedMonth?.month}-01`;
        params.StartDate = startDate;
        return params;
    }, [selectedMonth]);
    const exportExcelQuery = useReportExportQuery('monthly-receivables-excel', requestFilterParams);
    const [data, isLoading] = useReportMonthlyReceivableQuery(requestFilterParams);
    const benefits = useMemo(() => {
        if (!data?.BenefitTotals)
            return [];
        return Object.keys(data.BenefitTotals).map(x => ({
            Name: x,
            Value: data.BenefitTotals[x]
        }));
    }, [data]);
    const benefitTotal = useMemo(() => data?.BenefitTotals
        ? Object.keys(data.BenefitTotals).reduce((acc, cur) => (acc += data.BenefitTotals[cur]), 0)
        : 0, [data]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Relatórios'
        },
        {
            Title: 'Relatório de Recebíveis Mensal'
        }
    ], []);
    const RightContent = (React.createElement("div", { className: "min-w-[400px]" },
        React.createElement(MonthField, { name: "MonthYear", label: "Escolha o m\u00EAs", value: selectedMonth, onChange: setSelectedMonth, years: TimeUtils.getLastYears(3), mountOnCurrentMonth: true })));
    useDocumentTitle('Relatório de Recebíveis Mensal');
    if (isLoading) {
        return (React.createElement(React.Fragment, null,
            React.createElement(PageHeader, { title: "Relat\u00F3rio de Receb\u00EDveis Mensal", breadcrumb: breadcrumb, rightContent: RightContent }),
            React.createElement("div", { className: "flex justify-center" },
                React.createElement(Icon, { name: "spinner", size: 40, className: "stroke-gray-300" }))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Relat\u00F3rio de Receb\u00EDveis Mensal", breadcrumb: breadcrumb, rightContent: RightContent }),
        React.createElement("div", { className: "flex justify-end mb-4" },
            React.createElement("a", { href: exportExcelQuery, target: "_blank", rel: "noreferrer" },
                React.createElement(Button, { text: "Exportar", icon: "download", color: "info", animationHover: "pop" }))),
        React.createElement(Row, null,
            React.createElement(Col, { lg: 6 },
                React.createElement("div", { className: "flex flex-col justify-center bg-blue-light p-8 px-16 min-h-[160px]" },
                    React.createElement("h2", { className: "text-3xl font-semibold text-white" }, FormatterUtils.money(data.CAMTotal)),
                    React.createElement("p", { className: "text-xs tracking-tighter font-light text-white" }, "Total Processo CAM"))),
            React.createElement(Col, { lg: 6 },
                React.createElement("div", { className: "flex flex-col justify-center bg-blue-dark p-8 px-16 min-h-[160px]" },
                    React.createElement("h2", { className: "text-3xl font-semibold text-white" }, FormatterUtils.money(benefitTotal)),
                    React.createElement("p", { className: "text-xs tracking-tighter font-light text-white" }, "Total Benef\u00EDcios")))),
        React.createElement(Row, { className: "mt-4" },
            React.createElement(Col, { lg: 12 },
                React.createElement(Card, { className: "h-full" },
                    React.createElement("h2", { className: "text-lg font-semibold text-gray dark:text-gray-50 mb-2" }, "Benef\u00EDcios"),
                    React.createElement("div", { className: "grid grid-cols-1 divide-y" }, benefits.length ? (benefits.map((benefit, index) => (React.createElement("div", { key: index, className: "flex items-center justify-between py-2" },
                        React.createElement("p", { className: "text-sm text-gray-400 dark:text-gray-400 tracking-tighter" }, benefit.Name),
                        React.createElement("span", { className: "text-base text-gray dark:text-gray-50 font-medium" }, FormatterUtils.money(benefit.Value)))))) : (React.createElement("small", { className: "text-xs text-gray-400" }, "Nenhum benef\u00EDcio \u00E0 receber."))))))));
};
export default MonthlyReceivableReport;
