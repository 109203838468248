import { Validation } from 'utils';
export const CompensationRateFormSchema = Validation.object().shape({
    DailyRateId: Validation.string().required('Informe a diária').nullable(),
    ContributionDaysFrom: Validation.number()
        .nullable()
        .when('ContributionDaysTo', {
        is: value => value > 0,
        then: Validation.number().lessThan(Validation.ref('ContributionDaysTo'), 'Informe um valor válido')
    }),
    ContributionDaysTo: Validation.number().nullable(),
    Percentage: Validation.number()
        .required('Informe a porcentagem de contribuição')
        .min(1, 'Informe um valor maior do que 0')
        .max(100, 'Informe um valor menor do que 100'),
    Active: Validation.bool().required('Informe o status')
});
