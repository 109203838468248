import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { DAILY_RATE_QUERY_KEY } from './useDailyRateConfig';
export const useDailyRateQuery = (dailyRateId) => {
    const { errorCallback } = useAlert();
    const navigate = useNavigate();
    const getDailyRate = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/daily-rate/${dailyRateId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'DAILY_RATE_GET_FAILURE');
            navigate('/cam/daily-rates');
            throw error;
        });
    }, [errorCallback, dailyRateId, navigate]);
    const { data, isLoading } = useQuery([DAILY_RATE_QUERY_KEY, dailyRateId], getDailyRate, {
        enabled: !!dailyRateId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
