import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { MONTHLY_BASE_PERCENTAGE_LIST_QUERY_KEY, MONTHLY_BASE_PERCENTAGE_PAGINATION_QUERY_KEY, MONTHLY_BASE_PERCENTAGE_QUERY_KEY } from './useMonthlyBasePercentageConfig';
export const useMonthlyBasePercentageMutation = (monthlyBasePercentageId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createMonthlyBasePercentage = useCallback(async (monthlyBasePercentageData) => {
        return httpService.post('/monthly-base-percentage', monthlyBasePercentageData);
    }, [httpService]);
    const updateMonthlyBasePercentage = useCallback(async (monthlyBasePercentageData) => {
        return httpService.put('/monthly-base-percentage', monthlyBasePercentageData);
    }, [httpService]);
    const removeMonthlyBasePercentage = useCallback(async (monthlyBasePercentageId) => {
        return httpService.delete(`/monthly-base-percentage?id=${monthlyBasePercentageId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createMonthlyBasePercentage, {
        onSuccess: () => {
            queryClient.invalidateQueries(MONTHLY_BASE_PERCENTAGE_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries(MONTHLY_BASE_PERCENTAGE_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateMonthlyBasePercentage, {
        onSuccess: () => {
            queryClient.invalidateQueries(MONTHLY_BASE_PERCENTAGE_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries([MONTHLY_BASE_PERCENTAGE_QUERY_KEY, monthlyBasePercentageId]);
            queryClient.invalidateQueries(MONTHLY_BASE_PERCENTAGE_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removeMonthlyBasePercentage, {
        onSuccess: (_, monthlyBasePercentageId) => {
            queryClient.invalidateQueries(MONTHLY_BASE_PERCENTAGE_PAGINATION_QUERY_KEY);
            queryClient.removeQueries([MONTHLY_BASE_PERCENTAGE_QUERY_KEY, monthlyBasePercentageId]);
            queryClient.invalidateQueries(MONTHLY_BASE_PERCENTAGE_LIST_QUERY_KEY);
        }
    });
    return { create, update, remove, isCreating, isUpdating, isRemoving };
};
