import React, { useCallback, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { TextField, Button, FormField, FormFieldError, Form, MaskedTextField, SituationField, useFormikValidationHandler, FormikValidationHandler } from 'components';
import { useFormik } from 'formik';
import { useModulePermissionsByScope } from 'hooks';
import { DateFormat, Scope } from 'types/enum';
import { FormatterUtils } from 'utils';
import { convertToDependentDto } from './DependentForm.converter';
import { DependentSchema } from './DependentForm.schema';
const defaultValues = {
    Id: 0,
    RegistrationCode: '',
    Name: '',
    Email: '',
    CellPhone: '',
    BirthDate: '',
    RG: '',
    CPF: '',
    CreatedAt: '',
    UpdatedAt: '',
    Active: true
};
const DependentForm = ({ dependent, onSubmitDependent }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissionsByScope(Scope.DEPENDENT);
    const onSubmit = useCallback(async (dependentValues) => {
        const dependentData = convertToDependentDto(dependentValues);
        return onSubmitDependent(dependentData);
    }, [onSubmitDependent]);
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => DependentSchema,
        onSubmit,
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleChange, handleSubmit, isSubmitting, setValues } = formikContext;
    const onInitForm = useCallback(() => {
        if (!dependent?.Id)
            return;
        const dependentData = {
            Id: dependent.Id,
            RegistrationCode: dependent.RegistrationCode,
            Name: dependent.Name,
            Email: dependent.Email || '',
            CellPhone: dependent.CellPhone || '',
            BirthDate: FormatterUtils.date(dependent.BirthDate, DateFormat.DATE_PT_BR),
            RG: dependent.RG || '',
            CPF: dependent.CPF || '',
            CreatedAt: FormatterUtils.dateTime(dependent.CreatedAt),
            UpdatedAt: FormatterUtils.dateTime(dependent.UpdatedAt),
            Active: dependent.Active ?? true
        };
        setValues(dependentData);
    }, [dependent, setValues]);
    useEffect(onInitForm, [onInitForm]);
    return (React.createElement(Form, { name: "beneficiaryForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, null,
            !!dependent?.Id && (React.createElement(Col, { sm: 2 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "RegistrationCode", label: "C\u00F3digo de registro", value: values.RegistrationCode, disabled: true })))),
            React.createElement(Col, { sm: dependent?.Id ? 10 : 12 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Name", label: "Nome", value: values.Name, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Name" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "RG", label: "RG", value: values.RG, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "RG" }))),
            React.createElement(Col, { sm: 6 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "cpf", name: "CPF", label: "CPF", value: values.CPF, onChange: value => setFieldValue('CPF', value) }),
                    React.createElement(FormFieldError, { name: "CPF" }))),
            React.createElement(Col, { sm: 4 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "date", name: "BirthDate", label: "Data de nascimento", value: values.BirthDate, onChange: value => setFieldValue('BirthDate', value) }),
                    React.createElement(FormFieldError, { name: "BirthDate" }))),
            React.createElement(Col, { sm: 4 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Email", label: "E-mail", value: values.Email, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Email" }))),
            React.createElement(Col, { sm: 4 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "cellphone", name: "CellPhone", label: "Celular", value: values.CellPhone, onChange: value => setFieldValue('CellPhone', value) }),
                    React.createElement(FormFieldError, { name: "CellPhone" })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Situa\u00E7\u00E3o do dependente", handleChange: isActive => setFieldValue('Active', isActive), value: values.Active, falseLabel: "Inativo", trueLabel: "Ativo" })))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Salvar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default DependentForm;
