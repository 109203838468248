import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { USER_PAGINATION_QUERY_KEY, USER_QUERY_KEY } from './useUserConfig';
export const useUserMutation = (userId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createUser = useCallback(async (userData) => {
        return httpService.post('/user', userData);
    }, [httpService]);
    const updateUser = useCallback(async (userData) => {
        return httpService.put('/user', userData);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createUser, {
        onSuccess: () => {
            queryClient.invalidateQueries(USER_PAGINATION_QUERY_KEY);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateUser, {
        onSuccess: () => {
            queryClient.invalidateQueries(USER_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries([USER_QUERY_KEY, userId]);
        }
    });
    return { create, update, isCreating, isUpdating };
};
