import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { SOLICITATION_APPROVAL_KEY } from './useSolicitationApprovalConfig';
export const useSolicitationApprovalList = (solicitationId) => {
    const { errorCallback } = useAlert();
    const getSolicitationApprovals = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/solicitation-state-approval/solicitation/${solicitationId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'SOLICITATION_APPROVAL_GET_ALL_FAILURE');
            throw error;
        });
    }, [errorCallback, solicitationId]);
    const { data, isLoading } = useQuery([SOLICITATION_APPROVAL_KEY, solicitationId], getSolicitationApprovals, {
        enabled: !!solicitationId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    const memoizedData = useMemo(() => data ?? [], [data]);
    return [memoizedData, isLoading];
};
