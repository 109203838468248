import React from 'react';
import { Icon } from 'components/Icon';
import './Alert.scss';
const Alert = ({ title, message, icon }) => {
    return (React.createElement("div", { className: "alert-body flex items-center" },
        icon && (React.createElement("div", { className: "alert-icon mr-3" },
            React.createElement(Icon, { name: icon, size: 30 }))),
        React.createElement("div", { className: "alert-details" },
            title && React.createElement("h3", { className: "alert-title" }, title),
            message && React.createElement("p", { className: "alert-message" }, message))));
};
export default Alert;
