import React, { memo, useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import clsx from 'clsx';
import { Button, Card } from 'components';
const SolicitationCard = ({ title, className, classes, buttonAction, buttonActionLabel, showButtonAction, customButtom, children }) => {
    const hasButtonAction = useMemo(() => ((!!buttonAction && !!buttonActionLabel) || !!customButtom) && showButtonAction, [buttonAction, buttonActionLabel, customButtom, showButtonAction]);
    if (hasButtonAction) {
        return (React.createElement(Card, { className: className },
            React.createElement(Row, { justify: "between" },
                React.createElement(Col, { lg: "content", className: classes?.textColumn },
                    React.createElement("h2", { className: "text-lg font-semibold text-gray dark:text-gray-50 m-0" }, title)),
                React.createElement(Col, { lg: "content", className: clsx('flex justify-end', classes?.buttonColumn) }, customButtom || React.createElement(Button, { text: buttonActionLabel, icon: "add", color: "info", onClick: buttonAction }))),
            children));
    }
    return (React.createElement(Card, { className: className },
        React.createElement("h2", { className: "text-lg font-semibold text-gray dark:text-gray-50" }, title),
        children));
};
export default memo(SolicitationCard);
