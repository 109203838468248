import React, { useCallback, useEffect, useMemo } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import clsx from 'clsx';
import { Card } from 'components/Card';
import { Pagination } from 'components/Pagination';
import TableSortLabel from './TableSortLabel';
import './Table.scss';
function Table(props) {
    const { data, columns, className, cardClassName, noResultsMessage = 'Nenhum registro a ser exibido', isLoading, isFirstCall, withPageSize, currentPage = 1, perPage = 10, numberOfPages = 0, manualPagination = true, manualSortBy = true, disableSortBy, fetchSortedData, fetchPreviousPage, fetchNextPage, fetchPage, fetchPageSize } = props;
    const tableConfig = {
        columns,
        data,
        initialState: { pageIndex: 0 },
        manualPagination,
        manualSortBy,
        disableSortBy,
        defaultCanSort: false
    };
    if (manualPagination) {
        tableConfig.pageCount = numberOfPages;
        tableConfig.useControlledState = state => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useMemo(() => ({
                ...state,
                pageSize: perPage,
                pageIndex: currentPage - 1
            }), 
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [state, perPage, currentPage]);
        };
    }
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, canPreviousPage, canNextPage, pageCount, gotoPage, nextPage, previousPage, setPageSize, state: { sortBy } } = useTable(tableConfig, useSortBy, usePagination);
    const handleGoToPage = useCallback((pageIndex) => {
        if (manualPagination && fetchPage) {
            fetchPage(pageIndex + 1);
            return;
        }
        gotoPage(pageIndex);
    }, [fetchPage, manualPagination, gotoPage]);
    const handlePreviousPage = useCallback(() => {
        if (manualPagination && fetchPreviousPage) {
            fetchPreviousPage();
            return;
        }
        previousPage();
    }, [fetchPreviousPage, manualPagination, previousPage]);
    const handleNextPage = useCallback(() => {
        if (manualPagination && fetchNextPage) {
            fetchNextPage();
            return;
        }
        nextPage();
    }, [fetchNextPage, manualPagination, nextPage]);
    const handleChangePageSize = useCallback((pageSize) => {
        if (manualPagination && fetchPageSize) {
            fetchPageSize(pageSize);
            return;
        }
        setPageSize(pageSize);
    }, [fetchPageSize, manualPagination, setPageSize]);
    const handleChangeSort = useCallback(() => {
        if (sortBy?.length && fetchSortedData) {
            const sortByData = sortBy[0];
            fetchSortedData({
                sortAsc: !sortByData.desc,
                sortBy: sortByData.id
            });
        }
    }, [sortBy, fetchSortedData]);
    useEffect(handleChangeSort, [handleChangeSort]);
    return (React.createElement(Card, { id: "table-card", className: cardClassName },
        React.createElement("div", { className: "table-responsive", ref: props.ref },
            React.createElement("table", { ...getTableProps(), className: clsx('table', !!className && className, isLoading && 'is-loading') },
                React.createElement("thead", null, headerGroups.map((headerGroup, hgIndex) => (React.createElement("tr", { ...headerGroup.getHeaderGroupProps(), key: hgIndex }, headerGroup.headers.map((column, hIndex) => (React.createElement("th", { ...column.getHeaderProps(), key: hIndex }, column.canSort ? (React.createElement(TableSortLabel, { ...column.getSortByToggleProps(), isSorted: column.isSorted, direction: !column.isSortedDesc ? 'desc' : 'asc' }, column.render('Header'))) : (React.createElement("span", null, column.render('Header')))))))))),
                isFirstCall ? (React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", { colSpan: columns.length },
                            React.createElement("p", { className: "text-center" }, "Fa\u00E7a uma busca"))))) : isLoading ? (React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", { colSpan: columns.length },
                            React.createElement("p", { className: "text-center" }, "Carregando..."))))) : page.length ? (React.createElement("tbody", { ...getTableBodyProps() }, page.map((row, pIndex) => {
                    prepareRow(row);
                    return (React.createElement("tr", { ...row.getRowProps(), key: pIndex }, row.cells.map((cell, cIndex) => {
                        return (React.createElement("td", { ...cell.getCellProps(), key: cIndex }, cell.render('Cell')));
                    })));
                }))) : (React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", { colSpan: columns.length },
                            React.createElement("p", { className: "text-center" }, noResultsMessage)))))),
            React.createElement(Pagination, { perPage: perPage, currentPage: currentPage, numberOfPages: pageCount, withPageSize: withPageSize, isLoading: isLoading, canNextPage: canNextPage, canPreviousPage: canPreviousPage, handleGoToPage: handleGoToPage, handlePreviousPage: handlePreviousPage, handleNextPage: handleNextPage, handleChangePageSize: handleChangePageSize }))));
}
export default Table;
