import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { makeHttpService } from 'services/http';
import { RECOMPOSITION_INEQUALITY_SOLICITATION_QUERY_KEY } from './useRecompositionInequalityConfig';
export const useRecompositionInequalityBySolicitationQuery = (solicitationId, fetchData = true) => {
    const getRecompositionInequality = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/recomposition-inequality/solicitation/${solicitationId}`, {
            signal
        })
            .catch(error => {
            throw error;
        });
    }, [solicitationId]);
    const { data, isLoading } = useQuery([RECOMPOSITION_INEQUALITY_SOLICITATION_QUERY_KEY, solicitationId], getRecompositionInequality, {
        enabled: !!solicitationId && fetchData,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
