import React from 'react';
import { StyleSheet, View as PdfView } from '@react-pdf/renderer';
import { TextBold } from './Text';
const styles = StyleSheet.create({
    header: {
        padding: 8,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 0.5,
        borderBottomStyle: 'solid',
        borderBottomColor: '#CCCCCC'
    },
    footer: {
        padding: 8,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 0.5,
        borderBottomStyle: 'solid',
        borderBottomColor: '#CCCCCC'
    },
    row: {
        paddingHorizontal: 8,
        paddingVertical: 12,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 0.5,
        borderBottomStyle: 'solid',
        borderBottomColor: '#CCCCCC'
    },
    column: {
        flex: 1
    }
});
export function Table({ columns, data, style = {} }) {
    const hasFooter = columns.some(x => x.footer);
    return (React.createElement(PdfView, { style: {
            ...(style.table ?? {})
        } },
        React.createElement(PdfView, { style: { ...styles.header, ...style.header } }, columns.map((column, columnIndex) => (React.createElement(PdfView, { key: columnIndex, style: { ...styles.column, ...(column.style?.column ?? {}) } },
            React.createElement(TextBold, { fontSize: 9 }, column.header))))),
        data.map((data, dataIndex) => (React.createElement(PdfView, { key: dataIndex, style: { ...styles.row, ...style.row } }, columns.map((column, columnIndex) => (React.createElement(PdfView, { key: columnIndex, style: { ...styles.column, ...(column.style?.column ?? {}) } }, column.value(data))))))),
        hasFooter && (React.createElement(PdfView, { style: { ...styles.footer, ...style.footer } }, columns.map((column, columnIndex) => column.footer ? (React.createElement(PdfView, { key: columnIndex, style: { ...styles.column, ...(column.style?.column ?? {}) } },
            React.createElement(TextBold, { fontSize: 9 }, column.footer))) : null)))));
}
