import React from 'react';
import { Col, Row } from 'react-grid-system';
import { DateRangeField, FormField, MaskedTextField, Select, TextField } from 'components';
import { useBenefitList } from 'hooks';
const BenefitConsumptionTableFilterForm = ({ values, setFieldValue, handleChange }) => {
    const [benefits, isLoadingBenefits] = useBenefitList();
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 12 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "BenefitId", label: "Benef\u00EDcio", options: benefits, value: benefits?.find(x => x.Id === values.BenefitId), onChange: value => setFieldValue('BenefitId', value.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingBenefits }))),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "PersonCallName", label: "Nome Guerra", value: values.PersonCallName ?? '', onChange: handleChange }))),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "cpf", name: "PersonDocument", label: "CPF do associado", value: values.PersonDocument ?? '', onChange: value => setFieldValue('PersonDocument', value) })))),
        React.createElement(Row, { justify: "end" },
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(FormField, null,
                    React.createElement(DateRangeField, { startDate: values.DateRange[0], endDate: values.DateRange[1], onDatesChange: ({ startDate, endDate }) => setFieldValue('DateRange', [startDate, endDate]), isOutsideRange: () => false }))))));
};
export default BenefitConsumptionTableFilterForm;
