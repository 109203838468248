import React, { useEffect, useMemo } from 'react';
import { Row, Col } from 'react-grid-system';
import { TextField, Button, FormField, FormFieldError, Form, Select, SituationField, useFormikValidationHandler, FormikValidationHandler } from 'components';
import { useFormik } from 'formik';
import { useModulePermissions, useUserTypeList } from 'hooks';
import { FormatterUtils } from 'utils';
import { UserChangePasswordSchema, UserCreatePasswordSchema, UserSchema } from './UserForm.schema';
import PersonPasswordForm from './UserPasswordForm';
const defaultValues = {
    Id: 0,
    Name: '',
    Code: '',
    Username: '',
    CreatedAt: '',
    UserTypeId: null,
    AcceptedTerms: false,
    IsSolicitationApprover: false,
    Active: true,
    Password: '',
    NewPassword: '',
    ConfirmPassword: '',
    UpdatedAt: ''
};
const UserForm = ({ user, isLoading, isCreation, isProfileEdition, handleSubmit: handleSubmitUser }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions(user?.Id);
    const canEditForm = useMemo(() => isProfileEdition || canMutate, [canMutate, isProfileEdition]);
    const [userTypes, isLoadingUserTypes] = useUserTypeList();
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => getUserSchema(),
        onSubmit: async (userData) => handleSubmitUser(userData),
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleChange, handleSubmit, isSubmitting, setValues } = formikContext;
    const getUserSchema = () => {
        let baseSchema = UserSchema;
        if (isCreation) {
            baseSchema = baseSchema.concat(UserCreatePasswordSchema);
        }
        if (isProfileEdition) {
            baseSchema = baseSchema.concat(UserChangePasswordSchema);
        }
        return baseSchema;
    };
    useEffect(() => {
        if (!user?.Id)
            return;
        const userData = {
            Id: user.Id,
            Name: user.Name,
            Code: user.Code || '',
            Username: user.Username || '',
            UserTypeId: user.UserTypeId || null,
            Active: user.Active !== null ? user.Active : true,
            AcceptedTerms: user.AcceptedTerms !== null ? user.AcceptedTerms : false,
            IsSolicitationApprover: user.IsSolicitationApprover !== null ? user.IsSolicitationApprover : false,
            CreatedAt: FormatterUtils.dateTime(user.CreatedAt),
            UpdatedAt: FormatterUtils.dateTime(user.UpdatedAt)
        };
        setValues(userData);
    }, [user, setValues]);
    if (isLoading)
        return null;
    return (React.createElement(Form, { name: "userForm", onSubmit: handleSubmit, disabled: !canEditForm, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, { align: "center", justify: "center" },
            React.createElement(Col, { sm: 3 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Code", label: "C\u00F3digo", value: values.Code, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Code" }))),
            React.createElement(Col, { sm: 9 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Name", label: "Nome", value: values.Name, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Name" })))),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 8 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Username", label: "Usu\u00E1rio", value: values.Username, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Username" }))),
            React.createElement(Col, { sm: 4 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "UserTypeId", label: "Escolha o tipo", options: userTypes, value: userTypes.find((userType) => userType.Id === values.UserTypeId), onChange: option => setFieldValue('UserTypeId', option.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingUserTypes, isDisabled: isProfileEdition }),
                    React.createElement(FormFieldError, { name: "UserTypeId" })))),
        !isProfileEdition && (React.createElement(Row, null,
            React.createElement(Col, { xs: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Revisor de solicita\u00E7\u00E3o", handleChange: isSolicitationApprover => setFieldValue('IsSolicitationApprover', isSolicitationApprover), value: values.IsSolicitationApprover, falseLabel: "N\u00E3o", trueLabel: "Sim" }))),
            React.createElement(Col, { xs: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Situa\u00E7\u00E3o do usu\u00E1rio", handleChange: isActive => setFieldValue('Active', isActive), value: values.Active, falseLabel: "N\u00E3o", trueLabel: "Sim" }))),
            React.createElement(Col, { xs: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { disabled: true, label: "Aceitou os termos", handleChange: hasAccepted => setFieldValue('AcceptedTerms', hasAccepted), value: values.AcceptedTerms, falseLabel: "N\u00E3o", trueLabel: "Sim" }))))),
        (isCreation || isProfileEdition) && (React.createElement("div", { className: "mt-3" },
            React.createElement(PersonPasswordForm, { isCreation: isCreation, formikContext: formikContext }))),
        canEditForm && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Enviar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default UserForm;
