import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import BeneficiaryPersonalPictureDropzone from './BeneficiaryPersonalPictureDropzone';
const BeneficiaryPersonalPicture = ({ beneficiary }) => {
    const [selectedFile, setSelectedFile] = useState();
    const beneficiaryNameInitials = useMemo(() => {
        const name = beneficiary.Name.split(' ');
        if (name.length === 1)
            return name[0].charAt(0);
        const firstName = name[0];
        const lastName = name[name.length - 1];
        return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    }, [beneficiary]);
    const onInit = useCallback(() => {
        if (!beneficiary.ProfilePictureUrl)
            return;
        setSelectedFile({
            preview: beneficiary.ProfilePictureUrl
        });
    }, [beneficiary]);
    const onRemoveFile = useCallback(() => setSelectedFile(undefined), []);
    useEffect(onInit, [onInit]);
    const hasSelectedFile = Boolean(selectedFile?.preview);
    const transition = useTransition(selectedFile?.preview, {
        from: { position: 'absolute', opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    });
    const DefaultPictureElement = useMemo(() => React.createElement("span", { className: "text-gray text-4xl" }, beneficiaryNameInitials), [
        beneficiaryNameInitials
    ]);
    const SelectedPictureElement = useCallback(() => transition(({ opacity }, item) => item && (React.createElement(animated.div, { style: {
            position: 'absolute',
            opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] })
        } },
        React.createElement("img", { src: selectedFile?.preview, className: "", alt: "Avatar" })))), [selectedFile?.preview, transition]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex items-center mb-8" },
            React.createElement("div", { className: "relative inline-flex items-center justify-center w-36 h-36 bg-gray-100 rounded-full overflow-hidden" }, hasSelectedFile ? SelectedPictureElement() : DefaultPictureElement),
            React.createElement(BeneficiaryPersonalPictureDropzone, { beneficiary: beneficiary, hasSelectedFile: hasSelectedFile, onSelectFile: setSelectedFile, onRemoveFile: onRemoveFile }))));
};
export default BeneficiaryPersonalPicture;
