import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { AIRCRAFT_MODEL_LIST_QUERY_KEY, AIRCRAFT_MODEL_PAGINATION_QUERY_KEY, AIRCRAFT_MODEL_QUERY_KEY } from './useAircraftModelConfig';
export const useAircraftModelMutation = (aircraftModelId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createAircraftModel = useCallback(async (aircraftModelData) => {
        return httpService.post('/aircraft-model', aircraftModelData);
    }, [httpService]);
    const updateAircraftModel = useCallback(async (aircraftModelData) => {
        return httpService.put('/aircraft-model', aircraftModelData);
    }, [httpService]);
    const removeAircraftModel = useCallback(async (aircraftModelId) => {
        return httpService.delete(`/aircraft-model?id=${aircraftModelId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createAircraftModel, {
        onSuccess: () => {
            queryClient.invalidateQueries(AIRCRAFT_MODEL_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries(AIRCRAFT_MODEL_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateAircraftModel, {
        onSuccess: () => {
            queryClient.invalidateQueries(AIRCRAFT_MODEL_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries([AIRCRAFT_MODEL_QUERY_KEY, aircraftModelId]);
            queryClient.invalidateQueries(AIRCRAFT_MODEL_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removeAircraftModel, {
        onSuccess: (_, aircraftModelId) => {
            queryClient.invalidateQueries(AIRCRAFT_MODEL_PAGINATION_QUERY_KEY);
            queryClient.removeQueries([AIRCRAFT_MODEL_QUERY_KEY, aircraftModelId]);
            queryClient.invalidateQueries(AIRCRAFT_MODEL_LIST_QUERY_KEY);
        }
    });
    return { create, update, remove, isCreating, isUpdating, isRemoving };
};
