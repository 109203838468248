import React, { memo } from 'react';
import { Link } from 'react-router-dom';
const ForgotPasswordSentCallback = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: "auth-description" }, "Enviamos um e-mail para voc\u00EA, siga as instru\u00E7\u00F5es para recuperar sua senha."),
        React.createElement("div", { className: "flex flex-col items-center" },
            React.createElement(Link, { to: "/login", className: "mt-2" },
                React.createElement("span", { className: "text-xs inline-block" }, "Voltar para login")))));
};
export default memo(ForgotPasswordSentCallback);
