import React from 'react';
import { CompensationRateInternal, CompensationRateList } from './screens';
const CompensationRateModule = [
    {
        path: '/cam/compensation-rates',
        element: React.createElement(CompensationRateList, null)
    },
    {
        path: '/cam/compensation-rates/new',
        element: React.createElement(CompensationRateInternal, null)
    },
    {
        path: '/cam/compensation-rates/:compensationRateId',
        element: React.createElement(CompensationRateInternal, null)
    }
];
export { CompensationRateModule };
