import { Module } from 'types/enum';
export const ModuleUrlBlacklist = ['/dashboard', '/profile', '/not-found', '/not-authorized'];
export const ModuleUrlConfig = {
    [Module.DASHBOARD]: '/dashboard',
    [Module.USER]: '/users',
    [Module.BENEFIT]: '',
    [Module.BENEFIT_CATEGORY]: '/benefits/categories',
    [Module.BENEFICIARY]: '/beneficiaries',
    [Module.REPORT]: '',
    [Module.MONTHLY_BALANCE_REPORT]: '/reports/benefit-consumption-monthly-balance',
    [Module.BENEFIT_CONSUMPTION]: '/benefits/consumptions',
    [Module.CAM]: '',
    [Module.PLAN]: '/cam/plans',
    [Module.DAILY_RATE]: '/cam/daily-rates',
    [Module.COMPENSATION_RATE]: '/cam/compensation-rates',
    [Module.DESEASE]: '/cam/deseases',
    [Module.SOLICITATION]: '/cam/solicitations',
    [Module.SOLICITATION_REVISION]: '/cam/solicitation-revisions',
    [Module.JOB_DESCRIPTION]: '/cam/job-descriptions',
    [Module.OPERATIONAL]: '',
    [Module.OPERATION_BASE]: '/operational/operation-bases',
    [Module.AIRCRAFT_MODEL]: '/operational/aircraft-models',
    [Module.BANK]: '/operational/banks',
    [Module.MONTHLY_BASE_PERCENTAGE]: '/operational/monthly-percentages',
    [Module.RECOMPOSITION_INEQUALITY]: '/cam/recomposition-inequalities',
    [Module.BENEFIT_CONSUMPTION_BATCH]: '/benefits/consumptions/batches',
    [Module.BENEFIT_LIST]: '/benefits',
    [Module.BIRTHDAY_REPORT]: '/reports/birthdays',
    [Module.BENEFIT_CONSUMPTION_LATAM_REPORT]: '/reports/benefit-consumptions-latam',
    [Module.BENEFICIARY_AGE_REPORT]: '/reports/beneficiaries-by-age',
    [Module.MONTHLY_RECEIVABLE_REPORT]: '/reports/monthly-receivable',
    [Module.SOLICITATION_LEFT_OVER_MONEY_REPORT]: '/cam/solicitation-left-over-money',
    [Module.AWAY_WITHOUT_SOLICITATION]: '/cam/aways-without-solicitation',
    [Module.AWAY_BY_CAM]: '/cam/aways-by-cam',
    [Module.FINANCIAL_STATEMENTS]: '/financial/statements'
};
export const ModuleIconConfig = {
    [Module.DASHBOARD]: 'home',
    [Module.USER]: 'user',
    [Module.BENEFIT]: 'benefit',
    [Module.BENEFICIARY]: 'beneficiary',
    [Module.REPORT]: 'report',
    [Module.CAM]: 'solicitation',
    [Module.OPERATIONAL]: 'operation',
    [Module.FINANCIAL]: 'money-bag'
};
