import React, { useMemo } from 'react';
import { Icon, Modal, ScrollView } from 'components';
import { useBenefitConsumptionList } from 'hooks';
import { FormatterUtils } from 'utils';
import { BenefitConsumptionItem } from './BenefitConsumptionItem';
const BenefitConsumptionMonthlyBalanceDetailsModal = ({ selectedMonthlyBalance, onCloseModal }) => {
    const benefitConsumptionsFilterParams = useMemo(() => {
        if (!selectedMonthlyBalance?.BeneficiaryId)
            return {};
        const momentMonthYearReference = FormatterUtils.momentDate(selectedMonthlyBalance?.MonthYearReference?.toString());
        const startDate = momentMonthYearReference.startOf('month').format('YYYY-MM-DD HH:mm:ss');
        const endDate = momentMonthYearReference.endOf('month').format('YYYY-MM-DD HH:mm:ss');
        return {
            PersonDocument: selectedMonthlyBalance?.Beneficiary?.CPF,
            StartDate: startDate,
            EndDate: endDate
        };
    }, [selectedMonthlyBalance]);
    const [benefitConsumptions, isLoadingBenefitConsumptions] = useBenefitConsumptionList(benefitConsumptionsFilterParams);
    const showModal = Boolean(selectedMonthlyBalance?.BeneficiaryId);
    return (React.createElement(Modal, { show: showModal, handleClose: onCloseModal, title: "Consumos detalhados", closable: true }, isLoadingBenefitConsumptions ? (React.createElement(Icon, { name: "spinner", size: 35, className: "text-gray-light dark:text-gray-400" })) : (React.createElement(ScrollView, { maxHeight: 500 }, benefitConsumptions?.length ? (benefitConsumptions.map(benefitConsumption => (React.createElement(BenefitConsumptionItem, { key: benefitConsumption.Id.toString(), benefitConsumption: benefitConsumption })))) : (React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400" }, "Nenhum consumo encontrado."))))));
};
export default BenefitConsumptionMonthlyBalanceDetailsModal;
