import React, { useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import './PdfPreview.scss';
const reactPdf = require('react-pdf/dist/esm/entry.webpack5');
const { Document, Page } = reactPdf;
const PdfPreview = ({ pdfInstance }) => {
    const [numPages, setNumPages] = useState(0);
    const { ref, width = 1, height = 1 } = useResizeObserver();
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
    return (React.createElement("div", { ref: ref },
        React.createElement(Document, { className: "pdf-preview-wrapper", loading: null, noData: null, file: pdfInstance.url, onLoadSuccess: onDocumentLoadSuccess }, [...Array(numPages)].map((_, index) => (React.createElement(Page, { key: index, loading: null, pageNumber: index + 1, width: 880, renderAnnotationLayer: false, className: "mt-4" }))))));
};
export default PdfPreview;
