import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip, Icon } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useModulePermissions, useTableFilter, useDocumentTitle, useBenefitConsumptionMutation, useBenefitConsumptionPagination, useModulePermissionsByScope } from 'hooks';
import moment from 'moment-timezone';
import { Scope } from 'types/enum';
import { FormatterUtils } from 'utils';
import { BenefitConsumptionDetailsModal, BenefitConsumptionTableFilterForm } from '../components';
const BenefitConsumptionList = () => {
    const { canCreate, canRead, canUpdate, canDelete } = useModulePermissions();
    const { canCreate: canCreateBatch } = useModulePermissionsByScope(Scope.BENEFIT_CONSUMPTION_BATCH);
    const { success, confirm, errorCallback } = useAlert();
    const customParamsHandle = useCallback((filterParams) => {
        const params = {};
        if (filterParams.BenefitId) {
            params.BenefitId = filterParams.BenefitId;
        }
        if (filterParams.PersonDocument) {
            params.PersonDocument = filterParams.PersonDocument;
        }
        if (filterParams.PersonCallName) {
            params.SearchField = 'CallName';
            params.SearchQuery = filterParams.PersonCallName;
        }
        return params;
    }, []);
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter({
        customParamsHandle
    });
    const { data, isLoading, ...pagination } = useBenefitConsumptionPagination(requestFilterParams);
    const { remove } = useBenefitConsumptionMutation();
    const deleteBenefitConsumption = useCallback(async (benefitConsumptionId) => {
        await remove(benefitConsumptionId)
            .then(() => success({
            title: 'Consumo deletado!',
            message: 'Consumo deletado com sucesso.',
            id: 'BENEFIT_CONSUMPTION_DELETE_SUCCESS'
        }))
            .catch(err => errorCallback(err, 'BENEFIT_CONSUMPTION_DELETE_FAILURE'));
    }, [remove, success, errorCallback]);
    const onDeleteBenefitConsumption = useCallback((benefitConsumptionId) => {
        confirm({
            title: 'Você tem certeza?',
            description: (React.createElement("p", null,
                "O consumo ser\u00E1 deletado. ",
                React.createElement("br", null),
                " Essa a\u00E7\u00E3o \u00E9 irrevers\u00EDvel!")),
            confirmText: 'Sim, deletar',
            cancelText: 'Não, cancelar',
            onConfirm: async () => deleteBenefitConsumption(benefitConsumptionId)
        });
    }, [confirm, deleteBenefitConsumption]);
    const canDeleteBenefitConsumption = useCallback((benefitConsumption) => {
        const monthYearReference = moment(benefitConsumption.MonthYearReference, 'YYYY-MM-DD');
        const now = moment();
        return monthYearReference.isSameOrAfter(now, 'month');
    }, []);
    const [selectedBenefitConsumption, setSelectedBenefitConsumption] = useState(null);
    const onSelectBenefitConsumption = useCallback((selectedBenefitConsumption) => setSelectedBenefitConsumption(selectedBenefitConsumption), []);
    const onRemoveSelectedBenefitConsumptionMonthlyBalance = useCallback(() => setSelectedBenefitConsumption(null), []);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Benefícios',
            Url: '/benefits'
        },
        {
            Title: 'Consumos'
        }
    ], []);
    const columns = useMemo(() => [
        {
            Header: 'Consumo',
            accessor: 'Id',
            id: 'ConsumptionTypeId',
            disableSortBy: true,
            Cell: ({ row: { original: values } }) => (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "font-semibold text-gray dark:text-gray-50" }, values?.Benefit?.Name ?? values?.Plan?.Name),
                React.createElement("small", { className: "block text-xs text-gray-light dark:text-gray-400" }, values.BenefitId ? 'Benefício' : 'Plano')))
        },
        {
            Header: 'Associado/Dependente',
            accessor: 'Id',
            id: 'BeneficiaryDependent',
            disableSortBy: true,
            Cell: ({ row: { original: values } }) => {
                const beneficiaryId = values.BeneficiaryId ?? values.Dependent?.BeneficiaryId;
                return (React.createElement(Link, { to: `/beneficiaries/${beneficiaryId}`, target: "_blank" },
                    React.createElement("span", { className: "flex items-center font-semibold text-gray-light dark:text-gray-50" },
                        values?.Dependent?.Name ?? values?.Beneficiary?.Name,
                        React.createElement(Icon, { name: "new-tab", size: 14, className: "text-blue-light ml-1" })),
                    React.createElement("small", { className: "block text-xs text-gray-light dark:text-gray-400" }, values.DependentId ? 'Dependente' : 'Associado')));
            }
        },
        {
            Header: 'Valor Consumo',
            accessor: 'Value',
            Cell: ({ cell: { value } }) => FormatterUtils.money(value)
        },
        {
            Header: 'Data',
            accessor: 'Date',
            Cell: ({ cell: { value } }) => FormatterUtils.date(value)
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ row: { original: values }, cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" },
                    (canRead || canUpdate) && (React.createElement(Link, { to: `/benefits/consumptions/${value}` },
                        React.createElement(Tooltip, { id: `benefit-category-${value}-edit`, text: canUpdate ? 'Editar' : 'Visualizar' },
                            React.createElement(Button, { icon: canUpdate ? 'edit' : 'eye', color: "info", rounded: true })))),
                    canRead && (React.createElement(Tooltip, { id: `benefit-consumption-${value}-details`, text: "Ver detalhes do consumo" },
                        React.createElement(Button, { className: "ml-2", icon: "eye", color: "secondary", rounded: true, onClick: () => onSelectBenefitConsumption(values) }))),
                    canDelete && canDeleteBenefitConsumption(values) && (React.createElement(Tooltip, { id: `benefit-consumption-${value}-remove`, text: "Deletar" },
                        React.createElement(Button, { className: "ml-2", icon: "trash", color: "danger", title: "Deletar", onClick: () => onDeleteBenefitConsumption(value), rounded: true })))));
            }
        }
    ], [canRead, canUpdate, canDelete, onDeleteBenefitConsumption, canDeleteBenefitConsumption, onSelectBenefitConsumption]);
    useDocumentTitle('Consumos de Benefícios');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Consumos de Benef\u00EDcios", breadcrumb: breadcrumb }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            React.createElement("div", { className: "flex" },
                canCreate && (React.createElement(Link, { to: "/benefits/consumptions/new" },
                    React.createElement(Button, { text: "Novo Consumo", icon: "add", color: "info", animationHover: "pop" }))),
                canCreateBatch && (React.createElement(Link, { to: "/benefits/consumptions/batches" },
                    React.createElement(Button, { text: "Importar Consumos", icon: "upload", color: "info", variant: "outlined", animationHover: "pop", className: "ml-2" })))),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Consumos", formConfig: { filterParams }, handleFilter: setParams, customFilters: BenefitConsumptionTableFilterForm }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhum consumo a ser exibido" }),
        React.createElement(BenefitConsumptionDetailsModal, { selectedBenefitConsumption: selectedBenefitConsumption, onCloseModal: onRemoveSelectedBenefitConsumptionMonthlyBalance })));
};
export default BenefitConsumptionList;
