import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Table, Button, TableFilter, PageHeader, Tooltip } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useModulePermissions, useTableFilter, useDocumentTitle, useBankPagination, useBankMutation } from 'hooks';
const BankList = () => {
    const { canCreate, canRead, canUpdate, canDelete } = useModulePermissions();
    const { success, confirm, errorCallback } = useAlert();
    const { filterParams, requestFilterParams, setParams, showFilters, toggleFilters } = useTableFilter();
    const { data, isLoading, ...pagination } = useBankPagination(requestFilterParams);
    const { remove } = useBankMutation();
    const deleteBank = useCallback(async (bankId) => {
        await remove(bankId)
            .then(() => success({ title: 'Banco deletado!', message: 'Banco deletado com sucesso.', id: 'BANK_DELETE_SUCCESS' }))
            .catch(err => errorCallback(err, 'BANK_DELETE_FAILURE'));
    }, [remove, success, errorCallback]);
    const onDeleteBank = useCallback((bankId) => {
        confirm({
            title: 'Você tem certeza?',
            description: (React.createElement("p", null,
                "O banco ser\u00E1 deletado. ",
                React.createElement("br", null),
                " Essa a\u00E7\u00E3o \u00E9 irrevers\u00EDvel!")),
            confirmText: 'Sim, deletar',
            cancelText: 'Não, cancelar',
            onConfirm: async () => deleteBank(bankId)
        });
    }, [confirm, deleteBank]);
    const searchFields = useMemo(() => [
        {
            Value: 'Name',
            Description: 'Nome'
        },
        {
            Value: 'Code',
            Description: 'Código'
        }
    ], []);
    const columns = useMemo(() => [
        {
            Header: 'Nome',
            accessor: 'Name'
        },
        {
            Header: 'Situação',
            accessor: 'Active',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx('font-semibold', value ? 'text-green-light' : 'text-red-light') }, value ? 'ATIVO' : 'INATIVO'))
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" },
                    (canRead || canUpdate) && (React.createElement(Link, { to: `/operational/banks/${value}` },
                        React.createElement(Tooltip, { id: `banks-${value}-edit`, text: canUpdate ? 'Editar' : 'Visualizar' },
                            React.createElement(Button, { icon: canUpdate ? 'edit' : 'eye', color: "info", rounded: true })))),
                    canDelete && (React.createElement(Tooltip, { id: `banks-${value}-remove`, text: "Deletar" },
                        React.createElement(Button, { className: "ml-2", icon: "trash", color: "danger", title: "Deletar", onClick: () => onDeleteBank(value), rounded: true })))));
            }
        }
    ], [canRead, canUpdate, canDelete, onDeleteBank]);
    useDocumentTitle('Bancos');
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Bancos" }),
        React.createElement("div", { className: clsx('flex mb-4', canCreate && 'justify-between', !canCreate && 'justify-end') },
            canCreate && (React.createElement(Link, { to: "/operational/banks/new" },
                React.createElement(Button, { text: "Novo Banco", icon: "add", color: "info", animationHover: "pop" }))),
            React.createElement(Button, { text: `${showFilters ? 'Esconder' : 'Ver'} Filtros`, icon: "filter", color: "secondary", animationHover: "pop", onClick: toggleFilters })),
        React.createElement(TableFilter, { show: showFilters, title: "Bancos", formConfig: { searchFields, filterParams, withFields: true, withSituation: true }, handleFilter: setParams }),
        React.createElement(Table, { data: data, columns: columns, isLoading: isLoading, ...pagination, noResultsMessage: "Nenhum banco a ser exibido" })));
};
export default BankList;
