export class StorageService {
    hasJsonStructure(str) {
        if (typeof str !== 'string') {
            return false;
        }
        try {
            const result = JSON.parse(str);
            const type = Object.prototype.toString.call(result);
            return type === '[object Object]' || type === '[object Array]';
        }
        catch (err) {
            return false;
        }
    }
    async get(key) {
        const storageItemValue = localStorage.getItem(key);
        if (!storageItemValue) {
            return;
        }
        return this.hasJsonStructure(storageItemValue)
            ? JSON.parse(storageItemValue)
            : storageItemValue;
    }
    async set(key, value) {
        const isValueArrayOrObject = value instanceof Array || value instanceof Object;
        const storageItemValue = isValueArrayOrObject ? JSON.stringify(value) : String(value);
        return localStorage.setItem(key, storageItemValue);
    }
    async delete(key) {
        localStorage.removeItem(key);
    }
}
