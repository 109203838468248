import React from 'react';
import { Icon, PageHeader } from 'components';
import { useAuth } from 'contexts/auth.context';
import { useDashboardQuery, useDocumentTitle } from 'hooks';
import { FormatterUtils } from 'utils';
import { DashboardActionList, DashboardAlertsList, DashboardCamChart, DashboardCard } from '../components';
const Dashboard = () => {
    const { user } = useAuth();
    const [dashboardResume, isLoadingDashboardResume] = useDashboardQuery();
    useDocumentTitle('Dashboard');
    const PageHeaderComponent = (React.createElement(React.Fragment, null,
        React.createElement("p", { className: "text-gray-light dark:text-gray-400 text-sm" }, FormatterUtils.dateNow()),
        React.createElement(PageHeader, { title: `Olá, ${user.FirstName}!`, withDivisor: false })));
    if (isLoadingDashboardResume) {
        return (React.createElement(React.Fragment, null,
            PageHeaderComponent,
            React.createElement("div", { className: "mt-2" },
                React.createElement(Icon, { name: "spinner", size: 28, className: "text-gray-light dark:text-gray-400" }))));
    }
    return (React.createElement(React.Fragment, null,
        PageHeaderComponent,
        React.createElement("div", { className: "flex flex-row gap-4 mt-8" },
            React.createElement("div", { className: "flex flex-col justify-between gap-4 basis-1/2" },
                React.createElement(DashboardCard, { subtitle: "Associados", title: "LATAM", value: dashboardResume?.BeneficiaryLatamEmployee ?? 0 }),
                React.createElement(DashboardCard, { subtitle: "Associados", title: "S\u00F3cio Contribuinte", value: dashboardResume?.BeneficiaryContributingMember ?? 0 })),
            React.createElement("div", { className: "basis-1/2" },
                React.createElement(DashboardCamChart, { awayWithoutSolicitation: dashboardResume?.AwayWithoutSolicitation ?? 0, solicitationInProgress: dashboardResume?.SolicitationInProgress ?? 0, solicitationInRevision: dashboardResume?.SolicitationInRevision ?? 0 }))),
        React.createElement(DashboardActionList, null),
        React.createElement(DashboardAlertsList, null)));
};
export default Dashboard;
