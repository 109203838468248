import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { BANK_LIST_QUERY_KEY } from './useBankConfig';
export const useBankList = () => {
    const { errorCallback } = useAlert();
    const getBanks = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get('/bank/by-parameters', {
            signal
        })
            .then(res => res.Registers)
            .catch(error => {
            errorCallback(error, 'BANK_GET_ALL_FAILURE');
            return [];
        });
    }, [errorCallback]);
    const { data, isLoading } = useQuery([BANK_LIST_QUERY_KEY], getBanks, {
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    const memoizedData = useMemo(() => data ?? [], [data]);
    return [memoizedData, isLoading];
};
