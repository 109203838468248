import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Icon } from 'components';
const DashboardActionListItem = ({ title, description, icon, onClick, disabled, loading }) => {
    const handleOnClick = useCallback(() => {
        if (disabled || loading)
            return;
        onClick();
    }, [disabled, loading, onClick]);
    return (React.createElement("div", { className: clsx('relative bg-slate-100 dark:bg-slate-dark p-8 transition-colors duration-150 group hover:bg-blue-dark dark:hover:bg-blue-light', loading || disabled ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer'), onClick: handleOnClick },
        loading && (React.createElement("div", { className: "absolute top-4 right-4" },
            React.createElement(Icon, { name: "spinner", size: 20, className: "stroke-gray-800 dark:stroke-gray-50 transition-all duration-150 group-hover:stroke-gray-50 dark:group-hover:stroke-gray-800" }))),
        React.createElement("button", { className: "inline-flex items-center justify-center w-12 h-12 mb-2 text-gray-50 dark:text-gray-800 rounded-full bg-blue-dark dark:bg-blue-light" },
            React.createElement(Icon, { name: icon, size: 24, className: "transition-all duration-300 group-hover:scale-150" })),
        React.createElement("p", { className: "text-gray dark:text-gray-50 text-md font-semibold transition-colors duration-150 group-hover:text-gray-50 dark:group-hover:text-gray-800" }, title),
        React.createElement("p", { className: "text-gray-light dark:text-gray-400 text-sm transition-colors duration-150 group-hover:text-gray-400 dark:group-hover:text-gray-800" }, description)));
};
export default DashboardActionListItem;
