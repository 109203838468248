import * as Yup from 'yup';
import { ValidationUtils } from './validation.utils';
import { PASSWORD_VALIDATION_ERRORS } from './validations';
function cpfValidator(message) {
    return this.test('cpf', message, ValidationUtils.cpf);
}
function passwordValidator() {
    return this.test('password', function (value) {
        const res = ValidationUtils.password(value);
        return res === undefined
            ? true
            : this.createError({
                message: PASSWORD_VALIDATION_ERRORS[res]
            });
    });
}
function phoneValidator(message) {
    return this.test('phone', message, ValidationUtils.phone);
}
function dateValidator(message) {
    return this.test('date', message, ValidationUtils.date);
}
function dateBeforeValidator(message, dateToCompare) {
    return this.test('dateBefore', message, (value) => {
        return ValidationUtils.dateBefore(value, dateToCompare);
    });
}
function dateBeforeOrSameValidator(message, dateToCompare) {
    return this.test('dateBefore', message, (value) => {
        return ValidationUtils.dateSameOrBefore(value, dateToCompare);
    });
}
function dateAfterValidator(message, dateToCompare) {
    return this.test('dateAfter', message, (value) => {
        return ValidationUtils.dateAfter(value, dateToCompare);
    });
}
function zipCodeValidator(message) {
    return this.test('zipCode', message, ValidationUtils.zipCode);
}
Yup.addMethod(Yup.string, 'cpf', cpfValidator);
Yup.addMethod(Yup.string, 'password', passwordValidator);
Yup.addMethod(Yup.string, 'phone', phoneValidator);
Yup.addMethod(Yup.string, 'date', dateValidator);
Yup.addMethod(Yup.string, 'dateBefore', dateBeforeValidator);
Yup.addMethod(Yup.string, 'dateBeforeOrSame', dateBeforeOrSameValidator);
Yup.addMethod(Yup.string, 'dateAfter', dateAfterValidator);
Yup.addMethod(Yup.string, 'zipCode', zipCodeValidator);
export default Yup;
