import Axios from 'axios';
import qs from 'qs';
import { ApiException, StatusNumber } from 'types/error';
import { ParserUtils } from 'utils';
const DEFAULT_ERROR_MESSAGE = 'Ocorreu um erro inesperado, por favor tente novamente!';
export class HttpService {
    client;
    parseErrorMessage(errorMessage) {
        const parsedError = ParserUtils.parseJson(errorMessage);
        if (!parsedError || typeof parsedError === 'string')
            return errorMessage;
        if (Array.isArray(parsedError))
            return parsedError.join('\n');
        return DEFAULT_ERROR_MESSAGE;
    }
    handleError(error) {
        if (error.message === 'canceled')
            return new ApiException(StatusNumber.CLIENT_CLOSED_REQUEST, DEFAULT_ERROR_MESSAGE);
        if (error?.response?.data) {
            const errorResponse = error.response.data;
            if (errorResponse.code && errorResponse.code !== StatusNumber.UNPROCESSABLE_ENTITY) {
                const errorMessage = this.parseErrorMessage(errorResponse.error);
                return new ApiException(errorResponse.code, errorMessage);
            }
            if (errorResponse.error && errorResponse.error_description) {
                return new ApiException(StatusNumber.BAD_REQUEST, errorResponse.error_description);
            }
            return new ApiException(StatusNumber.BAD_REQUEST, DEFAULT_ERROR_MESSAGE);
        }
        return new ApiException(StatusNumber.BAD_REQUEST, DEFAULT_ERROR_MESSAGE);
    }
    createAxiosClient(baseUrl) {
        return Axios.create({
            baseURL: baseUrl,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            }
        });
    }
    constructor(baseUrl, authenticationService) {
        this.client = this.createAxiosClient(baseUrl);
        if (authenticationService) {
            this.client.interceptors.request.use(async (config) => {
                await authenticationService.load();
                const token = authenticationService.getToken();
                if (token) {
                    ;
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            });
        }
    }
    async get(path, config) {
        return this.client
            .get(path, config)
            .then(res => res.data)
            .catch((err) => Promise.reject(this.handleError(err)));
    }
    async post(path, payload, config) {
        return this.client
            .post(path, payload, config)
            .then(res => res.data)
            .catch((err) => Promise.reject(this.handleError(err)));
    }
    async put(path, payload, config) {
        return this.client
            .put(path, payload, config)
            .then(res => res.data)
            .catch((err) => Promise.reject(this.handleError(err)));
    }
    async delete(path, config) {
        return this.client
            .delete(path, config)
            .then(res => res.data)
            .catch((err) => Promise.reject(this.handleError(err)));
    }
}
