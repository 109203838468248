import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, FormFieldError, TextField } from 'components';
import { useModulePermissions } from 'hooks';
const SolicitationPaymentFlowForm = ({ installmentValues: initialInstallmentValues, installmentNumber, onSubmit }) => {
    const { canMutate } = useModulePermissions();
    const [installmentValues, setInstallmentValues] = useState([]);
    const renderInstallmentElement = useCallback((value, index) => (React.createElement("div", { key: index, className: "payment-flow-installment-item mb-3" },
        React.createElement("label", { className: "block text-xs text-gray-light dark:text-gray-400 mb-3" },
            "Parcela ",
            index + 1),
        React.createElement(TextField, { name: `PaymentFlowInstallment[${index}]`, label: "Qnt. de di\u00E1rias", onChange: e => {
                setInstallmentValues(installmentValues => {
                    const clonedInstallmentValues = [...installmentValues];
                    clonedInstallmentValues[index] = parseInt(e.target.value) ?? undefined;
                    return clonedInstallmentValues;
                });
            }, value: value, error: value > 40, type: "number", max: 40 }),
        React.createElement(FormFieldError, { label: "A qnt. de di\u00E1rias deve ser no m\u00E1ximo 40", show: value > 40 }))), []);
    const handleSubmitInstallment = useCallback(() => onSubmit(installmentValues), [installmentValues, onSubmit]);
    const canSubmit = useMemo(() => installmentValues.every(x => x && x <= 40), [installmentValues]);
    const onInitPaymentFlowForm = useCallback(() => {
        const installmentValues = [...Array(installmentNumber).keys()].map((_, index) => {
            const initialInstallmentValue = initialInstallmentValues.find((_, i) => i === index);
            return initialInstallmentValue || 0;
        });
        setInstallmentValues(installmentValues);
    }, [initialInstallmentValues, installmentNumber]);
    useEffect(onInitPaymentFlowForm, [onInitPaymentFlowForm]);
    return (React.createElement(React.Fragment, null,
        installmentValues.map(renderInstallmentElement),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Salvar", color: "success", type: "submit", animationHover: "pop", onClick: handleSubmitInstallment, disabled: !canSubmit })))));
};
export default SolicitationPaymentFlowForm;
