import React, { useCallback, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { Button, FormField, FormFieldError, Form, Select, FormikValidationHandler, useFormikValidationHandler } from 'components';
import { FieldArray, useFormik } from 'formik';
import { useModulePermissions, useBenefitList } from 'hooks';
import { convertToBeneficiaryBenefitDto } from './BeneficiaryBenefitForm.converter';
import { BeneficiaryBenefitFormSchema } from './BeneficiaryBenefitForm.schema';
const defaultBeneficiaryBenefitValue = {
    Id: 0,
    BenefitId: 0,
    CreatedAt: ''
};
const defaultValues = {
    BeneficiaryBenefit: [defaultBeneficiaryBenefitValue]
};
const BeneficiaryBenefitForm = ({ beneficiaryBenefit, beneficiaryType, isLoading, onSubmitBeneficiaryBenefit }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions(beneficiaryBenefit[0]?.Id);
    const [benefits, isLoadingBenefits] = useBenefitList({
        filter: (value) => value.Value > 0 &&
            (!beneficiaryType?.BeneficiaryTypeDefaultBenefits?.map(x => x.BenefitId).includes(value.Id) ?? true)
    });
    const onSubmit = useCallback((beneficiaryBenefitValues) => {
        const beneficiaryBenefitData = convertToBeneficiaryBenefitDto(beneficiaryBenefitValues);
        const benefitiLimitationRemovedIds = beneficiaryBenefitValues.BeneficiaryBenefit.filter(x => x.IsDeleted).map(x => x.Id);
        return onSubmitBeneficiaryBenefit(beneficiaryBenefitData, benefitiLimitationRemovedIds);
    }, [onSubmitBeneficiaryBenefit]);
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => BeneficiaryBenefitFormSchema,
        onSubmit,
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleChange, handleSubmit, isSubmitting } = formikContext;
    const onInitForm = useCallback(() => {
        if (!beneficiaryBenefit.length)
            return;
        const beneficiaryBenefitData = beneficiaryBenefit.map(x => ({
            ...x
        }));
        setFieldValue('BeneficiaryBenefit', beneficiaryBenefitData);
    }, [beneficiaryBenefit, setFieldValue]);
    const getBenefits = (beneficiaryBenefit) => {
        return benefits?.find(benefit => benefit.Id === beneficiaryBenefit.BenefitId);
    };
    useEffect(onInitForm, [onInitForm]);
    if (isLoading)
        return null;
    return (React.createElement(Form, { name: "beneficiaryBenefitForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(FieldArray, { name: "BeneficiaryBenefit", render: (arrayHelpers) => (React.createElement(React.Fragment, null,
                values.BeneficiaryBenefit.filter(x => !x.IsDeleted).map((beneficiaryBenefit, index) => (React.createElement(Row, { key: index },
                    React.createElement(Col, { sm: 12, xl: 11 },
                        React.createElement(FormField, null,
                            React.createElement(Select, { name: "BenefitId", label: "Escolha o benef\u00EDcio", options: benefits, value: getBenefits(beneficiaryBenefit), onChange: option => setFieldValue(`BeneficiaryBenefit[${index}].BenefitId`, option.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingBenefits, isDisabled: Boolean(beneficiaryBenefit.Id) }),
                            React.createElement(FormFieldError, { name: `BeneficiaryBenefit[${index}].BenefitId` }))),
                    React.createElement(Col, { xl: 1 },
                        React.createElement(Button, { icon: "trash", color: "danger", onClick: () => setFieldValue(`BeneficiaryBenefit[${index}].IsDeleted`, true), style: { marginTop: 5 }, rounded: true }))))),
                canMutate && (React.createElement(FormField, null,
                    React.createElement(Button, { icon: "add", color: "success", onClick: () => arrayHelpers.push(defaultBeneficiaryBenefitValue), style: { marginTop: -5 }, rounded: true }))))) }),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Salvar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default BeneficiaryBenefitForm;
