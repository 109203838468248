import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { OPERATION_BASE_QUERY_KEY } from './useOperationBaseConfig';
export const useOperationBaseQuery = (operationBaseId) => {
    const { errorCallback } = useAlert();
    const navigate = useNavigate();
    const getOperationBase = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/operation-base/${operationBaseId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'OPERATION_BASE_GET_FAILURE');
            navigate('/operational/operation-bases');
            throw error;
        });
    }, [errorCallback, operationBaseId, navigate]);
    const { data, isLoading } = useQuery([OPERATION_BASE_QUERY_KEY, operationBaseId], getOperationBase, {
        enabled: !!operationBaseId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
