import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { DEPENDENT_QUERY_KEY } from './useDependentConfig';
export const useDependentQuery = (dependentId) => {
    const { errorCallback } = useAlert();
    const getDependent = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/dependent/${dependentId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'DEPENDENT_GET_FAILURE');
            throw error;
        });
    }, [errorCallback, dependentId]);
    const { data, isLoading } = useQuery([DEPENDENT_QUERY_KEY, dependentId], getDependent, {
        enabled: !!dependentId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
