import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { SOLICITATION_QUERY_KEY } from './useSolicitationConfig';
export const useSolicitationQuery = (solicitationId) => {
    const { errorCallback } = useAlert();
    const navigate = useNavigate();
    const getSolicitation = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/solicitation/${solicitationId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'SOLICITATION_GET_FAILURE');
            navigate('/cam/solicitations');
            throw error;
        });
    }, [errorCallback, solicitationId, navigate]);
    const { data, isLoading } = useQuery([SOLICITATION_QUERY_KEY, solicitationId], getSolicitation, {
        enabled: !!solicitationId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
