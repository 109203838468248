import React from 'react';
import { View as PdfView, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
    base: {
        padding: 12,
        borderRadius: 5
    },
    light: {
        backgroundColor: '#FFFFFF',
        borderWidth: 0.5,
        borderStyle: 'solid',
        borderColor: '#CCCCCC'
    },
    dark: {
        backgroundColor: '#F5F6FA'
    }
});
export const Box = ({ marginTop, marginBottom, marginRight, marginLeft, style, dark, children }) => (React.createElement(PdfView, { style: {
        marginTop,
        marginBottom,
        marginRight,
        marginLeft,
        ...styles.base,
        ...(dark ? styles.dark : styles.light),
        ...(style ?? {})
    } }, children));
