import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { AWAY_WITHOUT_SOLICITATION_QUERY_KEY } from './useAwayWithoutSolicitationConfig';
export const useAwayWithoutSolicitationQuery = (awayWithoutSolicitationId) => {
    const { errorCallback } = useAlert();
    const navigate = useNavigate();
    const getAwayWithoutSolicitation = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/away-without-solicitation/${awayWithoutSolicitationId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'AWAY_WITHOUT_SOLICITATION_GET_FAILURE');
            navigate('/cam/aways-without-solicitation');
            throw error;
        });
    }, [errorCallback, awayWithoutSolicitationId, navigate]);
    const { data, isLoading } = useQuery([AWAY_WITHOUT_SOLICITATION_QUERY_KEY, awayWithoutSolicitationId], getAwayWithoutSolicitation, {
        enabled: !!awayWithoutSolicitationId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
