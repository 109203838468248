const getLastYears = (n) => {
    const currentYear = new Date().getFullYear();
    return [...Array(n).keys()].map(x => {
        return currentYear - x;
    });
};
const getNextYears = (n) => {
    const currentYear = new Date().getFullYear();
    return [...Array(n).keys()].map(x => {
        return currentYear + x;
    });
};
export const TimeUtils = {
    getLastYears,
    getNextYears
};
