import React from 'react';
import { Loading } from 'components/Loading';
import { useAuth } from 'contexts/auth.context';
import { AuthRoutes, PrivateRoutes } from 'routes';
const AppContainer = () => {
    const { isLogged, isLoading } = useAuth();
    if (isLoading) {
        return React.createElement(Loading, { show: true, iconSize: 45 });
    }
    return !isLogged ? React.createElement(AuthRoutes, null) : React.createElement(PrivateRoutes, null);
};
export default AppContainer;
