import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
export const useBenefitConsumptionMonthlyBalanceMutation = () => {
    const { success, errorCallback } = useAlert();
    const httpService = makeHttpService();
    const startManualCalculation = useCallback(async (monthYearReference) => {
        return httpService.post('/benefit-consumption-monthly-balance/manual-calculation', {
            MonthYearReference: monthYearReference
        });
    }, [httpService]);
    const { mutateAsync: calculate, isLoading: isCalculating } = useMutation(startManualCalculation);
    const onCalculate = useCallback(async () => {
        await calculate(null)
            .then(() => success({
            title: 'Cálculo Iniciado!',
            message: 'Cálculo iniciado com sucesso, aguarde a finalização. Esse processo pode levar vários minutos.',
            id: 'MONTHLY_BALANCE_MANUAL_CALCULATION_SUCCESS'
        }))
            .catch(err => errorCallback(err, 'MONTHLY_BALANCE_MANUAL_CALCULATION_FAILURE'));
    }, [calculate, success, errorCallback]);
    return { calculate: onCalculate, isCalculating };
};
