import React, { useMemo } from 'react';
import { Icon, Tooltip } from 'components';
import { FormatterUtils } from 'utils';
import './BenefitConsumptionItem.scss';
const BenefitConsumptionItem = ({ benefitConsumption }) => {
    const isReimbursed = useMemo(() => {
        if (!benefitConsumption.Benefit?.IsReimbursedFromLATAM)
            return false;
        return (benefitConsumption.IsBeneficiaryLATAM &&
            !benefitConsumption.IsBeneficiaryWithCAM &&
            !benefitConsumption.IsBeneficiaryAway);
    }, [benefitConsumption]);
    return (React.createElement("div", { className: "benefit-consumption-item" },
        React.createElement("div", { className: "benefit-consumption-item-content" },
            React.createElement("p", { className: "block text-sm font-medium text-gray dark:text-gray-50" }, benefitConsumption?.Benefit?.Name ?? benefitConsumption?.Plan?.Name),
            React.createElement("small", { className: "block text-xs text-gray-light dark:text-gray-400" }, benefitConsumption.BenefitId ? 'Benefício' : 'Plano'),
            !isReimbursed ? (React.createElement("span", { className: "block text-sm font-bold text-gray-light dark:text-gray-400 mt-2" }, FormatterUtils.money(benefitConsumption.Value))) : (React.createElement("div", { className: "flex items-center mt-2" },
                React.createElement("span", { className: "text-xs text-gray-light line-through dark:text-gray-400 mr-1" }, FormatterUtils.money(benefitConsumption.Value)),
                React.createElement("span", { className: "text-sm font-bold text-gray-light dark:text-gray-400" }, FormatterUtils.money(0)),
                React.createElement(Tooltip, { id: `info-tooltip-${benefitConsumption.Id}`, text: "Consumo reembols\u00E1vel LATAM" },
                    React.createElement(Icon, { name: "info", size: 14, className: "ml-1 text-gray dark:text-gray-50" }))))),
        React.createElement("div", { className: "benefit-consumption-item-date" },
            React.createElement(Icon, { name: "calendar", size: 15 }),
            React.createElement("small", null, FormatterUtils.dateTime(benefitConsumption.CreatedAt)))));
};
export default BenefitConsumptionItem;
