import { ModuleIconConfig, ModuleUrlConfig } from 'configs';
const getModuleUrl = (moduleId) => ModuleUrlConfig[moduleId];
const getModuleIcon = (moduleId) => ModuleIconConfig[moduleId];
const clearUrl = (url) => {
    const urlBlacklist = ['new', /[-\w]+report/g];
    const urlSplitted = url?.split('/') || [''];
    return urlSplitted
        .reduce((acc, cur) => {
        if (/^\d+$/.test(cur))
            return acc;
        if (urlBlacklist.find(x => x === cur || new RegExp(x).test(cur)))
            return acc;
        acc.push(cur);
        return acc;
    }, [])
        .join('/');
};
const isCreatePage = (url) => url.includes('new');
const isUpdatePage = (url) => {
    const urlSplitted = url?.split('/') || [''];
    return /[0-9]+$/.test(urlSplitted[urlSplitted.length - 1]);
};
const hasPermissionInModule = (url, permissions) => {
    const { canList, canCreate, canUpdate, canRead } = permissions;
    if (isCreatePage(url) && !canCreate) {
        return false;
    }
    if (isUpdatePage(url) && !canUpdate && !canRead) {
        return false;
    }
    if (!isCreatePage(url) && !isUpdatePage(url) && !canList) {
        return false;
    }
    return true;
};
const getCurrentModule = (url, modules) => {
    const cleanedUrl = clearUrl(url);
    const currentModule = modules.find(module => module.Url === cleanedUrl);
    if (currentModule)
        return currentModule;
    const currentChildModule = modules.reduce((result, module) => {
        const childModule = module.Children?.find(child => child.Url === cleanedUrl);
        if (!childModule)
            return result;
        result = childModule;
        return result;
    }, {});
    if (currentChildModule)
        return currentChildModule;
    return undefined;
};
export const ModuleUtils = {
    getModuleUrl,
    getModuleIcon,
    clearUrl,
    hasPermissionInModule,
    getCurrentModule
};
