import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Button, Loading, Modal, Table, Tooltip } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useModulePermissions, useDependentList, useDependentMutation, useDependentQuery, useDocumentTitle } from 'hooks';
import moment from 'moment-timezone';
import { DependentForm } from './DependentForm';
const BeneficiaryDependentTab = ({ beneficiaryId }) => {
    const { success, confirm, errorCallback } = useAlert();
    const { canRead, canCreate, canDelete } = useModulePermissions();
    const [showModal, setShowModal] = useState(false);
    const [selectedDependentId, setSelectedDependentId] = useState(undefined);
    const [dependents, isLoadingAll] = useDependentList(beneficiaryId);
    const [dependent] = useDependentQuery(selectedDependentId);
    const { create, update, remove } = useDependentMutation(beneficiaryId, selectedDependentId);
    const onClickNewDependent = useCallback(() => {
        setSelectedDependentId(undefined);
        setShowModal(true);
    }, []);
    const onClickEditDependent = useCallback((selectedDependentId) => {
        const selectedDependent = dependents.find(x => x.Id === selectedDependentId);
        if (!selectedDependent)
            return;
        setSelectedDependentId(selectedDependent.Id);
        setShowModal(true);
    }, [dependents]);
    const onCloseDependentModal = useCallback(() => {
        setSelectedDependentId(undefined);
        setShowModal(false);
    }, []);
    const onSubmitDependent = useCallback(async (dependentData) => {
        const dependentDataSubmit = { ...dependentData };
        dependentDataSubmit.BeneficiaryId = beneficiaryId;
        dependentDataSubmit.CreatedAt = moment().format();
        dependentDataSubmit.UpdatedAt = null;
        dependentDataSubmit.BirthDate = moment(dependentDataSubmit?.BirthDate, 'DD/MM/YYYY').format();
        if (dependentData.Id) {
            await update(dependentDataSubmit, {
                onSuccess: () => {
                    success({
                        title: 'Dependente atualizado!',
                        message: 'Dados do dependente atualizados com sucesso.',
                        id: 'DEPENDENT_PUT_SUCCESS'
                    });
                    onCloseDependentModal();
                },
                onError: err => errorCallback(err, 'DEPENDENT_PUT_FAILURE')
            });
        }
        else {
            await create(dependentDataSubmit, {
                onSuccess: () => {
                    success({
                        title: 'Dependente cadastrado!',
                        message: 'Dependente cadastrado com sucesso.',
                        id: 'DEPENDENT_POST_SUCCESS'
                    });
                    onCloseDependentModal();
                },
                onError: err => errorCallback(err, 'DEPENDENT_POST_FAILURE')
            });
        }
    }, [beneficiaryId, create, update, success, errorCallback, onCloseDependentModal]);
    const deleteDependent = useCallback((dependentId) => {
        return remove(dependentId, {
            onSuccess: () => success({
                title: 'Dependente deletado!',
                message: 'Dependente deletado com sucesso.',
                id: 'DEPENDENT_DELETE_SUCCESS'
            }),
            onError: err => errorCallback(err, 'DEPENDENT_DELETE_FAILURE')
        });
    }, [remove, success, errorCallback]);
    const onDeleteDependent = useCallback((dependentId) => {
        confirm({
            title: 'Você tem certeza?',
            description: (React.createElement("p", null,
                "O dependente ser\u00E1 deletado. ",
                React.createElement("br", null),
                " Essa a\u00E7\u00E3o \u00E9 irrevers\u00EDvel!")),
            confirmText: 'Sim, deletar',
            cancelText: 'Não, cancelar',
            onConfirm: () => deleteDependent(dependentId)
        });
    }, [confirm, deleteDependent]);
    const columns = useMemo(() => [
        {
            Header: 'Código de Registro',
            accessor: 'RegistrationCode'
        },
        {
            Header: 'Nome',
            accessor: 'Name'
        },
        {
            Header: 'Situação',
            accessor: 'Active',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => (React.createElement("span", { className: clsx(value ? 'text-green-light' : 'text-red-light') }, value ? 'ATIVO' : 'INATIVO'))
        },
        {
            Header: 'Ações',
            accessor: 'Id',
            id: 'ActionId',
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                return (React.createElement("div", { className: "flex items-center" },
                    React.createElement(Tooltip, { id: `dependents-${value}-edit`, text: canRead ? 'Editar' : 'Visualizar' },
                        React.createElement(Button, { icon: canRead ? 'edit' : 'eye', color: "info", rounded: true, onClick: () => onClickEditDependent(value) })),
                    canDelete && (React.createElement(Tooltip, { id: `dependents-${value}-remove`, text: "Deletar" },
                        React.createElement(Button, { className: "ml-2", icon: "trash", color: "danger", title: "Deletar", onClick: () => onDeleteDependent(value), rounded: true })))));
            }
        }
    ], [canRead, canDelete, onClickEditDependent, onDeleteDependent]);
    const pageTitle = useMemo(() => 'Dependentes do Associado', []);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(Loading, { show: isLoadingAll }),
        canCreate && (React.createElement("div", { className: "flex justify-end mb-4" },
            React.createElement(Button, { text: "Novo Dependente", icon: "add", color: "info", animationHover: "pop", onClick: onClickNewDependent }))),
        React.createElement(Table, { data: dependents, columns: columns, isLoading: isLoadingAll, noResultsMessage: "Nenhum dependente a ser exibido" }),
        React.createElement(Modal, { show: showModal, handleClose: onCloseDependentModal, size: "lg", title: selectedDependentId ? 'Editar Dependente' : 'Novo Dependente' },
            React.createElement(DependentForm, { dependent: dependent, onSubmitDependent: onSubmitDependent }))));
};
export default BeneficiaryDependentTab;
