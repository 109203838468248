import React, { memo } from 'react';
import clsx from 'clsx';
import { Card } from 'components';
import { FormatterUtils } from 'utils';
const DEFAULT_RDV_PERCENTAGE = 50;
const RecompositionInequalityResumeCard = ({ recompositionInequality }) => {
    return (React.createElement(Card, { className: "h-full" },
        React.createElement("h2", { className: "text-lg font-semibold text-gray mb-4" }, "Resumo"),
        React.createElement("div", { className: clsx('recomposition-inequality-details-card-item flex justify-between items-center') },
            React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400" }, "Valor Base"),
            React.createElement("p", { className: clsx('text-gray font-semibold') }, FormatterUtils.money(recompositionInequality.TotalValueMonth))),
        React.createElement("div", { className: clsx('recomposition-inequality-details-card-item flex justify-between items-center') },
            React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400" }, "Taxa Base Mensal"),
            React.createElement("p", { className: clsx('text-gray font-semibold') }, FormatterUtils.percentage(recompositionInequality.MonthlyBasePercentage?.Percentage))),
        React.createElement("div", { className: clsx('recomposition-inequality-details-card-item flex justify-between items-center') },
            React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400" }, "Subtotal"),
            React.createElement("p", { className: clsx('text-gray font-semibold') }, FormatterUtils.money(recompositionInequality.TotalValueMonthAfterPercentageBase))),
        React.createElement("div", { className: clsx('recomposition-inequality-details-card-item flex justify-between items-center') },
            React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400" }, "Taxa Base RDV"),
            React.createElement("p", { className: clsx('text-gray font-semibold') }, FormatterUtils.percentage(DEFAULT_RDV_PERCENTAGE))),
        React.createElement("div", { className: clsx('recomposition-inequality-details-card-item flex justify-between items-center') },
            React.createElement("p", { className: "text-xs text-gray-light dark:text-gray-400" }, "Total"),
            React.createElement("p", { className: clsx('text-gray font-semibold') }, FormatterUtils.money(recompositionInequality.TotalValueAfterRecompositionPercentage)))));
};
export default memo(RecompositionInequalityResumeCard);
