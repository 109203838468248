import React, { useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormField, SituationField, TextField, MaskedTextField, MonthField } from 'components';
const BirthdayReportTableFilterForm = ({ values, setFieldValue, handleChange }) => {
    const situationFieldOptions = useMemo(() => [
        { name: 'Todos', color: 'info', textColor: 'primary', value: null },
        { name: 'Ativos', color: 'success', textColor: 'success', value: true },
        { name: 'Inativos', color: 'danger', textColor: 'danger', value: false }
    ], []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(MonthField, { name: "MonthYear", label: "Escolha o m\u00EAs", value: values?.MonthYear, onChange: value => setFieldValue('MonthYear', value) }))),
            React.createElement(Col, { xs: 12, lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "CallName", label: "Nome de Guerra", value: values.CallName ?? '', onChange: handleChange })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(MaskedTextField, { maskType: "cpf", name: "PersonDocument", label: "CPF do associado", value: values.PersonDocument ?? '', onChange: value => setFieldValue('PersonDocument', value) }))),
            React.createElement(Col, { xs: 12, lg: 6 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "AssociateCode", label: "C\u00F3digo BP", value: values.AssociateCode ?? '', onChange: handleChange })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, className: "flex justify-end" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Filtrar por situa\u00E7\u00E3o", defaultOption: null, options: situationFieldOptions, handleChange: value => setFieldValue('Active', value), value: values.Active }))))));
};
export default BirthdayReportTableFilterForm;
