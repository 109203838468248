import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeHttpService } from 'services/http';
import { AWAY_WITHOUT_SOLICITATION_LIST_QUERY_KEY, AWAY_WITHOUT_SOLICITATION_PAGINATION_QUERY_KEY, AWAY_WITHOUT_SOLICITATION_QUERY_KEY } from './useAwayWithoutSolicitationConfig';
export const useAwayWithoutSolicitationMutation = (awayWithoutSolicitationId) => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createAwayWithoutSolicitation = useCallback(async (awayWithoutSolicitationData) => {
        return httpService.post('/away-without-solicitation', awayWithoutSolicitationData);
    }, [httpService]);
    const updateAwayWithoutSolicitation = useCallback(async (awayWithoutSolicitationData) => {
        return httpService.put('/away-without-solicitation', awayWithoutSolicitationData);
    }, [httpService]);
    const removeAwayWithoutSolicitation = useCallback(async (awayWithoutSolicitationId) => {
        return httpService.delete(`/away-without-solicitation?id=${awayWithoutSolicitationId}`);
    }, [httpService]);
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createAwayWithoutSolicitation, {
        onSuccess: () => {
            queryClient.invalidateQueries(AWAY_WITHOUT_SOLICITATION_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries(AWAY_WITHOUT_SOLICITATION_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateAwayWithoutSolicitation, {
        onSuccess: () => {
            queryClient.invalidateQueries(AWAY_WITHOUT_SOLICITATION_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries([AWAY_WITHOUT_SOLICITATION_QUERY_KEY, awayWithoutSolicitationId]);
            queryClient.invalidateQueries(AWAY_WITHOUT_SOLICITATION_LIST_QUERY_KEY);
        }
    });
    const { mutateAsync: remove, isLoading: isRemoving } = useMutation(removeAwayWithoutSolicitation, {
        onSuccess: (_, awayWithoutSolicitationId) => {
            queryClient.invalidateQueries(AWAY_WITHOUT_SOLICITATION_PAGINATION_QUERY_KEY);
            queryClient.removeQueries([AWAY_WITHOUT_SOLICITATION_QUERY_KEY, awayWithoutSolicitationId]);
            queryClient.invalidateQueries(AWAY_WITHOUT_SOLICITATION_LIST_QUERY_KEY);
        }
    });
    return { create, update, remove, isCreating, isUpdating, isRemoving };
};
