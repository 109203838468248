import React from 'react';
import { Row, Col } from 'react-grid-system';
import { FormField, Select } from 'components';
import { useDailyRateList } from 'hooks';
const DailyRateCustomTableFilter = ({ values, setFieldValue }) => {
    const [dailyRates, isLoadingDailyRates] = useDailyRateList();
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12 },
                React.createElement(FormField, null,
                    React.createElement(Select, { name: "DailyRateId", label: "Escolha uma Di\u00E1ria", options: dailyRates, value: dailyRates.find(dailyRate => dailyRate.Id === values?.DailyRateId), onChange: value => setFieldValue('DailyRateId', value.Id), getOptionLabel: option => option.Name, getOptionValue: option => option.Id.toString(), isLoading: isLoadingDailyRates, isClearable: true }))))));
};
export default DailyRateCustomTableFilter;
