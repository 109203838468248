import React, { memo } from 'react';
import { Collapse } from 'react-collapse';
import clsx from 'clsx';
import { Card } from 'components/Card';
import TableFilterForm from './TableFilterForm';
const TableFilter = memo(({ show, title, className, formConfig, handleFilter, customFilters }) => {
    return (React.createElement(Collapse, { isOpened: show },
        React.createElement("div", { className: clsx('pb-4', className) },
            React.createElement(Card, null,
                React.createElement("p", { className: "text-sm font-semibold text-gray dark:text-gray-50 mb-4" },
                    "Filtrar ",
                    title),
                React.createElement(TableFilterForm, { ...formConfig, handleFilter: async (filterParams) => handleFilter(filterParams), customFilters: customFilters })))));
});
export default TableFilter;
