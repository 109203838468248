import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { DAILY_RATE_LIST_QUERY_KEY } from './useDailyRateConfig';
export const useDailyRateList = () => {
    const { errorCallback } = useAlert();
    const getDailyRates = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get('/daily-rate/by-parameters', {
            signal
        })
            .then(res => res.Registers)
            .catch(error => {
            errorCallback(error, 'DAILY_RATE_GET_ALL_FAILURE');
            return [];
        });
    }, [errorCallback]);
    const { data, isLoading } = useQuery([DAILY_RATE_LIST_QUERY_KEY], getDailyRates, {
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    const memoizedData = useMemo(() => data ?? [], [data]);
    return [memoizedData, isLoading];
};
