import React, { useMemo } from 'react';
import { useRecompositionInequalityBySolicitationQuery, useSolicitationDurationQuery } from 'hooks';
import moment from 'moment';
import { SolicitationStatus, SolicitationTreatmentMethodLabel } from 'types/enum';
import { SolicitationUtils } from 'utils';
import { SolicitationCard } from '../Card';
import { SolicitationDetailsCardItem } from './Item';
const SolicitationDetailsCard = ({ solicitation }) => {
    const solicitationCurrentState = useMemo(() => SolicitationUtils.getLastState(solicitation.SolicitationStates), [
        solicitation.SolicitationStates
    ]);
    const isSolicitationFinished = solicitationCurrentState.StatusId === SolicitationStatus.FINISHED;
    const [recompositionInequality] = useRecompositionInequalityBySolicitationQuery(solicitation?.Id, isSolicitationFinished);
    const showRecompositionInequalityLabel = useMemo(() => solicitationCurrentState.StatusId === SolicitationStatus.FINISHED, [solicitationCurrentState]);
    const [solicitationDuration] = useSolicitationDurationQuery(solicitation?.Id);
    return (React.createElement(SolicitationCard, { title: "Detalhes do Requerimento", className: "h-full" },
        React.createElement(SolicitationDetailsCardItem, { label: "C\u00F3digo", value: solicitation.RegistrationCode }),
        !!solicitation.ReopenOfSolicitationId && (React.createElement(SolicitationDetailsCardItem, { label: "Reabertura", value: solicitation.ReopenOfSolicitation?.RegistrationCode, link: `/cam/solicitations/${solicitation.ReopenOfSolicitationId}` })),
        React.createElement(SolicitationDetailsCardItem, { label: "Status", value: solicitationCurrentState?.Status?.Name, valueClassName: SolicitationUtils.getSolicitationStateTextColor(solicitationCurrentState.StatusId) }),
        React.createElement(SolicitationDetailsCardItem, { label: "Anexo 10", value: solicitation.SolicitationDeseases.map(x => `${x.Desease?.Code} - ${x.Desease?.Name}`), multiValue: true }),
        React.createElement(SolicitationDetailsCardItem, { label: "M\u00E9todo Tratamento", value: SolicitationTreatmentMethodLabel[solicitation.SolicitationTreatmentMethod] }),
        React.createElement(SolicitationDetailsCardItem, { label: "Acidente de Trabalho", value: solicitation.WorkAccident, boolValue: true, boolColors: ['text-red', 'text-green'] }),
        !!solicitationDuration?.TotalDurationDays && (React.createElement(React.Fragment, null,
            React.createElement(SolicitationDetailsCardItem, { label: "Dura\u00E7\u00E3o estimada", value: `${solicitationDuration.TotalDurationDays} dias` }),
            React.createElement(SolicitationDetailsCardItem, { label: "Dias em andamento", value: `${solicitationDuration.ConsumedDays} dias` }),
            React.createElement(SolicitationDetailsCardItem, { label: "Dias restantes", value: `${solicitationDuration.RemainingDays} dias` }))),
        React.createElement(SolicitationDetailsCardItem, { label: "Data de Cria\u00E7\u00E3o", value: moment(solicitation.CreatedAt).format('DD/MM/YYYY HH:mm') }),
        React.createElement(SolicitationDetailsCardItem, { label: "Data de In\u00EDcio", value: moment(solicitation.StartDate).format('DD/MM/YYYY') }),
        React.createElement(SolicitationDetailsCardItem, { label: "Data Prevista de Fim", value: moment(solicitation.EndDate).format('DD/MM/YYYY') }),
        !!showRecompositionInequalityLabel && (React.createElement(SolicitationDetailsCardItem, { label: "RDV Criado", value: !!recompositionInequality?.Id, boolValue: true }))));
};
export default SolicitationDetailsCard;
