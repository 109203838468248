import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Loading, Card } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useDocumentTitle, useBenefitCategoryQuery, useBenefitCategoryMutation } from 'hooks';
import moment from 'moment-timezone';
import { BenefitCategoryForm } from '../components';
const BenefitCategoryInternal = () => {
    const benefitCategoryIdRoute = parseInt(useParams()?.benefitCategoryId ?? '0');
    const { success, errorCallback } = useAlert();
    const navigate = useNavigate();
    const [benefitCategory, isLoading] = useBenefitCategoryQuery(benefitCategoryIdRoute);
    const { create, update } = useBenefitCategoryMutation(benefitCategoryIdRoute);
    async function submitBenefitCategory(benefitCategoryData) {
        const benefitCategoryDataSubmit = { ...benefitCategoryData };
        benefitCategoryDataSubmit.CreatedAt = moment().format();
        benefitCategoryDataSubmit.UpdatedAt = null;
        if (benefitCategoryData.Id) {
            return update(benefitCategoryDataSubmit, {
                onSuccess: () => success({
                    title: 'Categoria atualizada!',
                    message: 'Dados da categoria atualizados com sucesso.',
                    id: 'BENEFIT_CATEGORY_PUT_SUCCESS'
                }),
                onError: err => errorCallback(err, 'BENEFIT_CATEGORY_PUT_FAILURE')
            });
        }
        else {
            return create(benefitCategoryDataSubmit, {
                onSuccess: res => {
                    success({
                        title: 'Categoria cadastrada!',
                        message: 'Categoria cadastrada com sucesso.',
                        id: 'BENEFIT_CATEGORY_POST_SUCCESS'
                    });
                    navigate(`/benefits/categories/${res.Id}`);
                },
                onError: err => errorCallback(err, 'BENEFIT_CATEGORY_POST_FAILURE')
            });
        }
    }
    const pageTitle = useMemo(() => `${benefitCategoryIdRoute ? 'Visualizar' : 'Nova'} Categoria`, [
        benefitCategoryIdRoute
    ]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Benefícios',
            Url: '/benefits'
        },
        {
            Title: 'Categorias',
            Url: '/benefits/categories'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Categorias de Benef\u00EDcios", breadcrumb: breadcrumb }),
        React.createElement(Loading, { show: isLoading }),
        React.createElement(Card, { id: "beneficiary-card" },
            React.createElement(BenefitCategoryForm, { benefitCategory: benefitCategory, isLoading: isLoading, handleSubmit: submitBenefitCategory }))));
};
export default BenefitCategoryInternal;
