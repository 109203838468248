import React, { createContext, useContext, useState, isValidElement, useCallback } from 'react';
import { toast as alert } from 'react-toastify';
import { Alert, Modal, Button } from 'components';
import { StatusNumber } from 'types/error';
const AlertContext = createContext({});
export const AlertProvider = ({ children }) => {
    const [confirmAlert, setConfirmAlert] = useState({});
    const [showConfirmAlert, toggleConfirmAlert] = useState(false);
    const [isLoading, toggleLoading] = useState(false);
    alert.configure({ draggable: false });
    const success = useCallback(({ title = 'SUCESSO!', message, id }) => {
        alert.success(React.createElement(Alert, { title: title, message: message, icon: "success" }), {
            className: 'alert alert-success',
            toastId: id
        });
    }, []);
    const error = useCallback(({ title = 'ERRO!', message, id }) => {
        alert.error(React.createElement(Alert, { title: title, message: message, icon: "error" }), {
            className: 'alert alert-error',
            toastId: id
        });
    }, []);
    const errorCallback = useCallback((apiException, id) => {
        if (apiException.statusNumber === StatusNumber.CLIENT_CLOSED_REQUEST)
            return;
        error({ message: apiException.message, id });
    }, [error]);
    function warning({ title = 'ATENÇÃO!', message, id }) {
        alert.warn(React.createElement(Alert, { title: title, message: message, icon: "warning" }), {
            className: 'alert alert-warning',
            toastId: id
        });
    }
    function confirm({ title = 'Você tem certeza?', description, onCancel, onConfirm, cancelText = 'Cancelar', confirmText = 'Confirmar' }) {
        setConfirmAlert(() => ({ title, description, onCancel, onConfirm, cancelText, confirmText }));
        toggleConfirmAlert(true);
    }
    async function cancelCallback() {
        if (onCancel && typeof onCancel === 'function') {
            toggleLoading(true);
            await onCancel();
        }
        toggleConfirmAlert(false);
        toggleLoading(false);
    }
    async function confirmCallback() {
        if (onConfirm && typeof onConfirm === 'function') {
            toggleLoading(true);
            await onConfirm();
        }
        toggleConfirmAlert(false);
        toggleLoading(false);
    }
    function getModalDescription() {
        if (description === null)
            return React.createElement(React.Fragment, null);
        if (isValidElement(description))
            return React.createElement(React.Fragment, null, description);
        return React.createElement("p", null, description);
    }
    const { title, description, onCancel, onConfirm, cancelText, confirmText } = confirmAlert;
    return (React.createElement(AlertContext.Provider, { value: { success, error, errorCallback, warning, confirm } },
        children,
        React.createElement(Modal, { show: showConfirmAlert, size: "alert", closable: false, title: title },
            getModalDescription(),
            React.createElement("div", { className: "flex justify-end mt-4" },
                cancelText && (React.createElement(Button, { color: "danger", className: "mr-2", disabled: isLoading, onClick: cancelCallback, text: cancelText })),
                React.createElement(Button, { color: "success", onClick: confirmCallback, loading: isLoading, text: confirmText })))));
};
export const useAlert = () => {
    const context = useContext(AlertContext);
    if (context === undefined)
        throw new Error('useAlert must be used within a AlertProvider.');
    return context;
};
