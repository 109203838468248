export var Module;
(function (Module) {
    Module[Module["DASHBOARD"] = 0] = "DASHBOARD";
    Module[Module["USER"] = 1] = "USER";
    Module[Module["BENEFIT"] = 2] = "BENEFIT";
    Module[Module["BENEFIT_CATEGORY"] = 3] = "BENEFIT_CATEGORY";
    Module[Module["BENEFICIARY"] = 4] = "BENEFICIARY";
    Module[Module["REPORT"] = 6] = "REPORT";
    Module[Module["MONTHLY_BALANCE_REPORT"] = 7] = "MONTHLY_BALANCE_REPORT";
    Module[Module["CAM"] = 8] = "CAM";
    Module[Module["PLAN"] = 10] = "PLAN";
    Module[Module["DAILY_RATE"] = 11] = "DAILY_RATE";
    Module[Module["COMPENSATION_RATE"] = 12] = "COMPENSATION_RATE";
    Module[Module["DESEASE"] = 13] = "DESEASE";
    Module[Module["SOLICITATION"] = 14] = "SOLICITATION";
    Module[Module["SOLICITATION_REVISION"] = 15] = "SOLICITATION_REVISION";
    Module[Module["JOB_DESCRIPTION"] = 16] = "JOB_DESCRIPTION";
    Module[Module["OPERATIONAL"] = 17] = "OPERATIONAL";
    Module[Module["OPERATION_BASE"] = 18] = "OPERATION_BASE";
    Module[Module["AIRCRAFT_MODEL"] = 19] = "AIRCRAFT_MODEL";
    Module[Module["BANK"] = 20] = "BANK";
    Module[Module["MONTHLY_BASE_PERCENTAGE"] = 21] = "MONTHLY_BASE_PERCENTAGE";
    Module[Module["RECOMPOSITION_INEQUALITY"] = 22] = "RECOMPOSITION_INEQUALITY";
    Module[Module["BENEFIT_CONSUMPTION_BATCH"] = 23] = "BENEFIT_CONSUMPTION_BATCH";
    Module[Module["BENEFIT_CONSUMPTION"] = 24] = "BENEFIT_CONSUMPTION";
    Module[Module["BENEFIT_LIST"] = 25] = "BENEFIT_LIST";
    Module[Module["BIRTHDAY_REPORT"] = 26] = "BIRTHDAY_REPORT";
    Module[Module["BENEFICIARY_AGE_REPORT"] = 27] = "BENEFICIARY_AGE_REPORT";
    Module[Module["BENEFIT_CONSUMPTION_LATAM_REPORT"] = 28] = "BENEFIT_CONSUMPTION_LATAM_REPORT";
    Module[Module["MONTHLY_RECEIVABLE_REPORT"] = 29] = "MONTHLY_RECEIVABLE_REPORT";
    Module[Module["SOLICITATION_LEFT_OVER_MONEY_REPORT"] = 30] = "SOLICITATION_LEFT_OVER_MONEY_REPORT";
    Module[Module["AWAY_WITHOUT_SOLICITATION"] = 31] = "AWAY_WITHOUT_SOLICITATION";
    Module[Module["AWAY_BY_CAM"] = 32] = "AWAY_BY_CAM";
    Module[Module["FINANCIAL"] = 33] = "FINANCIAL";
    Module[Module["FINANCIAL_STATEMENTS"] = 34] = "FINANCIAL_STATEMENTS";
})(Module || (Module = {}));
export var ScopeModule;
(function (ScopeModule) {
    ScopeModule["AIRCRAFT_MODEL"] = "Aircraft Model";
    ScopeModule["BANK"] = "Bank";
    ScopeModule["BENEFICIARY"] = "Beneficiary";
    ScopeModule["BENEFIT"] = "Benefit";
    ScopeModule["BENEFIT_CATEGORY"] = "Benefit Category";
    ScopeModule["BENEFIT_CONSUMPTION"] = "Benefit Consumption";
    ScopeModule["BENEFIT_CONSUMPTION_BATCH"] = "Benefit Consumption Batch";
    ScopeModule["BENEFIT_CONSUMPTION_MONTHLY_BALANCE"] = "Benefit Consumption Monthly Balance";
    ScopeModule["BENEFIT_CONSUMPTION_MONTHLY_BALANCE_MANUAL_CALCULATION"] = "Benefit Consumption Monthly Balance Manual Calculation";
    ScopeModule["COMPENSATION_RATE"] = "Compensation Rate";
    ScopeModule["DAILY_RATE"] = "Daily Rate";
    ScopeModule["DEPENDENT"] = "Dependent";
    ScopeModule["DESEASE"] = "Desease";
    ScopeModule["JOB_DESCRIPTION"] = "Job Description";
    ScopeModule["MONTHLY_BASE_PERCENTAGE"] = "Monthly Base Percentage";
    ScopeModule["OPERATION_BASE"] = "Operation Base";
    ScopeModule["PLAN"] = "Plan";
    ScopeModule["PREMIUM_RECIPIENT"] = "Premium Recipient";
    ScopeModule["RECOMPOSITION_INEQUALITY"] = "Recomposition Inequality";
    ScopeModule["REPORT"] = "Report";
    ScopeModule["SOLICITATION"] = "Solicitation";
    ScopeModule["SOLICITATION_DOCUMENT"] = "Solicitation Document";
    ScopeModule["SOLICITATION_NOTE"] = "Solicitation Note";
    ScopeModule["SOLICITATION_PAYMENT_FLOW"] = "Solicitation Payment Flow";
    ScopeModule["SOLICITATION_REVISION"] = "Solicitation Revision";
    ScopeModule["SOLICITATION_LEFT_OVER_MONEY"] = "Solicitation Left Over Money";
    ScopeModule["USER"] = "User";
    ScopeModule["AWAY_BY_CAM"] = "Away By Cam";
    ScopeModule["AWAY_WITHOUT_SOLICITATION"] = "Away Without Solicitation";
    ScopeModule["FINANCIAL"] = "Financial";
    ScopeModule["FINANCIAL_STATEMENT"] = "Financial Statement";
    ScopeModule["ALERTS"] = "Alerts";
    ScopeModule["AWAY_REASON"] = "Away Reason";
})(ScopeModule || (ScopeModule = {}));
