import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { RECOMPOSITION_INEQUALITY_SOLICITATION_QUERY_KEY } from 'hooks/recompositionInequality/useRecompositionInequalityConfig';
import { SOLICITATION_APPROVAL_KEY } from 'hooks/solicitationApproval/useSolicitationApprovalConfig';
import { SOLICITATION_REVISION_LIST_KEY } from 'hooks/solicitationRevision/useSolicitationRevisionConfig';
import { makeHttpService } from 'services/http';
import { SOLICITATION_PAGINATION_QUERY_KEY, SOLICITATION_QUERY_KEY } from './useSolicitationConfig';
export const useSolicitationMutation = () => {
    const queryClient = useQueryClient();
    const httpService = makeHttpService();
    const createSolicitation = useCallback(async (solicitationData) => {
        return httpService.post('/solicitation', solicitationData);
    }, [httpService]);
    const createSolicitationState = useCallback(async (solicitationStateData) => {
        return httpService.post('/solicitation-state', solicitationStateData);
    }, [httpService]);
    const extendSolicitation = useCallback(async (payload) => {
        return httpService.post('/solicitation/extend', payload);
    }, [httpService]);
    const voteSolicitation = useCallback(async (solicitationStateApprovalVoteData) => {
        return httpService.post('/solicitation-state-approval/vote', solicitationStateApprovalVoteData);
    }, [httpService]);
    const { mutateAsync: extend, isLoading: isExtending } = useMutation(extendSolicitation, {
        onSuccess: () => {
            queryClient.invalidateQueries(SOLICITATION_PAGINATION_QUERY_KEY);
        }
    });
    const { mutateAsync: createState, isLoading: isCreatingSolicitationState } = useMutation(createSolicitationState, {
        onSuccess: () => {
            queryClient.invalidateQueries(SOLICITATION_REVISION_LIST_KEY);
            queryClient.invalidateQueries(SOLICITATION_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries(SOLICITATION_QUERY_KEY);
            queryClient.invalidateQueries(RECOMPOSITION_INEQUALITY_SOLICITATION_QUERY_KEY);
            queryClient.refetchQueries(RECOMPOSITION_INEQUALITY_SOLICITATION_QUERY_KEY);
        }
    });
    const { mutateAsync: create, isLoading: isCreating } = useMutation(createSolicitation, {
        onSuccess: () => {
            queryClient.invalidateQueries(SOLICITATION_PAGINATION_QUERY_KEY);
        }
    });
    const { mutateAsync: vote, isLoading: isVoting } = useMutation(voteSolicitation, {
        onSuccess: () => {
            queryClient.invalidateQueries(SOLICITATION_PAGINATION_QUERY_KEY);
            queryClient.invalidateQueries(SOLICITATION_QUERY_KEY);
            queryClient.invalidateQueries(SOLICITATION_APPROVAL_KEY);
        }
    });
    return {
        create,
        createState,
        extend,
        vote,
        isCreating,
        isCreatingSolicitationState,
        isExtending,
        isVoting
    };
};
