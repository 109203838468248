import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Loading, Card } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useDocumentTitle, useJobDescriptionQuery, useJobDescriptionMutation } from 'hooks';
import moment from 'moment-timezone';
import { JobDescriptionForm } from '../components';
const JobDescriptionInternal = () => {
    const jobDescriptionIdRoute = parseInt(useParams()?.jobDescriptionId ?? '0');
    const { success, errorCallback } = useAlert();
    const navigate = useNavigate();
    const [jobDescription, isLoading] = useJobDescriptionQuery(jobDescriptionIdRoute);
    const { create, update } = useJobDescriptionMutation(jobDescriptionIdRoute);
    async function submitJobDescription(jobDescriptionData) {
        const jobDescriptionDataSubmit = { ...jobDescriptionData };
        jobDescriptionDataSubmit.CreatedAt = moment().format();
        jobDescriptionDataSubmit.UpdatedAt = null;
        if (jobDescriptionData.Id) {
            return update(jobDescriptionDataSubmit, {
                onSuccess: () => success({
                    title: 'Cargo atualizado!',
                    message: 'Dados do cargo atualizados com sucesso.',
                    id: 'JOB_DESCRIPTION_PUT_SUCCESS'
                }),
                onError: err => errorCallback(err, 'JOB_DESCRIPTION_PUT_FAILURE')
            });
        }
        else {
            return create(jobDescriptionDataSubmit, {
                onSuccess: res => {
                    success({
                        title: 'Cargo cadastrado!',
                        message: 'Cargo cadastrado com sucesso.',
                        id: 'JOB_DESCRIPTION_POST_SUCCESS'
                    });
                    navigate(`/cam/job-descriptions/${res.Id}`);
                },
                onError: err => errorCallback(err, 'JOB_DESCRIPTION_POST_FAILURE')
            });
        }
    }
    const pageTitle = useMemo(() => `${jobDescriptionIdRoute ? 'Visualizar' : 'Novo'} Cargo`, [jobDescriptionIdRoute]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Cargos',
            Url: '/cam/job-descriptions'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Cargos", breadcrumb: breadcrumb }),
        React.createElement(Loading, { show: isLoading }),
        React.createElement(Card, { id: "job-description-card" },
            React.createElement(JobDescriptionForm, { jobDescription: jobDescription, isLoading: isLoading, handleSubmit: submitJobDescription }))));
};
export default JobDescriptionInternal;
