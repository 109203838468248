import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { RECOMPOSITION_INEQUALITY_QUERY_KEY } from './useRecompositionInequalityConfig';
export const useRecompositionInequalityQuery = (recompositionInequalityId) => {
    const { errorCallback } = useAlert();
    const navigate = useNavigate();
    const getRecompositionInequality = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/recomposition-inequality/${recompositionInequalityId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'MONTHLY_BASE_PERCENTAGE_GET_FAILURE');
            navigate('/cam/recomposition-inequalities');
            throw error;
        });
    }, [recompositionInequalityId, errorCallback, navigate]);
    const { data, isLoading } = useQuery([RECOMPOSITION_INEQUALITY_QUERY_KEY, recompositionInequalityId], getRecompositionInequality, {
        enabled: !!recompositionInequalityId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
