import React, { memo, isValidElement, useMemo } from 'react';
import clsx from 'clsx';
import { Icon } from 'components/Icon';
var AlertBoxType;
(function (AlertBoxType) {
    AlertBoxType["INFO"] = "info";
    AlertBoxType["WARNING"] = "warning";
    AlertBoxType["SUCCESS"] = "success";
    AlertBoxType["ERROR"] = "error";
})(AlertBoxType || (AlertBoxType = {}));
const AlertBox = memo(({ type = 'info', description, className, classNameBody, button }) => {
    const alertTypeContainerClasses = useMemo(() => {
        const alertTypeClassesMapper = {
            [AlertBoxType.INFO]: 'bg-blue-light',
            [AlertBoxType.ERROR]: 'bg-red-light',
            [AlertBoxType.SUCCESS]: 'bg-green-light',
            [AlertBoxType.WARNING]: 'bg-orange-light'
        };
        return alertTypeClassesMapper[type];
    }, [type]);
    const alertTypeTextClasses = useMemo(() => {
        const alertTypeClassesMapper = {
            [AlertBoxType.INFO]: 'text-white',
            [AlertBoxType.ERROR]: 'text-white',
            [AlertBoxType.SUCCESS]: 'text-white',
            [AlertBoxType.WARNING]: 'text-white'
        };
        return alertTypeClassesMapper[type];
    }, [type]);
    return (React.createElement("div", { className: clsx(className && className, 'flex items-center p-4 rounded-lg', alertTypeContainerClasses) },
        React.createElement(Icon, { name: type, size: 24, color: "white" }),
        React.createElement("div", { className: clsx('ml-4 flex items-start justify-center flex-col w-full', classNameBody && classNameBody) },
            isValidElement(description) ? (description) : (React.createElement("p", { className: clsx('text-sm', alertTypeTextClasses) }, description)),
            isValidElement(button) ? button : null)));
});
export default AlertBox;
