import React, { useMemo } from 'react';
import { FormatterUtils } from 'utils';
const BeneficiaryHistoryItem = ({ beneficiaryModification }) => {
    const date = FormatterUtils.momentDate(beneficiaryModification.CreatedAt)?.format('DD [de] MMMM [de] YYYY');
    const [before, after] = useMemo(() => {
        const before = JSON.parse(beneficiaryModification.Before);
        const after = JSON.parse(beneficiaryModification.After);
        return [before, after];
    }, [beneficiaryModification]);
    return (React.createElement("li", { className: "mb-10 ml-4" },
        React.createElement("div", { className: "absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700" }),
        React.createElement("time", { className: "mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500" }, date),
        React.createElement("div", { className: "grid grid-cols-2 mt-4" },
            React.createElement("div", { className: "relative max-w-sm p-6 pt-12 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700" },
                React.createElement("span", { className: "absolute top-4 right-4 bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300" }, "antes"),
                React.createElement("div", { className: "flex justify-between" },
                    React.createElement("label", { className: "text-xs text-gray-light dark:text-gray-400" }, before?.Label ?? ''),
                    React.createElement("p", { className: "text-sm font-semibold text-gray dark:text-gray-50" }, before?.Value ?? ''))),
            React.createElement("div", { className: "relative max-w-sm p-6 pt-12 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700" },
                React.createElement("span", { className: "absolute top-4 right-4 bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300" }, "depois"),
                React.createElement("div", { className: "flex justify-between" },
                    React.createElement("label", { className: "text-xs text-gray-light dark:text-gray-400" }, after?.Label ?? ''),
                    React.createElement("p", { className: "text-sm font-semibold text-gray dark:text-gray-50" }, after?.Value ?? ''))))));
};
export default BeneficiaryHistoryItem;
