import React from 'react';
import { JobDescriptionInternal, JobDescriptionList } from './screens';
const JobDescriptionModule = [
    {
        path: '/cam/job-descriptions',
        element: React.createElement(JobDescriptionList, null)
    },
    {
        path: '/cam/job-descriptions/new',
        element: React.createElement(JobDescriptionInternal, null)
    },
    {
        path: '/cam/job-descriptions/:jobDescriptionId',
        element: React.createElement(JobDescriptionInternal, null)
    }
];
export { JobDescriptionModule };
