import React, { useCallback, useMemo } from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { PdfContent, PdfContentElements } from 'components';
import moment from 'moment-timezone';
import { FileType, SolicitationTreatmentMethod, SolicitationTreatmentMethodLabel } from 'types/enum';
import { FormatterUtils } from 'utils';
const styles = StyleSheet.create({
    signature: {
        borderTopWidth: 0.5,
        borderTopStyle: 'solid',
        borderTopColor: '#CCCCCC',
        paddingTop: 8
    }
});
const SolicitationDetailedReportContentPdf = ({ solicitation, solicitationDuration, solicitationDocuments, solicitationRevisions }) => {
    const solicitationPaymentFlow = useMemo(() => {
        if (!solicitation.SolicitationPaymentFlowHistory?.length)
            return;
        return solicitation.SolicitationPaymentFlowHistory[0];
    }, [solicitation]);
    const solicitationPaymentFlowInstallment = useMemo(() => {
        if (!solicitationPaymentFlow)
            return;
        if (!solicitationPaymentFlow.SolicitationPaymentFlowInstallments?.length)
            return;
        return solicitationPaymentFlow.SolicitationPaymentFlowInstallments[0];
    }, [solicitationPaymentFlow]);
    const solicitationPlanRange = useMemo(() => solicitationPaymentFlow?.Plan?.PlanRanges?.find(x => x.Id === solicitationPaymentFlowInstallment?.PlanRangeId), [solicitationPaymentFlow, solicitationPaymentFlowInstallment]);
    const hasDocumentAttached = useCallback((documentType) => {
        if (!solicitationDocuments?.length)
            return false;
        return solicitationDocuments.some(x => x.FileType === documentType);
    }, [solicitationDocuments]);
    const getDocumentLabel = useCallback((documentType) => {
        return hasDocumentAttached(documentType) ? 'Sim' : 'Não';
    }, [hasDocumentAttached]);
    const getDeseaseDaysOff = useCallback((desease) => {
        return solicitation.SolicitationTreatmentMethod === SolicitationTreatmentMethod.CONSERVATIVE
            ? desease.DaysOffConservative
            : desease.DaysOffSurgical;
    }, [solicitation]);
    const solicitationMainDesease = useMemo(() => solicitation.SolicitationDeseases.reduce((prev, curr) => getDeseaseDaysOff(prev.Desease) > getDeseaseDaysOff(curr.Desease) ? prev : curr), [solicitation.SolicitationDeseases, getDeseaseDaysOff]);
    const solicitationOtherDeseasesLabel = useMemo(() => solicitation.SolicitationDeseases.filter(x => x.Id !== solicitationMainDesease.Id)
        .map(x => x.Desease?.Code)
        .join(','), [solicitation.SolicitationDeseases, solicitationMainDesease]);
    const dateNow = useMemo(() => moment().format('D [de] MMMM [de] YYYY'), []);
    const footerLabel = useMemo(() => `São Paulo, ${moment().format('D [de] MMMM [de] YYYY')}`, []);
    const SolicitationResumeCard = (React.createElement(React.Fragment, null,
        React.createElement(PdfContentElements.Grid, null,
            React.createElement(PdfContentElements.GridItem, { size: 6, marginBottom: 4 },
                React.createElement(PdfContentElements.TextBold, null, "\u00C0 Junta Fiscalizadora do CAM - JFC")),
            React.createElement(PdfContentElements.GridItem, { size: 6, marginBottom: 4 },
                React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
                    React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Guerra"),
                    React.createElement(PdfContentElements.TextBase, null, solicitation.Beneficiary.CallName)),
                React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
                    React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Fun\u00E7\u00E3o"),
                    React.createElement(PdfContentElements.TextBase, null, solicitation.Beneficiary.JobDescription?.Name)),
                React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
                    React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "S\u00F3cio Desde"),
                    React.createElement(PdfContentElements.TextBase, null, FormatterUtils.date(solicitation.Beneficiary.AssociationStartDate))))),
        React.createElement(PdfContentElements.View, { marginBottom: 12 },
            React.createElement(PdfContentElements.TextBold, null, "Att. Sr. Presidente"),
            React.createElement(PdfContentElements.TextBase, null,
                "Eu, ",
                React.createElement(PdfContentElements.TextBold, null, solicitation.Beneficiary.Name),
                ", tendo pleno conhecimento dos Estatutos Sociais da Associa\u00E7\u00E3o dos Tripulantes da LATAM, venho atrav\u00E9s deste requerer os benef\u00EDcios do Processo CAM conforme o quadro abaixo:")),
        React.createElement(PdfContentElements.Box, null,
            React.createElement(PdfContentElements.Grid, null,
                React.createElement(PdfContentElements.GridItem, { size: 4, marginBottom: 4 },
                    React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Base para C\u00E1lculo"),
                    React.createElement(PdfContentElements.TextBase, null,
                        solicitationPlanRange?.DailyAmount,
                        " di\u00E1rias")),
                React.createElement(PdfContentElements.GridItem, { size: 4, marginBottom: 4 },
                    React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Valor da Di\u00E1ria"),
                    React.createElement(PdfContentElements.TextBase, null, FormatterUtils.money(solicitationPaymentFlowInstallment.DailyRateValue))),
                React.createElement(PdfContentElements.GridItem, { size: 4, marginBottom: 4 },
                    React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Valor para C\u00E1lculo"),
                    React.createElement(PdfContentElements.TextBase, null, FormatterUtils.money(solicitationPaymentFlowInstallment.DailyRateCalculatedValue))),
                React.createElement(PdfContentElements.GridItem, { size: 4, marginBottom: 4 },
                    React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Tipo de Tratamento"),
                    React.createElement(PdfContentElements.TextBase, null, SolicitationTreatmentMethodLabel[solicitation.SolicitationTreatmentMethod])),
                React.createElement(PdfContentElements.GridItem, { size: 4, marginBottom: 4 },
                    React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Acidente de Trabalho"),
                    React.createElement(PdfContentElements.TextBase, null, solicitation.WorkAccident ? 'Sim' : 'Não'))))));
    const SolicitationDocumentCard = (React.createElement(PdfContentElements.Box, { dark: true, marginTop: 12 },
        React.createElement(PdfContentElements.View, { marginBottom: 8 },
            React.createElement(PdfContentElements.TextBold, null, "Documentos")),
        React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
            React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Atestado"),
            React.createElement(PdfContentElements.TextBase, null, getDocumentLabel(FileType.MEDICAL_CERTIFICATE))),
        React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
            React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Carta da LATAM Encaminhando ao INSS"),
            React.createElement(PdfContentElements.TextBase, null, getDocumentLabel(FileType.LATAM_INSS_LETTER))),
        React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
            React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Carta M\u00E9dica da LATAM"),
            React.createElement(PdfContentElements.TextBase, null, getDocumentLabel(FileType.LATAM_MEDICAL_CERTIFICATE)))));
    const SolicitationDateCard = (React.createElement(PdfContentElements.Box, { marginTop: 12 },
        React.createElement(PdfContentElements.View, { marginBottom: 8 },
            React.createElement(PdfContentElements.TextBold, null, "Datas")),
        React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
            React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Afastamento da Escala"),
            React.createElement(PdfContentElements.TextBase, null, FormatterUtils.date(solicitation.ScheduleWithdraw.toString()))),
        React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
            React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Data do INSS"),
            React.createElement(PdfContentElements.TextBase, null, FormatterUtils.date(solicitation.INSSDate.toString())))));
    const SolicitationInformationCard = (React.createElement(PdfContentElements.Box, { dark: true, marginTop: 12 },
        React.createElement(PdfContentElements.View, { marginBottom: 8 },
            React.createElement(PdfContentElements.TextBold, null, "Informa\u00E7\u00F5es")),
        React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
            React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "C\u00F3digo CID"),
            React.createElement(PdfContentElements.TextBase, null, solicitationMainDesease.Desease.Code)),
        solicitationOtherDeseasesLabel && (React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
            React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Outro CID"),
            React.createElement(PdfContentElements.TextBase, null, solicitationOtherDeseasesLabel))),
        React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
            React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Prazo pelo Processo CAM"),
            React.createElement(PdfContentElements.TextBase, null,
                solicitationDuration.TotalDurationDays,
                " dias")),
        React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
            React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Saldo de dias para uso do benef\u00EDcio"),
            React.createElement(PdfContentElements.TextBase, null,
                solicitationDuration.RemainingDays,
                " dias")),
        React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginBottom: 4 },
            React.createElement(PdfContentElements.TextBold, { fontSize: 9 }, "Fim do 1\u00BA per\u00EDodo do CID"),
            React.createElement(PdfContentElements.TextBase, null, solicitation.EndDate ? FormatterUtils.date(solicitation.EndDate) : 'Em andamento'))));
    const SolicitationRevisionCard = solicitationRevisions.length ? (React.createElement(React.Fragment, null,
        React.createElement(PdfContentElements.View, { marginTop: 16 },
            React.createElement(PdfContentElements.TextBase, null, "A concess\u00E3o do benef\u00EDcio fica a crit\u00E9rio da JFC respeitando o per\u00EDodo m\u00E1ximo estabelecido no Processo CAM.")),
        React.createElement(PdfContentElements.Box, { marginTop: 16 },
            React.createElement(PdfContentElements.View, { marginBottom: 8 },
                React.createElement(PdfContentElements.TextBold, null, "Avalia\u00E7\u00F5es do JFC")),
            solicitationRevisions.map((solicitationRevision, i) => (React.createElement(PdfContentElements.Flex, { key: solicitationRevision.Id.toString(), justifyContent: "space-between", marginBottom: 4 },
                React.createElement(PdfContentElements.TextBold, { fontSize: 9 },
                    i + 1,
                    "\u00AA Avalia\u00E7\u00E3o"),
                React.createElement(PdfContentElements.TextBase, null, FormatterUtils.date(solicitationRevision.StartDate.toString())))))))) : null;
    const SignatureFooter = (React.createElement(React.Fragment, null,
        React.createElement(PdfContentElements.View, { marginBottom: 16 },
            React.createElement(PdfContentElements.TextBase, null, footerLabel)),
        React.createElement(PdfContentElements.Flex, { justifyContent: "space-between", marginTop: 40 },
            React.createElement(View, { style: styles.signature },
                React.createElement(PdfContentElements.View, { marginTop: 4 },
                    React.createElement(PdfContentElements.TextBase, null, "Junta Fiscalizadora do Processo CAM"))),
            React.createElement(View, { style: styles.signature },
                React.createElement(PdfContentElements.View, { marginTop: 4 },
                    React.createElement(PdfContentElements.TextBase, null, "Assinatura do Requerente"))))));
    const SolicitationDetailedReportBody = (React.createElement(React.Fragment, null,
        SolicitationResumeCard,
        SolicitationDocumentCard,
        SolicitationDateCard,
        SolicitationInformationCard,
        SolicitationRevisionCard,
        SignatureFooter));
    return (React.createElement(PdfContent, { title: "Processo CAM - Conv\u00EAnio de Aux\u00EDlio M\u00FAtuo", subtitle: `Requerimento ${solicitation.RegistrationCode}`, caption: dateNow, body: SolicitationDetailedReportBody }));
};
export default SolicitationDetailedReportContentPdf;
