import React from 'react';
import { BeneficiariesInternal, BeneficiariesList } from './screens';
const BeneficiariesModule = [
    {
        path: '/beneficiaries',
        element: React.createElement(BeneficiariesList, null)
    },
    {
        path: '/beneficiaries/new',
        element: React.createElement(BeneficiariesInternal, null)
    },
    {
        path: '/beneficiaries/:beneficiaryId',
        element: React.createElement(BeneficiariesInternal, null)
    }
];
export { BeneficiariesModule };
