import { memo } from 'react';
import { iconComponents } from './iconComponents';
const Icon = memo(({ name, size = 30, color = 'currentcolor', className, ...props }) => {
    const Icon = iconComponents[name];
    if (!Icon)
        return null;
    return Icon({
        fill: color,
        stroke: color,
        width: size,
        height: size,
        className,
        ...props
    });
});
export default Icon;
