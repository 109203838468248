import React from 'react';
import { StyleSheet, Text } from '@react-pdf/renderer';
const defaultFontSize = 10;
const styles = StyleSheet.create({
    base: {
        fontFamily: 'OpenSans',
        fontWeight: 400,
        color: '#363636',
        marginBottom: 0
    },
    medium: {
        fontWeight: 500
    },
    bold: {
        fontWeight: 600
    }
});
export const TextBase = ({ color = '#363636', fontSize = defaultFontSize, style, children }) => React.createElement(Text, { style: { ...styles.base, color, fontSize, ...(style ?? {}) } }, children);
export const TextMedium = ({ color = '#363636', fontSize = defaultFontSize, style, children }) => React.createElement(Text, { style: { ...styles.base, ...styles.medium, color, fontSize, ...(style ?? {}) } }, children);
export const TextBold = ({ color = '#363636', fontSize = defaultFontSize, style, children }) => React.createElement(Text, { style: { ...styles.base, ...styles.bold, color, fontSize, ...(style ?? {}) } }, children);
