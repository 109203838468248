import React from 'react';
import { BankInternal, BankList } from './screens';
const BankModule = [
    {
        path: '/operational/banks',
        element: React.createElement(BankList, null)
    },
    {
        path: '/operational/banks/new',
        element: React.createElement(BankInternal, null)
    },
    {
        path: '/operational/banks/:bankId',
        element: React.createElement(BankInternal, null)
    }
];
export { BankModule };
