import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthLayout, Icon } from 'components';
import { useAlert } from 'contexts/alert.context';
import hash from 'crypto-js/sha256';
import { useDocumentTitle } from 'hooks';
import { RecoverPasswordForm, ForgotPasswordSentCallback, ForgotPasswordRequestForm } from 'modules/ForgotPassword/components';
import queryString from 'query-string';
import { makeHttpService } from 'services';
import { AuthModule } from 'types/enum';
import './ForgotPassword.scss';
const ForgotPassword = () => {
    const location = useLocation();
    const queryStrings = queryString.parse(location.search);
    const navigate = useNavigate();
    const { success, errorCallback } = useAlert();
    const [token, setToken] = useState();
    const [isValidating, setIsValidating] = useState(false);
    const [isRequestSent, setIsRequestSent] = useState(false);
    const httpService = makeHttpService();
    const handleSolicitationRecoverPassword = async (email) => {
        await httpService
            .get(`/user/request-recover-password?username=${email}`)
            .then(() => {
            success({
                title: 'SUCESSO!',
                message: 'Solicitação de redefinição de senha enviado com sucesso.',
                id: 'RECOVER_PASSWORD_SOLICITATION_SUCCESS'
            });
            setIsRequestSent(true);
        })
            .catch(error => errorCallback(error, 'RECOVER_PASSWORD_SOLICITATION_ERROR'));
    };
    const handleRecoverPassword = async (values) => {
        const recoverPasswordData = {
            Token: token,
            NewPassword: hash(values.Password).toString(),
            ConfirmPassword: hash(values.ConfirmPassword).toString()
        };
        await httpService
            .post('/user/change-recover-password', recoverPasswordData)
            .then(() => {
            success({ title: 'SUCESSO!', message: 'Senha alterada com sucesso!', id: 'RECOVER_PASSWORD_SUCCESS' });
            navigate('/login');
        })
            .catch(error => errorCallback(error, 'RECOVER_PASSWORD_ERROR'));
    };
    const handleValidateForgotPasswordToken = useCallback(async (token) => {
        setIsValidating(true);
        await httpService
            .get(`/user/validate-recover-password-token?recoverPasswordToken=${token}`)
            .then(() => setToken(token))
            .catch(error => errorCallback(error, 'VALIDATE_PASSWORD_ERROR'));
        setIsValidating(false);
    }, []);
    const onInitForgotPassword = useCallback(() => {
        if (!queryStrings?.t)
            return;
        handleValidateForgotPasswordToken(queryStrings.t);
    }, []);
    useEffect(onInitForgotPassword, [onInitForgotPassword]);
    useDocumentTitle('Recuperar senha');
    return (React.createElement(AuthLayout, { position: "right", type: AuthModule.RECOVER_PASSWORD },
        React.createElement("div", { className: "recover-password-container" },
            React.createElement("h3", { className: "auth-title" }, "Recuperar Senha"),
            isValidating ? (React.createElement(React.Fragment, null,
                React.createElement("p", { className: "auth-description" }, "Aguarde enquanto validamos a sua requisi\u00E7\u00E3o..."),
                React.createElement(Icon, { name: "spinner", size: 40 }))) : !token && !isRequestSent ? (React.createElement(ForgotPasswordRequestForm, { handleSubmit: handleSolicitationRecoverPassword })) : isRequestSent ? (React.createElement(ForgotPasswordSentCallback, null)) : (React.createElement(RecoverPasswordForm, { onSubmit: handleRecoverPassword })))));
};
export default ForgotPassword;
