import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { BENEFICIARY_TYPE_QUERY_KEY } from './useBeneficiaryTypeConfig';
export const useBeneficiaryTypeQuery = (beneficiaryTypeId) => {
    const { errorCallback } = useAlert();
    const getBeneficiaryType = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/beneficiary-type/${beneficiaryTypeId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'BENEFICIARY_TYPE_GET_FAILURE');
            throw error;
        });
    }, [errorCallback, beneficiaryTypeId]);
    const { data, isLoading } = useQuery([BENEFICIARY_TYPE_QUERY_KEY, beneficiaryTypeId], getBeneficiaryType, {
        enabled: !!beneficiaryTypeId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    return [data, isLoading];
};
