import React from 'react';
import { RecompositionInequalityCreation, RecompositionInequalityList, RecompositionInequalityView } from './screens';
const RecompositionInequalityModule = [
    {
        path: '/cam/recomposition-inequalities',
        element: React.createElement(RecompositionInequalityList, null)
    },
    {
        path: '/cam/recomposition-inequalities/new',
        element: React.createElement(RecompositionInequalityCreation, null)
    },
    {
        path: '/cam/recomposition-inequalities/:recompositionInequalityId',
        element: React.createElement(RecompositionInequalityView, null)
    }
];
export { RecompositionInequalityModule };
