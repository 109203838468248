import moment from 'moment-timezone';
import { Validation } from 'utils';
export const SolicitationCreationFormSchema = Validation.object().shape({
    Beneficiary: Validation.object().required('Informe o associado').nullable(),
    Deseases: Validation.array().min(1, 'Informe o anexo 10'),
    SolicitationTreatmentMethod: Validation.number().required('Informe o método de tratamento').nullable(),
    StartDate: Validation.string().required('Informe a data de início').date('Informe uma data válida'),
    ScheduleWithdraw: Validation.string()
        .required('Informe a data de afastamento')
        .date('Informe uma data válida')
        .dateBefore('Informe uma data válida', moment()),
    INSSDate: Validation.string().required('Informe a data do INSS').date('Informe uma data válida'),
    SolicitationPaymentFlowInstallments: Validation.array()
        .of(Validation.number())
        .required('Configure o fluxo de pagamento')
        .min(1, 'Configure o fluxo de pagamento')
});
