import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
export const useScopeList = (fetchData = true) => {
    const { errorCallback } = useAlert();
    const getScopes = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get('/scope/all', {
            signal
        })
            .catch(error => {
            errorCallback(error, 'SCOPE_GET_ALL_FAILURE');
            return [];
        });
    }, [errorCallback]);
    const { data, isLoading } = useQuery(['scopes'], getScopes, {
        enabled: fetchData,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    const memoizedData = useMemo(() => data ?? [], [data]);
    return [memoizedData, isLoading];
};
