import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Loading, Card } from 'components';
import { useAlert } from 'contexts/alert.context';
import { useDocumentTitle, useCompensationRateQuery, useCompensationRateMutation } from 'hooks';
import moment from 'moment-timezone';
import { CompensationRateForm } from '../components';
const CompensationRateInternal = () => {
    const compensationRateIdRoute = parseInt(useParams()?.compensationRateId ?? '0');
    const { success, errorCallback } = useAlert();
    const navigate = useNavigate();
    const [compensationRate, isLoading] = useCompensationRateQuery(compensationRateIdRoute);
    const { create, update } = useCompensationRateMutation(compensationRateIdRoute);
    async function submitCompensationRate(compensationRateData) {
        const compensationRateDataSubmit = { ...compensationRateData };
        compensationRateDataSubmit.CreatedAt = moment().format();
        compensationRateDataSubmit.UpdatedAt = null;
        if (compensationRateData.Id) {
            await update(compensationRateDataSubmit, {
                onSuccess: () => success({
                    title: 'Carência atualizada!',
                    message: 'Dados da carência atualizados com sucesso.',
                    id: 'COMPENSATION_RATE_PUT_SUCCESS'
                }),
                onError: err => errorCallback(err, 'COMPENSATION_RATE_PUT_FAILURE')
            });
        }
        else {
            await create(compensationRateDataSubmit, {
                onSuccess: res => {
                    success({
                        title: 'Carência cadastrada!',
                        message: 'Carência cadastrada com sucesso.',
                        id: 'COMPENSATION_RATE_POST_SUCCESS'
                    });
                    navigate(`/cam/compensation-rates/${res.Id}`);
                },
                onError: err => errorCallback(err, 'COMPENSATION_RATE_POST_FAILURE')
            });
        }
    }
    const pageTitle = useMemo(() => `${compensationRateIdRoute ? 'Visualizar' : 'Nova'} Carência`, [
        compensationRateIdRoute
    ]);
    const breadcrumb = useMemo(() => [
        {
            Title: 'Carência (Escalonamento)',
            Url: '/cam/compensation-rates'
        },
        {
            Title: pageTitle
        }
    ], [pageTitle]);
    useDocumentTitle(pageTitle);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: "Car\u00EAncia (Escalonamento)", breadcrumb: breadcrumb }),
        React.createElement(Loading, { show: isLoading }),
        React.createElement(Card, { id: "compensation-rate-card" },
            React.createElement(CompensationRateForm, { compensationRate: compensationRate, isLoading: isLoading, handleSubmit: submitCompensationRate }))));
};
export default CompensationRateInternal;
