import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { BENEFICIARY_TYPE_LIST_QUERY_KEY } from './useBeneficiaryTypeConfig';
export const useBeneficiaryTypeList = () => {
    const { errorCallback } = useAlert();
    const getBeneficiaryTypes = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get('/beneficiary-type/all', {
            signal
        })
            .catch(error => {
            errorCallback(error, 'BENEFICIARY_TYPE_GET_ALL_FAILURE');
            return [];
        });
    }, [errorCallback]);
    const { data, isLoading } = useQuery([BENEFICIARY_TYPE_LIST_QUERY_KEY], getBeneficiaryTypes, {
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    const memoizedData = useMemo(() => data ?? [], [data]);
    return [memoizedData, isLoading];
};
