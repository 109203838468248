import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'components';
import { useSolicitationInstallment } from 'hooks';
import SolicitationPaymentFlowForm from './SolicitationPaymentFlowForm';
const SolicitationPaymentFlowFormPresentation = ({ formValues, installmentValues, onSubmit }) => {
    const [showPaymentFlowModal, setShowPaymentFlowModal] = useState(false);
    const togglePaymentFlowModal = useCallback(() => setShowPaymentFlowModal(showPaymentFlowModal => !showPaymentFlowModal), []);
    const [installmentCount, setInstallmentCount] = useState(0);
    const [getSolicitationInstallmentCount, isLoadingSolicitationInstallmentCount] = useSolicitationInstallment();
    const onGetDaysOff = useCallback(async () => {
        if (!formValues.Beneficiary?.Id || !formValues.Deseases.length || !formValues.SolicitationTreatmentMethod)
            return;
        const installmentCountPostData = {
            BeneficiaryId: formValues.Beneficiary?.Id,
            Deseases: formValues.Deseases,
            SolicitationTreatmentMethod: formValues.SolicitationTreatmentMethod
        };
        const installmentCount = await getSolicitationInstallmentCount(installmentCountPostData);
        setInstallmentCount(installmentCount > 0 ? installmentCount : 0);
    }, [
        formValues.Beneficiary,
        formValues.Deseases,
        formValues.SolicitationTreatmentMethod,
        getSolicitationInstallmentCount
    ]);
    const onSubmitInstallment = useCallback((installmentValues) => {
        togglePaymentFlowModal();
        onSubmit(installmentValues);
    }, [togglePaymentFlowModal, onSubmit]);
    useEffect(() => {
        onGetDaysOff();
    }, [onGetDaysOff]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { text: "Configurar fluxo de pagamento", color: "info", animationHover: "pop", onClick: togglePaymentFlowModal, loading: isLoadingSolicitationInstallmentCount, disabled: !installmentCount }),
        React.createElement(Modal, { show: showPaymentFlowModal, handleClose: togglePaymentFlowModal, title: "Configurar fluxo de pagamento", closable: true },
            React.createElement(SolicitationPaymentFlowForm, { installmentValues: installmentValues, installmentNumber: installmentCount, onSubmit: onSubmitInstallment }))));
};
export default SolicitationPaymentFlowFormPresentation;
