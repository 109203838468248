import React, { useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { TextField, Button, FormField, FormFieldError, Form, SituationField, useFormikValidationHandler, FormikValidationHandler, FormFieldSeparator } from 'components';
import { FieldArray, useFormik } from 'formik';
import { useModulePermissions } from 'hooks';
import { convertToPlanDto } from './PlanForm.converter';
import { PlanFormSchema } from './PlanForm.schema';
import { PlanRangeItemForm } from './PlanRangeItemForm';
const defaultPlanRangeValue = {
    Id: 0,
    PlanId: 0,
    DailyAmount: 0,
    Value: 0
};
const defaultValues = {
    Id: 0,
    Name: '',
    PlanRanges: [defaultPlanRangeValue],
    Active: true
};
const PlanForm = ({ plan, isLoading, handleSubmit: handleSubmitPlan }) => {
    const [canValidate, setCanValidate] = useFormikValidationHandler();
    const { canMutate } = useModulePermissions(plan?.Id);
    const formikContext = useFormik({
        initialValues: defaultValues,
        validationSchema: () => PlanFormSchema,
        onSubmit: async (planData) => handleSubmitPlan(convertToPlanDto(planData)),
        validateOnChange: canValidate,
        enableReinitialize: true
    });
    const { values, setFieldValue, handleChange, handleSubmit, isSubmitting, setValues } = formikContext;
    const visiblePlanRanges = values.PlanRanges.filter(x => !x.IsDeleted);
    useEffect(() => {
        if (!plan?.Id)
            return;
        const planRanges = plan.PlanRanges?.length ? plan.PlanRanges.map(x => ({ ...x })) : [];
        const planData = {
            Id: plan.Id,
            Name: plan.Name,
            Active: plan.Active ?? true,
            PlanRanges: planRanges
        };
        setValues(planData);
    }, [plan, setValues]);
    if (isLoading)
        return null;
    return (React.createElement(Form, { name: "planForm", onSubmit: handleSubmit, disabled: !canMutate, formikContext: formikContext },
        React.createElement(FormikValidationHandler, { setCanValidate: setCanValidate }),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 12 },
                React.createElement(FormField, null,
                    React.createElement(TextField, { name: "Name", label: "Nome", value: values.Name, onChange: handleChange }),
                    React.createElement(FormFieldError, { name: "Name" })))),
        React.createElement(FormFieldSeparator, { text: "Configura\u00E7\u00F5es", className: "mt-3", icon: "info" }),
        React.createElement(FieldArray, { name: "PlanRanges", render: (arrayHelpers) => (React.createElement(React.Fragment, null,
                visiblePlanRanges.map((planRange, index) => (React.createElement(PlanRangeItemForm, { key: index, planRange: planRange, index: index }))),
                canMutate && (React.createElement(FormField, null,
                    React.createElement(Button, { icon: "add", color: "success", onClick: () => arrayHelpers.push(defaultPlanRangeValue), style: { marginTop: -5 }, rounded: true }))))) }),
        React.createElement(Row, null,
            React.createElement(Col, { xs: "content" },
                React.createElement(FormField, null,
                    React.createElement(SituationField, { label: "Situa\u00E7\u00E3o do plano", handleChange: isActive => setFieldValue('Active', isActive), value: values.Active, falseLabel: "Inativo", trueLabel: "Ativo" })))),
        canMutate && (React.createElement("div", { className: "flex justify-center md:justify-end mt-4" },
            React.createElement(Button, { size: "large", text: "Enviar", color: "success", type: "submit", loading: isSubmitting, animationHover: "pop" })))));
};
export default PlanForm;
