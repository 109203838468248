import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import logo from 'assets/images/logo.png';
import { styles as S } from './PdfContent.style';
const PdfContent = ({ title, caption, subtitle, body }) => {
    return (React.createElement(Document, null,
        React.createElement(Page, { size: "A4", style: S.page, break: true },
            React.createElement(View, { style: S.header },
                React.createElement(View, null,
                    React.createElement(Text, { style: S.caption }, caption),
                    React.createElement(Text, { style: S.title }, title),
                    React.isValidElement(subtitle) ? subtitle : React.createElement(Text, { style: S.subtitle }, subtitle)),
                React.createElement(Image, { src: logo, style: S.logo })),
            body)));
};
export default PdfContent;
