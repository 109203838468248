import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { TimeMillisecondsConstants } from 'configs';
import { useAlert } from 'contexts/alert.context';
import { makeHttpService } from 'services/http';
import { BENEFICIARY_BENEFIT_LIST_QUERY_KEY } from './useBeneficiaryBenefitConfig';
export const useBeneficiaryBenefitList = (beneficiaryId) => {
    const { errorCallback } = useAlert();
    const getBeneficiaryBenefits = useCallback(async ({ signal }) => {
        return makeHttpService()
            .get(`/beneficiary-benefit/beneficiary/${beneficiaryId}`, {
            signal
        })
            .catch(error => {
            errorCallback(error, 'BENEFICIARY_BENEFIT_GET_ALL_FAILURE');
            return [];
        });
    }, [errorCallback, beneficiaryId]);
    const { data, isLoading } = useQuery([BENEFICIARY_BENEFIT_LIST_QUERY_KEY, beneficiaryId], getBeneficiaryBenefits, {
        enabled: !!beneficiaryId,
        staleTime: TimeMillisecondsConstants.THIRTY_MINUTES
    });
    const memoizedData = useMemo(() => data ?? [], [data]);
    return [memoizedData, isLoading];
};
