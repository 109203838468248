import React from 'react';
import { animated, useTransition } from 'react-spring';
import './BaseFormFieldError.scss';
const BaseFormFieldError = ({ label, show }) => {
    const transition = useTransition(show, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    });
    return transition((styles, item) => item ? (React.createElement(animated.div, { className: "form-field-error", style: styles },
        React.createElement("label", { className: "field-error" }, label))) : null);
};
export default BaseFormFieldError;
