import React from 'react';
import { getIn, useFormikContext } from 'formik';
import BaseFormFieldError from './BaseFormFieldError';
const FormikFormFieldError = ({ name }) => {
    const { errors } = useFormikContext() || {};
    const errorMessage = getIn(errors, name);
    const showErrorMessage = !!(errorMessage && errorMessage.length);
    return React.createElement(BaseFormFieldError, { label: errorMessage, show: showErrorMessage });
};
export default FormikFormFieldError;
