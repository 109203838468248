import { Validation } from 'utils';
export const PremiumRecipientSchema = Validation.object().shape({
    Name: Validation.string().required('Informe o nome'),
    CPF: Validation.string().required('Informe o CPF').cpf('Informe um CPF válido'),
    Email: Validation.string().required('Informe o e-mail').email('Informe um e-mail válido'),
    CellPhone: Validation.string().required('Informe o celular'),
    PremiumPercentage: Validation.number()
        .required('Informe o percentual de premiação')
        .max(100, 'Informe um percentual válido')
});
