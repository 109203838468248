const isNullOrUndefined = (value) => value === null || value === undefined;
const isNotNullOrUndefined = (value) => !isNullOrUndefined(value);
const isEmptyObject = (value) => isNullOrUndefined(value) || Object.keys(value).length === 0;
const isNotEmptyObject = (value) => !isEmptyObject(value);
const isEmptyList = (value) => isNullOrUndefined(value) || !value?.length;
const isNotEmptyList = (value) => !isEmptyList(value);
export const sharedValidation = {
    isNullOrUndefined,
    isNotNullOrUndefined,
    isEmptyObject,
    isNotEmptyObject,
    isEmptyList,
    isNotEmptyList
};
