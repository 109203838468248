import { DateFormat } from 'types/enum';
import { FormatterUtils } from 'utils';
export const convertToAwayWithoutSolicitationDto = (values) => {
    return {
        ...values,
        Beneficiary: null,
        AwayStartDate: FormatterUtils.date(values.AwayStartDate, DateFormat.DATE_EN_US),
        AwayEndDate: values.AwayEndDate ? FormatterUtils.date(values.AwayEndDate, DateFormat.DATE_EN_US) : null,
        BeneficiaryId: values.Beneficiary.Id
    };
};
