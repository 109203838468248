import React, { createContext, useCallback, useMemo, useState } from 'react';
export const StepperFormContext = createContext({});
const StepperFormProvider = ({ initialIndex, items, children }) => {
    const [activeIndex, setActiveIndex] = useState(initialIndex);
    const activeItem = useMemo(() => items.find((_, i) => i === activeIndex), [items, activeIndex]);
    const onPressItem = useCallback((itemKey) => {
        const itemIndex = items.findIndex(x => x.key === itemKey);
        if (itemIndex !== -1)
            setActiveIndex(itemIndex);
    }, [items, setActiveIndex]);
    return (React.createElement(StepperFormContext.Provider, { value: {
            activeItem,
            onPressItem
        } }, children));
};
export default StepperFormProvider;
