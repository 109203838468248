import React, { useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { TabContent } from './TabContent';
import { TabHeader } from './TabHeader';
const Tabs = ({ items, initialKey }, ref) => {
    const [activeKey, setActiveKey] = useState(initialKey ?? items[0].key);
    const visibleItems = items.filter(x => x.visible !== false);
    const onChangeKey = useCallback((key) => setActiveKey(key), []);
    useImperativeHandle(ref, () => ({
        changeActiveKey: onChangeKey
    }));
    return (React.createElement(TabsPrimitive.Root, { value: activeKey, onValueChange: onChangeKey },
        React.createElement("div", { className: "relative bg-white dark:bg-slate mb-4" },
            React.createElement(TabsPrimitive.List, { className: "flex border-b border-solid border-gray-200 dark:border-gray-700 h-12" }, visibleItems.map(tabItem => (React.createElement(TabsPrimitive.Trigger, { key: tabItem.key, value: tabItem.key, disabled: tabItem.disabled, asChild: true },
                React.createElement(TabHeader, { title: tabItem.title, isDisabled: tabItem.disabled, isActive: tabItem.key === activeKey })))))),
        React.createElement("div", { className: "relative bg-white dark:bg-slate overflow-hidden py-4" }, visibleItems.map(tabItem => (React.createElement(TabsPrimitive.Content, { key: tabItem.key, value: tabItem.key, forceMount: true, asChild: true },
            React.createElement(TabContent, { isActive: tabItem.key === activeKey }, tabItem.content)))))));
};
const TabsComponent = forwardRef(Tabs);
TabsComponent.displayName = 'Tabs';
export default TabsComponent;
